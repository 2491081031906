import React from 'react';
import TagLabel from './TagLabel';

import TagsSearch from '../TagsSearch/TagsSearch';
import {toast} from 'react-toastify';
class TagsField extends React.Component{

  constructor(props){
    super(props);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.onSetTags = this.onSetTags.bind(this);
    this.onEscape = this.onEscape.bind(this);
    this.state={
      editing:false,
      tags:this.props.content
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.content!== this.props.content){
      this.setState({tags:this.props.content});
    }
  }
  onSaveChanges=()=>{
    let tagNames = this.state.tags.map(tag =>{
      return tag.name
    })
    this.props.onClickOut(tagNames);
  }

  onSetTags = (tags) =>{
     let tagsDict = tags.map((tag)=>{
        return {name:tag}
      })
    this.setState({tags:tagsDict});
  }
  handleDblClick = (e) =>{
    if (this.node.contains(e.target)){

      this.setState({
        editing:true,
      })
    }
    else if(this.state.editing!==false){
      if (this.state.tags.length>1000){
        toast.error('This field is limited to 1000 characters. Please trim your entry.')
        return
      }
      this.onSaveChanges();
      this.setState({editing:false})
    };
  }
  componentWillMount(){
    document.addEventListener('dblclick',this.handleDblClick,false);
  }
  componentWillUnmount(){
    document.removeEventListener('dblclick',this.handleDblClick,false);
  }

  onEscape = (e)=>{
    if (e.key ==='Escape'){
    this.setState({editing:false})}
  }
  render(){
    const  tags  = this.props.content;
    let tagsList = <div className = "helpText">Add Tags</div>;
      let tagsSearch;
      if (this.state.tags && this.state.tags.length>0){
        tagsSearch = this.state.tags.map((entity,i)=>{
          return entity.name});
      }
    if (tags && tags.length>0){

        tagsList = tags.map((entity,i)=>{
        return <TagLabel tag = {entity} key = {i}/>
      });
    }

    return (

      <div className='tag-info-field' ref = {node => this.node = node}>
        {this.props.nolabel?null:<label className='document-label noselect' >{this.props.contentType} </label>}
        {this.state.editing?

          <TagsSearch
            onSendTags = {this.onSetTags}
            tags = {tagsSearch}
            onEsc = {this.onEscape}
            />
          :

            <div className = 'tag-info wordbreak'>{tagsList}
            </div>
          }
    </div>)

  }
  }
export default TagsField;
