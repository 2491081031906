import React from 'react';
class RemoveIngredientAttributeButton extends React.Component{
    constructor(props){
        super();
        this.state = {
            show:false,
        }
    }
    showRemove = () =>{
        this.setState({show:true});
        this.props.onStartRemove();
    }
    cancelRemove = () =>{
        this.setState({show:false});
        this.props.onCancelRemove();
    }
    onRemoveAttribute = () =>{
        this.setState({show:false})
        this.props.removeProperties();
    }
    render(){
        let expanded;
        
        if (!this.state.show){
        return(
            <div>
                <div className = "recipe-add-ingredient" onClick = {this.showRemove}>- Remove Attribute</div>
                
            </div>
        )}
        return(
            <div>
                <div className = "recipe-add-ingredient" onClick = {this.showRemove}>- Remove Selected Attributes</div>
                <div className = "confirm-cancel-button-container">
                <div className = "recipe-edit-button-confirm"onClick = {this.onRemoveAttribute}>Confirm</div>
                <div className = "recipe-edit-button-cancel" onClick = {this.cancelRemove}>Cancel</div>
                </div>
                {this.state.error}
                
            </div>
        )
    }
}

export default RemoveIngredientAttributeButton