import React from 'react';
import './TagDictionaryEntry.css';
class TagDictionaryEntry extends React.Component{

  onClickLetter = (e)=>{
    this.props.setAlphabetLetter(this.props.letter)
  }

  render(){
    let firstLetter = this.props.letter[0];

    return(
      <div className = "dictionaryEntry" id={this.props.letter[0] ===
          this.props.activeLetter?"activeLetter":null}
          onClick = {this.onClickLetter}>{firstLetter}
      </div>
)
  }
}
export default TagDictionaryEntry;
