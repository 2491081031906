import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { withRouter } from 'react-router-dom'
import TagNavigator from '../Tags/TagNavigator';
import CookieWrapper from '../CookieWrapper/CookieWrapper';
import PageButtons from '../PageButtons/PageButtons';
import DeleteButton from '../Buttons/DeleteButton';
import ArchiveButton from '../Buttons/ArchiveButton';
import IngredientTagButton from './IngredientTagButton';
import SendDocumentToButton from '../Buttons/SendDocumentToButton';
import Search from '../Search/Search';
import IngredientNavRow from './IngredientNavRow';
import qs from 'qs';
import IngredientPreview from './IngredientPreview';
import ActiveTagsNavigator from '../Tags/ActiveTagsNavigator';
import ExcludedTagsNavigator from '../Tags/ExcludedTagsNavigator';
import { Information } from '../Icons/IconLibrary';
import CreateTagButton from '../Buttons/CreateTagButton';
import { toast } from 'react-toastify';
import Tutorial2Element from '../Layout/Help/Onboarding/Tutorial2Element';
import NewIngredientButton from './NewIngredientButton';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.onGetDocuments = this.onGetDocuments.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.onClearSelected = this.onClearSelected.bind(this);
    this.onLastSelected = this.onLastSelected.bind(this);
    this.onChangeTags = this.onChangeTags.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.tagNavigatorRef = React.createRef();
    this.searchStringGenerator = this.searchStringGenerator.bind(this);
    this.onGetNewTopTags = this.onGetNewTopTags.bind(this);
    this.onToggleDocPreview = this.onToggleDocPreview.bind(this);
    this.onCloseDocPreview = this.onCloseDocPreview.bind(this);
    this.replaceActiveDocuments = this.replaceActiveDocuments.bind(this);
    this.state = {
      docList: [],
      activeTags: [],
      excludedTags: [],
      activeDocuments: [],
      selectedDocument: null,
      showPreviewer: true,
      searchQuery: '',
      numResults: 0,
      status: 'Accepted',
      order_by: ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.onGetDocuments();
    }
  }
  onSelectDocument = (doc) => {
    this.setState(prevState => {
      let activeDocuments = prevState.activeDocuments;
      let selectedDoc = prevState.selectedDocument;
      let activeDocumentsIds = (activeDocuments.map((entity) => { return entity.id }));

      if (
        activeDocumentsIds.indexOf(doc.id) > -1
      ) {
        activeDocuments = activeDocuments.filter(docs => docs.id !== doc.id);
      }
      else {
        activeDocuments = [...activeDocuments, doc];

      }
      if (prevState.selectedDocument !== doc) {
        selectedDoc = doc;
      }
      return { activeDocuments: activeDocuments, selectedDocument: selectedDoc };
    }
    );
  }
  orderDocsByRecency = () => {
    const searchString = qs.parse(this.props.location.search.substr(1));
    if (searchString.order_by === 'date_created_desc') {
      this.searchStringGenerator('date_created_asc', 'order_by');
    } else {
      this.searchStringGenerator('date_created_desc', 'order_by')
    }
  }
  orderDocsByName = () => {
    const searchString = qs.parse(this.props.location.search.substr(1));
    if (searchString.order_by === 'name_asc') {
      this.searchStringGenerator('name_desc', 'order_by');
    } else {
      this.searchStringGenerator('name_asc', 'order_by')
    }
  }
  orderDocsByDate = () => {
    const searchString = qs.parse(this.props.location.search.substr(1));
    if (searchString.order_by === 'date_expiration_desc') {
      this.searchStringGenerator('date_expiration_asc', 'order_by');
    } else {
      this.searchStringGenerator('date_expiration_desc', 'order_by')
    }
  }

  onClearSelected = () => {
    this.setState({ activeDocuments: [], selectedDocument: null })
  }
  onSelectAll = () => {
    this.onClearSelected();
    this.setState({ activeDocuments: this.state.docList })
  }
  toggleTagFilterSwitch = () => {
    this.searchStringGenerator('', 'filterSwitch');
  }
  componentDidMount() {
    this.onGetDocuments();
  }
  onBackButtonEvent = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  }
  searchStringGenerator(input, type) {
    const searchString = qs.parse(this.props.location.search.substr(1));
    if (!searchString.page) {
      searchString.page = 1;
    }
    if (!searchString.per_page) {
      searchString.per_page = 20;
    }
    if (!searchString.status) {
      searchString.status = 'accepted';
    }
    if (searchString.q && searchString.q === '') {
      delete searchString.q;
    }
    switch (type) {
      case 'tags':
        if (input) {
          searchString.tag = input.join();
          searchString.page = 1;
        }
        else {
          delete searchString.tag
          searchString.page = 1;
        }
        break;
      case 'excludedTags':
        if (input) {
          searchString.exclude_tag = input.join();
          searchString.page = 1;
        }
        else {
          delete searchString.exclude_tag
          searchString.page = 1;
        }
        break;
      case 'pageSize':
        searchString.per_page = input;
        if (this.state.numResults / searchString.per_page < searchString.page) {
          searchString.page = Math.ceil(this.state.numResults / searchString.per_page);
        }
        break;
      case 'page':
        searchString.page = input;
        break;
      case 'search':
        searchString.q = input;
        searchString.page = 1;
        break;
      case 'clearDate':
        delete searchString.expires_after;
        delete searchString.expires_before;
        break;
      case 'afterDate':
        searchString.expires_after = input;
        break;
      case 'beforeDate':
        searchString.expires_before = input;
        break;
      case 'order_by':
        searchString.order_by = input;
        break;
      case 'orgName':
        searchString.organization_name = input; //{organization_name:input}
        break;
      case 'filterSwitch':
        if (searchString.filter_active_ingredient_tags) {
          delete searchString.filter_active_ingredient_tags;
        }
        else {
          searchString.filter_active_ingredient_tags = true;
        }
        break;
      default:
    }

    let base_url = qs.stringify(searchString, { indices: false });
    this.onSearchStringChangeHelper('?' + base_url)
    return new Promise((resolve, reject) => {
      resolve(this.props.history.push({
        search: base_url,
        state: this.state,
      })
      )
    })
  }
  onCloseDocPreview() {
    this.setState({ showPreviewer: false })
  }
  onToggleDocPreview() {
    let active = !this.state.showPreviewer;
    this.setState({ showPreviewer: active });
  }
  onGetNewTopTags = () => {
    this.tagNavigatorRef.current.onGetTags();
  }
  onSearchStringChangeHelper = (searchString) => {
    Request.authRequest(Routes.ingredients.index(searchString),
      'GET').then((response) => {
        if (response.status === 200) {
          response.json().then(body => {
            this.setState({
              docList: body.results,
              numResults: body.total_results,
              selectedDocument: null,
              activeDocuments: [],
            }
            )
          }
          )
        }
        else {
          response.json().then((error) => {
            toast.error(error.message);
          });
        }
      }).catch((error) => {
        toast.error(error.message);
      }
      )
  }

  onGetDocuments = () => {
    Request.authRequest(Routes.ingredients.index(this.props.location.search),
      'GET').then((response) => {
        if (response.status === 200) {
          response.json().then(body => {
            let newActiveDocuments = this.replaceActiveDocuments(body.results, this.state.activeDocuments);

            if (this.state.selectedDocument) {
              let docList = body.results.concat();
              let newSelectedDoc = docList.filter(docs => docs.id === this.state.selectedDocument.id);

              if (newSelectedDoc === []) {
                newSelectedDoc = null;
              }
              this.setState({
                docList: body.results,
                numResults: body.total_results,
                selectedDocument: newSelectedDoc[0],
                activeDocuments: newActiveDocuments
              })
              return
            }
            this.setState({
              docList: body.results,
              numResults: body.total_results,
              activeDocuments: newActiveDocuments,
              docListLoaded: true
            })
          })
        }

        else {
          response.json().then((error) => {
            toast.error(error.message);
          });
        }
      }).catch((error) => {
        toast.error(error.message);
      }
      )
  }
  replaceActiveDocuments(documentList, activeList) {
    let newActiveDocuments = [];
    let documentListIDs = documentList.map((doc) => {
      return doc.id
    })
    for (let i = 0; i < activeList.length; i++) {
      let index = documentListIDs.indexOf(activeList[i].id);
      if (index > -1) {
        newActiveDocuments.push(documentList[index]);
      }
    }
    return newActiveDocuments;
  }

  /* if the promise is rejected we need error handling*/

  onChangeTags = (tags) => {
    this.searchStringGenerator(tags, 'tags');
  }
  onChangeExcludedTags = (tags) => {
    this.searchStringGenerator(tags, 'excludedTags');
  }
  onChangePage = (pageNumber) => {
    this.onClearSelected();
    this.searchStringGenerator(pageNumber, 'page');
  }
  handleSearchRequest = (updated_string) => {
    this.searchStringGenerator(updated_string, 'search');
  }

  onChangePageSize = (e) => {
    this.searchStringGenerator(e.target.value, 'pageSize');
  }
  onLastSelected() {
    /* this function is empty to reuse the navigator component between the tags page and the document page.*/
    return
  }
  onGetPinnedTags = () => {
    this.tagNavigatorRef.current.onGetPinnedTags();
  }
  render() {
    let documentRowList = <div className="loaderContainer"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>

    if (this.state.docList.length === 0 && this.state.docListLoaded) {
      documentRowList = <div className="helpText">No Ingredients. Create a new ingredient above!</div>
    } else if (this.state.docList.length > 0) {
      documentRowList = this.state.docList.map((entity, i) => {
        return <IngredientNavRow ingredient={entity} key={i} onSelectDoc={this.onSelectDocument}
          activeDocuments={this.state.activeDocuments}
          onGetDocuments={this.onGetDocuments} />
      })
    }
    let selectedDocument = this.state.selectedDocument;
    let activeDocuments = this.state.activeDocuments;
    const searchString = qs.parse(this.props.location.search.substr(1));
    let perPage = 20;
    let page = 1;
    let query;
    if (searchString.q) {
      query = searchString.q;
    }
    if (searchString.per_page) {
      perPage = searchString.per_page;
    }
    if (searchString.page) {
      page = searchString.page
    }
    let filter_active_tags;
    let filter_active_ingredient_tags = false;
    if (searchString.filter_active_ingredient_tags) {
      filter_active_ingredient_tags = true;
    }
    let tutorial;
    if (searchString.tutorial) {
      tutorial = <Tutorial2Element />;
    }
    let activeTags = [];
    let excludedTags = [];
    if (searchString.tag) {
      activeTags = searchString.tag.split(',');
      if (!Array.isArray(activeTags)) {
        activeTags = [activeTags]
      }
    }
    if (searchString.exclude_tag) {
      excludedTags = searchString.exclude_tag.split(',');
      if (!Array.isArray(excludedTags)) {
        excludedTags = [excludedTags]
      }

    }
    let organization_name;
    if (searchString.organization_name) {
      organization_name = searchString.organization_name;
    }
    let expires_after;
    let expires_before
    if (searchString.expires_after) {
      expires_after = searchString.expires_after;
    }
    if (searchString.expires_before) {
      expires_before = searchString.expires_before;
    }
    return (
      <div className="columns">
        <div className="left-documents">
          <div className="left-documents-tags boxShadow">
            <div className="left-documents-tags-header noselect">Tags Bank
              <CreateTagButton onGetTags={this.onGetNewTopTags} />
            </div>
            <TagNavigator onLastSelected={this.onLastSelected}
              onChangeTags={this.onChangeTags}
              onChangeExcludedTags={this.onChangeExcludedTags}
              activeTags={activeTags}
              excludedTags={excludedTags}
              toggleSwitch={this.toggleTagFilterSwitch}
              isFiltered={filter_active_tags}
              isIngredientFiltered={filter_active_ingredient_tags}
              ref={this.tagNavigatorRef}
              showFilter={true}

            />
          </div>
          {tutorial}
        </div>
        <div className="middle-container">
          <div className="navigation-selectedTagsContainer">
            <ActiveTagsNavigator activeTags={activeTags} onChangeTags={this.onChangeTags}
              onLastSelected={this.onLastSelected}
              excludedTags={excludedTags}
              onChangeExcludedTags={this.onChangeExcludedTags}
              onGetPinnedTags={this.onGetPinnedTags} />
            <ExcludedTagsNavigator activeTags={activeTags}
              excludedTags={excludedTags}
              onChangeTags={this.onChangeTags}
              onChangeExcludedTags={this.onChangeExcludedTags}
              onLastSelected={this.onLastSelected}
              onGetPinnedTags={this.onGetPinnedTags} />
          </div>
          <div className="middle-documents boxShadow">
            <div className="middle-documentsTitle">
              <div>Ingredients</div>
              <div className="documents-SearchContainer">
                <Search id='documentSearch' sendSearch={this.handleSearchRequest} placeholder="Search for a document"
                  query={query} />


              </div>
              <NewIngredientButton />
            </div>
            <div className="navigation-header">

              <button className="button-information" onClick={this.onToggleDocPreview}>
                <img className='buttonIcon' src={Information} alt='Toggle show document preview' title='Toggle show document preview' />
              </button>
              <IngredientTagButton
                docs={activeDocuments} onGetDocuments={this.onGetDocuments} onGetTags={this.onGetNewTopTags} />

              <ArchiveButton ingredients
                docs={activeDocuments} onGetDocuments={this.onGetDocuments} onGetTags />
              <DeleteButton
                items={activeDocuments} onGetDocuments={this.onGetDocuments} onClear={this.onClearSelected}
                type="ingredients" />


            </div>


            <div className="results-container">
              <div className="results-numResults">
                {this.state.numResults} total results
                {activeDocuments.length > 0 ?
                  <div className="selectedDocText">
                    - {activeDocuments.length} Documents Selected
                    <button className="clearSelectedButton" onClick={this.onClearSelected}>Clear All</button>
                  </div>
                  :
                  <div className="selectedDocText" onClick={this.onSelectAll}>
                    - Select All
                  </div>
                }
              </div>
              <div className="resultsShown noselect">

                <div className="sortBy" onClick={this.orderDocsByRecency}> &nbsp; Sort By Recency</div>
                <select className="resultsShown-selector"
                  value={perPage}
                  onChange={this.onChangePageSize}>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select> Results Shown

              </div>
            </div>
            <div className="navigation-docs-table">
              <div className="navigation-docs-tableheader-name">Name
              </div>
              <div className="navigation-docs-tableheader-expires">Price</div>
              <div className="navigation-docs-tableheader-tags">Tags</div>
            </div>
            <div className="documents">
              {documentRowList}
            </div>
            <PageButtons page={page} perPage={perPage}
              numResults={this.state.numResults} onChangePage={this.onChangePage} />

          </div>
        </div>
        {this.state.showPreviewer !== false ?
          <div className="right-documents">
            <IngredientPreview doc={selectedDocument}
              onGetDocuments={this.onGetDocuments}
              onGetTags={this.onGetNewTopTags}
              onCloseDocPreview={this.onCloseDocPreview}
            />

          </div>
          : null
        }

      </div>
    )
  };
}
export default withRouter(CookieWrapper(Navigation));
