import React from 'react';

import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import RemindSignatureButton from './RemindSignatureButton';
import './Signatures.css';
import LoadingButton from '../../Buttons/LoadingButton';
class SignatureComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showConfirmReject:false,
      comment:'',
      loading:false,
    }
  }
  acceptSignatures = () =>{
    this.setState({loading:true});
    Request.authRequest(Routes.documents.sign(this.props.signature.id),
      'POST',JSON.stringify({status:'Signed'}),'application/json').then((response)=>{
    if (response.status===200){
    response.json().then(body=>{
      this.props.onGetDocuments();
      this.setState({loading:false});
        })
      }
      else {
        response.json().then((error) => {
          toast.error(error.message);
          this.setState({loading:false});
          if (error.errors){
            toast.error(error.errors[0]);
          }
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }

  rejectSignatures = () =>{
    this.setState({loading:true});
    Request.authRequest(Routes.documents.sign(this.props.signature.id),
      'POST',JSON.stringify({status:'Rejected',comment:this.state.comment}),'application/json').then((response)=>{
    if (response.status===200){
    response.json().then(body=>{
      this.setState({loading:false});
      this.props.onGetDocuments();
      this.hideConfirmReject();
        })
      }
      else {
        response.json().then((error) => {
          this.setState({loading:false});
          toast.error(error.message);
          this.hideConfirmReject();
          if (error.errors){
            toast.error(error.errors[0]);
          }
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }
  showConfirmReject=()=>{
    this.setState({showConfirmReject:true})
  }
  hideConfirmReject=()=>{
    this.setState({showConfirmReject:false, comment:''})
  }
  onKeyDown = (e) =>{
    if (e.key === 'Escape'){
      this.setState({showConfirmReject:false,comment:''})
    }
  }
  handleEnter=(e)=>{
    if (e.key ==='Enter'){
      this.rejectSignatures();
    }
  }
  onChangeComment = (e)=>{
    this.setState({
			comment: e.target.value,
		});
  }
  render(){
    let signature = this.props.signature;
    let statusDotColor;
    let status = "Pending";
    if (signature.status ==="Signed"){
      statusDotColor = {backgroundColor: "#18A54D"};
      status = "Signed"
    } else if (signature.status ==="Rejected"){
      statusDotColor = {backgroundColor:"#E65531"};
      status = "Rejected";
    }
    else {
      statusDotColor = {backgroundColor:"#FFB50B"}
    }
    let comment = "None";
    if (signature.comment){
      comment = signature.comment;
    }
    let reminderCounter;
    if (signature.reminder_counter && signature.reminder_counter>0){
      reminderCounter = <div className = "signature-reminderNumber">Reminded {signature.reminder_counter} times</div>
    }
    let buttons;
    if (this.props.currentUser){
      if (this.props.currentUser.email == this.props.signature.user_to_sign.email){
      if (this.props.signature.status === "Pending"){
      buttons = (<div>
      <div className = "signature-signatureButtons">
        <div
          className = "signatureSmallButton-primary">
          <LoadingButton onClick = {this.acceptSignatures}
	          text = 'Approve' isLoading = {this.state.loading} small/>
          </div>
        <div onClick = {this.showConfirmReject} className =
            "signatureSmallButton-secondary">Reject</div>
        </div>

        </div>
      )
      }
    } else if (this.props.signature.status ==="Pending"){
      buttons = <RemindSignatureButton signatures = {[signature]}/>;
    }

    }
    return(
      <div className = "signature-signatureComponent-outerContainer">
      <div className = "signature-signatureComponent">
      <div className = "signature-signatureInfo">
        <div>{signature.user_to_sign.first_name} {signature.user_to_sign.last_name}
        </div>
        <div>{signature.user_to_sign.email}
        </div>
                {reminderCounter}
      </div>
      <div className = "signature-componentRightSide">
      <div className = "signature-signatureStatus">
      <div className = "statusDot" style = {statusDotColor}></div>
        {status}
      </div>
      <div >

          {buttons}

        </div>
      </div>
    </div>
    {signature.status=='Rejected'?<div className = "signature-rejectedComment">Reason for rejection: {comment}</div>:null}
    {this.state.showConfirmReject?
      <div className = "signature-confirmReject">
        <textarea
          value={this.state.comment}
          type='text'
          className='signature-comment-field'
          onChange={this.onChangeComment}
          onKeyPress={this.handleEnter}
          onKeyDown = {this.onKeyDown}
          placeholder = "Enter an optional reason for rejecting this document."/>
        <div className = "signature-confirmRejectComponent">
        <div className = "signatureSmallButton-primary"

          ><LoadingButton onClick = {this.rejectSignatures}
          text = 'Confirm' isLoading = {this.state.loading} small/>
          </div>


        <div className = "signatureSmallButton-secondary"
          onClick = {this.hideConfirmReject}>Cancel</div>
        </div>
      </div>
    :null}
  </div>)
  }
}
export default SignatureComponent;
