import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import {Download} from '../Icons/IconLibrary';
import './Buttons.css';
import './DownloadButton.css';
import {toast} from 'react-toastify';
class DownloadButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showOptions:false
    }
  }
  showOptions = ()=>{
    this.setState({showOptions:true})
  }
  hideOptions = ()=>{
    this.setState({showOptions:false})
  }
  onDownloadOriginalFile = (e) => {
    if (this.props.docs.length >0)
		{
      for (let i =0 ; i < this.props.docs.length; i ++){
      Request.authRequest(Routes.documents.edit(this.props.docs[i].document.id),
											  'GET').then((response) => {
      if (response.status===200){
			response.json().then(body => {
        let url = body['document_service_information']['url'];
        if (!url){
    			toast.error(`You don't have access to download the original file or this file may be too large to be autoconverted.
              Please contact your administrator.`);
    		}
				window.open(url, body.name)
              });
            } else {
              response.json().then((error) => {
                toast.error(error.message);
              });
            }
        }).catch((error) => {
        toast.error(error.message);
        }
        )
    }}
    else(
      toast.error('Please select a document to download')
    )
	}
  onDownloadPDFFile = (e)=>{
    if (this.props.docs.length >0)
		{
      for (let i =0 ; i < this.props.docs.length; i ++){
        if (this.props.docs[i].document.preview_content_type=== 'application/pdf' &&
        this.props.docs[i].document_service_information.previewUrl){
          window.open(this.props.docs[i].document_service_information.previewUrl, this.props.docs[i].name)
        } else {
      Request.authRequest(Routes.documents.edit(this.props.docs[i].document.id),
											  'GET').then((response) => {
      if (response.status===200){
			response.json().then(body => {
        if (body.document.preview_content_type === 'application/pdf' && body.document_service_information.previewUrl){
          window.open(body['document_service_information']['previewUrl'], body.name);
        }
        else {
          let url = body['document_service_information']['url'];
          if (!url){
      			toast.error(`You don't have access to download the original file or this file may be too large to be autoconverted.
                Please contact your administrator.`);
      		}
				window.open(url, body.name)}
              });
            } else {
              response.json().then((error) => {
                toast.error(error.message);
              });
            }
        }).catch((error) => {
        toast.error(error.message);
        }
        )
    }
  }
    }
    else(
      toast.error('Please select a document to download')
    )
  }
  onCheckPDF = () =>{
    if (this.props.docs.length ===0) {
        toast.error('Please select a document to download')
        return
    }
    if (this.props.docs.length === 1){
      let doc = this.props.docs[0];
      if (doc.document.preview_content_type==='application/pdf'){
        this.showOptions();
        return
      } else {
            Request.authRequest(Routes.documents.edit(this.props.docs[0].document.id),
      											  'GET').then((response) => {
            if (response.status===200){
      			response.json().then(body => {
              if (body.document.preview_content_type=== 'application/pdf' &&
              body.document_service_information.previewUrl){
                this.showOptions();
                return
              }
      				window.open(body['document_service_information']['url'], body.name)
                    });
                  } else {
                    response.json().then((error) => {
                      toast.error(error.message);
                    });
                  }
              }).catch((error) => {
              toast.error(error.message);
              }
              )
    }
  }
    else {
      this.showOptions();
    }
  }
  render(){
    return (
      <div>
      <button className='headerButton noselect' id = "buttonDownload" onClick={this.onCheckPDF}>
          <img className='buttonIcon' src={Download} alt='Download currently selected items' title = 'Download currently selected items'
            id = "buttonDownload"/>

      </button>
      {this.state.showOptions?
        <div className = "download-OptionsContainer boxShadow">

          <div onClick = {this.onDownloadOriginalFile} className = "download-asOriginalButton">Download Original</div>
          <div onClick = {this.onDownloadPDFFile} className = "download-asPDFButton">Download as PDF (if available)</div>
          <div onClick = {this.hideOptions} className = "download-closeButton"> x</div>
        </div>
        :null}
      </div>
    )
  }
}
export default DownloadButton;
