import React from 'react';
class AddIngredientAttributeButton extends React.Component{
    constructor(props){
        super();
        this.state = {
            show:false,
            key:'',
            value:'',
            error:''
        }
    }
    toggleShow = () =>{
        this.setState({show:!this.state.show})
    }
    onChangeKey = (e)=>{
        this.setState({key:e.target.value, error:''})
    }
    onChangeValue = (e)=>{
        this.setState({value:e.target.value, error:''})
    }
    onAddAttribute = () =>{
        if (this.state.key == ''||this.state.value ==''){
            return this.setState({error:'Both fields are required'})
        }
        this.props.sendAttribute(this.state.key,this.state.value)
    }
    render(){
        let expanded;
        
        if (!this.state.show){
        return(
            <div>
                <div className = "recipe-add-ingredient" onClick = {this.toggleShow}>+ Add Attribute</div>
                
            </div>
        )}
        return(
            <div>
                <div>
                    <div>Name</div>
                    <input onChange = {this.onChangeKey} value = {this.state.key}/>
                </div>
                <div>
                    <div>Value</div>
                    <textarea onChange = {this.onChangeValue} value = {this.state.value}/>
                </div>
                <div className = "confirm-cancel-button-container">
                <div className = "recipe-edit-button-confirm"onClick = {this.onAddAttribute}>Add</div>
                <div className = "recipe-edit-button-cancel" onClick = {this.toggleShow}>Hide</div>
                </div>
                {this.state.error}
                
            </div>
        )
    }
}

export default AddIngredientAttributeButton