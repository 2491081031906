import React from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import IngredientRow from '../IngredientRow';
import InfoField from '../../Documents/InfoField';
import PriceInfoField from './PriceInfoField';
import DensityInfoField from './DensityInfoField';
import DocumentDateField from '../../Documents/DocumentDateField';
import DocumentNotesField from '../../Documents/DocumentNotesField';
import TagsField from '../../Documents/TagsField';
import DeleteButton from '../../Buttons/DeleteButton';
import TagCleaner from '../../../helpers/TagCleaner';
import IngredientAttributeInfoField from './IngredientAttributeInfoField';
import RemindDocumentAccept from '../../Documents/RemindDocumentAccept';
import SendDocumentToButton from '../../Buttons/SendDocumentToButton';
import ShortIngredientRow from './ShortIngredientRow';
import ShortLogsRow from '../../Logs/ShortLogsRow';
import SolidInfoField from './SolidInfoField';
import CookieWrapper from '../../CookieWrapper/CookieWrapper';
import SignatureButton from '../../Documents/Signatures/SignatureButton';
import IngredientVersionButton from './IngredientVersionButton';
import VersionHistoryReportButton from '../../Documents/VersionHistory/VersionHistoryReportButton';
import IngredientUsedIn from './IngredientUsedIn';
import AddIngredientAttributeButton from './AddIngredientAttributeButton';
import RemoveIngredientAttributeButton from './RemoveIngredientAttributeButton';
import RelatedTagDocuments from '../../RelatedTagDocuments/RelatedTagDocuments';
import DocumentUploadModalButton from '../../Documents/DocumentsUploadModalButton';
class IngredientViewer extends React.Component {
	constructor(props) {
		super(props);
		this.onSaveChanges = this.onSaveChanges.bind(this);
		this.onSaveTagsChanges = this.onSaveTagsChanges.bind(this);
		this.onSaveRelatedTagsChanges = this.onSaveRelatedTagsChanges.bind(this);
		this.onShowVersionHistory = this.onShowVersionHistory.bind(this);
		this.onShowLogs = this.onShowLogs.bind(this);
		this.onGetIngredient = this.onGetIngredient.bind(this);
		this.addPropertyToDelete = this.addPropertyToDelete.bind(this);
		this.removePropertyToDelete = this.removePropertyToDelete.bind(this);
		this.onStartRemove = this.onStartRemove.bind(this);
		this.onCancelRemove = this.onCancelRemove.bind(this);
		this.state = {
			error: '',
			ingredient: '',
			preview: false,
			logs: [],
			showVersionHistory: false,
			loaded: false,
			removeProperties: false,
			propertiesToRemove: []
		}
	}

	componentDidMount() {
		this.onGetIngredient();
		this.onGetLogs();
	}
	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.onGetIngredient();
		}
	}
	onCancelRemove = () => {
		this.setState({ propertiesToRemove: [], removeProperties: false });
	}
	removeProperties = () => {
		let ingredient = this.state.ingredient;
		let input_format = {
			name: ingredient.name,
			density: ingredient.f_density_kg_L,
			price: ingredient.f_price_kg,
			properties: ingredient.properties
		}
		for (let i = 0; i < this.state.propertiesToRemove.length; i++) {
			delete input_format.properties[this.state.propertiesToRemove[i][0]]
		}

		Request.authRequest(Routes.ingredients.edit(this.state.ingredient.id),
			'PUT', JSON.stringify(
				input_format
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetIngredient();
					toast.success('Successfully edited the ingredient')
					this.onGetLogs();
					this.setState({
						removeProperties: false, propertiesToRemove: []
					})
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
					console.log(error);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	toggleRemoveProperties = () => {
		this.setState({ removeProperties: !this.state.removeProperties })
	}
	addPropertyToDelete = (property) => {
		this.setState({
			propertiesToRemove:
				[...this.state.propertiesToRemove, property]
		})

	}
	removePropertyToDelete = (property) => {
		this.setState({
			propertiesToRemove:
				this.state.propertiesToRemove.filter(properties => properties != property)
		})
	}
	onGetLogs() {
		Request.authRequest(Routes.logs.ingredients(this.props.match.params.id), 'GET').then((response) => {
			response.json().then(body => {
				this.setState({ logs: body })
			})
		})
	}
	onGetIngredient() {
		Request.authRequest(Routes.ingredients.edit(this.props.match.params.id),
			'GET').then((response) => {

				if (response.status === 200) {
					response.json().then(body => {
						this.setState({ ingredient: body, loaded: true })
						this.onGetLogs();
					})
				}
				else {
					response.json().then((error) => {
						this.setState({ loaded: true })
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}
	changeProperty = (originalProperty, key, value) => {
		let ingredient = this.state.ingredient;
		let input_format = {
			name: ingredient.name,
			density: ingredient.f_density_kg_L,
			price: ingredient.f_price_kg,
			properties: ingredient.properties,
			notes: ingredient.notes,
			user_defined_id: ingredient.user_defined_id
		}
		if (originalProperty[0] != key) {
			delete input_format.properties[originalProperty[0]]
		}
		input_format.properties[key] = value
		Request.authRequest(Routes.ingredients.edit(this.state.ingredient.id),
			'PUT', JSON.stringify(
				input_format
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetIngredient();
					toast.success('Successfully edited the ingredient')
					this.onGetLogs();
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
					console.log(error);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	onSaveChanges(contentType, content) {
		let ingredient = this.state.ingredient;
		let input_format = {
			name: ingredient.name,
			density: ingredient.f_density_kg_L,
			price: ingredient.f_price_kg,
			properties: ingredient.properties,
			notes: ingredient.notes,
			user_defined_id: ingredient.user_defined_id
		}
		switch (contentType) {
			case 'ID':
				input_format.user_defined_id = content;
				break;
			case 'Name':
				input_format.name = content;
				break;
			case 'Price':
				input_format.price = content;
				break;
			case 'Density':
				input_format.density = content;
				break;
			case 'Notes':
				input_format.notes = content;
				break;
			default:

		}

		Request.authRequest(Routes.ingredients.edit(this.state.ingredient.id),
			'PUT', JSON.stringify(
				input_format
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetIngredient();
					toast.success('Successfully edited the ingredient')
					this.onGetLogs();
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
					console.log(error);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	addAttribute = (key, value) => {
		let ingredient = this.state.ingredient;
		let input_format = {
			name: ingredient.name,
			density: ingredient.f_density_kg_L,
			price: ingredient.f_price_kg,
			properties: ingredient.properties,
			notes: ingredient.notes,
			user_defined_id: ingredient.user_defined_id
		}
		input_format.properties[key.trim()] = value.trim()
		Request.authRequest(Routes.ingredients.edit(this.state.ingredient.id),
			'PUT', JSON.stringify(
				input_format
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetIngredient();
					toast.success('Successfully edited the ingredient')
					this.onGetLogs();
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
					console.log(error);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)

	}
	onSaveTagsChanges = (tagsArray) => {
		/* this string, unlike other tags operations is the whole tags list */
		let ingredient = this.state.ingredient;
		if (tagsArray.length === 0) {
			toast.error('Please enter tags. All documents must have at least one tag.')
			return
		}
		let trimmedTags = TagCleaner.parseTagArray(tagsArray);
		Request.authRequest(Routes.ingredients.tags(ingredient.id),
			'PUT', JSON.stringify({
				tag_names: trimmedTags,
			}), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetIngredient();
					this.onGetLogs();
					toast.success('Successfully applied tags!')
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}

	onSaveRelatedTagsChanges(tagsArray) {
		/* this string, unlike other tags operations is the whole tags list */
		let ingredient = this.state.ingredient;
		if (tagsArray.length === 0) {
			toast.error('Please enter tags. All documents must have at least one tag.')
			return
		}
		let trimmedTags = TagCleaner.parseTagArray(tagsArray);
		Request.authRequest(Routes.ingredients.relatedTags(ingredient.id),
			'PUT', JSON.stringify({
				tag_names: trimmedTags,
			}), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetIngredient();
					this.onGetLogs();
					toast.success('Successfully applied tags!')
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	onReturnNavigation = () => {
		this.props.history.push('/ingredients/')
	}
	onShowLogs = () => {
		this.setState({ showVersionHistory: false });
	}
	onShowVersionHistory = () => {
		this.setState({ showVersionHistory: true });
	}
	onStartRemove = () => {
		this.setState({ removeProperties: true })
	}
	navRecipe = () => {
		window.location.href = '/recipes/' + this.state.ingredient.produced_from_recipe_id
	}
	render() {
		let ingredient = this.state.ingredient;
		let properties;
		// below is antique for premade document code
		const doc = this.state.ingredient;
		let date_expiration;
		let versionHistory;
		let docLoader = <div className="loaderContainer"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
		let sharedWithList = (<div className="helpText">This document has not been sent to anyone</div>);
		let fromOrganization;
		let versionNumber;
		let status;
		let draft;
		let related_tags = [];
		let version_number;
		let is_solid = false;
		let used_in = <div className="helpText">This ingredient isn't used in any recipes.</div>
		if (this.state.ingredient) {
			related_tags = ingredient.related_tags;
			if (ingredient.density == 0) {
				is_solid = true;
			}
			properties = Object.entries(ingredient.properties);
			properties = properties.map((property, i) => {
				return <IngredientAttributeInfoField property={property}
					addPropertyToDelete={this.addPropertyToDelete} removePropertyToDelete={this.removePropertyToDelete}
					key={i} changeProperty={this.changeProperty} removeProperties={this.state.removeProperties} />
			});
			if (ingredient.used_in_recipes.length > 0) {
				used_in = ingredient.used_in_recipes.map((recipe, i) => {
					return (<IngredientUsedIn recipe={recipe} key={i} />)
				})
			}
			if (ingredient.version_number != null) {
				version_number = 'v' + ingredient.version_number;
				if (ingredient.is_current) {
					version_number = version_number + " - current"
				}
			}
		}
		let producedFromRecipe;
		let documents = <div className="helpText">No documents related to this ingredient</div>;
		if (doc) {
			if (!doc.date_expiration) {
				date_expiration = null;
			}
			else {
				date_expiration = doc.date_expiration.substring(0, 10);
			}
			if (doc.produced_from_recipe_name) {
				producedFromRecipe = <div className="helpText pointer" onClick={this.navRecipe}>
					Produced from recipe {doc.produced_from_recipe_name}</div>
			}
			if (doc.version_history_with_self.length > 1) {
				versionHistory = doc.version_history_with_self.map((entity, i) => {
					return <ShortIngredientRow ingredient={entity} key={i} current_ingredient={doc} />
				});
			}
			else {
				versionHistory = (<div className="helpText">No other versions</div>)
			}
			let statusWords = doc.status;
			if (doc.tags && doc.tags.length > 0) {
				let tag_names = doc.tags.map((entity, i) => {
					return entity.name;
				})
				if (tag_names.indexOf("Draft") > -1) {
					draft = "DRAFT";
					statusWords = "DRAFT";
				}
				if (tag_names.indexOf("Archive") > -1) {
					statusWords = "Archive";
				}
			}
			status = <div className="documentViewer-uneditableHelpText noselect">Status: {statusWords}</div>
		}
		let logsList;
		if (this.state.logs) {
			logsList = this.state.logs.map((entity, i) => {
				return <ShortLogsRow log={entity} key={i} />
			})
		}

		return (
			<div className='recipe-viewer'>

				<div className="recipe-main boxShadow">
					<button className='button-ReturnViewer' onClick={this.onReturnNavigation}> Back to Ingredients </button>
					<div className="recipeInfo">
						{/* {this.state.loaded?null:docLoader} */}
						<div className="recipeInfo-main">
							<div className="recipeInfo-left">
								<div className="recipe-title">{doc.name} - {version_number}</div>
								<InfoField content={doc.user_defined_id} contentType='ID'
									doc={doc} onClickOut={this.onSaveChanges} />
								<InfoField content={doc.name} contentType='Name'
									className="document-viewer-fields-content"
									doc={doc}
									onClickOut={this.onSaveChanges} />
								<PriceInfoField contentType='Price'
									className="document-viewer-fields-content"
									ingredient={ingredient}
									onSavePrice={this.onSaveChanges} />
								<DensityInfoField contentType='Density'
									className="document-viewer-fields-content"
									ingredient={ingredient}
									onClickOut={this.onSaveChanges} />
								<SolidInfoField density={ingredient.density}
									className="document-viewer-fields-content"
									contentType="Physical State"
									ingredient={ingredient}
									onClickOut={this.onSaveChanges} />
							</div>
							<div >
								<div className="recipeInfo-right">
									<IngredientVersionButton ingredient={ingredient} />
									<DeleteButton items={[ingredient]}
										type="ingredients"
										onGetDocuments={this.onReturnNavigation} onClear={this.onReturnNavigation} />
								</div>
							</div>
						</div>
						{doc ? <div>
							<TagsField content={ingredient.tags} contentType='Tags' doc={doc}
								onClickOut={this.onSaveTagsChanges} />
							<DocumentNotesField content={doc.notes} contentType='Notes'
								doc={doc} onClickOut={this.onSaveChanges} /></div> : null}

						<div>
							{producedFromRecipe}
						</div>


					</div>
					<div className="recipe-ingredient-container">
						{properties}
						<AddIngredientAttributeButton sendAttribute={this.addAttribute} />
						<RemoveIngredientAttributeButton
							onStartRemove={this.onStartRemove}
							removeProperties={this.removeProperties} onCancelRemove={this.onCancelRemove} />
					</div>

					<div className="recipe-ingredient-container">
						<div className="recipe-ingredient-title">
							<div>Documents</div>
							<div className="relatedDocuments-buttonContainer">
								<TagsField content={ingredient.related_tags} contentType='Related Tags' doc={doc}
									onClickOut={this.onSaveRelatedTagsChanges} />
								<DocumentUploadModalButton related_tags={related_tags} onGetDocuments={this.onGetIngredient} />

							</div>
						</div>
						<RelatedTagDocuments related_tags={related_tags} isIngredientRelatedFiltered={true} showTagNavigator />

					</div>
				</div>
				<div>
					<div className='recipe-viewer-right-box boxShadow recipe-viewer-right-top-box '>
						<div className='document-viewer-column-header-text'>
							Used In
						</div>
						<div className="ingredient-used-in-container">
							{used_in}
						</div>
					</div>
					<div className='document-viewer-rightColumn recipe-viewer-right-margin boxShadow'>
						<div className="document-viewer-rightColumn-header">
							{this.state.showVersionHistory ?
								<div className="document-viewer-column-header-text" >
									<div onClick={this.onShowLogs}
										className="document-viewer-tabInactive">Logs</div>
									<div className="document-viewer-tabActive">
										Version History

									</div>
								</div> :
								<div className="document-viewer-column-header-text" >
									<div className="document-viewer-tabActive">Logs</div>
									<div onClick={this.onShowVersionHistory}
										className="document-viewer-tabInactive" id="versionHistory">
										Version History
									</div>

								</div>}
						</div>

						{this.state.showVersionHistory ?
							<div className='versionHistory'>

								<div className="document-versionHistoryContents">

									{versionHistory}
								</div>
							</div>
							:
							<div className='logSummary'>

								<div className="logSummary-contents">
									{logsList}
								</div>

							</div>}


					</div>
				</div>

				<div>
					{this.state.error}

				</div>
			</div>
		);
	}
}

export default withRouter(CookieWrapper(IngredientViewer));
