import React from 'react';

import './DocumentRow.css';
import TagLabel from './TagLabel';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import { DropTarget } from 'react-dnd';

const receiverTarget = {
  drop(props,monitor,component) {
    component.onDropCurrentTag(monitor.getItem());
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),

  };
}
class DocumentRow extends React.Component {
	constructor(){
		super();
		this.state={
		isActive:false,
		}
		}

	componentDidUpdate(prevProps){
		if (prevProps.activeDocuments!==this.props.activeDocuments){
			let activeDocumentsIds =(this.props.activeDocuments.map((entity)=>{return entity.document.id}));
			if (activeDocumentsIds.indexOf(this.props.document.document.id)>-1){
				this.setState({isActive:true})
			}
			else{
				this.setState({isActive:false})
			}}
	}
	onGoToViewer = (e) =>{
		e.stopPropagation();
		window.location.href= `/documents/${this.props.document.document.id}`;
	}
	onClickDocumentRow = () =>{
		this.props.onSelectDoc(this.props.document)
	}
	onDropCurrentTag(tag){
		this.onAddTag(tag.name)
	}
	onAddTag=(tag)=>{
      let doc = this.props.document;
      let oldTagNames = doc.tag_names;

      Request.authRequest(Routes.documents.tag(doc.document.id),
                          'PUT',JSON.stringify({
                    				tag_names: [...oldTagNames, tag],
                    			}), 'application/json'
                        ).then((response) => {
                          if (response.status===200){
                          response.json().then(body => {
                              this.props.onGetDocuments();
                              toast.success('Successfully applied tags!');
                            });
                          } else {
                            response.json().then((error) => {
                              toast.error(error.message);
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
    }
	render() {

		const doc = this.props.document;
		let tagList;
		if (doc.tags){
		 tagList = doc.tags.map((entity,i)=>{
			return <TagLabel tag={entity} key ={i}/>
		})}
		let date_expiration;
		let version_number;
		if (doc.version_number){
			version_number = 'v' + doc.version_number;
			if (doc.version_history.length>0 && doc.version_number === doc.version_history.length + doc.version_history[doc.version_history.length-1].version_number){
				version_number = version_number + " - current"
			}
      else if (doc.version_history && doc.version_history.length>0 &&
        doc.version_number > doc.version_history[0].version_number){
        version_number = version_number + " - current";
      }
		}
    let draft;
    if (doc.tags && doc.tags.length>0){
      let tag_names = doc.tags.map((entity,i)=>{
 			    return entity.name
 		   })
      if (tag_names.indexOf("Draft")>-1){
        draft = "DRAFT"
      }
    }

		if (!doc.version_number && doc.version_history.length>0){
			version_number = 'v0'
		}
    if (doc.date_expiration===null){
      date_expiration='';
    }
    else{
      date_expiration=new Date(doc.date_expiration);
			date_expiration = date_expiration.toDateString();

    }
		let user_defined_id;
    let showExpiredDot;
    var date = new Date();
    if (date.toISOString() > doc.date_expiration){
      showExpiredDot = true;

    }
		if (doc.user_defined_id !== 'null'&& doc.user_defined_id && doc.user_defined_id!=='undefined'){
			user_defined_id = doc.user_defined_id
		}
	    const {connectDropTarget} = this.props;
	    return connectDropTarget(
			<div className = 'document-row'
				id={this.state.isActive?'document-row-active':null}  onClick ={this.onClickDocumentRow}>
					<div className = "document-row-info">
						<span className='document-row-name wordbreak'> {doc.name} </span>
						<span className='document-row-id wordbreak'> {user_defined_id} {version_number} {draft}</span>
					</div>

						<span className = 'document-expiration-date'>
              {showExpiredDot?<div
                className = "expiredDot"/>:null}
              {date_expiration}</span>


					<div className = 'document-tag-container'>
							{tagList}
					</div>


				<div className = "button-document-row-infoViewer" onClick = {this.onGoToViewer}>Details</div>
				
				


			</div>
		);
	}
}

export default DropTarget('tag', receiverTarget, collect)(DocumentRow);
