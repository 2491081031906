import React from 'react';

import '../DocumentRow.css';
import TagLabel from '../TagLabel';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import { DropTarget } from 'react-dnd';
const receiverTarget = {
	drop(props, monitor, component) {
		component.onDropCurrentTag(monitor.getItem());
	}
};

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),

	};
}
class DocumentRow extends React.Component {
	constructor() {
		super();
		this.state = {
			isActive: false,
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.activeDocuments !== this.props.activeDocuments) {
			let activeDocumentsIds = (this.props.activeDocuments.map((entity) => { return entity.batch_sheet_id }));
			if (activeDocumentsIds.indexOf(this.props.document.batch_sheet_id) > -1) {
				this.setState({ isActive: true })
			}
			else {
				this.setState({ isActive: false })
			}
		}
	}
	onGoToViewer = (e) => {
		e.stopPropagation();
		window.location.href = `/batch_sheet/${this.props.document.batch_sheet_id}`;
	}
	onClickDocumentRow = () => {
		this.props.onSelectDoc(this.props.document)
	}
	onDropCurrentTag(tag) {
		this.onAddTag(tag.name)
	}
	onAddTag = (tag) => {
		let doc = this.props.document;
		let oldTagNames = doc.tag_names;

		Request.authRequest(Routes.documents.tag(doc.recipe.id),
			'PUT', JSON.stringify({
				tag_names: [...oldTagNames, tag],
			}), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.props.onGetDocuments();
					toast.success('Successfully applied tags!');
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	render() {

		const doc = this.props.document;
		let tagList;

		if (doc.tags) {
			tagList = doc.tags.map((entity, i) => {
				return <TagLabel tag={entity} key={i} />
			})
		}

		let draft;
		if (doc.tags && doc.tags.length > 0) {
			let tag_names = doc.tags.map((entity, i) => {
				return entity.name
			})
			if (tag_names.indexOf("Draft") > -1) {
				draft = "DRAFT"
			}
		}

		let is_receiver = doc.batch_sheet.is_receiver;
		let send_receive = doc.batch_sheet.receiving_organization_name
		if (is_receiver) {
			send_receive = doc.batch_sheet.sending_organization_name;
		}
		let user_defined_id;

		if (doc.user_defined_id !== 'null' && doc.user_defined_id && doc.user_defined_id !== 'undefined') {
			user_defined_id = doc.user_defined_id
		}
		const { connectDropTarget } = this.props;
		return connectDropTarget(
			<div className='document-row'
				id={this.state.isActive ? 'document-row-active' : null} onClick={this.onClickDocumentRow}>
				<div className="document-row-info">

					<span className='document-row-name wordbreak'> {doc.name} </span>
					<span className='document-row-id wordbreak'> Batch Sheet {user_defined_id} </span>
				</div>

				<span className='document-expiration-date'>
					{send_receive}
				</span>


				<div className='document-tag-container'>
					{tagList}
				</div>



				<div className="button-document-row-infoViewer" onClick={this.onGoToViewer}>Details</div>


			</div>
		);
	}
}

export default DropTarget('tag', receiverTarget, collect)(DocumentRow);
