import React from 'react';
import PreviewButton from './PreviewButton';

import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import './SendDocumentToButton.css';
class SendToSelectedDocuments extends React.Component{
  constructor(){
    super();
    this.state={
      docInfo:''
    }
  }
  componentDidMount(){
  if (this.props.doc && !this.props.doc.document.preview_type){
      this.getDocument();

  } else if (this.props.doc===null){
    this.getDocument();
  }
  }
  getDocument(){
    Request.authRequest(Routes.documents.edit(this.props.doc.document.id),
	   'GET').then((response)=>{

		if (response.status===200){
		response.json().then(body=>{
			this.setState({docInfo:body})
				})
			}
			else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
  render(){
    let doc;

    if (this.state.docInfo){
      doc = this.state.docInfo; // if we had to reGet the doc to check its preview state
    } else {
      doc = this.props.doc; // if we don't have to get the doc to check its preview state
    }

    let can_send_as_pdf;
    if (doc){
       can_send_as_pdf = doc.document.preview_content_type=== "application/pdf";
       if (doc.document.file_extension === ".jpg"||
         doc.document.file_extension === ".jpeg"||
           doc.document.file_extension === ".png"){
           statusDotColor = {backgroundColor:"#FFB50B"};
           sendMessage = "This file will be sent as an img"
         }
     }
    let statusDotColor;
    let sendMessage;
    if (can_send_as_pdf) {
        statusDotColor = {backgroundColor: "#18A54D"};
        sendMessage = "This document can be sent as pdf";}
      else {
        statusDotColor = {backgroundColor:"#E65531"};
        sendMessage = "This document can't be sent as pdf";
        }

    return(
      <div className = "sendToSelected-docContainer">
        <div className = "sendToSelected-infoContainer">
          <div>{doc.name}
          </div>
          <PreviewButton doc = {doc}/>
        </div>
        <div className = "sendToSelected-sendStatus">
          <div className = "statusDot" style = {statusDotColor}></div>
          {sendMessage}
        </div>
      </div>
    )
  }
}
export default SendToSelectedDocuments;
