import React from 'react'
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Autosuggest from 'react-autosuggest';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './TagsSuggestions.css';

class TagsSearch extends React.Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.autocompleteRenderInput = this.autocompleteRenderInput.bind(this);
    this.timerID = null;
    this.onChangeInput = this.onChangeInput.bind(this);
    this.state = {
      tags: [],
      suggestions: [],
      tag: ''
    }
  }

  handleChange(tags) {
    this.setState({ tags });

    this.props.onSendTags(tags);
  }
  componentDidMount() {
    if (this.props.tags) {
      this.setState({ tags: this.props.tags })
    }
    document.addEventListener('keydown', this.props.onEsc)
    this._isMounted = true;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.tags !== this.props.tags) {
      if (!this.props.tags) {
        this.setState({ tags: [] })
        return
      }
      this.setState({ tags: this.props.tags });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.props.onEsc);
    this._isMounted = false;
  }

  onChangeInput = (tag) => {
    this.setState({ tag })
  }
  autocompleteRenderInput = ({ addTag, ...props }) => {
    let suggestions = [];

    const handleOnChange = (e, { newValue, method }) => {
      if (method === 'enter') {
        e.preventDefault()
      } else {
        props.onChange(e)
      }
    }
    const fetchRequest = (inputValue) => {
      if (this.props.noSearch) {
        return
      }
      if (this.timerID) {
        window.clearTimeout(this.timerID);
      }
      this.timerID = window.setTimeout(() =>
        Request.authRequest(Routes.tags.search('?per_page=5&q=' + inputValue.value),
          'GET').then((response) => {
            response.json().then(body => {
              if (this._isMounted) {
                this.setState({ suggestions: body.results })
              }
              return body.results
            })
          }
          ), 400)
    }

    const getValue = (suggestion) => {
      if (suggestion) {
        return suggestion.name
      }
    }
    const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
    const inputLength = inputValue.length;
    const theme = {
      suggestion: {
        display: 'inline-block',
        listStyleType: 'none',
        backgroundColor: '#F0F0F0',
        borderRadius: '5px',
        margin: '0 2px 2px 0',
        color: '#808080'
      },
      suggestionsList: {
        padding: '0px',
        margin: '0px'
      },
      suggestionHighlighted: {
        color: 'white',
        backgroundColor: 'rgb(205,189,169)'
      }
    }
    return (
      <Autosuggest
        theme={theme}
        ref={props.ref}
        suggestions={this.state.suggestions}
        shouldRenderSuggestions={(value) => value && value.trim().length > 0}
        getSuggestionValue={(suggestion) => getValue(suggestion)}
        renderSuggestion={(suggestion) => <div className="TagsSuggestionRow">
          {suggestion.name}
        </div>}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.name)
        }}
        onSuggestionsClearRequested={() => suggestions = []}
        onSuggestionsFetchRequested={fetchRequest}
      />
    )
  }
  pasteSplit(data) {
    const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r'];
    return data.split(new RegExp(separators.join('|'))).map(d => d.trim());
  }
  render() {
    var defaultprops = {
      className: 'react-tagsinput-input',
      placeholder: `Enter tags you'd like to apply`
    }
    if (this.props.placeholder) {
      defaultprops.placeholder = this.props.placeholder;
    }

    return (
      <TagsInput
        value={this.state.tags}
        addKeys={[9, 13, 186, 188]} // tab, enter, space, semicolon, comma
        inputProps={defaultprops}
        onlyUnique
        addOnPaste
        pasteSplit={this.pasteSplit}
        onChange={this.handleChange}
        renderInput={this.autocompleteRenderInput}
        inputValue={this.state.tag}
        onChangeInput={this.onChangeInput}
        addOnBlur={true}
      />
    )
  }
}

export default TagsSearch;
