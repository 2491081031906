import React from 'react';
import TagsSearch from '../../TagsSearch/TagsSearch';
import { DropTarget } from 'react-dnd';
const receiverTarget = {
  drop(props,monitor,component) {
    component.onDropCurrentTag(monitor.getItem());
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}
class ParentTagInput extends React.Component{
  onDropCurrentTag(tag){
    this.props.sendTags(this.props.tags.concat(tag.name))
  }
  render(){
    const {connectDropTarget} = this.props;


    return connectDropTarget(
      <div className = "tag-ancestry-label">Parent Tags
        <TagsSearch onSendTags = {this.props.sendTags}
        tags = {this.props.tags}
      onEsc = {this.onEscape}
      placeholder = "Drop or enter tags you'd like to apply"/></div>
    )
  }
}

export default DropTarget('tag', receiverTarget, collect)(ParentTagInput)
