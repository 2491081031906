import React from 'react';

import './TagLabel.css';

class TagLabel extends React.Component{
  render(){
    let tag = this.props.tag;
    return(
    <div className = "tagLabel-container" id = {this.props.tag.is_accessible?null:"notAccessibleTag"}>
        {tag.name}
    </div>)
  }
}

export default TagLabel;
