import React from 'react';
import ActiveTag from '../Tags/ActiveTag';

import './InternalSelectTool.css';
class InternalSelectActive extends React.Component{
  constructor(){
    super();
    this.onAddActiveTag=this.onAddActiveTag.bind(this);
    this.onRemoveTag=this.onRemoveTag.bind(this);
    this.onReplaceTag=this.onReplaceTag.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.changeGetInfoAgain = this.changeGetInfoAgain.bind(this);
    this.state={
      topTags:[],
      searchQuery:'',
      page:1,
      has_next:true,
      getInfoAgain:false,
    }
  }

  handleSearchRequest = (updated_string) => {
    this.setState({searchQuery:updated_string},
      async ()=>{
        this.onGetTags()
      }

    )
  }
  changeGetInfoAgain=()=>{
    this.setState({getInfoAgain:!this.state.getInfoAgain})
  }
  onAddActiveTag = (tag)=>{
    if (Array.isArray(this.props.activeTags)){
      this.props.onChangeTags([...this.props.activeTags,tag]);
    }
    else {
      this.props.onChangeTags([this.props.activeTags,tag])
    }
  }
  onRemoveTag=(tag,index)=>{
    let newArray = this.props.activeTags.concat();
    newArray.splice(index,1);
    this.props.onChangeTags(newArray);
  }
  onClearTags = ()=>{

      this.props.onChangeTags(null);

  }
  onReplaceTag=(tag,index)=>{

      let replacedTags= this.props.activeTags.concat();
      replacedTags[index]= tag;
      this.props.onChangeTags(replacedTags);
      this.props.onLastSelected(tag);
    }
  onGetPinnedTags = ()=>{
      this.tagNavigatorRef.current.onGetPinnedTags();
    }
  render()
  { 
    let activeTagList = [];
    if (this.props.activeTags){
    activeTagList= this.props.activeTags.map(
      (entity,i)=>{
        return <ActiveTag key={i}
          tag={entity}
          index={i}
          onAddActiveTag={this.onAddActiveTag}
          onRemoveTag={this.onRemoveTag}
          onReplaceTag={this.onReplaceTag}
          onLastSelected={this.props.onLastSelected}
          onGetTags = {this.onGetTags}
          getInfoAgain = {this.state.getInfoAgain}
          onGetPinnedTags = {this.props.onGetPinnedTags}
          />
      }
    );
    }

    return(
      <div className = "internalActiveTags-container">
        <div className = "internalSelectTags-header-text">
          <div>Active Tags</div>
          <button className = "internal-buttonClearTags" onClick = {this.onClearTags}>Clear Tags</button>
        </div>


            <div className = "activeTagList-container">
              {activeTagList.length>0 ? activeTagList : <div className="helpText noselect activeTagsHelp">None Selected</div>}
            </div>
      </div>
  )
  }
}
export default InternalSelectActive;
