import React from 'react';
import { DropTarget } from 'react-dnd';

const receiverTarget = {
  drop(props,monitor,component) {
    component.onDropCurrentTag(monitor.getItem());
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
  };
}
class TagDropZone extends React.Component{
  onDropCurrentTag(tag){
    this.props.sendTags(tag.name)
  }
  render(){
    const {connectDropTarget} = this.props;
    return connectDropTarget(
      <div className = " tagDropZone helpText">Select or drop a tag to edit it.</div>
    )
  }
}

export default DropTarget('tag', receiverTarget, collect)(TagDropZone)
