import React from 'react';
import { toast } from 'react-toastify';
import './PriceInfoField.css';
import UnitSelector from '../../UnitSelector/UnitSelector';
class InfoField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      price: 0.0,
      currentUnits: 'kg'
    }
  }
  onSaveChanges() {
    let price = this.state.price + ' / ' + this.state.currentUnits;
    this.props.onSavePrice('Price', price);
  }
  componentDidMount() {
    if (this.props.content !== null) {
      this.setState({ string: this.props.content })
    }
  }
  handleDblClick = (e) => {
    if (this.node.contains(e.target)) {
      this.setState({
        editing: true
      })
    }
    else if (this.state.editing !== false) {
      this.onSaveChanges();
      this.setState({ editing: false })
    };
  }
  changeUnits = (units) => {
    this.setState({ currentUnits: units });
  }
  componentWillMount() {
    document.addEventListener('dblclick', this.handleDblClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('dblclick', this.handleDblClick, false);
  }
  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.onSaveChanges();

      this.setState({ editing: false })
    }

  }
  onChangePriceInfo = (e) => {
    this.setState({
      price: e.target.value,
    });
  }
  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.setState({ editing: false })
    }
  }
  render() {
    let price;
    let ingredient = this.props.ingredient
    if (!ingredient) {
      return (null)
    }
    return (

      <div className='doc-info-field' ref={node => this.node = node}>
        {this.props.nolabel ? null : <label className='document-label noselect' >
          {this.props.contentType} </label>}

        {this.state.editing ?

          <div className="priceInfoInput"><input
            value={this.state.price}
            type='number'
            step='0.01'
            className='document-info-field'
            onChange={this.onChangePriceInfo}
            onKeyPress={this.handleEnter}
            onKeyDown={this.onKeyDown} />
            per
            <UnitSelector changeUnits={this.changeUnits} unit={this.state.currentUnits} />
          </div>
          :

          <div >
            <div className='priceInfoContainer'>
              <div className="priceInfoItem">${ingredient.f_price_kg}</div>
              <div className="priceInfoItem">${ingredient.f_price_L}</div>
            </div>
            <div className='priceInfoContainer'>
              <div className="priceInfoItem">${ingredient.f_price_gal}</div>
              <div className="priceInfoItem">${ingredient.f_price_lb}</div>
            </div>
          </div>
        }
      </div>)

  }
}
export default InfoField;
