import React from 'react';
import './FeaturesPage.css';
class PublicFAQ extends React.Component{
  render(){
    return(
      <div className = "public-faq-container  boxShadow">


        <div className = "public-faq-container-contents">
          <h1>FAQ</h1>
          <div className = "faq-questions">
            <h4 className = "question">I'm looking for a desktop application. Do you support mobile or desktop?</h4>
                  We're working on it. If you're interested, let us know by reaching out to nathan@ivydocuments.com.

            <h4 className = "question">How does the tags system work?</h4>
                Tags do everything a folder, filter and tag do. You can apply
                multiple tags at once and the documents shown will have all of the tags
                or one of the tags children applied to them. Tags are also hierarchical,
                 allowing you to create nested tags just like you have nested folders.

            <h4 className = "question">Why use tags?</h4>
              Documents don’t fit well within a single category. Files often
                need to be used in multiple different contexts,
                but traditional folder systems force you to decide where the one “right”
                place to put a document, leading to confusion and lost documents.
                Tags let you retrieve your documents flexibly, from multiple different angles.

              <h4 className = "question">How do I tag my files?</h4>
                  You can add tags using the tags interface, or just typing in comma separated tags as inputs.

              <h4 className = "question">Can I batch import my files?</h4>
                  Yes! Onboarding is easy and takes about 30 min.
                  You can dump in your existing folder system and our system will handle the rest.
                  We offer free guided onboarding as well, which you can schedule by emailing nathan@ivydocuments.com.

              <h4 className = "question">Can I add metadata to my documents?</h4>
                Yes, in addition to tags and filenames we support adding unique ids, notes, and expiration dates.

              <h4 className = "question">Can I tag multiple files at once?</h4>
                Yes, just select the files and use the tag button. You can enter multiple tags separated by commas.

              <h4 className = "question">What filetypes are supported?</h4>
                  Any file type can go into the system. We currently only support doc, docx, ppt, xlsx, jpg, jpeg, png and pdf type
                  files for in-app preview.


              <h4 className = "question">What are templates and how do they work?</h4>
                  Templates allow you to simplify and automate repeated requests for the same set of documents from one or multiple people.
                  Each template has a name, a set of tags and individual template slots.
                  You can receive multiple documents per template slot, and approve them individually.

              <h4 className = "question">Do our suppliers/customers need to have accounts?</h4>

                  No, if you send documents to a person who doesn't have an account, they'll be sent as attachments.
                  If you send a request for documents, they'll receive an authorized link.

                <h4 className = "question">I received a request. What is this and what do I do?</h4>
                  You've received a request from another organization that has your email that is requesting a certain set of documents.
                  Check the messages they sent for additional explanation, or contact the sender directly.

                <h4 className = "question">Can I import my contacts? </h4>
                    Yes, you can import your contacts from any mail application that allows export (primarily GSuite).


                <h4 className = "question">Can I get notifications about my documents?</h4>
                Yes. You can set up notifications for document expiration, as well as receive notifications whenever a document is accepted, received or rejected.
              <h4 className = "question">Do you support integrations with other applications?</h4>
                    Yes, contact us directly at help@ivydocuments.com.

            </div>
        </div>
      </div>
    )
  }
}

export default PublicFAQ;
