import React from 'react';
import './AddIngredient.css';
import Modal from '../../Modal/Modal';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import IngredientNavRow from '../../Ingredients/IngredientNavRow';
import LoadingButton from '../../Buttons/LoadingButton';
import Search from '../../Search/Search';
import qs from 'qs';
import PageButtons from '../../PageButtons/PageButtons';
import TagNavigator from '../../Tags/TagNavigator';
import InternalSelectActive from '../../InternalSelect/InternalSelectActive';
import ActiveTagsNavigator from '../../Tags/ActiveTagsNavigator';
class AddIngredientButton extends React.Component {
  constructor(props) {
    super();
    this.tagNavigatorRef = React.createRef();
    this.state = {
      addNew: false,
      showModal: false,
      ingredientList: [],
      loaded: false,
      currentIngredient: '',
      weightRatio: 0,
      activeTags: [],
      requestAdding: false,
      searchString: 'filter_active_ingredient_tags=true&tag=Ingredient'
    }
  }
  onShowModal = (e) => {
    e.stopPropagation();
    this.setState({ showModal: true })
    this.getIngredients();
  }
  searchStringGenerator(input, type) {
    const searchString = qs.parse(this.state.searchString);
    if (!searchString.page) {
      searchString.page = 1;
    }
    if (!searchString.per_page) {
      searchString.per_page = 20;
    }
    if (!searchString.status) {
      searchString.status = 'accepted';
    }
    if (searchString.q && searchString.q === '') {
      delete searchString.q;
    }
    switch (type) {
      case 'tags':
        if (input) {
          searchString.tag = input.join();
          searchString.page = 1;
        }
        else {
          delete searchString.tag
          searchString.page = 1;
        }
        break;
      case 'excludedTags':
        if (input) {
          searchString.exclude_tag = input.join();
          searchString.page = 1;
        }
        else {
          delete searchString.exclude_tag
          searchString.page = 1;
        }
        break;
      case 'pageSize':
        searchString.per_page = input;
        if (this.state.numResults / searchString.per_page < searchString.page) {
          searchString.page = Math.ceil(this.state.numResults / searchString.per_page);
        }
        break;
      case 'page':
        searchString.page = input;
        break;
      case 'search':
        searchString.q = input;
        searchString.page = 1;
        break;
      case 'clearDate':
        delete searchString.expires_after;
        delete searchString.expires_before;
        break;
      case 'afterDate':
        searchString.expires_after = input;
        break;
      case 'beforeDate':
        searchString.expires_before = input;
        break;
      case 'order_by':
        searchString.order_by = input;
        break;
      case 'orgName':
        searchString.organization_name = input; //{organization_name:input}
        break;
      case 'filterSwitch':
        if (searchString.filter_active_ingredient_tags) {
          delete searchString.filter_active_ingredient_tags;
        }
        else {
          searchString.filter_active_ingredient_tags = true;
        }
        break;
      default:
    }

    let base_url = qs.stringify(searchString, { indices: false });
    this.setState({ searchString: base_url });
    this.onSearchStringChangeHelper('?' + base_url)

  }
  onSearchStringChangeHelper = (searchString) => {
    Request.authRequest(Routes.ingredients.index(searchString),
      'GET').then((response) => {
        if (response.status === 200) {
          response.json().then(body => {
            this.setState({
              ingredientList: body.results,
              numResults: body.total_results,
              selectedDocument: null,
              activeDocuments: [],
            }
            )
          }
          )
        }
        else {
          response.json().then((error) => {
            toast.error(error.message);
          });
        }
      }).catch((error) => {
        toast.error(error.message);
      }
      )
  }
  getIngredients = () => {
    Request.authRequest(Routes.ingredients.index('?' + this.state.searchString),
      'GET').then((response) => {
        if (response.status === 200) {
          response.json().then(body => {

            this.setState({
              ingredientList: body.results,
              loaded: true,
              numResults: body.total_results
            })

          })
        }

        else {
          response.json().then((error) => {
            toast.error(error.message);
          });
        }
      }).catch((error) => {
        toast.error(error.message);
      }
      )
  }
  onHideModal = () => {
    this.setState({
      showModal: false
    })
  }
  onEscape() {
    return
  }
  changeWeightRatio = (e) => {
    this.setState({ weightRatio: e.target.value });
  }
  onSelectIngredient = (ingredient) => {
    if (ingredient == this.state.currentIngredient) {
      this.setState({ currentIngredient: '' })
    } else {
      this.setState({ currentIngredient: ingredient })
    }
  }
  addIngredientToRecipe = () => {
    if (this.state.currentIngredient == '') {
      return
    }
    this.setState({ requestAdding: true });
    Request.authRequest(Routes.recipes.addIngredient(this.props.recipe.id),
      'POST', JSON.stringify({
        ingredient_id: this.state.currentIngredient.id,
        weight_ratio: this.state.weightRatio,
      }), 'application/json'
    ).then((response) => {

      if (response.status === 200) {
        response.json().then(body => {
          toast.success(`Successfully added ${this.state.currentIngredient.name}`)

          this.setState({
            currentIngredient: '', weightRatio: 0,
            loaded: true, requestAdding: false
          })
          this.props.onGetLogs();
          this.props.onGetRecipe();
        })
      }
      else {
        response.json().then((error) => {
          this.setState({ loaded: true, requestAdding: false })
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )

  }
  onChangeTags = (tags) => {
    this.searchStringGenerator(tags, 'tags');
  }
  onChangeExcludedTags = (tags) => {
    this.searchStringGenerator(tags, 'excludedTags');
  }
  onChangePage = (pageNumber) => {
    this.onClearSelected();
    this.searchStringGenerator(pageNumber, 'page');
  }
  handleSearchRequest = (updated_string) => {
    this.searchStringGenerator(updated_string, 'search');
  }

  onChangePageSize = (e) => {
    this.searchStringGenerator(e.target.value, 'pageSize');
  }
  onLastSelected() {
    /* this function is empty to reuse the navigator component between the tags page and the document page.*/
    return
  }
  onGetPinnedTags = () => {
    this.tagNavigatorRef.current.onGetPinnedTags();
  }
  render() {
    let expanded;
    let ingredientList = <div className="loaderContainer"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>;
    if (this.state.loaded == true) {
      ingredientList = this.state.ingredientList.map((entity, i) => {
        return <IngredientNavRow ingredient={entity} key={i} selectedIngredient={this.state.currentIngredient}
          onSelectDoc={this.onSelectIngredient} recipe={this.props.recipe} />
      })
    }
    let currentIngredient = 'None';
    if (this.state.currentIngredient) {
      currentIngredient = <div>{this.state.currentIngredient.name}</div>
    }
    let perPage = 20;
    let page = 1;
    let query;
    const searchString = qs.parse(this.state.searchString);
    if (searchString.q) {
      query = searchString.q;
    }
    if (searchString.per_page) {
      perPage = searchString.per_page;
    }
    if (searchString.page) {
      page = searchString.page
    }
    let filter_active_tags;
    let filter_active_ingredient_tags = false;
    if (searchString.filter_active_ingredient_tags) {
      filter_active_ingredient_tags = true;
    }
    let activeTags = [];
    let excludedTags = [];
    if (searchString.tag) {
      activeTags = searchString.tag.split(',');
      if (!Array.isArray(activeTags)) {
        activeTags = [activeTags]
      }
    }
    if (searchString.exclude_tag) {
      excludedTags = searchString.exclude_tag.split(',');
      if (!Array.isArray(excludedTags)) {
        excludedTags = [excludedTags]
      }

    }
    return (
      <div>
        <div className="recipe-add-ingredient" onClick={this.onShowModal}>+ Add Ingredient</div>
        <div>
          <Modal show={this.state.showModal} onClose={this.onHideModal} title='Add Ingredient' >
            <div>Select ingredients below to add to this recipe.
            </div>
            <div className="ingredientRecipe-currentlySelected">
              <div><div className="ingredientRecipe-headerText">Currently Selected:</div>
                <div className="ingredientRecipe-mainText">{currentIngredient}</div>
              </div>
              <div>
                <div className="ingredientRecipe-headerText">Weight Ratio</div>
                <input className="ingredientRecipe-weightRatioInput" max={100} min={0} type="number" value={this.state.weightRatio}
                  onChange={this.changeWeightRatio} />

              </div>
              <div className='addIngredientButton'> <LoadingButton onClick={this.addIngredientToRecipe}
                text={'+ Add'} isLoading={this.state.requestAdding} /></div>
            </div>
            <div className="is-spaced-between addIngredient-searchContainers">
              <div className="is-spaced-between addIngredient-tagsContainer">
                <div className="addIngredient-activeTags">
                  <InternalSelectActive activeTags={activeTags} onChangeTags={this.onChangeTags}
                    onLastSelected={this.onLastSelected}
                    onGetPinnedTags={this.onGetPinnedTags} />
                </div>
                <div className="addIngredient-tagsNavigator">

                  <TagNavigator onLastSelected={this.onLastSelected}
                    onChangeTags={this.onChangeTags}
                    onChangeExcludedTags={this.onChangeExcludedTags}
                    activeTags={activeTags}
                    excludedTags={excludedTags}
                    toggleSwitch={this.toggleTagFilterSwitch}
                    isFiltered={filter_active_tags}
                    isIngredientFiltered={filter_active_ingredient_tags}
                    ref={this.tagNavigatorRef}
                    showFilter={true}

                  /></div>



              </div>
              <div className="addIngredient-ingredientsContainer">
                <Search id='documentSearch' sendSearch={this.handleSearchRequest} placeholder="Search for an ingredient"
                  query={query} />
                <div>
                  {ingredientList}
                </div>
                <PageButtons page={page} perPage={perPage}
                  numResults={this.state.numResults} onChangePage={this.onChangePage} />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

export default AddIngredientButton