import React from 'react';
import { toast } from 'react-toastify';
import './PriceInfoField.css';
import UnitSelector from '../../UnitSelector/UnitSelector';
class SolidInfoField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      density: 1,
      physical_state: 1,
      currentUnits: 'kg / L'
    }
  }
  onSaveChanges() {
    let density = this.state.density + this.state.currentUnits;
    this.props.onClickOut('Density', density);
  }

  componentDidMount() {
    if (this.props.content !== null) {
      this.setState({ string: this.props.content })
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.ingredient.is_solid && prevProps.ingredient.is_solid != this.props.ingredient.is_solid) {
      this.setState({ physical_state: 0, density: 0 })
    }
  }
  handleDblClick = (e) => {
    let immutable;
    let ingredient = this.props.ingredient;
    for (let i = 0; i < ingredient.used_in_recipes.length; i++) {
      if (ingredient.used_in_recipes[i].immutable) {
        immutable = true;
      }
    }
    if (immutable) {
      toast.error('This ingredient is used in locked recipes. Version this ingredient before changing its state.')
      return
    }
    if (this.node.contains(e.target)) {
      this.setState({
        editing: true
      })
    }
    else if (this.state.editing !== false) {
      this.onSaveChanges();
      this.setState({ editing: false })
    };
  }
  changeUnits = (e) => {
    this.setState({
      density: e.target.value,
      physical_state: e.target.value
    });
  }
  componentWillMount() {
    document.addEventListener('dblclick', this.handleDblClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('dblclick', this.handleDblClick, false);
  }
  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.onSaveChanges();

      this.setState({ editing: false })
    }

  }
  onChangePriceInfo = (e) => {
    this.setState({
      density: e.target.value,
    });
  }
  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.setState({ editing: false })
    }
  }
  render() {
    let density = this.state.density;
    let ingredient = this.props.ingredient;
    if (!ingredient) {
      return null
    }
    let physical_state = this.state.physical_state;
    let state = 'Liquid';
    if (ingredient.is_solid) {
      state = 'Solid';
      density = 0;
    }
    let lockedMessage;
    let immutable;
    for (let i = 0; i < ingredient.used_in_recipes.length; i++) {
      if (ingredient.used_in_recipes[i].immutable) {
        immutable = true;
      }
    }
    if (immutable) {
      lockedMessage = "This ingredient is used in locked recipes. Cannot change the state of a locked ingredient."
    }
    return (

      <div className='doc-info-field' ref={node => this.node = node}>
        {this.props.nolabel ? null : <label className='document-label noselect' >
          {this.props.contentType} </label>}

        {this.state.editing ?
          <div>
            <div className="priceInfoInput">
              <div>
                <select className="unitSelector" onChange={this.changeUnits}
                  value={physical_state}>
                  <option value="1">Liquid</option>
                  <option value="0">Solid</option>
                </select>
              </div>
            </div>
            <div>{lockedMessage}</div>
          </div>
          :

          <div className='priceInfoContainer'>
            <div>{state}</div>
          </div>
        }
      </div>)

  }
}
export default SolidInfoField;
