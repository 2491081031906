import React from 'react';
import './LandingPage.css';
import ConfusedGuy from './imgs/LandingPage/IVY-04.png';
import GoodGuy from './imgs/LandingPage/IVY-05.png';
import pat from './imgs/LandingPage/pat.png';
import njin from './imgs/LandingPage/njin.jpg';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import tagsBanner from './imgs/LandingPage/TagsGood.png';
import foldersBanner from './imgs/LandingPage/FoldersBad.png';
import InteractiveDemo from './InteractiveDemo/InteractiveDemo';
import Cookies from '../../helpers/Cookies';

class LandingPage extends React.Component{
  constructor(props){
    super(props);
    this.state={
      firstEmail:'',
      secondEmail:'',
      showGoMobile:false,
    }
  }
  goMobile = () =>{
    window.location.href= 'https://m.ivydocuments.com/?from_web=true';
  }
  componentDidMount(){
    const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (vw < 700 && !(window.location.search.indexOf('from_mobile')>-1)){
      this.goMobile();
    }
    let loggedIn = localStorage.getItem('loggedIn');
		if (loggedIn){
			window.location.href = '/home';
		}
  }
  onChangeFirstEmail = (e) => {
		this.setState({
			firstEmail: e.target.value,
			error: '',
		});
	}
  onChangeSecondEmail = (e) => {
		this.setState({
			secondEmail: e.target.value,
			error: '',
		});
	}
  onAttemptFirstEmailSignup = () => {
			Request.authRequest(Routes.auth.signup, 'POST', JSON.stringify({
				email: this.state.firstEmail,
			}), 'application/json').then((response) => {
				if (response.status === 200) {
					response.json().then((body) => {
						window.location.href = '/resend-confirm';
					});
				} else if
					(response.status === 409){
						this.setState({ error: 'User with this email already exists.' });
				}
				else {
					response.json().then((error) => {
						this.setState({ error: error.message });
					});
				}
			}).catch((error) => {
				this.setState({ error: error.message });
			});
		}
  goSignup = () =>{
    window.location.href = '/signup';
  }
	handleFirstEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAttemptFirstEmailSignup();
    }
  }
  onAttemptSecondEmailSignup = () => {
			Request.authRequest(Routes.auth.signup, 'POST', JSON.stringify({
				email: this.state.secondEmail,
			}), 'application/json').then((response) => {
				if (response.status === 200) {
					response.json().then((body) => {
						window.location.href = '/resend-confirm';
					});
				} else if
					(response.status === 409){
						this.setState({ error: 'User with this email already exists.' });
				}
				else {
					response.json().then((error) => {
						this.setState({ error: error.message });
					});
				}
			}).catch((error) => {
				this.setState({ error: error.message });
			});
		}
	handleSecondEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAttemptSecondEmailSignup();
    }
  }
  render(){
    return(
      <div className = "pageContainer">
        <div className = "pageContents">


        <div className = "landing-header boxShadow">
          <img src = {tagsBanner}
            className = "landing-banner"
            alt = "Tag your Documents and Files with Ivy"
            title = "Tag your Documents and Files with Ivy"/>
          <div className = "tagsBannerText">
            <div className = "bannerTitle">Next Gen File Management</div>
            <div className = "bannerText">Tags Based Organization, Metadata, Version History,
            Expiration Tracking, Document Approval Workflows.
            <br/>
            Everything you need.</div>
          <button className = "landing-imageButton" onClick = {this.goSignup}>Get Started</button>
          </div>
        </div>

          <InteractiveDemo/>
        <div className = "landing-header customerReview boxShadow">
          <div className = "customerReview-header">See what our customers are saying</div>
        <div className = "pricingPlan-customerQuote">
        &ldquo;  Ivy Documents is a simple and straightforward document management system. Over the past few months,
          Ivy has transformed the way we operate using our documents.
          Ivy Documents is a great platform for easy document storage and retrieval
          using their tagging system. Any changes made in the system are also traceable.
          As roles of users are customizable, Ivy Documents allowed us to pass the ISO 17025
          audit criteria. The technical support team has also been valuable in helping us meet
          our specific needs. We are happy to be associated with Ivy Documents and look forward to a long-term association.&rdquo;
        </div>
            <div className = "pricingPlan-author">— Aytunc Atabek, Sr. Director of Global Quality and R&D, Vita Coco</div>
        </div>
        <div className = "landing-header boxShadow">
          <img src = {foldersBanner}
            className = "landing-banner"
            alt = "Tag your Documents and Files with Ivy"
            title = "Tag your Documents and Files with Ivy"/>
          <div className = "foldersBannerText">
          <div className = "bannerTitle">Folders Suck.</div>
            <div className = "bannerText">Files don’t fit within just one folder, creating a
              mass of duplicate and unrelated folders, versioning nightmares,
               and lost documents, wasting you and your team time. </div>
               <a href = "https://meetings.hubspot.com/nathan220"
                 >
              <button className = "landing-imageButton">Book a Demo</button></a>
           </div>
        </div>
        <div className = "landing-section landing-secondsignup boxShadow">
          <div className = "landing-sectionTitle"> Organization should make sense. Folders don't.

          </div>
          <div className = "landing-paragraphText">Stop digging through your folders and pulling out documents.
            Work with the documents you want in the context you want.
            Simple file management, using Ivy's document tagging system.
          </div>
          <input type='text' onChange={this.onChangeSecondEmail}
            id='email' className='landing-input-text'
            placeholder = {'Enter your email'}
            onKeyPress = {this.handleSecondEnter}/>
    			{this.state.error}
    			<button className='landing-form-button primary'
            onClick={this.onAttemptSecondEmailSignup}> Get Started</button>

        </div>


        </div>
      </div>
    )
  }
}

export default LandingPage;
