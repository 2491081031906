import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './TagDictionary.css';
import ToggleSwitch from '../Tags/ToggleSwitch';
import qs from 'qs';

import TagDictionaryEntry from './TagDictionaryEntry';
import { toISODateString } from 'react-dates';
class TagDictionary extends React.Component {
  constructor() {
    super();
    this.setAlphabetLetter = this.setAlphabetLetter.bind(this);
    this.onMakeDictionary = this.onMakeDictionary.bind(this);
    this.state = {
      tags: [],
      letter: '',
    }
  }
  componentDidMount() {
    this.onGetTags();

  }
  onGetTags() {
    const searchString = {
      page: 1,
      per_page: 300, //arbitrarily large number
      nested: this.props.isNestedFiltered
    };
    let base_url = qs.stringify(searchString);
    base_url = '?' + base_url
    Request.authRequest(Routes.tags.search(base_url),
      'GET').then((response) => {
        if (response.ok) {
          response.json().then(body => {
            this.onMakeDictionary(body.results)
          })
        }

      });
  }
  clearLetter = () => {
    this.setState({ tags: '' })
  }
  onMakeDictionary(tags) {

    let dict = tags.reduce((accumulator, currentValue) => {
      // c[0] should be the first letter of an entry

      if (currentValue.name.length > 0) {
        let firstLetter = currentValue.name[0].toLocaleUpperCase()

        // either push to an existing dict entry or create one
        if (accumulator[firstLetter]) {
          accumulator[firstLetter].push(currentValue)
        }
        else {
          accumulator[firstLetter] = [currentValue]
        }


      }
      return accumulator
    }, {})
    // flatten the dictionary to an array
    this.setState({ tags: Object.entries(dict).sort() });

    if (this.props.letter) {
      this.setAlphabetLetter(Object.entries(dict).sort().filter(
        (tag) => tag[0] === this.props.letter)[0]);
    }
  }
  setAlphabetLetter = (letter) => {

    this.props.setTags(letter);
  }
  onClearSelectedLetter = () => {

    this.props.onClearSelectedLetter();
  }
  render() {
    let alphabet;
    if (this.state.tags.length > 0) {
      alphabet = this.state.tags.map((entity, i) => {
        return <TagDictionaryEntry letter={entity}
          key={i}
          setAlphabetLetter={this.setAlphabetLetter}
          activeLetter={this.props.letter} />;
      })
    }
    let isFiltered = this.props.isFiltered || this.props.isIngredientFiltered;
    let isNestedFiltered = this.props.isNestedFiltered;
    return (
      <div className="tagDictionary-container">
        <div className="tagDictionary-headerButtons">
          <div className="tagDictionary-topTagButton" id={this.props.letter ? null : "activeTextGreen"}
            onClick={this.onClearSelectedLetter}>All Tags</div>
          {this.props.showFilter ? <div>
            <div>
              <div className="tagDictionary-toggleSwitch">
                <div className="tagDictionary-filterLabel" id={isFiltered ? "activeTextGreen" : null}>Filter</div>
                <ToggleSwitch toggleSwitch={this.props.toggleSwitch} active={isFiltered} />
              </div>
            </div>
            <div className="tagDictionary-toggleSwitch">
              <div className="tagDictionary-filterLabel" id={isNestedFiltered ? "activeTextGreen" : null}>Top Level Only</div>
              <ToggleSwitch toggleSwitch={this.props.toggleNestedSwitch} active={isNestedFiltered} />
            </div>
          </div> : null}
        </div>
        <div className="tagDictionary-alphabet">{alphabet}</div>

      </div>)
  }
}
export default TagDictionary;
