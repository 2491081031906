import React from 'react';
import './BatchSheetViewer.css';
import './BatchSheetRow.css';
class BatchSheetRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weight_basis_kg: 100,
        }
    }
    render() {
        let ingredient = this.props.ingredient;
        let properties;
        if (ingredient.properties) {
            properties = Object.entries(ingredient.properties).map((property, i) => {
                return <div className=" batchSheet-field">
                    <div>{property[0]}</div>
                    <div>{property[1]}</div>
                </div>
            })
        }
        return (
            <div className="batchSheetTable-rowContainer">
                <tr className="batchSheetTable-row">
                    <td className="wordbreak ingredient-name">
                        {ingredient.ingredient_name}
                    </td>
                    <td className="wordbreak ingredient-percent">
                        {ingredient.weight_ratio.toFixed(4)}
                    </td>
                    <td className="wordbreak batchSheet-field">
                        {ingredient.f_weight_kg}
                    </td>
                    <td className="wordbreak batchSheet-field">
                        {ingredient.f_weight_lb}
                    </td>
                    {this.props.ignore_density ? null : <td className="wordbreak batchSheet-field">
                        {ingredient.f_vol_L}
                    </td>}
                    {this.props.ignore_density ? null : <td className="wordbreak batchSheet-field">
                        {ingredient.f_vol_gal}
                    </td>}

                    {this.props.ignore_density ? null : <td className="wordbreak batchSheet-minor-field">
                        {ingredient.f_density_kg_L}
                    </td>}
                    {this.props.ignore_density ? null : <td className="wordbreak batchSheet-minor-field">
                        {ingredient.f_density_lb_gal}
                    </td>}
                    <td className="batchIngredient-propertiesContainer">{properties}</td>

                </tr>

            </div >
        )
    }
}
export default BatchSheetRow;