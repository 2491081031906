import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import Modal from '../../Modal/Modal';
import VersionHistoryReportComponent from './VersionHistoryReportComponent';
class VersionHistoryReportButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
        versionHistory:[],
        showReport:false,
        paid:true,
    }
  }
  componentDidMount=()=>{
    this.paidCheck();
  }
  paidCheck = ()=>{
    Request.authRequest(Routes.organization.index,
      'GET').then((response)=>{
        if (response.status===200){
        response.json().then(body=>{
          this.setState({paid:body.paid})
            })
          }
          else {
            response.json().then((error) => {
              toast.error(error.message);
            });
          }
        }).catch((error) => {
          toast.error(error.message);
        }
        )
  }
  onShowModal=()=>{
    this.setState({showReport:true})
    this.onGetVersionHistoryReport();
  }
  onHideModal=()=>{
    this.setState({showReport:false})
  }
  onGetVersionHistoryReport = () =>{
  if (!this.props.doc){
    return
  }
  Request.authRequest(Routes.documents.versionReport(this.props.doc.document.id),
  	'GET').then((response)=>{
  		if (response.status===200){
  		response.json().then(body=>{
  			this.setState({versionHistory:body, loaded:true})

  				})
  			}
  			else {
  				response.json().then((error) => {
  					toast.error(error.message);
  				});
  			}
  		}).catch((error) => {
  			toast.error(error.message);
  		}
  		)
  }
  render(){
    let versions = <div className = "loaderContainer"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>;
    if (this.state.loaded){
        versions = "No other versions";
      }
    if (this.state.versionHistory.length>0){
      versions = this.state.versionHistory.map((version,i)=>{
        return <VersionHistoryReportComponent version = {version} key = {i}/>
      })
    }

    return(
      <div >
        <div onClick = {this.onShowModal} className = "viewVersionReportButton">View Version Report</div>
        <Modal show={this.state.showReport} onClose={this.onHideModal} title='Version History Report' >
        <div>
          To print this page or save as pdf, right click and press Print.

          {this.state.paid?versions:"This is a paid feature. Please upgrade to view all of your version information in one place!"}
        </div>
      </Modal>
      </div>
    )
  }
}

export default VersionHistoryReportButton;
