import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import NotificationSettingsCheckbox from './NotificationSettingsCheckbox';
class NotificationSettings extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      receive_signature_completed:false,
      receive_signature_request:false,
      receive_signature_reminder:false,
      receive_document_approval:false,
      receive_document_reminder:false,
    }
  }
  componentDidMount = () =>{
    this.onGetNotificationsSettings();
  }
  onGetNotificationsSettings=()=>{
    Request.authRequest(Routes.notifications.settings,'GET').then((response)=>{
      if (response.status ===200){
          response.json().then(body => {
            this.setState({receive_signature_completed:body.receive_signature_completed,
              receive_signature_request:body.receive_signature_request,
              receive_signature_reminder:body.receive_signature_reminder,
              receive_document_approval:body.receive_document_approval,
              receive_document_reminder:body.receive_document_reminder,
            })})
      }
    })
  }
  onChangeCheckbox = (type,value)=>{
    switch (type) {
      case 'Signature Completion':
        this.setState({
          receive_signature_completed:!value
        },()=> this.changeNotificationSettings())
        break;
      case 'Signature Requests':
      this.setState({
        receive_signature_request:!value
      },()=> this.changeNotificationSettings())
        break;
      case 'Signature Reminders':
      this.setState({
        receive_signature_reminder:!value
      },()=> this.changeNotificationSettings())
      break;
      case 'Document Approvals':
      this.setState({
        receive_document_approval:!value
      },()=> this.changeNotificationSettings())
      break;
      case 'Document Reminders':
        this.setState({
      receive_document_reminder : !value
    }, ()=>this.changeNotificationSettings())
        break;

    }
  }
  changeNotificationSettings = () =>{
    Request.authRequest(Routes.notifications.settings,'PUT',
    JSON.stringify(this.state),'application/json').then(
        (response) => {
          if (response.status===200){
          response.json().then(body => {
            this.onGetNotificationsSettings();
            toast.success('Changed Individual Email Notification Settings')
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
    toast.error(error.message);
    }
  )
  }
  render(){
    let options = this.props.user.notification_settings;
    let receive_signature_completed = <NotificationSettingsCheckbox notificationType = {'Signature Completion'}
      isChecked = {this.state.receive_signature_completed} onChangeCheckbox = {this.onChangeCheckbox}/>
    let receive_signature_request = <NotificationSettingsCheckbox notificationType = {'Signature Requests'}
      isChecked = {this.state.receive_signature_request} onChangeCheckbox = {this.onChangeCheckbox}/>
    let receive_signature_reminder = <NotificationSettingsCheckbox notificationType = {'Signature Reminders'}
      isChecked = {this.state.receive_signature_reminder} onChangeCheckbox = {this.onChangeCheckbox}/>
    let receive_document_approval = <NotificationSettingsCheckbox notificationType = {'Document Approvals'}
      isChecked = {this.state.receive_document_approval} onChangeCheckbox = {this.onChangeCheckbox}/>
    let receive_document_reminder = <NotificationSettingsCheckbox notificationType = {'Document Reminders'}
      isChecked = {this.state.receive_document_reminder} onChangeCheckbox = {this.onChangeCheckbox}/>
    return(
      <div>
        <div className = "individualNotificationSettings-title">Your Personal Email Settings </div>
        {receive_signature_completed}
        {receive_signature_request}
        {receive_signature_reminder}
        {receive_document_approval}
        {receive_document_reminder}
      </div>
    )
  }
}

export default NotificationSettings;
