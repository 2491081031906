import React from 'react';
import './PreviewButton.css';
import Routes from '../../helpers/Routes';
import Loadable from 'react-loadable';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import {Eye, LeftArrow,RightArrow,ZoomIn,ZoomOut} from '../Icons/IconLibrary';

const LoadablePDFPage = Loadable({
	loader: () => import('react-pdf'),
	loading() {
    return <div>Loading...</div>
  },
  render(loaded, props) {
    let Page = loaded.Page;
    return <Page {...props}/>;
  }
});
const LoadablePDFDocument = Loadable({
  loader: () => import('react-pdf/dist/entry.webpack'),
	loading() {
    return <div>Loading...</div>
  },
  render(loaded, props) {
    let Document = loaded.Document;
    return <Document {...props}/>;
  }
});
class PreviewButton extends React.Component {
	constructor(){
		super();
		this.onPreview = this.onPreview.bind(this);
		this.state={
		preview:false,
		numPages: null,
    pageNumber: 1,
		scale:1.2,
		retrievedDoc:''
		}
	}
	onPreview = (e) =>{
  //   if (!this.props.doc.document.preview_content_type)){
  //     toast.error(`We currently only support docx, doc, xlsx, csv, ppt, png, jpg, and pdf previewing. If you'd like other files, please sent us a message at help@ivydocuments.com.`)
	// 		return
  //   }
		window.addEventListener("keyup", this.handleEscape, false);

		if (!this.props.doc.document.preview_content_type){
		Request.authRequest(Routes.documents.edit(this.props.doc.document.id),
											  'GET').then((response) => {
            if (response.status === 200) {

      			response.json().then(body => {
      				this.setState({preview: body['document_service_information']['previewUrl'],retrievedDoc:body});
							if (!body['document_service_information']['previewUrl']){
								toast.error('This file type is not supported, or the file is too large to be previewed (max 25 MB).');
							}
      					}
				)
      }
			}
		);
		return;
		}
		this.setState({preview: this.props.doc.document_service_information.previewUrl});
		if (!this.props.doc.document_service_information.previewUrl){
			toast.error('This file type is not supported, or the file is too large to be previewed (max 25 MB).');
		}
	}
	handleEscape=(e)=>{
		if (e.key === "Escape"){
			this.onClosePreview();
		}
	}
	onClosePreview=(e)=>{
		window.removeEventListener('keyup',this.handleEscape)
		this.setState({preview:false,pageNumber:1,numPages:null,scale:1.2})
	}
	zoomIn = () => {
		this.setState({scale:this.state.scale+.2})
	}
	zoomOut = () =>{
		this.setState({scale: Math.max(this.state.scale-.2,.3)})
	}
	componentDidUpdate(prevProps){
		if (prevProps.doc !== this.props.doc){
			this.setState({preview:false})
		}
	}
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }
	pageGoBack = ()=>{
		this.setState({pageNumber:Math.max(this.state.pageNumber-1,1)})
	}
	pageGoForward= ()=>{
		this.setState({pageNumber:Math.min(this.state.pageNumber+1,this.state.numPages)})
	}
	pageInputChange = (e)=>{
		let number = e.target.value;
		let newPage = parseInt(number,10);
		if (number < 1){
			newPage = 1;
		}
		if (number > this.state.numPages){
			newPage = this.state.numPages;
		}
		this.setState({pageNumber:newPage})
	}
  render(){
		let document = this.props.doc;
		if (this.state.retrievedDoc){
			document=this.state.retrievedDoc;
		}
    if (!document){
      return <span className={this.props.templates?'button-preview-templates':'button-preview'} >Preview</span>
    }
		if (this.state.preview){
      if (document.document.preview_content_type === 'image/jpeg' || document.document.preview_content_type === 'image/png' ||
			document.document.preview_content_type === 'image/gif'|| document.document.preview_content_type === 'image/webp'){
				return(
						<div className = 'preview-png'>
							<div className = "preview-png-previewer">
								<img src = {this.state.preview} className = "imagePreviewer-container" alt = ""/>
							</div>
							<button className = 'closePreview'
								onClick = {this.onClosePreview}>
								Close
							</button>
						</div>
					)
			}
			else if (document.document.preview_content_type === 'application/pdf'){
				const { pageNumber, numPages } = this.state;
				return (
					<div className = "preview" id = "preview-pdf">
					<LoadablePDFDocument
          	file={this.state.preview}
          	onLoadSuccess={this.onDocumentLoadSuccess}
            loading = {<div className = "loadingPDFMessage"> Loading PDF...
            </div>}>
						<LoadablePDFPage pageNumber={pageNumber} scale = {this.state.scale} />
							<button className = 'closePreview'
								onClick = {this.onClosePreview}>
								Close
							</button>
					</LoadablePDFDocument>
					<div className = "pdf-pages">
							<img src = {LeftArrow} className = "pdfPageButtons"
								onClick = {this.pageGoBack} alt = "Previous Page" title = "Previous Page"/>
								<div>Page <input
									value = {this.state.pageNumber}
									className = "pdf-pagesInput"
									onChange = {this.pageInputChange}/> of {numPages}</div>
							<img src = {RightArrow} className = "pdfPageButtons"
								onClick = {this.pageGoForward} alt = "Next Page" title = "Next Page"/>
							<img src = {ZoomIn} className = "pdfPageButtons"
								onClick = {this.zoomIn} alt = "Zoom In" title = "Zoom In"/>
							<img src = {ZoomOut} className = "pdfPageButtons"
								onClick = {this.zoomOut} alt = "Zoom Out" title = "Zoom Out"/>
						</div>
						<div onClick = {this.onClosePreview} className ="pdfCloseButton"></div>

      </div>

				)
			}
			else {
				return (
					<div className = "preview">
						<div className = "preview-errorText">This file type is not supported. We currently only support docx, doc, xlsx, csv, ppt, png, jpg, and pdf previewing.
						</div></div>
				)
			}
    }
		if (this.props.documentViewer){
			return (<img src = {Eye} className='button-preview-documentViewer'
				onClick = {this.onPreview} alt = "Preview the document" title = "Preview the document"/>)
		}

		return (
			<span className={this.props.templates?'button-preview-templates':'button-preview'} onClick = {this.onPreview}>Preview</span>
		)
  }
}
export default PreviewButton
