import React from 'react';
import './RecentlySent.css';
class AcceptedExternalDocRow extends React.Component{
  goDoc = ()=>{
    window.location.href=`/documents/${this.props.signature.permission_to_sign.document_id}`;
  }
  render(){
    let signature = this.props.signature;
    let statusDotColor;
    let status = "Pending";
    if (signature.status ==="Signed"){
      statusDotColor = {backgroundColor: "#18A54D"};
      status = "Signed"
    } else if (signature.status ==="Rejected"){
      statusDotColor = {backgroundColor:"#E65531"};
      status = "Rejected";
    }
    else {
      statusDotColor = {backgroundColor:"#FFB50B"}
    }
    let comment = "None";
    if (signature.comment){
      comment = signature.comment;
    }
    let dateCreated = "Requested on " + new Date(signature.date_created).toDateString();
    let date;
    if (signature.date_modified){
      date = new Date(signature.date_modified).toDateString();
    }
    let reminderCounter;
    if (signature.reminder_counter && signature.reminder_counter>0){
      reminderCounter = <div className = "signature-reminderNumber">Reminded {signature.reminder_counter} times</div>
    }
    return(
      <div className = "versionReport-signature">
      <div className = "versionReport-signatureComponent" onClick = {this.goDoc}>
      <div className = "signature-signatureInfo">
        <div>{signature.user_to_sign.first_name} {signature.user_to_sign.last_name} signed {signature.permission_to_sign.name}
        </div>
        <div>{signature.user_to_sign.job_title} {signature.user_to_sign.email}
        </div>
        {dateCreated}{reminderCounter}
      </div>
      <div className = "signature-componentRightSide">
      <div className = "signature-signatureStatus">
      <div className = "statusDot" style = {statusDotColor}></div>
        {status}
      </div>
      {date}
      {signature.status=='Rejected'?<div className = "signature-rejectedComment">Reason for rejection: {comment}</div>:null}

      </div>
    </div>

  </div>)
  }

}

export default AcceptedExternalDocRow;
