import React from 'react';
import './TagAccess.css';
import { DropTarget } from 'react-dnd';
import SelectedTag from './SelectedTag';
const receiverTarget = {
  drop(props,monitor,component) {
    component.onDropCurrentTag(monitor.getItem());
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),

  };
}
class SelectedTagContainer extends React.Component{

  onDropCurrentTag= (tag)=>{
    this.props.onAddTag(tag.name);
  }
  render(){
    let selectedTags = <div className = "smallHelpText"> Drag and drop from below or click to select</div>
    if (this.props.activeTags.length>0){

      selectedTags = this.props.activeTags.map((tag,i)=>{
        return <SelectedTag tag = {tag} key = {i} onRemoveTag = {this.props.onRemoveTag}/>
      })
    }
    const {connectDropTarget} = this.props;
    return connectDropTarget(
      <div className = "tagAccess-activeTags">
        {selectedTags}
            </div>
    )
  }
}
export default DropTarget('tag', receiverTarget, collect)(SelectedTagContainer);
