import React from 'react';
import Routes from './helpers/Routes';
import Request from './helpers/Request';
import './ErrorBoundary.css';
import {toast} from 'react-toastify';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo:'' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(errorInfo, info) {
    // You can also log the error to an error reporting service
    if (window.location.host == 'localhost:3000'){
      return
    }
    Request.authRequest(Routes.errorReporting.frontEnd,"POST",
      JSON.stringify({errorInfo:`${errorInfo}`,location:info,
        url:window.location.href}),
      'application/json').then((response)=>{
        if (response.status === 200 ){
          toast.success(`We've sent an error report to our team.`)
        }
        else {
          response.json().then((error) => {
            toast.error(error.message);
          });
        }
    }).catch((error) => {
    toast.error(error.message);
    }
    )
  }
  goToHome = () =>{
    window.location.href = '/home';
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
      <div className = "errorBoundaryContainer boxShadow"><h1>Uh Oh! </h1>
        It looks like something went wrong. An error report has already been sent to our team.
        We'd love your help in resolving this as fast as possible - please send a description of what you were doing to
        <br/>
        <h2>help@ivydocuments.com </h2><br/>
        and attach any screenshots. 
        Please note we do not fully support Internet Explorer 11 which may be the cause of this crash - use
        Chrome, Firefox or Safari
        Thanks and we're working on it!
        <div onClick = {this.goToHome} className = "primary modal-button boxShadow">Click here to get back to Ivy.</div>

      </div>
    )
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
