import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
class ResendConfirm extends React.Component{
  constructor(){
    super();
    this.state={
      email:'',
      showHelp:false,
    }
  }
  onChangeEmail = (e) => {
		this.setState({
			email: e.target.value,
			error: '',
		});
	}
  onHandleClick=(e)=>{
    Request.authRequest(Routes.auth.signup, 'POST', JSON.stringify({
      email: this.state.email,
    }), 'application/json').then((response) => {
      if (response.status === 200) {
        response.json().then((body) => {
          this.setState({showHelp:true})
        });
      } else if
        (response.status === 409){
          this.setState({ error: 'User with this email already exists.' });
      }
      else {
        response.json().then((error) => {
          this.setState({ error: error.message });
        });
      }
    }).catch((error) => {
      this.setState({ error: error.message });
    });
  }
  onReturnLogin = () =>{
    localStorage.removeItem('token');
		window.location.href = '/login';
  }
  render(){
    return (
    <div className='helpText-confirmation boxShadow'>
    <h2>Please check your inbox for a confirmation email to verify your account. </h2>
    <br/>
      <br/>If you have not receieved an email,
      press the button below to send another email.
      Enter your email to resend confirmation.
      <input type='text' onChange={this.onChangeEmail} id='email' className='input-text'/>
      <button className ="form-button primary" onClick = {this.onHandleClick}>Resend Confirmation
      </button>
      {this.state.showHelp?<div>Thanks. Another email has been sent.
      </div>:null}
      {this.state.error}
      {this.state.error? 'If there is no email found, you might have signed up under an incorrect email. Please try signing up again':null}

      <button className = "form-button secondary" onClick = {this.onReturnLogin}> Return to Login</button>
    </div>);
  }
}

export default ResendConfirm;
