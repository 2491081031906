import React from 'react';
import PropTypes from 'prop-types';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './Logs.css';
import ShortLogsRow from './ShortLogsRow';

import {Link} from 'react-router-dom';
class Logs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			logs: [],
			perPage:8,
			page: 1,
			total_results: 0,
			isLoaded:false,
		}
	}
	onChangePage(pageNumber){
		this.setState({page:pageNumber});
	}
	componentDidMount() {
		this.onGetLogs();
	}
	onGetLogs(){
		Request.authRequest(Routes.logs.recipeAppList('?logs_per_page=8'), 'GET').then((response) => {
			if (response.status === 200) {
				response.json().then((logs) => {
					this.setState({ logs:logs.results,isLoaded:true,total_logs:logs.total_results});

				})
			} else {
				response.json().then((error) => {
					this.setState({ error: error.message });
				});
			}
		}).catch((error) => {
			this.setState({ error: error.message });
		});
	}

	render() {
		let logs = <div className = "loaderContainer"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>;
			if (this.state.isLoaded){
				logs = <div className = "smallHelpText">No Logs</div>
			}
		if (this.state.logs.length >0){
			 logs = this.state.logs.map((log, i) => {
		  return <ShortLogsRow log = {log} key = {i}/>
		});}
		return (

					<div className="logSummary">
						<div className='logsheader noselect ' id = 'logs-header'>
							Recent Changes
						</div>
						{logs}
						<Link  className = "summaryLogs-seeMore" to ='/logs'> See More </Link>
					</div>


		);
	}
}

Logs.propTypes = {
	summary: PropTypes.bool,
}

export default Logs;
