import React from 'react';
import PreviewButton from '../../Buttons/PreviewButton';
class IngredientDocumentRow extends React.Component {

	onGoToViewer = (e) =>{
		e.stopPropagation();
		window.location.href= `/documents/${this.props.document.document.id}`;
	}
	render() {

		const doc = this.props.document;
		let tagList;
		let date_expiration;
		let version_number;
		if (doc.version_number){
			version_number = 'v' + doc.version_number;
			if (doc.version_history.length>0 && doc.version_number === doc.version_history.length + doc.version_history[doc.version_history.length-1].version_number){
				version_number = version_number + " - current"
			}
      else if (doc.version_history && doc.version_history.length>0 &&
        doc.version_number > doc.version_history[0].version_number){
        version_number = version_number + " - current";
      }
		}
    let draft;
    if (doc.tags && doc.tags.length>0){
      let tag_names = doc.tags.map((entity,i)=>{
 			    return entity.name
 		   })
      if (tag_names.indexOf("Draft")>-1){
        draft = "DRAFT"
      }
    }

		if (!doc.version_number && doc.version_history.length>0){
			version_number = 'v0'
		}
    if (doc.date_expiration===null){
      date_expiration='';
    }
    else{
      date_expiration=new Date(doc.date_expiration);
			date_expiration = date_expiration.toDateString();

    }
		let user_defined_id;
    let showExpiredDot;
    var date = new Date();
    if (date.toISOString() > doc.date_expiration){
      showExpiredDot = true;

    }
		if (doc.user_defined_id !== 'null'&& doc.user_defined_id && doc.user_defined_id!=='undefined'){
			user_defined_id = doc.user_defined_id
		}
	    const {connectDropTarget} = this.props;
	    return connectDropTarget(
			<div className = 'document-row'
				id={this.state.isActive?'document-row-active':null}  onClick ={this.onClickDocumentRow}>
					<div className = "document-row-info">
						<span className='document-row-name wordbreak'> {doc.name} </span>
						<span className='document-row-id wordbreak'> {user_defined_id} {version_number} {draft}</span>
					</div>

						<span className = 'document-expiration-date'>
              {showExpiredDot?<div
                className = "expiredDot"/>:null}
              {date_expiration}</span>


			  			<PreviewButton doc  = {doc}/>
						<div className = "button-document-row-infoViewer" onClick = {this.onGoToViewer}>Details</div>


			</div>
		);
	}
}

export default IngredientDocumentRow;
