import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
class NotificationFrequency extends React.Component{
  constructor(props){
    super(props);
    this.onSetPreferences= this.onSetPreferences.bind(this);
    this.state={
      editing:false,
      frequency:props.frequency,
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.frequency!== this.props.frequency ){
      this.setState({frequency:this.props.frequency});
    }
  }
  onSetPreferences(){
    if ( this.state.frequency === this.props.frequency){
      return
    }
    const notification_settings = {notification_frequency:this.state.frequency}
    Request.authRequest(Routes.notifications.settings,'PUT',JSON.stringify(notification_settings),'application/json').then(
          (response) => {
            if (response.status ===200){
            response.json().then(body => {
                toast.success('Changed notification settings!');
                this.props.onCancel();
              });
            } else {
              response.json().then((error) => {
                toast.error(error.message);
              });
            }
      }).catch((error) => {
        toast.error(error.message);
      }
    )

  }


  onChangeFrequency = (e) => {

		this.setState({
			frequency: e.target.value,
		});

	}

  render(){

    return (
      <div className = "notificationsSettings">
            <div className = "notificationsSettings-value">
              <label className='document-label' > Days between Notifications </label>


                <input value={this.state.frequency}
                  type = 'number'
                  className='notification-info-field'
                  onChange= {this.onChangeFrequency}
                  onKeyPress={this.handleEnter}/>
                <div className = "helpText"> To receive emails daily, enter 1. To receive emails weekly, enter 7.
                   <br/>
                   Any other whole number values are supported.</div>
          </div>

          <div className = "notificationsSettings-savebuttons">
            <button className = "smallButton primarybutton" onClick = {this.onSetPreferences}> Save </button>
            <button className = "smallButton secondarybutton" onClick = {this.props.onCancel}>Close</button>
          </div>
        </div>)

  }
}
export default NotificationFrequency;
