import React from 'react';
import './Buttons.css';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import TagCleaner from '../../helpers/TagCleaner';
import TagsSearch from '../TagsSearch/TagsSearch';
class AddChildTagButton extends React.Component{
  constructor(){
    super();
    this.onEnterTagName = this.onEnterTagName.bind(this);
    this.state={
      tagNames :'',
      showModal:false,

    }
  }
  onShowModal = (e) =>{
    e.stopPropagation();
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false,tagNames:''
  })}

  onEnterTagName = (tags) => {
		this.setState({
			tagNames: tags
		});
	}

  onAddTag = () =>{
    if (!this.state.tagNames){
      toast.error('Please enter tags. Empty tag names are not allowed.')
      return
    }
    let trimmedTags = TagCleaner.parseTagArray(this.state.tagNames);
    if (trimmedTags.length === 0){
      toast.error('Please enter tags. Empty tag names are not allowed.')
      return
    }
    if (this.state.tagNames.length>500){
      toast.error('This field is limited to 500 characters. Please trim your entry.')
      return
    }
    let tag = this.props.tag;
    let tag_child_names = tag.child_tags.map(x => x.name)
    let tag_parent_names= [];
    if (tag.parent_tags){
      if (tag.parent_tags.length > 0) {
      tag_parent_names = tag.parent_tags.map(x => x.name)}
    }
    Request.authRequest(Routes.tags.tag(this.props.tag.name),
                      'PUT',JSON.stringify({
                        child_tag_names:[...tag_child_names,...trimmedTags],
                        parent_tag_names:tag_parent_names
                      }), 'application/json'

                    ).then((response) => {
                      if (response.status ===200){
                      response.json().then(body => {
                        this.props.replaceTagInfo(body);
                        this.setState({tagNames:'',showModal:false});
                        this.props.onGetTags(this.props.tag.name);
                          toast.success('Successfully added tags');
                        });
                      } else {
                        response.json().then((error) => {

                          toast.error(error.message);
                          if (error.errors){
          									toast.error(error.errors[0])
          								}
                        });
                      }
                }).catch((error) => {
                  toast.error(error.message);
                }
              )
  }
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAddTag();
    }
  }
  onEscape (){
    return}
  renderModal(){
    if (this.props.tag){
      return(<div className = "modalText">
          You currently have the {this.props.tag.name} tag selected.
          Enter the name of the child tags you'd like to add, separated by commas.
          <TagsSearch onSendTags = {this.onEnterTagName}
          onEsc = {this.onEscape}/>

        <button className= 'modal-button primary' onClick={this.onAddTag}> Create Tag </button>
      </div>)
    }
    else {
      return (<div className = "modalText"> Please select a tag</div>)
    }
  }
  selectButtonType(){
    if (this.props.page==="tags"){
      return(<button className='headerButton noselect' id = "buttonAddSubTagTagsPage" onClick = {this.onShowModal}>
          <span>+</span>
      </button>)
    }
    else{
      return(
        <button className='headerButton noselect' id = "buttonAddSubTag" onClick = {this.onShowModal}>
            Add Child
        </button>)}
  }
  render() {
    const modalText=this.renderModal();
    const buttonType=this.selectButtonType();
    return (
      <div id = "addSubTag-container">
        {buttonType}

        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Add Child Tags' >
          {modalText}
        </Modal>
      </div>
    )
  }
}
export default AddChildTagButton;
