import React from 'react';
import {SearchIcon} from '../Icons/IconLibrary';
import './Search.css'

class Search extends React.Component{
  constructor(){
    super();
    this.timerID = null;
    this.state={
      searchQuery:'',

    }
  }
  componentDidMount=()=>{
    if (this.props.query){
      this.setState({
        searchQuery:this.props.query
      })
    }
  }
  onChangeSearchQuery = (e) => {
		this.setState({
			searchQuery: e.target.value,
		});
	}
  onClearSearch = () => {
		this.setState({
			searchQuery: '',
		});
    this.props.sendSearch('')
	}

  handleKeyPress = (e) => {
    if (this.timerID) {
      window.clearTimeout(this.timerID);
    }
    this.timerID = window.setTimeout(() => {this.props.sendSearch(this.state.searchQuery)},400)

    if (e.key === 'Enter') {
      this.props.sendSearch(this.state.searchQuery)
    }

  }
  handleBackSpace = (e)=>{
    if (e.keyCode === 8) {
      if (this.timerID) {
        window.clearTimeout(this.timerID);
      }
      this.timerID = window.setTimeout(() => {this.props.sendSearch(this.state.searchQuery)},400)

    }
  }
  render(){
    return(
      <div className='search-container'>
            <img className='search-icon noselect' src={SearchIcon} alt=''/>

            <input
              type='text'
              className='search'
              placeholder={this.props.placeholder}
              value={this.state.searchQuery}
              onChange={this.onChangeSearchQuery}
              onKeyPress={this.handleKeyPress}
              onKeyDown={this.handleBackSpace}
            />
          <span className='search-clear noselect' onClick={this.onClearSearch}> Clear </span>
          </div>
        )
  }
}
export default Search;
