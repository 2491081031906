import React from 'react';
import './Buttons.css';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import TagCleaner from '../../helpers/TagCleaner';
import LoadingButton from './LoadingButton';
class TagButton extends React.Component{
  constructor(){
    super()
    this.state={
      tagNames :'',
      showModal:false,
      loading:false,
    }
  }
  onShowModal = () =>{
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false,tagNames:''
    })
  }

  onEnterTagNames = (e) => {
		this.setState({
			tagNames: e.target.value,
		});
	}
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onMakeTag();
    }
  }
  onMakeTag =() =>{
    if (this.state.tagNames === '') {
      toast.error('Please enter a tag name');
      return;
    }
    let tags = TagCleaner.parseTagString(this.state.tagNames);
    if (tags.length === 0){
      toast.error('Please enter tags. Empty tag names are not allowed.')
      return
    }
    if (this.state.tagNames.length>500){
      toast.error('This field is limited to 500 characters. Please trim your entry.')
      return
    }
    this.setState({loading:true});
    for (let i = 0; i < tags.length ; i ++){

        Request.authRequest(Routes.tags.list,
    											  'POST',JSON.stringify({
                        			name:tags[i]
                        		}), 'application/json').then((response) => {

                              if (response.status===200){
          			response.json().then(body => {
          				toast.success(`Successfully made tag`);
                  this.onHideModal();
                  this.props.onGetTags();
                  this.setState({loading:false, showModal:false});
                  if (this.props.onGetNewDictionary){
                    this.props.onGetNewDictionary();
                  }
                      });
                    } else {
                      response.json().then((error) => {
                        toast.error(error.message)
                      });

                    }
                }).catch((error) => {
                toast.error(error.message);
                }
            )
      }
  }

  render(){
    return (
      <div className = "button-tagnavigator-addtag">
        <button className='headerButton noselect' id = "buttonCreateTag" onClick = {this.onShowModal}>
            + Add
        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Make Tag' >
          <div>
            <div className = "helpText-CreateTagButton">Enter the name for new top level tags, separated
              by commas.
            </div>
            <input
              value={this.state.tagNames}
              type='text'
              className='input-info-field'
              placeholder = 'Enter some new tags'
              onChange={this.onEnterTagNames}
              onKeyPress = {this.handleEnter}
              />

           <button className= 'modal-button primary' > 
                  <LoadingButton onClick = {this.onMakeTag} small
        text = 'Create Tag' isLoading = {this.state.loading}/>
           </button>
          </div>
        </Modal>
      </div>
    )
  }
}
export default TagButton;
