import React from 'react';
import BatchSheetViewer from './BatchSheetViewer';
import Modal from '../../Modal/Modal';
import './BatchSheetViewer.css';
class BatchSheetViewerButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }
    onShowModal = () => {

        this.setState({ showModal: true })
    }
    onHideModal = () => {
        this.setState({
            showModal: false
        })
    }
    render() {
        return (
            <div >
                <div className="batchSheetButton-toggleModal" onClick={this.onShowModal}>View</div>
                <Modal show={this.state.showModal} onClose={this.onHideModal} title='Batch Sheet' extraWide>
                    <BatchSheetViewer recipe={this.props.recipe} doc={this.props.doc} ignore_density={this.props.ignore_density} />
                </Modal>
            </div>
        )
    }
}
export default BatchSheetViewerButton;