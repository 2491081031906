import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import SignatureComponent from './SignatureComponent';
import UserComponent from './UserComponent';
import SelectedUserComponent from './SelectedUserComponent';
import RemindSignatureButton from './RemindSignatureButton';
import ManagerSignatureComponent from './ManagerSignatureComponent';
import './Signatures.css';
import LoadingButton from '../../Buttons/LoadingButton';
class SignatureList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedUsers:[],
      organization:'',
      currentUser:'',
      urgent:false,
      managerStatus:false,
      loading:false
    }
    this.timerID = null;
  }
  componentDidMount = () =>{
    this.getOrganization();
    this.getUser();
  	}
  showDocuments = () =>{
    this.setState({showDocuments:true})
  }

  hideDocuments = () =>{
    this.setState({showDocuments:false,permission_to_update:''})
  }
  clearTimer = ()=>{
    this.timerID = null;
  }


  getOrganization = ()=>{
    Request.authRequest(Routes.organization.index, 'GET').then((response)=>{
  		if (response.status===200){
  		response.json().then(body=>{
  			this.setState({organization:body})

  				})
  			}
  			else {
  				response.json().then((error) => {
  					toast.error(error.message);
  				});
  			}
  		}).catch((error) => {
  			toast.error(error.message);
  		}
  		)
  }
  getUser = ()=>{
    Request.authRequest(Routes.profile.view, 'GET').then((response)=>{
  		if (response.status===200){
  		response.json().then(body=>{
  			this.setState({currentUser:body})

  				})
  			}
  			else {
  				response.json().then((error) => {
  					toast.error(error.message);
  				});
  			}
  		}).catch((error) => {
  			toast.error(error.message);
  		}
  		)
  }

  requestSignatures= ()=>{
    if (this.timerID) {
      return;
    }
    this.timerID = window.setTimeout(() => {this.clearTimer()},500);
    if (this.state.selectedUsers.length===0){
      toast.error('Please select a user');
      return
    }
    let emails = this.state.selectedUsers.map((user,i)=>{
      return user.email
    });
    this.setState({loading:true});
    const params = {user_emails:emails,urgent:this.state.urgent,
        manager_status:this.state.managerStatus};
    let id_to_sign;
    if (this.props.doc.document){
      id_to_sign = this.props.doc.document.id;
    }
    if (this.props.doc.recipe_id){
      id_to_sign = this.props.doc.recipe_id
    }
    Request.authRequest(Routes.documents.signature(id_to_sign),
      'POST', JSON.stringify(params),'application/json').then((response)=>{
    if (response.status===200){
    response.json().then(body=>{
      this.props.onGetDocuments();
      this.setState({selectedUsers:[],urgent:false,managerStatus:false, loading:false});
        })
      }
      else {
        response.json().then((error) => {
          this.setState({loading:false});
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      this.setState({loading:false});
      toast.error(error.message);
    }
    )

  }

  deleteSignatures = () =>{
    Request.authRequest(Routes.documents.signature(this.props.doc.document.id),
      'DELETE','application/json').then((response)=>{
    if (response.status===200){
    response.json().then(body=>{
      this.props.onGetDocuments();
      this.setState({selectedUsers:[]});
        })
      }
      else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }
  onAddUser = (user) =>{
    this.setState({selectedUsers:[...this.state.selectedUsers,user]})
  }
  onRemoveUser = (user) =>{
    this.setState({selectedUsers:this.state.selectedUsers.filter(users => users !== user)})
  }
  toggleUrgent = () =>{
    this.setState({urgent:!this.state.urgent})
  }
  toggleManagerStatus = () =>{
    this.setState({managerStatus:!this.state.managerStatus})
  }
  render(){
    let signatures = <div className = "smallHelpText">No Signatures</div>;
    let doc = this.props.doc;
    let managerSignatures;
    if (doc.signatures && doc.signatures.length>0){
      managerSignatures = doc.signatures.map((signature,i)=>{
        if (signature.is_manager_signature){
        return <ManagerSignatureComponent signature = {signature} key = {i}
          currentUser = {this.state.currentUser} onGetDocuments = {this.props.onGetDocuments}/>}
        return
        })

      signatures = doc.signatures.map((signature,i)=>{
        if (!signature.is_manager_signature){
        return <SignatureComponent signature = {signature} key = {i}
          currentUser = {this.state.currentUser} onGetDocuments = {this.props.onGetDocuments}/>}
        return
        })
      }
    let users;
    if (this.state.organization){
      users = this.state.organization.members.map((user,i)=>{
        return <UserComponent user = {user} onAddUser = {this.onAddUser} key = {i}/>
      })
    }
    let selectedUsers = <div className = "smallHelpText">None selected</div>;
    if (this.state.selectedUsers.length > 0){
      selectedUsers = this.state.selectedUsers.map((user,i)=>{
        return <SelectedUserComponent user = {user} key = {i} onRemoveUser = {this.onRemoveUser}/>
      })
    }

    return(
      <div>
      <div className = "signatures-container">
        <div className = "signatures-leftcurrentSignatures">
          <div className = "signatureTitle">Current Signatures</div>
          {managerSignatures}
          {signatures}
        </div>
        <div className = "signatures-middle">
          <div className = "manageSignatures-buttons">
            Select users from the right to request signatures, or manage signatures on the left.

            <div>Selected users
              <div>{selectedUsers}</div>
            </div>
            <div className = "signatureList-buttonContainer">
              {this.state.selectedUsers.length>0?
                <div>
                  <div className = "remindConfirmationCheckbox">
                    <input
                      checked = {this.state.urgent}
                      name = 'expiresBoolean'
                      type='checkbox'
                      id = 'expiresBoolean'
                      onChange = {this.toggleUrgent}/>
                    <div>Mark as Urgent
                    </div>
                  </div>
                  <div className = "remindConfirmationCheckbox">
                    <input
                      checked = {this.state.managerStatus}
                      name = 'expiresBoolean'
                      type='checkbox'
                      id = 'expiresBoolean'
                      onChange = {this.toggleManagerStatus}/>
                    <div>Final Approval Signatures
                    </div>
                  </div>
              </div>:null}
              <div 
                className = "signatureSmallButton-primary signatureList-signatureRequestButton">
                  <LoadingButton onClick = {this.requestSignatures} 
	text = 'Request Signatures' isLoading = {this.state.loading} small/>
                  </div>
              <div onClick = {this.deleteSignatures}
                className = "signatureSmallButton-secondary signatureRemoveButton">Remove All Unsigned Signatures</div>
              <RemindSignatureButton signatures = {this.props.doc.signatures} multiple/>
            </div>
          </div>


        </div>
        <div className = "signatures-rightUsers">
          <div>User List</div>
        {users}
        </div>

    </div>


      </div>
    )
  }
}
export default SignatureList;
