import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import UserComponent from './UserComponent';
import SelectedUserComponent from './SelectedUserComponent';
import './Signatures.css';

class SetSignatureList extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      selectedUsers:[],
      organization:'',
      currentUser:'',
      managerStatus:false,
    }
  }
  componentDidMount = () =>{
    this.getOrganization();
    this.getUser();
  	}

    toggleManagerStatus = () =>{
      this.setState({managerStatus:!this.state.managerStatus})
    }
  getOrganization = ()=>{
    Request.authRequest(Routes.organization.index, 'GET').then((response)=>{
  		if (response.status===200){
  		response.json().then(body=>{
  			this.setState({organization:body})

  				})
  			}
  			else {
  				response.json().then((error) => {
  					toast.error(error.message);
  				});
  			}
  		}).catch((error) => {
  			toast.error(error.message);
  		}
  		)
  }
  getUser = ()=>{
    Request.authRequest(Routes.profile.view, 'GET').then((response)=>{
  		if (response.status===200){
  		response.json().then(body=>{
  			this.setState({currentUser:body})

  				})
  			}
  			else {
  				response.json().then((error) => {
  					toast.error(error.message);
  				});
  			}
  		}).catch((error) => {
  			toast.error(error.message);
  		}
  		)
  }

  sendRequestedSignatures = () =>{
    this.props.sendSignatures(this.state.selectedUsers,this.state.managerStatus);
  }


  onAddUser = (user) =>{
    this.setState({selectedUsers:[...this.state.selectedUsers,user]})
  }
  onRemoveUser = (user) =>{
    this.setState({selectedUsers:this.state.selectedUsers.filter(users => users !== user)})
  }
  render(){

    let users;
    if (this.state.organization){
      users = this.state.organization.members.map((user,i)=>{
        return <UserComponent user = {user} onAddUser = {this.onAddUser} key = {i}/>
      })
    }
    let selectedUsers = <div className = "smallHelpText">None selected</div>;
    if (this.state.selectedUsers.length > 0){
      selectedUsers = this.state.selectedUsers.map((user,i)=>{
        return <SelectedUserComponent user = {user} key = {i} onRemoveUser = {this.onRemoveUser}/>
      })
    }

    return(
      <div>
      <div className = "signatures-container">

        <div className = "signatures-middle">

          <div className = "manageSignatures-buttons">
            Select users from the right to request signatures.

            <div>Selected users
              <div>{selectedUsers}</div>
            </div>
            <div className = "signatureList-buttonContainer">
              {this.state.selectedUsers.length>0?
              <div className = "remindConfirmationCheckbox">
                <input
                  checked = {this.state.managerStatus}
                  name = 'expiresBoolean'
                  type='checkbox'
                  id = 'expiresBoolean'
                  onChange = {this.toggleManagerStatus}/>
                <div>Final Approval Signatures
                </div>
              </div>:null}
              <div onClick = {this.sendRequestedSignatures}
                className = "signatureSmallButton-primary signatureList-signatureRequestButton">Save Selection</div>
              </div>
          </div>


        </div>
        <div className = "signatures-rightUsers">
          <div>User List</div>
        {users}
        </div>

    </div>


      </div>
    )
  }
}
export default SetSignatureList;
