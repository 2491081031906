import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import TagsInput from 'react-tagsinput';
import Request from '../../../helpers/Request';
class ChangeNotificationSettings extends React.Component{
  constructor(props){
    super(props);
    this.onSetPreferences= this.onSetPreferences.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state={
      editing:false,
      days:this.props.organization.notification_of_expiration,
    }
  }

  onSetPreferences(){

    if ( this.state.days !== this.props.organization.notification_of_expiration){

    let organization = this.props.organization;
    organization.notification_of_expiration =  this.state.days;
    Request.authRequest(Routes.organization.index,'PUT',JSON.stringify(organization),'application/json').then(
          (response) => {
            if (response.status ===200){
            response.json().then(body => {

                toast.success('Changed your expiration notification settings!')
                this.props.onCancel();
              });
            } else {
              response.json().then((error) => {
                toast.error(error.message);
              });
            }
      }).catch((error) => {
        toast.error(error.message);
      }
    )
  }

  }


  handleChange(days) {
    for ( let i = 0; i < days.length ; i++){
      if (typeof(days[i])!=="number"){
        days[i] = parseInt(days[i],10)
      }
    }
    this.setState({days})
  }

  render(){
    var defaultprops = {
      className: 'react-tagsinput-input',
      placeholder: 'Enter one or more numbers'
    }
    return (
      <div className = "notificationsSettings">
            <div className = "notificationsSettings-value">

                   <label className='document-label' > Expiration Notification </label>
                     <TagsInput value={this.state.days} onChange={this.handleChange}
                       addKeys={[9, 13, 32, 186, 188]}
                       inputProps={defaultprops}
                       onlyUnique
                       addOnBlur = {true}

                       />
                     <div className = "helpText"> Enter the number of days before expiration that you
                        would like to send a notification <br/> that a document is expiring.
                        Any whole number values are supported.</div>
          </div>

          <div className = "notificationsSettings-savebuttons">
            <button className = "smallButton primarybutton" onClick = {this.onSetPreferences}> Save </button>
            <button className = "smallButton secondarybutton" onClick = {this.props.onCancel}>Close</button>
          </div>
        </div>)

  }
}
export default ChangeNotificationSettings;
