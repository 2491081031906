import React from 'react';
import TagDictionaryEntry from '../../TagManager/TagDictionaryEntry';
class SentToDictionary extends React.Component{
  constructor(){
    super();
    this.setAlphabetLetter = this.setAlphabetLetter.bind(this);
    this.state={
      letter:'',
    }
  }
  clearLetter = ()=>{
    this.setState({contacts:''})
  }
  setAlphabetLetter = (letter)=>{
    this.props.setContacts(letter);
  }
  onClearSelectedLetter = ()=>{

    this.props.onClearSelectedLetter();
  }
  render(){
    let alphabet;
    let dict;

    if (this.props.contacts && this.props.contacts.length>0){
      dict = this.props.contacts.reduce((accumulator, currentValue) => {
      // c[0] should be the first letter of an entry

      if (currentValue.name.length>0){
        let firstLetter = currentValue.name[0].toLocaleUpperCase()

        // either push to an existing dict entry or create one
        if (accumulator[firstLetter]){
          accumulator[firstLetter].push(currentValue)}
        else {
          accumulator[firstLetter] = [currentValue]}


      }
      return accumulator
      }, {})
      dict = Object.entries(dict).sort()
      // flatten the dictionary to an array
      alphabet = dict.map((entity,i)=>{
        return <TagDictionaryEntry letter = {entity}
          key = {i}
          setAlphabetLetter = {this.setAlphabetLetter}
          activeLetter = {this.props.letter}/>;
      })
    }

    return(
      <div className = "SentToDictionary-container">
        <div className = "tagDictionary-topTagButton SentToDictionary-all" onClick = {this.onClearSelectedLetter}>All</div>

          {alphabet}
          </div>)
  }
}
export default SentToDictionary;
