import React from 'react';
import ParentTag from './ParentTag';
import ChildTag from './ChildTag';
import './ActiveTag.css';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import AddChildTagButton from '../Buttons/AddChildTagButton';
import AddParentTagButton from '../Buttons/AddParentTagButton';
import PinTagButton from './PinTagButton';
import {toast} from 'react-toastify';
import {
	DragSource,

} from 'react-dnd';
const tagSource = {
  beginDrag(props,monitor,component){
		if (props.tag.is_accessible){
    return {name:props.tag}
	}
  }
}
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),

  }
}

class ActiveTag extends React.Component{
  constructor(props){
    super(props);
    this.replaceTagInfo = this.replaceTagInfo.bind(this);
		this.onRemoveTag = this.onRemoveTag.bind(this);
    this.state={
      showChildren:false,
      showParents:false,
      isExpanded:false,
      tag_type:'',
      tagInfo:props.tag,
    }
  }

  replaceTagInfo(tag){
    this.setState({tagInfo:tag})
  }
  componentDidUpdate(prevProps,prevState){
    if (this.props.tag!==prevProps.tag || this.props.getInfoAgain!==prevProps.getInfoAgain){
      this.getTagInfo(this.props.tag);
    }

  }
	getTag=()=>{
		this.getTagInfo(this.props.tag);
	}
  componentDidMount(){
    this.getTagInfo(this.props.tag);
  }
  toggleExpand=()=>{
    this.setState({ isExpanded:false,
      showParents:false,
      showChildren:false,
     });

     this.props.onLastSelected(this.state.tagInfo.name);
  }

  removeTag =(e)=>{
    e.stopPropagation();
    this.props.onRemoveTag(this.props.tag,this.props.index);

  }
	onRemoveTag =()=>{
    // this does not have e.stopPropagation, used for tag access 403.
    this.props.onRemoveTag(this.props.tag,this.props.index);

  }
  onToggleParentTags=(e)=>{
    e.stopPropagation();
    let expanded = !this.state.showParents;
    this.setState({
      showParents: expanded,
      showChildren:false,
      isExpanded:true,
    })
  }

  onToggleChildTags=(e)=>{
    e.stopPropagation();
    let expanded = !this.state.showChildren;
    this.setState({
      showChildren: expanded,
      showParents:false,
      isExpanded:true,
    })

  }
  getTagInfo=(tag)=>{
    Request.authRequest(Routes.tags.edit(tag),
											  'GET').then((response) => {
			if (response.status ===200){
			response.json().then(body => {
				this.setState({tagInfo:body})})
			}
			else {
				response.json().then((error) => {
					toast.error(error.message);
					this.onRemoveTag();
				});
			}
		}).catch((error) => {
		toast.error(error.message);
		}
		)
  }

  onRenderParentTags(){
    const tag=this.state.tagInfo;
		if (!tag.parent_tags){
			this.getTagInfo(this.props.tag);
			return
		}
    const parentTagList = tag.parent_tags.sort(function(a,b){
			return a.name.localeCompare(b.name)}).map((entity,i)=>{
      return <ParentTag tag={entity} key= {i} parentKey= {this.props.index}
        onLastSelected={this.props.onLastSelected}
        onReplaceTag={this.props.onReplaceTag}
        onGetTags = {this.props.onLastSelected}
        />
    });

    if (parentTagList.length>0){

      return (
        <div className = "subTags-container">
					<div className = "subTags-buttonsContainer">

	          <AddParentTagButton tag={tag} onReplaceTag={this.props.onReplaceTag}
	            parentKey= {this.props.index}
	            replaceTagInfo={this.replaceTagInfo}
	            onGetTags = {this.props.onLastSelected}
	            />
				</div>
          {parentTagList}
        </div>);
    } else {

      return(
        <div className = "subTags-container">
					<div className = "subTags-buttonsContainer">
	          <AddParentTagButton tag={tag} onReplaceTag={this.props.onReplaceTag}
	            parentKey= {this.props.index}
	            replaceTagInfo={this.replaceTagInfo}
	            onGetTags = {this.props.onLastSelected}
	            />
					</div>
          <div className = "helpText-subtags">
            No Parents
          </div>
        </div>)
      }
  }
  onRenderChildTags(){
    const tag=this.state.tagInfo;
		if (!tag.child_tags){
			this.getTagInfo(this.props.tag);
			return
		}
    const childTagList = tag.child_tags.sort(function(a,b){
			return a.name.localeCompare(b.name)
		}).map((entity,i)=>{
      return <ChildTag tag={entity} key= {i} parentKey= {this.props.index}
        onLastSelected={this.props.onLastSelected}
        onReplaceTag={this.props.onReplaceTag}
        onGetTags = {this.props.onLastSelected}
        />
    });

    if (childTagList.length>0){

      return (
        <div className = "subTags-container">
					<div className = "subTags-buttonsContainer">
          <AddChildTagButton tag={tag} onReplaceTag={this.props.onReplaceTag}
            parentKey= {this.props.index}
            replaceTagInfo={this.replaceTagInfo}
            onGetTags = {this.props.onLastSelected}
            />
				</div>
          {childTagList}
        </div>);
    } else {

      return(
        <div className = "subTags-container">
					<div className = "subTags-buttonsContainer">
	          <AddChildTagButton tag={tag} onReplaceTag={this.props.onReplaceTag}
	            parentKey= {this.props.index}
	            replaceTagInfo={this.replaceTagInfo}
	            onGetTags = {this.props.onLastSelected}
	            />
				</div>
          <div className = "helpText-subtags">
            No Children
          </div>
        </div>)
      }
  }
  render(){
    const tag=this.state.tagInfo;
    let subTags;
    if (this.state.showParents){
      subTags= this.onRenderParentTags();
    } else if (this.state.showChildren){
      subTags = this.onRenderChildTags();
    }
    const { connectDragSource} = this.props;
    return connectDragSource(
      <div className = "activeTag-container" >
          <div className = "activeTag-primary" onClick = {this.toggleExpand}>
					<PinTagButton tag = {tag} onGetTag = {this.getTagInfo}
							onGetPinnedTags = {this.props.onGetPinnedTags}/>
						<div className ="tagLabel-spacer">
          <div className = "tagLabel">

            {tag.name}
          </div>
          <div className = "activeTag-buttons">
            <span className = "button-childTags" onClick = {this.onToggleParentTags}> &#8679;
            </span>
            <span className = "button-childTags" onClick = {this.onToggleChildTags}> &#8681;
            </span>

            <span className = "removeTag noselect"  onClick = {this.removeTag}>x</span>

          </div>
				</div>
          </div>
          <div className = "subTags-container boxShadow">{subTags}</div>



      </div>
    )
  }
}
export default DragSource('tag', tagSource, collect)(ActiveTag)
