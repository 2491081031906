import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import DocumentsUploadModal from './DocumentsUploadModal';
class DocumentsUploadModalButton extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      loading: false,
    }
  }

  onShowModal = () => {
    this.setState({ showModal: true })
  }
  onHideModal = () => {
    this.setState({
      showModal: false, only_as_pdf: false
    });
    this.props.onGetDocuments();
  }

  render() {

    return (
      <div>
        <div className="buttonUpload noselect" onClick={this.onShowModal}>
          Upload
        </div>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Upload Documents' >
          <DocumentsUploadModal onCloseModal={this.onHideModal} related_tags={this.props.related_tags} />
        </Modal>
      </div>
    )
  }
}
export default DocumentsUploadModalButton;
