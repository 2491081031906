import React from 'react';
import {Delete} from '../Icons/IconLibrary';
import './Buttons.css';

import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
/*
Delete button can take in type: contacts, documents, tags, templates. This specifies
the type for the delete call
*/
class DeleteButton extends React.Component{
  constructor(){
    super();
    this.state={
      showModal:false,
    }
    //this.handleDelete = this.handleDelete.bind(this);
  }
  onShowModal=()=>{
    if ((this.props.items.length ===0 || !this.props.items) && (this.props.activeBatchSheets && this.props.activeBatchSheets.length>0)){
      toast.error('Sent batch sheets cannot be deleted, only archived.')
      return;
    }
    if (this.props.items.length ===0 || !this.props.items){
      toast.error('Please select one or more items to delete')
      return;
    }
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false
  })}
  deleteTemplateGroup(){
    Request.authRequest(Routes.templateGroups.edit(this.props.items[0].id),
                      'DELETE').then(
                        (response) => {
                          if(response.status === 200){
                            toast.success('Successfully deleted the selected template')
                            this.setState({showModal:false})
                            window.location.href = '/sendreceive/templates';
                      } else {
                        response.json().then((error) => {
                          toast.error(error.message);

                        });
                      }
                }).catch((error) => {
                  toast.error(error.message);
                }
              )
  }
  deleteRecipe(){
    
    if (Array.isArray(this.props.items)){
      for (let i = 0; i < this.props.items.length;i++){
        Request.authRequest(Routes.recipes.edit(this.props.items[i].recipe_id),
                          'DELETE').then(
                            (response) => {
                              if(response.status === 200){
                                toast.success('Successfully deleted the selected recipe')
                                this.setState({showModal:false})
                               
                                this.props.onGetDocuments();
                          } else {
                            response.json().then((error) => {
                              toast.error(error.message);

                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
                  }
                }
                else{
                  Request.authRequest(Routes.recipes.edit(this.props.items.recipe_id),
                          'DELETE').then(
                            (response) => {
                              if(response.status === 200){
                                toast.success('Successfully deleted the selected recipe')
                                this.setState({showModal:false})
                                
                                this.props.onGetDocuments();
                          } else {
                            response.json().then((error) => {
                              toast.error(error.message);

                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
                }
                
  }
  deleteIngredient(){
    if (Array.isArray(this.props.items)){
      for (let i = 0; i < this.props.items.length;i++){
      Request.authRequest(Routes.ingredients.edit(this.props.items[i].id),
                        'DELETE').then(
                          (response) => {
                            if(response.status === 200){
                              toast.success('Successfully deleted the selected ingredient')
                              this.setState({showModal:false})
                              this.props.onGetDocuments();
                        } else {
                          response.json().then((error) => {
                            toast.error(error.message);
  
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
      }
    }
    else{
      Request.authRequest(Routes.ingredients.edit(this.props.items.id),
                        'DELETE').then(
                          (response) => {
                            if(response.status === 200){
                              toast.success('Successfully deleted the selected ingredient')
                              this.setState({showModal:false})
                              this.props.onGetDocuments();
                        } else {
                          response.json().then((error) => {
                            toast.error(error.message);
  
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
    }
  }
  deleteTagEntities(){
    if (Array.isArray(this.props.items)){
      for (let i =0; i < this.props.items.length;i++){
        Request.authRequest(Routes.tags.edit(this.props.items[i]),
  											  'DELETE').then(
                            (response) => {
                              if(response.ok){
                                this.props.onGetTags();

                                toast.success('Successfully deleted the selected tags')
                                this.setState({showModal:false})
                          } else {
                            response.json().then((error) => {
                              if(error.errors){

                              toast.error(error.errors[0])}
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
    }
  }
    else {
      Request.authRequest(Routes.tags.edit(this.props.items),
                        'DELETE').then(
                          (response) => {
            if(response.ok){
              this.props.onGetTags();
              toast.success(`Successfully deleted the ${this.props.items} tag`)
              this.setState({showModal:false})
              } else {
                response.json().then((error) => {
                  toast.error(error.message);
                  if(error.errors){

                  toast.error(error.errors[0])}
                });
              }
        }).catch((error) => {
          toast.error(error.message);
        }
      )
    }
  }
  deleteDocumentEntities(){

     for(let i = 0 ; i< this.props.items.length;i++){

      Request.authRequest(Routes.documents.edit(this.props.items[i].document.id),
											  'DELETE').then(
                          (response) => {
              if (response.status ===200){
      			    response.json().then(
                body => {
                    this.setState({showModal:false});
                    toast.success(`Successfully deleted the selected document`);
                    this.props.onGetDocuments();
                    this.props.onClear();
                  });
                } else {
                  response.json().then((error) => {
                    toast.error(error.message);
                  });
                }
          }).catch((error) => {
            toast.error(error.message);
          }
        )
    }
  }
  deleteContactsEntities(){
    for (let i = 0; i < this.props.items.length; i++){

     Request.authRequest(Routes.contacts.edit(this.props.items[i].id),
                       'DELETE').then(
                         (response) => {
             if (response.status ===200){
               response.json().then(
               body => {
                   this.setState({showModal:false});
                   toast.success(`Successfully deleted the selected contact`);
                   this.props.onGetContacts();
                   this.props.onClearSelected();
                 });
               } else {
                 response.json().then((error) => {
                   toast.error(error.message);
                 });
               }
         }).catch((error) => {
           toast.error(error.message);
         }
       )
   }
  }
  getDeleteType=()=>{
    switch (this.props.type) {
      case ("contacts"):
        return this.deleteContactsEntities();

      case ("documents"):
        return this.deleteDocumentEntities();

      case ("tags"):
        return this.deleteTagEntities();
      case ("ingredients"):
        return this.deleteIngredient();
      case ("recipes"):
        return this.deleteRecipe();
      case ("templateGroups"):
        return this.deleteTemplateGroup();
      case ("template"):

      break;
      default:

    }
  }
  additionalText(){

    if (this.props.type ==='tags'){
      return (
        <div> WARNING: Deleting tags is potentially dangerous. If you are trying to
          remove associations, use the parent and child bars to the right. This
          button deletes all tags in the Active Tag section. Be sure you want to do this.
        </div>
      )
    }
    if (this.props.type ==='ingredient'){
      return (
        <div> Deleting an ingredient will remove it from all current recipes it is used in, in addition to deleting it.
          Previous versions won't be able to access that ingredient either. 
        </div>
      )
    }
  }

  render(){

    return(
      <div style ={{display:'flex',alignItems:'center'}}>
        <button className='headerButton noselect' id = "buttonDelete" onClick = {this.onShowModal}>
          {this.props.text?<div style ={{color:'var(--fill-green)',display:'flex',alignItems:'center'}}>Delete</div>:<img className='buttonIcon'  src={Delete}
          alt='Delete currently selected items' title = 'Delete currently selected items' />}

        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Delete Items' >
        <div className = "button-modalText">
          <p>
          You are about to delete {this.props.items.length} &nbsp;
           items. This change cannot be undone.
           <br/>

        </p>
          {this.additionalText()}
        <button className= 'modal-button primary' onClick={this.getDeleteType}> Confirm Delete </button>
        </div>
      </Modal>
      </div>


    )
  }
}
export default DeleteButton;
