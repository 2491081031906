import React from 'react';
import introJs from 'intro.js';
import Step1Upload from './Step1Upload.json';
import './Tutorial.css';
import qs from 'qs';
class Tutorial1UploadButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
      completedPage:false,
      showContinue:false,
    }
  }
  completeTutorial=()=>{
    this.setState({completedPage:true});
  }
  componentDidMount(){
    if (window.location.search.indexOf('tutorial'>-1)){
      this.onClickTutorial();
    }
  }
  onClickTutorial = () =>{
    introJs().exit();
    let intro = introJs().setOptions({steps:Step1Upload, doneLabel: "Next page", showStepNumbers:false}).start().oncomplete(function() {
      window.location.href = 'http://ivydocuments.com/documents/?status=accepted&tutorial=true';
  });
  }
  showContinue=()=>{
    this.setState({showContinue:true});
  }
  continueToTags=()=>{
    window.location.href = 'http://ivydocuments.com/documents/?status=accepted&tutorial=true';
  }
  hideContinue=()=>{
    this.setState({showContinue:false});
  }
  render(){
    if (this.state.showContinue){
      return(
        <div className = "tutorial2Container">
          <div onClick = {this.hideContinue} className = "tutorialButton boxShadow">Tutorial</div>
          <div className = "tutorialOptions boxShadow">
            <div className = "tutorialOption" onClick = {this.onClickTutorial}>Show Steps</div>
            <div className = "tutorialOption" onClick = {this.continueToTags}> Continue</div>
          </div>
          </div>
      )
    }
    return(
      <div className = "tutorial2Container">
        <div onClick = {this.showContinue} className = "tutorialButton boxShadow">Tutorial</div>

        </div>
    )
  }
}

export default Tutorial1UploadButton
