import React from 'react';
import './VersionHistoryReportComponent.css';
import VersionHistoryReportSignature from './VersionHistoryReportSignature';
import DocumentReportSentTo from './DocumentReportSentTo';
class VersionHistoryReportComponent extends React.Component{
  render(){
    let version = this.props.version;
    let dateCreated = new Date(version.date_created).toDateString();
    let dateExpiration = "None";
    if (version.date_expiration){
      dateExpiration  = new Date(version.date_expiration).toDateString();
    }
    let signatures = <div className = "smallHelpText">None</div>;
    let minorName;
    if (version.user_defined_id){
      minorName = version.user_defined_id;
    }
    if (version.signatures.length>0){
      signatures = version.signatures.map((signature,i)=>{
        return <VersionHistoryReportSignature key = {i} signature = {signature}/>
      })
    }
    let notes = <div className = "smallHelpText">None</div>;
    if (version.notes){
      notes=version.notes;
    }
    let sentTo;
    if (version.shared_with.length>0){
      sentTo = version.shared_with.map((share,i)=>{
            return <DocumentReportSentTo doc = {version} key = {i} sentTo = {share}/>

      })

      }

    return(
      <div className = "versionReport-componentContainer">
        <div className = "versionReport-nameContainer">
          <div className = "versionReport-name">
            <div className = "versionReport-Label">Version {version.version_number} - Name</div>
            <div >{version.name}
              <div className = "versionReport-minorName">{minorName}</div>
            </div>
          </div>
        <div className = "versionReport-dateContainer">
          <div className = "versionReport-date">
            <div className = "versionReport-Label">Date Created</div>
            <div>{dateCreated}</div>
          </div>
          <div className = "versionReport-date">
            <div className = "versionReport-Label">Expiration Date</div>
            <div>{dateExpiration}</div>
          </div>
          </div>
        </div>
        <div className = "versionReport-notes">
          <div className = "versionReport-Label">Notes</div>
          <div>{notes}</div>
        </div>
        <div className = "versionReport-signatures">
          <div className = "versionReport-Label">Signatures</div>
          {signatures}
        </div>
        <div className = "versionReport-signatures">
          <div className = "versionReport-Label">Shared With</div>
          {sentTo}
        </div>
        <div>
        </div>
      </div>
    )
  }
}

export default VersionHistoryReportComponent;
