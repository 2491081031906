import React from 'react';
import './ContactsSuggestionRow.css'
class ContactsSuggestionRow extends React.Component{
  render(){
    let suggestion = this.props.suggestion;

    return(
      <div className = "ContactsSuggestionRow">
        <div className = "ContactsSuggestionRow-leftContainer">
          <div className = "ContactsSuggestionRow-name">
            {suggestion.first_name} {suggestion.last_name}
          </div>
          <div className = "ContactsSuggestionRow-organization">
            {suggestion.organization}
          </div>
        </div>
        <div className = "ContactsSuggestionRow-email">
          {suggestion.email}
        </div>
      </div>
    )
  }
}
export default ContactsSuggestionRow
