

class TagCleaner{
  static parseTagString(tagString){
    if (!tagString){
      return
    }
    let newTags= tagString.split(',')
    let trimmedTags = newTags.map((entity,i)=>{
      return entity.trim()
    })
    trimmedTags = trimmedTags.filter(tags=> tags!=='');
    return trimmedTags
  }
  static parseTagArray(tagArray){
    if (tagArray.length ===0) {
      return []
    }
    else if (tagArray.length === 1 && !tagArray[0]){
      return []
    }
    let trimmedTags;
    let newTagArray = tagArray.filter(tags=> tags);
    if (tagArray.length > 1){
     trimmedTags = newTagArray.map((entity,i)=>{
      return entity.trim()
    })}
    else {
      trimmedTags = [newTagArray[0].trim()]
      }

    trimmedTags = trimmedTags.filter(tags=> tags!=='');
    return trimmedTags
  }

}

export default TagCleaner;
