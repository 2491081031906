import React from 'react';
import './Layout.css';
const ToS = (props) => {
	return (
		<div className='termsofservice'>
			<h1> Terms of Service </h1>


			<p>These Terms of Service (“Terms of Service”) govern your use of and access to the website and file management system accessible at IvyDocuments.com (the “Service”). By accessing or using the Service, or by otherwise accepting these Terms of Service, you (an individual or entity) agree to the terms and conditions set forth in these Terms of Service. If you violate or do not agree to these Terms of Service, your access to and use of the Service is unauthorized.
	These Terms of Service may be changed at any time and notice of such changes shall be given by posting updated Terms of Service on the Service.  Your continued use of the Service thereafter constitutes acceptance of the updated Terms of Service.
	Definitions
	 “Ivy Documents” means Ivy Food Technology, Inc. and any subsidiaries and affiliates of Ivy Documents (including any that Ivy Documents may form or acquire in the future). We also refer to Ivy Documents as 'we', 'us' and 'our'.
	“Service” means the Site and the file management service accessible through the Site.
	“Site” means the website at www.IvyDocuments.com.
	When we say “you” or “your” we mean users of the Service, including visitors to the Service.
	</p>
	1. Registration and Eligibility
	<p>
	When creating or updating an Account on the Service, you may be required to provide Ivy Documents with certain personal information. This information will be held and used in accordance with Ivy Documents’ Privacy Policy https://ivydocuments.com/privacypolicy which is incorporated herein by reference. You agree that you will supply accurate and complete information to Ivy Documents, and that you will update that information promptly after it changes. You further agree that:

	– You shall not create an Account or access the Service if you are under the age of 13;
	– If you are between the ages of 13 and 17, you represent that your legal guardian has reviewed and agreed to these Terms of Service;
	– You shall not have more than one Account at any given time, and shall not create an account using a false identity or inaccurate information, or on behalf of someone other than yourself;
	- You shall not have an Account or use the Service if you have been previously removed by Ivy Documents.
	</p>
	3. Limited License
	<p>
	Ivy Documents grants you a limited, revocable, non-exclusive, non-transferable license to access and use the Service for your own use subject to the other terms of these Terms of Service.

	Certain features (“Paid Features”) of the Service may be available only to users of paid subscriptions.  You acknowledge that your access to Paid Features may be subject to a separate agreement with a third party (e.g., your employer) and that your access to Paid Features may be terminated in accordance with that agreement.
	</p>
	4. Fees
	<p>
	If you purchase any of our Paid Features, you agree to pay us the corresponding fees. Any subscriptions will renew for the original subscription period unless cancelled in accordance with the cancellation procedures available on the Site. You agree that we may use your payment information to charge you for subscription renewals.
	</p>
	5. Changes to the Service
	<p>
	From time to time and without prior notice to you, we may change, expand and improve the Service. We may also, at any time, cease to continue operating part or all of the Service or selectively disable certain aspects of the Service or portions of the Site. Any modification or elimination of the Service will be done in our sole discretion and without an ongoing obligation or liability to you, and your use of the Site and Service do not entitle you to the continued provision or availability of the Service.
	</p>
	6. Your Use of the Service
	<p>
	As a condition of using the Service, you agree not to use the Service for any purpose that is prohibited by these Terms of Service. You are responsible for all of your activity in connection with the Service and you shall abide by all local, state, national, and international laws and regulations and any applicable regulatory codes. You agree to the following:

	– You may not share your account information or permit anyone to access your account or complete any tests on your behalf
	– You may not resell, rent, lease, loan, sublicense, distribute, or otherwise transfer rights to the Service
	– You may not modify, reverse engineer, decompile or disassemble the Service
	– You may not copy, adapt, alter, modify, translate, or create derivative works of the Service without written authorization of Ivy Documents
	– You may not circumvent or disable any technological features or measures in the Service for protection of intellectual property rights
	– You may not use the Service in an attempt to, or in conjunction with, any device, program, or service designed to circumvent technological measures employed to control access to, or the rights in, a content file or other work protected by the copyright laws of any jurisdiction
	– You may not use or access the Site or Service to compile data in a manner that is used or usable by a competitive product or service
	– You shall not use your Account to advertise, solicit, or transmit any commercial advertisements, including chain letters, junk e-mail or repetitive messages to anyone
	– You shall not use your Account to engage in any unlawful conduct

	Any use of the Service contrary to this Section 6 shall immediately terminate the license set forth in Section 3.
	</p>
	7. User Content
	<p>
	“User Content” means any documents, computer files, communications, images, sounds, and all the material, data, and information that you upload or transmit through the Service, including profile information. By transmitting or submitting any User Content while using the Service, you affirm, represent and warrant that such transmission or submission is (a) accurate and not confidential; (b) not in violation of any applicable laws, contractual restrictions or other third party rights, and that you have permission from any third party whose personal information or intellectual property is comprised in the User Content; (c) free of viruses, adware, spyware, worms or other malicious code; (d) does not constitute unauthorized advertising or “spamming”; and (e) that you have adequate proprietary rights or license to grant the User Content License below.  You further acknowledge and agree that any of your personal information within such content will at all times be processed by Ivy Documents in accordance with its Privacy Policy.

	You retain ownership and any intellectual property rights of your User Content. Company does not claim any intellectual property rights to your User Content. By using Ivy Documents, you agree to provide a limited license which enable Company to offer the services. You hereby grant Company and a perpetual, irrevocable, worldwide, fully paid-up and royalty free, non-exclusive, limited license (“User Content License”), including the right to sublicense and assign to third parties, and right to use, host, store, copy, reproduce, fix, adapt, modify, improve, translate, reformat, create derivative works from, introduce into circulation, publish, distribute, transmit, and enter into computer memory your User Content as well as all modified and derivative works thereof in order to provide the Service.
	</p>
	8. Copyright Policy
	<p>
	Ivy Documents has implemented the following policy to help respect the ownership rights of creators:

	In accordance with the Digital Millennium Copyright Act of 1998, the text of which may be found at https://www.copyright.gov/legislation/dmca.pdf, Ivy Documents will respond expeditiously to claims of copyright infringement on the Site or through the Service.

	If you are a copyright holder and believe that your copyrighted material is being infringed on the Site or Service, you may provide our designated agent (listed below) with a written notice (“Infringement Notice”) containing the information below. Your Infringement Notice may be forwarded to the party that made the content available.

	In order to file an Infringement Notice, please provide us with the following information:

	– An identification of the copyright claimed to have been infringed;
	– A description of the nature and exact location (URL if available) of the content that you claim to infringe your copyright;
	– Your name, address, telephone number and email address;
	– A statement by you (the copyright holder or such owner’s agent): (a) that you believe in good faith that the use of the content that you claim to infringe your copyright is not authorized by law; (b) that all of the information contained in your Infringement Notice is accurate; and (c) under penalty of perjury, that you are the copyright owner or a person authorized to act on their behalf;
	– A physical or electronic signature of the copyright owner or other person authorized to act on their behalf;

	Such Infringement Notice may be sent to:
	Ivy Food Technology, Inc.
	nathan@ivydocuments.com
	</p>
	9. Account Security
	<p>
	Your account may not be shared. You are solely responsible for activity that occurs on your account and it is your responsibility to protect your personal data and maintain the confidentiality of your user information and password. You are also responsible for immediately notifying Ivy Documents of any unauthorized use of your account, or breach of your account information or password. Ivy Documents will not be liable for any loss that you may incur as a result of someone else using your username or password, either with or without your knowledge. To the extent allowable by law, you shall be liable for any expenses and reasonable attorney’s fees for your failure to safeguard user and password information and/or promptly notify Ivy Documents about unauthorized use of your account or breach of your account information or password.
	</p>
	10. Enforcing Security
	<p>
	Actual or attempted unauthorized use of the Service may result in criminal and/or civil prosecution. For your protection, we reserve the right to view, monitor, and record activity on the Service without notice or further permission from you, to the fullest extent permitted by applicable law. Any information obtained by monitoring, reviewing, or recording is subject to review by law enforcement organizations in connection with the investigation or prosecution of possible criminal activity on any of the Site.
	</p>
	11. Third Party Links
	<p>
	The Service may give you access to links to third-party websites (“Third Party Sites”). Ivy Documents does not endorse any of these Third Party Sites and does not control them in any manner. Accordingly, Ivy Documents does not assume any liability associated with Third Party Sites. You need to take appropriate steps to determine whether accessing a Third Party Site is appropriate, and to protect your personal information and privacy on such Third Party Site.
	</p>
	12. Indemnity
	<p>
	User Indemnification. You hereby agree to defend, indemnify, and hold harmless Company (including its officers, directors, employees, agents, contractors, representatives, suppliers, subsidiaries, parents, affiliated companies, and insurers) from any third party claim, suit, or proceeding arising out or related to your use of the Service. Your obligations set forth in this Section 12 include, without limitation, payment of losses, damages, judgments, settlements, attorneys' fees, and other expenses and costs.
	</p>
	13. Warranty Disclaimer
	<p>
	THE SITE AND SERVICE ARE PROVIDED “AS IS”, “AS AVAILABLE” AND ARE PROVIDED WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED, SAVE TO THE EXTENT REQUIRED BY LAW. IVY DOCUMENTS, AND ITS DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, SUPPLIERS, PARTNERS AND CONTENT PROVIDERS DO NOT WARRANT THAT: (A) THE SERVICE WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; (B) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (C) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (D) THE RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SITE AND SERVICE IS SOLELY AT YOUR OWN RISK. SOME STATES / COUNTRIES DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
	</p>
	14. Limitation of Liability
	<p>
	IN NO EVENT SHALL IVY DOCUMENTS, ITS OFFICERS, DIRECTORS, AGENTS, AFFILIATES, EMPLOYEES, ADVERTISERS, OR DATA PROVIDERS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA) WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), EQUITY OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS SITE, SERVICE, OR USER CONTENT.  IN NO EVENT WILL IVY DOCUMENTS’ TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF SERVICE, OR USE OF OR INABILITY TO USE THE SERVICE, EXCEED THE AMOUNT YOU HAVE PAID TO IVY DOCUMENTS FOR USE OF THE SERVICE IN THE TWELVE (12) MONTHS PRIOR TO THE CLAIM, OR ONE HUNDRED DOLLARS ($100) IF YOU HAVE NOT HAD ANY PAYMENT OBLIGATIONS TO IVY DOCUMENTS, AS APPLICABLE. THE LIMITATION OF LIABILITY HEREIN IS A FUNDAMENTAL ELEMENT OF THE BASIS OF THE BARGAIN AND REFLECTS A FAIR ALLOCATION OF RISK. THE SERVICE WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS AND YOU AGREE THAT THE LIMITATIONS AND EXCLUSIONS OF LIABILITY, DISCLAIMERS AND EXCLUSIVE REMEDIES SPECIFIED HEREIN WILL SURVIVE EVEN IF FOUND TO HAVE FAILED IN THEIR ESSENTIAL PURPOSE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
	</p>
	15. Termination
	<p>
	Ivy Documents may terminate your license to use the Service with or without cause at any time, with or without notice. Should You breach these Terms of Service or fail to comply with any term of these Terms of Service, your right to use the Service shall terminate immediately and without notice.

	If your access to the Service is paid for or otherwise provided through a third party customer of the Service on an enterprise basis (“Customer”), then your access to the Service is subject to an agreement between Ivy Documents and such Customer.  Accordingly, upon termination of that agreement, or for any other reason set forth in such agreement, your access to the Service may be terminated.

	You may also choose to terminate these Terms of Service by simply discontinuing use of the Service, provided that your termination of Paid Features may be subject to cancellation terms set forth on the Site.

	All provisions of these Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
	</p>
	16. Miscellaneous
	<p>
	Any dispute arising from these Terms of Service shall be governed by the laws of the State of California without regard to its conflict of law provisions. SOLE AND EXCLUSIVE JURISDICTION FOR ANY ACTION OR PROCEEDING ARISING OUT OF OR RELATED TO THESE TERMS OF SERVICE SHALL BE IN AN APPROPRIATE STATE OR FEDERAL COURT LOCATED IN SAN FRANCISCO COUNTY, STATE OF CALIFORNIA AND THE PARTIES UNCONDITIONALLY WAIVE THEIR RESPECTIVE RIGHTS TO A JURY TRIAL.

	Any cause of action you may have with respect to your use of the Site and Service must be commenced within one (1) year after the claim or cause of action arises.

	If for any reason a court of competent jurisdiction finds any provision of these Terms of Service, or a portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to affect the intent of these Terms of Service, and the remainder of these Terms of Service shall continue in full force and effect. A printed version of these Terms of Service shall be admissible in judicial or administrative proceedings.
	</p>
	17. No Class Actions
	<p>
	All claims between the parties related to these Terms of Service will be litigated individually and the parties will not consolidate or seek class treatment for any claim, unless previously agreed to in writing by the parties.

	BY USING THE SERVICE OR ACCESSING THE SITE OR SERVICE, YOU HEREBY ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE, UNDERSTAND IT, AND AGREE TO BE BOUND BY ITS TERMS AND CONDITIONS.
	</p>

		</div>
	);
}

export default ToS;
