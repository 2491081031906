import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import './TagAccess.css';
class RemoveExcludedTagAccessButton extends React.Component{
  removeExcludedTag = ()=>{
    //This also causes a bunch of redundant get org calls.
    let tags = this.props.tags.concat();
    let users = this.props.users.concat();
    for (let i =0; i < tags.length; i++){
      for (let j =0; j < users.length; j++){
        Request.authRequest(Routes.tags.access(tags[i]),
                          'DELETE',JSON.stringify({email:users[j].email,deny:true}),
                          'application/json').then(
                            (response) => {
                              if(response.ok){
                                response.json().then((body) => {
                                  this.props.onChangeAccess();
                          }
                        )} else {
                            response.json().then((error) => {
                              toast.error(error.message);
                              if (error.errors){
                                toast.error(error.errors[0])
                              }
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
                }
              }
  }
  render(){
    return(
        <button className= 'changeTagAccessButton secondary' id = "RemoveTagAccessButton"
          onClick={this.removeExcludedTag}> Remove Excluded Tags from Users</button>

    )
  }
}
export default RemoveExcludedTagAccessButton;
