import React from 'react';
import { toast } from 'react-toastify';
class IngredientAttributeInfoField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      key: this.props.property[0],
      value: this.props.property[1],
      deleting: false,
    }
  }
  onToggleCheckbox = () => {
    this.setState({ deleting: !this.state.deleting }, this.sendDelete())

  }
  sendDelete = () => {
    if (this.state.deleting != true) {
      this.props.addPropertyToDelete(this.props.property);
    }
    else {
      this.props.removePropertyToDelete(this.props.property);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.property !== this.props.property) {
      this.setState({
        key: this.props.property[0],
        value: this.props.property[1]
      });
    }
  }
  onSaveChanges() {
    if (this.state.key === this.props.property[0] && this.state.value == this.props.property[1]) {
      return
    }
    this.props.changeProperty(this.props.property, this.state.key, this.state.value)
  }

  handleDblClick = (e) => {
    if (this.node.contains(e.target)) {
      this.setState({
        editing: true
      })
    }
    else if (this.state.editing !== false) {

      this.onSaveChanges();
      this.setState({ editing: false })
    };
  }
  componentWillMount() {
    document.addEventListener('dblclick', this.handleDblClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('dblclick', this.handleDblClick, false);
  }
  handleEnter = (e) => {
    if (e.key === 'Enter') {
      if (this.state.string.length > 100) {
        toast.error('This field is limited to 100 characters. Press escape to revert changes.');
        return
      }
      this.onSaveChanges();

      this.setState({ editing: false })
    }

  }
  onChangeKey = (e) => {
    this.setState({
      key: e.target.value,
    });
  }
  onChangeValue = (e) => {
    this.setState({
      value: e.target.value,
    });
  }
  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.setState({ editing: false, string: this.props.content })
    }
  }
  render() {
    let property = this.props.property;
    return (

      <div className='ingredient-property-container' ref={node => this.node = node}>
        {this.props.removeProperties ? <div>
          <input type="checkbox" checked={this.state.deleting} value={this.state.deleting} onChange={this.onToggleCheckbox} />
        </div> : null}
        <div className="ingredient-property-info">
          {this.state.editing ?
            <div >
              <input
                value={this.state.key}
                type='text'
                className='document-info-field'
                onChange={this.onChangeKey}
                onKeyPress={this.handleEnter}
                onKeyDown={this.onKeyDown} />

              <textarea
                value={this.state.value}
                type='text'
                className='document-info-field'
                onChange={this.onChangeValue}
                onKeyPress={this.handleEnter}
                onKeyDown={this.onKeyDown} />
            </div> :
            <div>
              <label className='document-label noselect' >
                {property[0]} </label>
              <div >{property[1]}
              </div>
            </div>
          }
        </div>
      </div>)

  }
}
export default IngredientAttributeInfoField;
