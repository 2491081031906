import React from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import IngredientRow from '../../Ingredients/IngredientRow';
import InfoField from '../../Documents/InfoField';
import DocumentNotesField from '../../Documents/DocumentNotesField';
import TagsField from '../../Documents/TagsField';
import DeleteButton from '../../Buttons/DeleteButton';
import TagCleaner from '../../../helpers/TagCleaner';
import RemindDocumentAccept from '../../Documents/RemindDocumentAccept';
import SendBatchSheetToButton from './SendBatchSheetToButton';
import ShortRecipeDocRow from '../../Documents/ShortRecipeDocRow';
import ShortLogsRow from '../../Logs/ShortLogsRow';
import RecipeVersionButton from './RecipeVersionButton';
import './RecipeViewer.css';
import CookieWrapper from '../../CookieWrapper/CookieWrapper';
import ReleaseCriteriaTable from './ReleaseCriteriaTable';
import SignatureButton from '../../Documents/Signatures/SignatureButton';
import RecipeForkButton from './RecipeForkButton';
import RecipeLockButton from './RecipeLockButton';
import VersionHistoryReportButton from '../../Documents/VersionHistory/VersionHistoryReportButton';
import AddIngredientButton from './AddIngredientButton';
import RelatedTagDocuments from '../../RelatedTagDocuments/RelatedTagDocuments';
import DeleteIngredientButton from './DeleteIngredientButton';
import UnitSelector from '../../UnitSelector/UnitSelector';
import PriceTable from './PriceTable';
import BatchSheetViewerButton from '../BatchSheetViewer/BatchSheetViewerButton';
import BOMButton from '../BatchSheetViewer/BOMButton';
import RecipeBatchSheetPreviewButton from '../BatchSheetViewer/RecipeBatchSheetPreviewButton';
import IngredientFromRecipeButton from '../IngredientFromRecipeButton';
import ToggleDensity from './ToggleDensity';
import DocumentUploadModalButton from '../../Documents/DocumentsUploadModalButton';
import ToggleRequiresSignatures from './ToggleRequiresSignatures';
class RecipeViewer extends React.Component {
	constructor(props) {
		super(props);
		this.onSaveChanges = this.onSaveChanges.bind(this);
		this.onSaveTagsChanges = this.onSaveTagsChanges.bind(this);
		this.onSaveRelatedTagsChanges = this.onSaveRelatedTagsChanges.bind(this);
		this.onShowVersionHistory = this.onShowVersionHistory.bind(this);
		this.onShowLogs = this.onShowLogs.bind(this);
		this.onGetRecipe = this.onGetRecipe.bind(this);
		this.onGetLogs = this.onGetLogs.bind(this);
		this.confirmDeleting = this.confirmDeleting.bind(this);
		this.cancelDeleting = this.cancelDeleting.bind(this);
		this.startDeleting = this.startDeleting.bind(this);
		this.addIngredientToDelete = this.addIngredientToDelete.bind(this);
		this.removeIngredientToDelete = this.removeIngredientToDelete.bind(this);
		this.state = {
			error: '',
			recipe: '',
			preview: false,
			logs: [],
			showVersionHistory: false,
			loaded: false,
			currentUnits: 'kg',
			deleting: false,
			ingredients_to_delete: [],
			showVersionHistory: false
		}
	}

	componentDidMount() {
		this.onGetRecipe();
		this.onGetLogs();
	}
	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.onGetRecipe();
		}
	}

	onGetLogs() {
		Request.authRequest(Routes.logs.document(this.props.match.params.id), 'GET').then((response) => {
			response.json().then(body => {
				this.setState({ logs: body })
			})
		})
	}
	onGetRecipe() {
		Request.authRequest(Routes.recipes.edit(this.props.match.params.id),
			'GET').then((response) => {

				if (response.status === 200) {
					response.json().then(body => {
						this.setState({ recipe: body, loaded: true })
						this.onGetLogs();
					})
				}
				else {
					response.json().then((error) => {
						this.setState({ loaded: true })
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}
	changeUnits = (units) => {
		this.setState({ currentUnits: units });
	}
	onSaveChanges(contentType, content) {
		let doc = JSON.parse(JSON.stringify(this.state.recipe));
		switch (contentType) {
			case 'ID':
				doc.user_defined_id = content;
				break;
			case 'Name':
				doc.name = content;
				break;
			case 'Notes':
				doc.notes = content;
				break;

			default:
				return;
		}
		Request.authRequest(Routes.documents.edit(this.state.recipe.recipe_id),
			'PUT', JSON.stringify(
				doc
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetRecipe();
					this.onGetLogs();
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)

	}
	onSaveTagsChanges(tagsArray) {
		/* this string, unlike other tags operations is the whole tags list */
		let recipe = this.state.recipe;
		if (tagsArray.length === 0) {
			toast.error('Please enter tags. All documents must have at least one tag.')
			return
		}
		let trimmedTags = TagCleaner.parseTagArray(tagsArray);
		Request.authRequest(Routes.documents.tag(recipe.recipe_id),
			'PUT', JSON.stringify({
				tag_names: trimmedTags,
			}), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetRecipe();
					this.onGetLogs();
					toast.success('Successfully applied tags!')
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	onToggleIgnoreDensity = (ignore_density) => {
		let recipe = this.state.recipe.recipe;
		let recipe_fields = {
			immutable: recipe.immutable,
			units_per_case: recipe.units_per_case,
			weight_per_unit: recipe.weight_per_unit,
			ignore_density: ignore_density,
			requires_signatures: false
		}
		if (recipe.requires_signatures) {
			recipe_fields.requires_signatures = recipe.requires_signatures;
		}
		Request.authRequest(Routes.recipes.edit(this.state.recipe.recipe_id),
			'PUT', JSON.stringify(
				recipe_fields
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetRecipe();
					this.onGetLogs();

				});
			} else {
				response.json().then((error) => {
					console.log(error)
					toast.error(error.message);
					if (error.errors) {
						toast.error(error.errors[0])
					}
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	onToggleRequiresSignatures = (requires_signatures) => {
		let recipe = this.state.recipe.recipe;
		let recipe_fields = {
			immutable: recipe.immutable,
			units_per_case: recipe.units_per_case,
			weight_per_unit: recipe.weight_per_unit,
			ignore_density: false,
			requires_signatures: requires_signatures
		}
		if (recipe.ignore_density) {
			recipe_fields.ignore_density = recipe.ignore_density;
		}
		Request.authRequest(Routes.recipes.edit(this.state.recipe.recipe_id),
			'PUT', JSON.stringify(
				recipe_fields
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetRecipe();
					this.onGetLogs();
				});
			} else {
				response.json().then((error) => {
					console.log(error)
					toast.error(error.message);
					if (error.errors) {
						toast.error(error.errors[0])
					}
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	addIngredientToDelete = (ingredient) => {
		this.setState({
			ingredients_to_delete:
				[...this.state.ingredients_to_delete, ingredient]
		})

	}
	removeIngredientToDelete = (ingredient) => {
		this.setState({
			ingredients_to_delete:
				this.state.ingredients_to_delete.filter(ingredients => ingredients != ingredient)
		})
	}
	startDeleting = () => {
		this.setState({ deleting: true })
	}
	cancelDeleting = () => {
		this.setState({ deleting: false, ingredients_to_delete: [] })
	}
	onReturnNavigation = () => {
		this.props.history.push(`/recipes/`)
	}
	onShowLogs = () => {
		this.setState({ showVersionHistory: false });
	}
	onShowVersionHistory = () => {
		this.setState({ showVersionHistory: true });
	}

	onSaveRelatedTagsChanges = (tagsArray) => {
		/* this string, unlike other tags operations is the whole tags list */
		let recipe = this.state.recipe;

		let trimmedTags = TagCleaner.parseTagArray(tagsArray);
		Request.authRequest(Routes.recipes.relatedTags(recipe.recipe_id),
			'PUT', JSON.stringify({
				tag_names: trimmedTags,
			}), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetRecipe();
					this.onGetLogs();
					toast.success('Successfully applied tags!')
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}
	confirmDeleting = () => {
		this.setState({ deleting: false })

		for (let i = 0; i < this.state.ingredients_to_delete.length; i++) {
			Request.authRequest(
				Routes.recipes.changeIngredient(this.state.recipe.recipe.id,
					this.state.ingredients_to_delete[i].ingredient.id),
				'DELETE'
			).then((response) => {
				if (response.status === 200) {
					response.json().then(body => {
						this.onGetRecipe();
						this.onGetLogs();
						toast.success(`Successfully removed the selected ingredients!`)
					});
				} else {
					response.json().then((error) => {
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
		}
	}
	onShowLogs = () => {
		this.setState({ showVersionHistory: false });
	}
	onShowVersionHistory = () => {
		this.setState({ showVersionHistory: true });
	}
	render() {
		let recipe = this.state.recipe.recipe;

		let ingredients;
		let recipe_ingredients;
		let total_weight_ratio;
		let price;
		let documents = <div className="helpText">No documents. Add a related tag to associate documents!</div>;
		let releaseCriteria;
		let related_tags;
		let density;
		let ignore_density;
		let requires_signatures;
		if (recipe) {
			releaseCriteria = recipe.release_criteria;
			ignore_density = recipe.ignore_density;
			requires_signatures = recipe.requires_signatures;
			related_tags = recipe.related_tags
			total_weight_ratio = <div className={recipe.total_weight_ratio == 100 ?
				"recipe-totalWeight recipe-totalWeightComplete" : "recipe-totalWeight"}>
				{recipe.total_weight_ratio}%</div>;
			if (this.state.currentUnits == 'kg') {
				price = recipe.f_total_price_kg;
				density = recipe.f_total_density_kg_L;
			}
			else if (this.state.currentUnits == 'L') {
				price = recipe.f_total_price_L;
				density = recipe.f_total_density_kg_L;
			}
			else if (this.state.currentUnits == 'lb') {
				price = recipe.f_total_price_lb;
				density = recipe.f_total_density_lb_gal;
			}
			else if (this.state.currentUnits == 'gal') {
				price = recipe.f_total_price_gal;
				density = recipe.f_total_density_lb_gal;
			}
			if (recipe.related_tags && recipe.related_tags.length > 0) {
				documents = <RelatedTagDocuments related_tags={recipe.related_tags} showTagNavigator />
			}
			if (ignore_density) {
				density = "No Density - Solid Recipe"
			}
			if (recipe.recipe_ingredients && recipe.recipe_ingredients.length > 0) {
				recipe_ingredients = recipe.recipe_ingredients;
				ingredients = recipe.recipe_ingredients.map((entity, i) => {
					return <IngredientRow recipe_ingredient={entity} key={i} currentUnits={this.state.currentUnits}
						recipe={recipe} onGetLogs={this.onGetLogs} deleting={this.state.deleting} onGetRecipe={this.onGetRecipe} onGetDocuments={this.onGetDocuments}
						addIngredientToDelete={this.addIngredientToDelete}
						removeIngredientToDelete={this.removeIngredientToDelete}
					/>
				})
			}
		}

		// below is antique for premade document code
		const doc = this.state.recipe;
		let date_expiration;
		let versionHistory;
		let docLoader = <div className="loaderContainer"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
		let sharedWithList = (<div className="helpText">No batch sheets.</div>);
		let fromOrganization;
		let versionNumber;
		let status;
		let draft;
		let recipeForkButton;
		let recipeLockButton;
		let showBatchSheetViewer = false;
		let id;
		if (doc.name) {
			recipeForkButton = <RecipeForkButton recipe={doc} />
			recipeLockButton = <RecipeLockButton recipe={recipe} doc={doc} onGetRecipe={this.onGetRecipe} onGetLogs={this.onGetLogs} />
			if (!doc.date_expiration) {
				date_expiration = null;
			}
			else {
				date_expiration = doc.date_expiration.substring(0, 10);
			}
			let statusWords = doc.status;
			if (doc.tags && doc.tags.length > 0) {
				let tag_names = doc.tags.map((entity, i) => {
					return entity.name;
				})
				if (tag_names.indexOf("Draft") > -1) {
					draft = "DRAFT";
					statusWords = "DRAFT";
				}
				if (tag_names.indexOf("Archive") > -1) {
					statusWords = "Archive";
				}
			}
			status = <div className="documentViewer-uneditableHelpText noselect">Status: {statusWords}</div>
			if (doc.version_history_with_self.length > 1) {
				versionNumber = 'Version ' + doc.version_number;
				if (doc.version_history_with_self.length > 1 && doc.version_number === doc.version_history_with_self.length - 1
				) {
					versionNumber = versionNumber + " - current";
				} else if (doc.version_history_with_self.length > 1 &&
					doc.version_number > doc.version_history_with_self[0].version_number) {
					versionNumber = versionNumber + " - current";
				}
			}

			if (doc.version_history_with_self.length > 1) {
				versionHistory = doc.version_history_with_self.map((entity, i) => {
					return <ShortRecipeDocRow document={entity} key={i} recipe={doc} />
				});
			}
			else {
				versionHistory = (<div className="helpText">No other versions</div>)
			}
			if (doc.recipe.batch_sheets && doc.recipe.batch_sheets.permission.length > 0) {
				showBatchSheetViewer = true;
				id = recipe.batch_sheets.id;
				sharedWithList = doc.recipe.batch_sheets.permission.map((receiver, i) => {
					let date_sent = new Date(receiver.date_created);
					date_sent = date_sent.toDateString();
					let date_approved;
					let reminderCounter;
					if (receiver.reminder_counter) {
						reminderCounter = <div className="reminderCounter-text">Reminded {receiver.reminder_counter} times</div>
					}
					if (receiver.date_approved) {
						let date_approved_object = new Date(receiver.date_approved)
						date_approved = date_approved_object.toDateString();
					}
					if (!receiver.organization.name) {
						return
					}
					let reminderButton;
					// if (receiver.status === 'Pending') {
					// 	reminderButton = <RemindDocumentAccept receiver={receiver}
					// 		doc={doc} remindBatchSheet />
					// }
					return (
						<div key={i} className="template-toOrganizationContainer">
							<div className="template-toOrganizationContainer-row">
								<div className="is-spaced-between">
									<div className="document-toOrganization">{receiver.organization.name}
									</div>

									<div className="document-toOrganization-date">
										{date_sent}
									</div>
								</div>

							</div>
							<div className="document-status-sentToContainer">
								<div className="receiverStatus">Status: {receiver.status} {date_approved} </div>
								<div className="reminderButton-container"> {reminderButton}
									{reminderCounter}</div>
							</div>
						</div>
					)
				})
			}
		}
		let logsList = <div className="helpText">No logs for this recipe.</div>;
		if (this.state.logs.length > 0) {
			logsList = this.state.logs.map((entity, i) => {
				return <ShortLogsRow log={entity} key={i} />
			})
		}
		return (
			<div className='recipe-viewer'>

				<div className="recipe-main boxShadow">
					<button className='button-ReturnViewer' onClick={this.onReturnNavigation}> Back to Recipes </button>
					<div className="recipeInfo">
						{/* {this.state.loaded?null:docLoader} */}
						<div className="recipeInfo-main">
							<div className="recipeInfo-left">
								<div className="recipe-title">{doc.name} </div>
								<div>{versionNumber}</div>
								<InfoField content={doc.user_defined_id} contentType='ID'
									doc={doc} onClickOut={this.onSaveChanges} />
								<InfoField content={doc.name} contentType='Name' doc={doc}
									onClickOut={this.onSaveChanges} />

							</div>
							<div className="recipeInfo-rightContainer">
								<div className="recipeInfo-right">

									<DeleteButton items={[doc]}
										type="recipes"
										onGetDocuments={this.onReturnNavigation} onClear={this.onReturnNavigation} />
									<SignatureButton doc={doc} onGetDocuments={this.onGetRecipe} />
									{recipeForkButton}
									<BOMButton recipe={recipe} doc={doc} onGetRecipe={this.onGetRecipe} />
									<RecipeBatchSheetPreviewButton recipe={recipe} ignore_density={ignore_density} />
									<IngredientFromRecipeButton recipe={recipe} />
									{recipeLockButton}
									<RecipeVersionButton recipe={recipe} />


								</div>
							</div>

						</div>
						<div>
							{doc ? <div>
								<TagsField content={doc.tags} contentType='Tags' doc={doc}
									onClickOut={this.onSaveTagsChanges} />
								<DocumentNotesField content={doc.notes} contentType='Notes'
									doc={doc} onClickOut={this.onSaveChanges} /></div> : null}
							<ToggleRequiresSignatures requires_signatures={requires_signatures} onClickOut={this.onToggleRequiresSignatures}
								contentType="requiresSignatures" />
						</div>

						<div className="recipe-ingredient-container">
							<div className="recipe-ingredient-title">
								<div className="recipe-ingredient-title-section">
									<div className="recipe-ingredient-title-text">Ingredients</div>
									<div className="recipe-cost-selector">
										<div className="recipe-cost"> ${price},   {density}
										</div>

									</div>
								</div>
								<div className="recipe-ingredient-title-section">
									Units: <UnitSelector changeUnits={this.changeUnits} unit={this.state.currentUnits}
										ignore_density={ignore_density} />
									<div>Total Percentage: {total_weight_ratio}</div>
								</div>

							</div>
						</div>
						<table>
							{ingredients}
						</table>
						<div className="recipeViewer-changeIngredientButtons">
							<AddIngredientButton onGetLogs={this.onGetLogs}
								onGetRecipe={this.onGetRecipe} recipe={recipe} />
							<DeleteIngredientButton startDeleting={this.startDeleting}
								cancelDeleting={this.cancelDeleting}
								confirmDeleting={this.confirmDeleting}
								deleting={this.state.deleting}
								recipe={recipe} />
						</div>
					</div>
					<ToggleDensity ignore_density={ignore_density} onClickOut={this.onToggleIgnoreDensity} contentType="ignore_density" />
					{recipe ? <PriceTable ingredients={recipe_ingredients} recipe={recipe}
						onGetRecipe={this.onGetRecipe} onGetLogs={this.onGetLogs}
						ignore_density={ignore_density} /> : null}
					<ReleaseCriteriaTable releaseCriteria={releaseCriteria} recipe={recipe} onGetRecipe={this.onGetRecipe}
						onGetLogs={this.onGetLogs} />

					<div className="recipe-ingredient-container">
						<div className="recipe-ingredient-title">
							<div className="recipe-ingredient-title-text">Documents</div>
							<div className="relatedDocuments-buttonContainer">
								<TagsField content={related_tags} contentType='Related Tags' doc={doc}
									onClickOut={this.onSaveRelatedTagsChanges} />
								<DocumentUploadModalButton related_tags={related_tags} onGetDocuments={this.onGetRecipe} />

							</div>
						</div>
						{documents}

					</div>
				</div>
				<div>
					<div className='recipe-viewer-right-box boxShadow recipe-viewer-right-top-box'>
						<div className='document-viewer-column-header-text'>
							<div className="batchSheetButton-container"> <div>Batch Sheets</div>
								{showBatchSheetViewer ?
									<BatchSheetViewerButton recipe={recipe} doc={doc} ignore_density={ignore_density} /> : null}
							</div>
							<SendBatchSheetToButton recipe={recipe} doc={doc} viewer getRecipe={this.onGetRecipe} ignore_density={ignore_density} />
						</div>
						<div className="sentTo-tableHeader">
							<div >
								Sent To</div>
							<div>Sent On
							</div>
						</div>
						<div className='document-viewer-permissions '>
							{sharedWithList}
						</div>
					</div>
					<div className='document-viewer-rightColumn boxShadow recipe-viewer-right-margin'>
						<div className="document-viewer-rightColumn-header">
							{this.state.showVersionHistory ?
								<div className="document-viewer-column-header-text" >
									<div onClick={this.onShowLogs}
										className="document-viewer-tabInactive">Logs</div>
									<div className="document-viewer-tabActive">
										Version History

									</div>
								</div> :
								<div className="document-viewer-column-header-text" >
									<div className="document-viewer-tabActive">Logs</div>
									<div onClick={this.onShowVersionHistory}
										className="document-viewer-tabInactive" id="versionHistory">
										Version History
									</div>

								</div>}
						</div>

						{this.state.showVersionHistory ?
							<div className='versionHistory'>

								<div className="document-versionHistoryContents">

									{versionHistory}
								</div>
							</div>
							:
							<div className='logSummary'>

								<div className="logSummary-contents">
									{logsList}
								</div>

							</div>}


					</div>
				</div>

				<div>
					{this.state.error}

				</div>
			</div>
		);
	}
}

export default withRouter(CookieWrapper(RecipeViewer));
