import React from 'react';

import './TopTag.css';

import {
	DragSource,

} from 'react-dnd';
const tagSource = {
  beginDrag(props){
		if (props.tag.is_accessible){
    return {name:props.tag.name}
	}
  },

}
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
  }
}

class TopTag extends React.Component{
  onHandleClick = (e) =>{
		if (this.props.tag.is_accessible){
		e.stopPropagation();
		if (e.shiftKey){
			this.props.onAddExcludedTag(this.props.tag.name);
	    this.props.onLastSelected(this.props.tag.name);
		} else {
	    this.props.onAddActiveTag(this.props.tag.name);
	    this.props.onLastSelected(this.props.tag.name);
		}
		}
  }
  render(){
    let tag = this.props.tag;
    const { connectDragSource} = this.props;
    return connectDragSource(
      <div className = "tagLabel-container" onClick ={this.onHandleClick}
				id = {this.props.tag.is_accessible?null:"notAccessibleTag"}>
          <div className = "tagLabel-name">{tag.name}</div>
      </div>
    )
  }
}
export default DragSource('tag', tagSource, collect)(TopTag);
