import React from 'react';
import moment from 'moment';
import './Timeline.css';
class TimelineItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: true,
    }
  }
  onToggleExpand = () => {
    this.setState({
      showMore: !this.state.showMore
    });
  }
  render() {
    let monthDocs = this.props.monthDocs;
    let month = monthDocs[0];
    let docs = monthDocs[1];
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    let monthName = monthNames[month];
    let docNames = docs.map((doc,i) => {
      return <div className="timeline-docContainer" key = {i}>
        <div className={this.props.hidden?null:"timelineItem-name"}>{doc.name}
        </div>
        <div className={this.props.hidden?null:"timelineItem-date"}>{moment(doc.date_expiration).format('ll')}
        </div>
      </div>
    });
    let currentMonth = this.props.now.month();
    let monthNumber;
    if (month < currentMonth) {
      monthNumber = (month - currentMonth + 12) / 12 * 90;
    } else {
      monthNumber = (month - currentMonth) / 12 * 90;
    }

    if (this.props.hidden){
      return (<div className="timelineItem-containerHidden" style={{
          left: `${monthNumber}%` 
        }} >
        <div className = "timeline-hiddenMonth">{monthName}</div>
        <div >{docNames}</div>
      </div>)
    }
    if (this.props.topRow) {
      return (<div className="timelineItem-container" id="timeline-topRow" style={{
          left: `${monthNumber}%`
        }}>
        <div className = "timeline-monthName" onClick = {this.onToggleExpand}>
          {monthName} {moment(docs[0].date_expiration).year()}
        </div>
        {this.state.showMore?<div className="timeline-docNamesContainer">{docNames}</div>:null}

      </div>)
    } else {
      return (<div className="timelineItem-container" style={{
          left: `${monthNumber}%`
        }}>
        <div className = "timeline-monthName" onClick = {this.onToggleExpand}>
          {monthName} {moment(docs[0].date_expiration).year()}
        </div>
        {this.state.showMore?<div>{docNames}</div>:null}
      </div>)
    }
  }
}

export default TimelineItem;
