import React from 'react';
import PropTypes from 'prop-types';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './Logs.css';
import LogsRow from './LogsRow';
import qs from 'qs';
import CookieWrapper from '../CookieWrapper/CookieWrapper';
import PageButtons from '../PageButtons/PageButtons';
import RecentlySent from './RecentlySent/RecentlySent';
import RecentlySigned from './RecentlySent/RecentlySigned';
class Logs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			logs: [],
			pageSize:20,
			page: 1,
			total_logs: 0,
		}
	}
	onChangePage = (pageNumber) =>{
		this.searchStringGenerator(pageNumber,'page');
	}
	componentDidMount() {
		this.onGetLogs();
	}
	onGetLogs(){
		Request.authRequest(Routes.logs.recipeAppList(this.props.location.search), 'GET').then((response) => {
			if (response.status === 200) {
				response.json().then((logs) => {
					this.setState({ logs:logs.results, total_logs: logs.total_results});
				})
			} else {
				response.json().then((error) => {
					this.setState({ error: error.message });
				});
			}
		}).catch((error) => {
			this.setState({ error: error.message });
		});
	}
	componentDidUpdate(prevProps){
    if (prevProps.location.search !== this.props.location.search){
      this.onGetLogs();
    }
  }
	searchStringGenerator(input,type){

    const searchString=qs.parse(this.props.location.search.substr(1));

    if (!searchString.page){
      searchString.page = 1;
    }
    if (!searchString.logs_per_page){
      searchString.logs_per_page = 20;
    }
    switch (type) {
      case 'pageSize':
        searchString.per_page = input;
				if (this.state.total_logs/searchString.logs_per_page < searchString.page){
        searchString.page = Math.ceil(this.state.total_logs/searchString.logs_per_page);}
        break;
      case 'page':
        searchString.page = input;
        break;
      default:
		}

		let base_url = qs.stringify(searchString);
		return new Promise((resolve, reject) => {
			resolve(this.props.history.push({
				search:base_url,
				state:this.state,
				})
			)
		})

	}
	render() {
		let perPage = 20;
    let page = 1;
			const searchString = qs.parse(this.props.location.search.substr(1));
    if (searchString.logs_per_page){
      perPage = searchString.logs_per_page;
    }
    if (searchString.page){
      page = searchString.page
    }

		const logs = this.state.logs.map((log, i) => {
		  return <LogsRow log = {log} key = {i}/>
		});
		return (
			<div className = "logs-container">
				<div >
					<div className='logs boxShadow'>
						<div className='logsheader noselect' id = 'logs-header'>
							Logs
						</div>
						<div className = "logsTableHeader">
							<div className = "logsTableHeader-action">Logged Action</div>
							<div className = "logsTableHeader-timestamp">Timestamp</div>
							<div className = "logsTableHeader-user">User</div>
							<div></div>
						</div>
						{logs}
						</div>
						<PageButtons page = {page} perPage = {perPage}
	            numResults = {this.state.total_logs} onChangePage = {this.onChangePage}/>
			</div>

					</div>

		);
	}
}

Logs.propTypes = {
	summary: PropTypes.bool,
}

export default CookieWrapper(Logs);
