import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = (props) => {
	const { location } = props;
	let FooterComponent;
	switch(location.pathname) {
		case '/login':
		case '/signup':
		case '/organization/join':
			FooterComponent = (
				<div className='footer footer-unauth'>
					<div className='footer-links'>
						<a href = "https://www.ivydocuments.com"> About</a>
						<Link to='/termsofservice'>
							<span className='footer-link'> Terms of Use </span>
						</Link>
						<Link to='/privacypolicy'>
							<span className='footer-link'> Privacy Policy </span>
						</Link>
						<span className='footer-link copyright'> © 2018 Ivy Food Technology </span>
					</div>
				</div>
			);
			break;
		case '/logout':
			FooterComponent = null;
			break;
		default:
			FooterComponent = (
        <div>
          <div className = 'footer-buffer'>
          </div>
  				<div className='footer-auth' >
            <div className='footer-links'>
  						<a href = "https://www.ivydocuments.com"> About</a>
  						<Link to='/termsofservice'>
  							<span className='footer-link'> Terms of Service </span>
  						</Link>
  						<Link to='/privacypolicy'>
  							<span className='footer-link'> Privacy Policy </span>
  						</Link>
  						<span className='footer-link copyright'> © 2018 Ivy Food Technology </span>
  					</div>
          </div>
        </div>
			);
			break;
	}
	return FooterComponent;
}

export default Footer;
