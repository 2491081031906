import React from 'react';

import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import './DeleteTagButton.css';
class DeleteTagButton extends React.Component{
  constructor(){
    super();
    this.deleteTag = this.deleteTag.bind(this);
    this.state={
      showModal:false,
    }
  }
  onShowModal = ()=>{
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false
  })}

  deleteTag = ()=>{
        Request.authRequest(Routes.tags.edit(this.props.tag),
  											  'DELETE').then(
                            (response) => {
                              if(response.ok){
                                toast.success(`Successfully deleted the ${this.props.tag} tag`)
                                this.props.onDeleteTag(this.props.tag);
                                this.setState({showModal:false});
                          } else {
                            response.json().then((error) => {
                              toast.error(error.message);
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )

  }

  render(){
    return(
      <div className = "buttonDeleteTag">
        <button className='headerButton noselect' id = "buttonDeleteTag" onClick = {this.onShowModal}>
          Delete Tag

        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Delete Items' >
        <div className = "button-modalText">
          <p>
          You are about to delete {this.props.tag} &nbsp;
           items. This change cannot be undone.
           <br/>
             WARNING: Deleting tags is potentially dangerous. If you are trying to
               remove associations, use the parent and child bars to the right. This
               deletes the {this.props.tag} tag. Be sure you want to do this.
        </p>

        <button className= 'form-button primary' onClick={this.deleteTag}> Yes </button>
        </div>
      </Modal>
      </div>


    )
  }
}
export default DeleteTagButton;
