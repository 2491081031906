import React from 'react';
import { Upload } from '../../Icons/IconLibrary';
import Modal from '../../Modal/Modal';
import LoadingButton from '../../Buttons/LoadingButton';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
class RecipeVersionButton extends React.Component{
    constructor(props){
        super(props);
        this.state={
          showModal:false,
          loading:false,
        }
      }
      onShowModal = () =>{
        this.setState({showModal:true})
      }
      onHideModal=()=>{
        this.setState({showModal: false
      })}
      versionRecipe = () =>{
        this.setState({loading:true})
        Request.authRequest(
            Routes.recipes.version(this.props.recipe.id),
                        'POST'
                      ).then((response) => {
                        if (response.status===200){
                        response.json().then(body => {

                          if (this.props.recipe.produced_ingredient){
                            this.setState({loading:true})
                  Request.authRequest(
                      Routes.ingredients.version(this.props.recipe.produced_ingredient.id),
                                  'POST', JSON.stringify({version_recipes:true}), 'application/json'
                                ).then((response) => {
                                  if (response.status===200){
                                  response.json().then(ingredient_body => {
                                    window.location.href = `/recipes/` + body.recipe_id
                                      toast.success(`Successfully versioned the produced ingredient!`)
                                    });
                                  } else {
                                    response.json().then((error) => {
                                      this.setState({loading:false});
                                      toast.error(error.message);
                                    });
                                  }
                            }).catch((error) => {
                              toast.error(error.message);
                            }
                          )




                          }
                          else{
                            window.location.href = `/recipes/` + body.recipe_id
                            toast.success(`Successfully versioned the recipe!`)}
                          });
                        } else {
                          response.json().then((error) => {
                            this.setState({loading:false});
                            toast.error(error.message);
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
				
      }
    render(){
      let produced_ingredient;
      if (this.props.recipe && this.props.recipe.produced_ingredient){
        produced_ingredient=true;
      }
        return(
            <div>
      <img src = {Upload} className = "signatureButton-text" onClick = {this.onShowModal}
        alt = "Create a new version of this recipe" title = "Create a new version of this recipe "/>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Version Recipe' >
        <div><div>Version this recipe. If the recipe is not locked, it will be locked 
          and its current state will be copied to a new version. </div>
          
        {produced_ingredient?<div>This recipe produces an ingredient. 
            Versioning this recipe will version the ingredient, and the recipes the ingredient is used in.</div>:null}</div>
        
            <div className= 'modal-button primary'>
            <LoadingButton onClick = {this.versionRecipe} 
        text = 'Create New Version' isLoading = {this.state.loading}/>
            </div>
        </Modal>

      </div>
        )
    }
}

export default RecipeVersionButton;