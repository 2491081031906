import React from 'react';
import {Link} from 'react-router-dom';
import './SendReceiveHeader.css';

class PermissionsNavigator extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showHoverMenu:false
		}}
    onHandleMouseOver = ()=>{
      this.setState({showHoverMenu:true})
    }
    onHandleMouseLeave = ()=>{
      this.setState({showHoverMenu:false})
    }
    render() {
      if (this.state.showHoverMenu){
				return(
					<div className = "PermissionsNavigator-container" onMouseLeave = {this.onHandleMouseLeave}>
            <Link className = 'permissions-links'
              to ='/sendreceive/templates'>
              Send/Receive
							<div className = "dropdownTriangle"></div>
						</Link>
            <div className = "PermissionsNavigator-submenu">
									<Link className = 'templates-submenu-links'
										to ='/sendreceive/templates'>
										Your Templates
									</Link>
									<Link className = 'templates-submenu-links' id = "introJS-receivedRequests" to = '/sendreceive/requests/'>
										Received Requests
									</Link>
									<Link className = "templates-submenu-links" to ='/sendreceive/pending/'>
										Pending Documents
									</Link>
									<Link className = 'templates-submenu-links' to = '/sendreceive/contacts'>
										Contacts
									</Link>
                  </div>
					</div>

				)
    	}
     else {return (
      <div className = "PermissionsNavigator-container" onMouseEnter = {this.onHandleMouseOver}>

              <Link className = 'permissions-links'
                to ='/sendreceive/templates'>
                Send/Receive
								<div className = "dropdownTriangle"></div>
							</Link>
            </div>
    )}
  }

}
export default PermissionsNavigator;
