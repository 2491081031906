import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../../Modal/Modal';
import DocumentReportComponent from './DocumentReportComponent';
import Timeline from './Timeline/Timeline';
import './Timeline/Timeline.css';
import TimeDiff from '../../Statistics/OverallTimeDiff';

class DocumentReportButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReport: false,
      showTimeline: false,
      paid:true,
    }
  }
  componentDidMount =()=>{
    this.paidCheck();
  }
  paidCheck = ()=>{
    Request.authRequest(Routes.organization.index,
      'GET').then((response)=>{
        if (response.status===200){
        response.json().then(body=>{
          this.setState({paid:body.paid})
            })
          }
          else {
            response.json().then((error) => {
              toast.error(error.message);
            });
          }
        }).catch((error) => {
          toast.error(error.message);
        }
        )
  }
  onShowModal = () => {
    this.setState({showReport: true})
  }
  onHideModal = () => {
    this.setState({showReport: false})
  }
  onToggleTimeline = () =>{
    this.setState({showTimeline:!this.state.showTimeline})
  }
  render() {
    let documents = "None";
    let activeDocs;
    if (this.props.activeDocs.length > 0) {
      activeDocs = this.props.activeDocs;
      documents = this.props.activeDocs.map((doc, i) => {
        return <DocumentReportComponent doc={doc} key={i} sentTo={this.props.sentTo}/>
      })
    } else if (this.props.docs.length > 0) {
      activeDocs = this.props.docs;
      documents = this.props.docs.map((doc, i) => {
        return <DocumentReportComponent doc={doc} key={i} sentTo={this.props.sentTo}/>
      })
    }
    if (!this.state.paid){
      return(
        <div>
          <div onClick={this.onShowModal} className="viewVersionReportButton">View Document Report</div>
          <Modal show={this.state.showReport} onClose={this.onHideModal} title='Document Report' extraWide="extraWide">
            <div>
              <div className="smallHelpText">To print this page or save as pdf, right click and press Print. If documents are actively selected, this report will only show actively selected documents. Otherwise, it will show all documents in view. To see the statuses of documents for only one entity, select a Shared With filter under advanced.
              </div>
              "This is a paid feature! Please upgrade to see all your documents info, and a expiration timeline in one view."
            </div>
          </Modal>
        </div>
      )
    }
    return (<div >
      <div onClick={this.onShowModal} className="viewVersionReportButton">View Document Report</div>
      <Modal show={this.state.showReport} onClose={this.onHideModal} title='Document Report' extraWide="extraWide">
        <div>
          <div className="smallHelpText">To print this page or save as pdf, right click and press Print. If documents are actively selected, this report will only show actively selected documents. Otherwise, it will show all documents in view. To see the statuses of documents for only one entity, select a Shared With filter under advanced.
          </div>
          <div>{this.state.showTimeline?
              <div className = "buttonHideTimeline" onClick = {this.onToggleTimeline}>Hide Timeline</div>:
              <div className = "buttonHideTimeline" onClick = {this.onToggleTimeline}>Show Timeline </div>}
          </div>
          {this.state.showTimeline?<Timeline docs={activeDocs}/>:null}
          <TimeDiff docs = {activeDocs}/>
          {documents}
        </div>
      </Modal>
    </div>)
  }
}

export default DocumentReportButton;
