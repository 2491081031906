import React from 'react';
import './InfoField.css';
import {SingleDatePicker} from 'react-dates';
import moment from 'moment';
const falseFunc = ()=>false;
class DocumentDateField extends React.Component{
  constructor(props){
    super(props);
    this.state={
      editing:false,
      expiring:true,
      focused: false,
      date:this.props.contents
    }
  }
  componentDidUpdate(prevProps,prevState){
    if (prevProps.content!== this.props.content){
      if (this.props.content){
      this.setState({date:this.props.content, expiring:true});
    } else{
      this.setState({date:null,expiring:false})
    }
      }
    }


  componentDidMount(){
    if (!this.props.content){
      this.setState({expiring:false, date:null})

    }
    else{
        this.setState({date:this.props.content})
    }
  }

  onSaveChanges(){
    if (this.state.date !== null && this.state.expiring === true){

      this.props.onClickOut(this.props.contentType,this.state.date);}
      else{
        this.props.onClickOut(this.props.contentType,null);
      }
  }
  handleDblClick = (e) =>{
    if (this.node.contains(e.target)){
      this.setState({
        editing:true
      })
    }
    else if(this.state.editing!==false){
      this.onSaveChanges();
      this.setState({editing:false})
    };
  }
  componentWillMount(){
    document.addEventListener('dblclick',this.handleDblClick,false);
  }
  componentWillUnmount(){
    document.removeEventListener('dblclick',this.handleDblClick,false);
  }
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onSaveChanges();
      this.setState({editing:false})
    }
  }
  onChangeDocumentDate = (date) => {
    if (date){
		this.setState({
			date: date._d
		});
  }
	}
  onChangeExpiring = (e) => {
    let toggleexpiring = !this.state.expiring;
		this.setState({
			expiring: toggleexpiring,
		});
	}
  render(){
    let date = new Date(this.state.date);
    let dateString;
    let inputDate;
    if (this.state.date){
      dateString = date.toDateString();
    };
    let datePickerDate = null
    if (this.state.date){
      datePickerDate = moment(this.state.date);
    }
    if (this.state.editing){
    return (

      <div className='doc-info-field' ref = {node => this.node = node}>
        <label className='document-label noselect' >Expires On </label>

          <div className = "datePickerContainer">
            {this.state.expiring?
              <SingleDatePicker
                date={datePickerDate}
                onDateChange={this.onChangeDocumentDate}
                focused={this.state.focused}
                onFocusChange={({ focused }) => this.setState({ focused })}
                numberOfMonths= {1}
                isOutsideRange= {falseFunc}
                id="documentDateField"
              />
            :null}
            <div>
              <input
                checked = {!this.state.expiring}
                name = 'expiresBoolean'
                type='checkbox'
                id = 'expiresBoolean'
                onChange={this.onChangeExpiring}/>
              <label htmlFor ='expiresBoolean'> This document doesn't expire</label>
            </div>
          </div>
        </div>
      )
    }
    else return(
      <div className='doc-info-field' ref = {node => this.node = node}>
        <label className='document-label noselect' >Expires On </label>
            <div className = 'document-info'>{dateString}
            </div>
          </div>
          )

  }

}
export default DocumentDateField;
