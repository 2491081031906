import React from 'react';
import './DocumentReportComponent.css';
import DocumentReportSignature from './DocumentReportSignature';
import DocumentReportSentTo from './DocumentReportSentTo';
class DocumentReportComponent extends React.Component {
  render() {
    let doc = this.props.doc;
    let dateCreated = new Date(doc.date_created).toDateString();
    let dateExpiration = "None";
    if (doc.date_expiration) {
      dateExpiration = new Date(doc.date_expiration).toDateString();
    }
    let signatures = <div className="smallHelpText">None</div>;
    let minorName;
    if (doc.user_defined_id) {
      minorName = doc.user_defined_id;
    }
    if (doc.signatures.length > 0) {
      signatures = doc.signatures.map((signature, i) => {
        return <DocumentReportSignature key={i} signature={signature}/>
      })
    }
    let notes = <div className="smallHelpText">None</div>;
    if (doc.notes) {
      notes = doc.notes;
    }
    let sent_to = <div className="smallHelpText">None</div>;
    if (doc.shared_with.length > 0) {
      sent_to = doc.shared_with.map((share, i) => {
        if (this.props.sentTo) {
          if (share.name == this.props.sentTo) {
            return <DocumentReportSentTo doc={doc} key={i} sentTo={share}/>
          }
        } else {
          return <DocumentReportSentTo doc={doc} key={i} sentTo={share}/>
        }
      })

    }

    return (<div className="versionReport-componentContainer">
      <div className="versionReport-nameContainer">
        <div className="versionReport-name">
          <div className="versionReport-Label">Version {doc.version_number}
            - Name</div>
          <div >{doc.name}
            <div className="versionReport-minorName">{minorName}</div>
          </div>
        </div>
        <div className="versionReport-dateContainer">
          <div className="versionReport-date">
            <div className="versionReport-Label">Date Created</div>
            <div>{dateCreated}</div>
          </div>
          <div className="versionReport-date">
            <div className="versionReport-Label">Expiration Date</div>
            <div>{dateExpiration}</div>
          </div>
        </div>
      </div>
      <div className="versionReport-notes">
        <div className="versionReport-Label">Notes</div>
        <div>{notes}</div>
      </div>
      <div className="versionReport-signatures">
        <div className="versionReport-Label">Signatures</div>
        {signatures}
      </div>
      <div className="versionReport-signatures">
        <div className="versionReport-Label">Shared With</div>
        {sent_to}
      </div>
      <div></div>
    </div>)
  }
}

export default DocumentReportComponent;
