import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import IngredientTemplateAttributeInfoField from './IngredientTemplateAttributeInfoField';
import AddIngredientTemplateAttribute from './AddIngredientTemplateAttributeButton';
import RemoveIngredientAttributeButton from '../IngredientViewer/RemoveIngredientAttributeButton'
import './IngredientTemplates.css';
class IngredientTemplateRow extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            expanded: false,
            propertiesToRemove: [],
            removeProperties: false,
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.template.id != this.props.template.id) {
            this.setState({ expanded: false, propertiesToRemove: [], removeProperties: false });
        }
    }
    toggleExpand = () => {
        this.setState({ expanded: !this.state.expanded });
        this.onSelectTemplate();
    }
    toggleRemoveProperties = () => {
        this.setState({ removeProperties: !this.state.removeProperties })
    }
    addPropertyToDelete = (property) => {
        this.setState({
            propertiesToRemove:
                [...this.state.propertiesToRemove, property]
        })

    }
    removePropertyToDelete = (property) => {
        this.setState({
            propertiesToRemove:
                this.state.propertiesToRemove.filter(properties => properties != property)
        })
    }
    onCancelRemove = () => {
        this.setState({ propertiesToRemove: [], removeProperties: false });
    }
    changeProperty = (property, key, value) => {
        let template = this.props.template;
        let input_format = {
            name: template.name,
            properties: template.properties
        }
        delete input_format.properties[property[0]]
        input_format.properties[key.trim()] = value.trim();

        Request.authRequest(Routes.ingredients.template(this.props.template.id),
            'PUT', JSON.stringify(
                input_format
            ), 'application/json'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.props.onGetTemplates();
                    this.setState({
                        removeProperties: false, propertiesToRemove: []
                    })
                });
            } else {
                response.json().then((error) => {
                    toast.error(error.message);
                    console.log(error);
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )
    }
    removeProperties = () => {
        let template = this.props.template;
        let input_format = {
            name: template.name,
            properties: template.properties
        }
        for (let i = 0; i < this.state.propertiesToRemove.length; i++) {
            delete input_format.properties[this.state.propertiesToRemove[i][0]]
        }

        Request.authRequest(Routes.ingredients.template(this.props.template.id),
            'PUT', JSON.stringify(
                input_format
            ), 'application/json'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.props.onGetTemplates();
                    this.setState({
                        removeProperties: false, propertiesToRemove: []
                    })
                });
            } else {
                response.json().then((error) => {
                    toast.error(error.message);
                    console.log(error);
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )
    }
    addAttribute = (key, value) => {
        let template = this.props.template;
        let input_format = {
            name: template.name,
            properties: template.properties,
        }
        input_format.properties[key.trim()] = value.trim();
        Request.authRequest(Routes.ingredients.template(this.props.template.id),
            'PUT', JSON.stringify(
                input_format
            ), 'application/json'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.props.onGetTemplates();
                    toast.success('Successfully edited the template')
                });
            } else {
                response.json().then((error) => {
                    toast.error(error.message);
                    console.log(error);
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )

    }
    deleteTemplate = () => {
        Request.authRequest(Routes.ingredients.template(this.props.template.id),
            'DELETE'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.props.onGetTemplates();
                    toast.success('Successfully deleted the template')
                });
            } else {
                response.json().then((error) => {
                    toast.error(error.message);
                    console.log(error);
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )
    }
    onStartRemove = () => {
        this.setState({ removeProperties: true })
    }
    onSelectTemplate = () => {
        this.props.sendSelectedTemplate(this.props.template);
    }
    render() {
        let template = this.props.template;
        let properties;
        if (template) {
            properties = Object.entries(template.properties);
            properties = properties.map((property, i) => {
                return <IngredientTemplateAttributeInfoField property={property}
                    addPropertyToDelete={this.addPropertyToDelete} removePropertyToDelete={this.removePropertyToDelete}
                    key={i} changeProperty={this.changeProperty} removeProperties={this.state.removeProperties} />
            })
        }
        let currentSelected = false;
        if (this.props.currentTemplate.id == this.props.template.id) {
            currentSelected = true;
        }
        return (
            <div className="ingredientTemplateRow-container" id={currentSelected ? 'ingredientTemplate-selected' : null}>
                <div className="ingredientTemplateRow-header">
                    <div className="ingredientTemplateRow-name"
                        onClick={this.toggleExpand}>{template.name}</div>
                    <div >
                        <div className="ingredientTemplateRow-delete" onClick={this.deleteTemplate}>Delete</div>
                    </div>
                </div>
                {this.state.expanded ?
                    <div>
                        <div>{properties}</div>
                        <div className="template-attributeEditButtonContainer">
                            <AddIngredientTemplateAttribute sendAttribute={this.addAttribute} />
                            <RemoveIngredientAttributeButton
                                onStartRemove={this.onStartRemove}
                                removeProperties={this.removeProperties} onCancelRemove={this.onCancelRemove} />
                        </div>
                    </div> : null}
            </div>
        )
    }
}
export default IngredientTemplateRow