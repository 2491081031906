import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../../Modal/Modal';
import TagNavigator from '../../Tags/TagNavigator';
class MergeTagsButton extends React.Component{
    constructor(){
      super();
      this.mergeTag = this.mergeTag.bind(this);
      this.onChangeTags = this.onChangeTags.bind(this);
      this.state={
        showModal:false,
        mergeIntoTag:''
      }
    }
    onShowModal = ()=>{
      this.setState({showModal:true})
    }

    onHideModal=()=>{
      this.setState({showModal: false
    })}

    mergeTag = ()=>{
      if (this.props.tag.name === this.state.mergeIntoTag){
        toast.error('You cannot merge a tag into itself.');
        return;
      }
      this.toastID = toast.info('Merging...');
      this.setState({showModal:false})
          Request.authRequest(Routes.tags.merge(this.props.tag.name,this.state.mergeIntoTag),
    											  'PUT').then(
                              (response) => {
                                if(response.ok){
                                  toast.update(this.toastID, {render:`Successfully merged the
                                     ${this.state.mergeIntoTag} tag into ${this.props.tag.name}`, type: toast.TYPE.SUCCESS, autoClose: 5000 })
                                  this.props.onMergeTag(this.props.tag.name);
                                  this.props.onGetNewTopTags();
                                  this.props.onDeleteMergedTag(this.state.mergeIntoTag);
                                  this.setState({showModal:false, mergeIntoTag:''});
                            } else {
                              response.json().then((error) => {
                                toast.error(error.message);
                                if (error.errors){
                                  toast.error(error.errors[0])
                                }
                              });
                            }
                      }).catch((error) => {
                        toast.error(error.message);
                      }
                    )

    }
    onChangeTags = (tag)=>{
      return
    }
    onLastSelected = (tag)=>{
      this.setState({mergeIntoTag:tag})
    }
    render(){
      let mergeText = `Select a tag to merge into ${this.props.tag.name}.`;
      if (this.state.mergeIntoTag){
        mergeText = `You are about to merge ${this.state.mergeIntoTag} into ${this.props.tag.name}.`;
      }
      return(
        <div className = "buttonMergeTag">
          <button className='mergeButton noselect' id = "buttonMergeTag" onClick = {this.onShowModal}>
            Merge Tag

          </button>
          <Modal show={this.state.showModal} onClose={this.onHideModal} title='Merge Items' >
            <TagNavigator onLastSelected={this.onLastSelected}
              onChangeTags = {this.onChangeTags}
              activeTags={this.state.mergeIntoTag}/>
          <div className = "button-modalText">
            <p>
            {mergeText}  </p>

          <button className= 'form-button primary' onClick={this.mergeTag}> Merge </button>
          </div>
        </Modal>
        </div>


      )
    }
  }
export default MergeTagsButton;
