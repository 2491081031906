import React from 'react';
import { toast } from 'react-toastify';

import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import PaymentModal from './PaymentModal';
class CurrentCardComponent extends React.Component{
  constructor(props){
    super(props);
    this.state={
      currentCard:''
    }
  }
  onShowModal = () =>{
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false})
  }
  deleteCard = ()=>{
    Request.authRequest(Routes.payments.edit,
                    'DELETE').then(
                      (response) => {
                        if(response.status === 200){
                          toast.success('Successfully deleted the credit card information. Please enter a new card')
                          this.setState({showModal:false})
                          this.props.onDeleteCard();
                    } else {
                      response.json().then((error) => {
                        toast.error(error.message);

                      });
                    }
              }).catch((error) => {
                toast.error(error.message);
              }
            )
          }
  render(){
    let card = this.props.card;
    return(
      <div>
        <div>Your subscription is charged to the {card.Type} ending in {card.Last4}.
          You will be billed in {card.DaysLeft} days.
        </div>
        <div className = "DeleteCardButton" onClick = {this.onShowModal}> Delete This Card and Cancel Subscription
        </div>
        <PaymentModal show={this.state.showModal} onClose={this.onHideModal}
          title='Delete Card Information' >

             <div className = "deleteCardText">Confirm the deletion of your credit card information</div>
            <span className = "modal-button primary" onClick = {this.deleteCard}> Confirm Delete </span>

          </PaymentModal>
      </div>
    )
  }
}
export default CurrentCardComponent;
