import React from 'react';

class NotificationSettingsCheckbox extends React.Component{

  onChangeCheckbox = () =>{
    this.props.onChangeCheckbox(this.props.notificationType, this.props.isChecked)
  }
  render(){
    return(
      <div>
        <input type = 'checkbox' checked = {this.props.isChecked}
          onChange = {this.onChangeCheckbox}/>  You receive email notifications upon {this.props.notificationType}.
      </div>
    )
  }
}

export default NotificationSettingsCheckbox;
