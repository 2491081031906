import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import {toast} from 'react-toastify';
import LoadingButton from '../Buttons/LoadingButton';
class OrganizationCreate extends React.Component {
	constructor() {
		super();
		this.state = {
			organizationName: '',
			error: '',
			administrator: 'yes',
			firstName:'',
			lastName:'',
			jobTitle:'',
		};
	}
	onChangeFirstName = (e) => {
		this.setState({
			firstName: e.target.value,
			error: '',
		});
	}

	onChangeLastName = (e) => {
		this.setState({
			lastName: e.target.value,
			error: '',
		});
	}
	onChangeJobTitle = (e) => {
		this.setState({
			jobTitle: e.target.value,
			error: '',
		});
	}
	isValid(str){
 		return !/[~`!#$%\^&*+=\-\[\]\\;,/{}|\\":<>\?]/g.test(str);
	}
	onSaveUser = () =>{
		if (this.state.firstName===''){
			toast.error('Please enter a first name');
			return
		}
		if (this.state.lastName===''){
			toast.error('Please enter a last name');
			return
		}
		if (this.state.organizationName.length ===0){
			toast.error('Please enter an organization name.');
			return
		}
		if (!this.isValid(this.state.firstName) || !this.isValid(this.state.lastName) || !this.isValid(this.state.organizationName)){
			toast.error('Special characters are not allowed for any field.')
			return
		}
    let user = {first_name:this.state.firstName,last_name:this.state.lastName,
		job_title:this.state.jobTitle};
		this.setState({loading:true});
      Request.authRequest(Routes.profile.view,
                        'PUT',JSON.stringify(user), 'application/json'
                      ).then((response) => {
                        response.json().then(body => {
                          this.onAttemptSubmit();

          }
        )
      }
    );

  }
	onChangeOrganizationName = (e) => {
		this.setState({
			organizationName: e.target.value,
			error: '',
		});
	}

	onAdministratorChange = (e) => {
		this.setState({
			administrator: e.target.value,
		})
	}

	onAttemptSubmit = () => {
		
		Request.authRequest(Routes.organization.create, 'POST', JSON.stringify({
			name: this.state.organizationName.trim(),
		}), 'application/json').then((response) => {
			if (response.status === 200) {
				response.json().then((body) => {
					const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
					if (vw < 700 && !(window.location.search.indexOf('from_mobile')>-1)){
						window.location.href= 'https://m.ivydocuments.com/home';
					} else {
						window.location.href = '/home';
					}
				});
			} else {
				response.json().then((error) => {
					
					this.setState({ error: error.message , loading:false});
					if (error.errors){
						toast.error(error.errors[0])
					}
				});
			}
		}).catch((error) => {
			this.setState({ error: error.message, loading:false});
		});
	}

	render() {
		const administratorHelpText = this.state.administrator === 'yes' ? null :
																	'We\'ll help you get set up as the administrator. You can change the administrator at any time in the Settings';

		return (
			<div className='authform-large boxShadow'>


				<Link to='/organization/join'>
						<button className='form-button create-org'> Join an Existing Organization </button>
					</Link>
				<div className = "organization-create-or-container">
					<div className = "organization-create-line"></div>
					<div className = "organization-create-or">or</div>
					<div className = "organization-create-line">
					</div>
				</div>
				<div className='create-org-header'> Create a New Organization </div>
				<label className='input-label' htmlFor='firstName'> First Name </label>
				<input type='text' onChange={this.onChangeFirstName} id='firstName' className='input-text'/>
				<label className='input-label' htmlFor='lastName'> Last Name </label>
				<input type='text' onChange={this.onChangeLastName} id='lastName' className='input-text'/>
				<label className='input-label' htmlFor='jobTitle'> Job Title </label>
				<input type='text' onChange={this.onChangeJobTitle} id='jobTitle' className='input-text'/>

				<div className='join-org'>
					<label className='input-label' htmlFor='orgName'> Organization Name </label>
					<div className='input-container'>
						<input type='text' onChange={this.onChangeOrganizationName} id='orgName' className='input-text access-code-input'/>
					</div>
					{this.state.error}
					<div className='register-text'> Will you be the administrator? </div>
					<div>
						<label htmlFor='radio-yes'> Yes </label>
						<input
							type='radio'
							value={'yes'}
							id='radio-yes'
							checked={this.state.administrator === 'yes'}
							onChange={this.onAdministratorChange}
						/>
					</div>
					<div>
						<label htmlFor='radio-no'> No </label>
						<input
							type='radio'
							value={'no'}
							id='radio-no'
							checked={this.state.administrator === 'no'}
							onChange={this.onAdministratorChange}
						/>
					</div>
					<div >{administratorHelpText} </div>

					<button className='form-button primary' >
						 
						 <LoadingButton onClick={this.onSaveUser}
							text = 'Create a New Organization' 
							isLoading = {this.state.loading}/>

						  </button>


				</div>

			</div>
		)
	}
}

export default OrganizationCreate;
