import React from 'react';
class PriceRow extends React.Component {
    render() {
        let recipe_ingredient = this.props.ingredient;
        let ingredient = recipe_ingredient.ingredient;
        let units = false;
        let vol_per_unit;
        let wt_per_unit;

        if (this.props.displayedUnits == 'metric_g_mL') {
            units = true;
            vol_per_unit = recipe_ingredient.f_vol_per_unit_mL
            wt_per_unit = recipe_ingredient.f_weight_per_unit_g
        }
        else if (this.props.displayedUnits == 'metric_kg_L') {
            units = true;
            vol_per_unit = recipe_ingredient.f_vol_per_unit_L
            wt_per_unit = recipe_ingredient.f_weight_per_unit_kg
        }
        else if (this.props.displayedUnits == 'imperial_lb_gal') {
            units = false;
            vol_per_unit = recipe_ingredient.f_vol_per_unit_gal
            wt_per_unit = recipe_ingredient.f_weight_per_unit_lb
        }
        if (this.props.ignore_density) {
            return (
                <tr className="batchSheetRow">
                    <td>{ingredient.name}</td>
                    <td>{ingredient.f_price_kg}</td>
                    <td>{ingredient.f_price_lb}</td>
                    <td>{recipe_ingredient.weight_ratio}</td>
                    <td>{wt_per_unit}</td>
                    <td>{units ? recipe_ingredient.f_weight_per_case_kg :
                        recipe_ingredient.f_weight_per_case_lb}</td>
                    <td>${recipe_ingredient.f_cost_per_unit}</td>
                    <td>${recipe_ingredient.m_cost_per_case.toFixed(4)}</td>

                </tr>
            )
        }
        return (
            <tr className="batchSheetRow">
                <td>{ingredient.name}</td>
                <td>{ingredient.f_price_kg}</td>
                <td>{ingredient.f_price_lb}</td>
                <td>{recipe_ingredient.weight_ratio}</td>
                <td>{vol_per_unit}</td>
                <td>{wt_per_unit}</td>
                <td>{units ? recipe_ingredient.f_total_vol_per_case_L :
                    recipe_ingredient.f_total_vol_per_case_gal}</td>
                <td>{units ? recipe_ingredient.f_weight_per_case_kg :
                    recipe_ingredient.f_weight_per_case_lb}</td>
                <td>${recipe_ingredient.f_cost_per_unit}</td>
                <td>${recipe_ingredient.m_cost_per_case.toFixed(4)}</td>

            </tr>
        )
    }
}

export default PriceRow;