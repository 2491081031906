import React from 'react';
import Dropzone from 'react-dropzone';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './DocumentsUpload.css';
import { toast } from 'react-toastify';
import TagCleaner from '../../helpers/TagCleaner';
import TagsSearch from '../TagsSearch/TagsSearch';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import SetSignatures from './Signatures/SetSignatures';
import SelectedUserComponent from './Signatures/SelectedUserComponent';
import qs from 'qs';
import LoadingButton from '../Buttons/LoadingButton';
import Tutorial1UploadButton from '../Layout/Help/Onboarding/Tutorial1UploadButton';
class DocumentsUpload extends React.Component {
	constructor() {
		super();
		this.onAttemptUpload = this.onAttemptUpload.bind(this);
		this.onChangeTags = this.onChangeTags.bind(this);
		this.onGetPresignedURL = this.onGetPresignedURL.bind(this);
		this.setGDrive = this.setGDrive.bind(this);
		this.TagsSearch = React.createRef();
		this.state = {
			files: null,
			error: '',
			folderFiles: '',
			user_defined_id: '',
			date_expiration: null,
			expiring: true,
			notes: '',
			tags: [],
			googleFolder: '',
			email: '',
			focused: false,
			requiresSignatures: false,
			selectedUsers: [],
			urgent: false,
			progress: 0,
			totalFiles: 0,
			selectedManagerUsers: [],
			uploadError: false,
		}
	}
	toastId = null;
	componentDidMount() {
		this.getDefaultDate();
	}
	clearTagsSearch = () => {
		this.TagsSearch.current.handleChange([]);
	}
	getDefaultDate = () => {
		var date = new Date();
		date.setUTCFullYear(date.getFullYear() + 1);
		this.setState({ date_expiration: date.toISOString() })
	}
	onDropFile = (acceptedFiles) => {
		this.setState({
			files: acceptedFiles,
			error: '',
		});
	}
	async requestSignatures(doc, selectedUsers, selectedManagerUsers) {
		let emails = selectedUsers.map((user, i) => {
			return user.email
		})
		if (selectedUsers.length === 0) {
			this.requestManagerSignatures(doc, selectedManagerUsers);
			return
		}
		const params = { user_emails: emails, urgent: this.state.urgent, manager_status: false };
		return await Request.authRequest(Routes.documents.signature(doc.document.id),
			'POST', JSON.stringify(params), 'application/json').then((response) => {
				if (response.status === 200) {
					response.json().then(body => {
						if (selectedManagerUsers.length > 0) {
							return this.requestManagerSignatures(doc, selectedManagerUsers);

						} else {
							this.setState({ progress: this.state.progress + 1 });
							if (this.state.progress < this.state.totalFiles - 1) {
								toast.update(this.toastID, {
									render: `Uploaded ${this.state.progress}
				out of ${this.state.totalFiles} files`
								})
							}


							return body
						}
					})
				}
				else {
					response.json().then((error) => {
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)

	}
	requestManagerSignatures = (doc, selectedManagerUsers) => {
		let emails = this.state.selectedManagerUsers.map((user, i) => {
			return user.email
		})
		const params = {
			user_emails: emails, urgent: this.state.urgent,
			manager_status: true
		};
		Request.authRequest(Routes.documents.signature(doc.document.id),
			'POST', JSON.stringify(params), 'application/json').then((response) => {
				if (response.status === 200) {
					response.json().then(body => {
						this.setState({ progress: this.state.progress + 1 });
						if (this.state.progress < this.state.totalFiles - 1) {
							toast.update(this.toastID, {
								render: `Uploaded ${this.state.progress}
				out of ${this.state.totalFiles} files`
							})
						}
					})
				}
				else {
					response.json().then((error) => {
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}

	onDropFolder = (e) => {
		this.setState({
			folderFiles: [...e.target.files],
			error: '',
		});
	}
	onChangeDocumentDate = (date) => {
		if (date) {
			this.setState({
				date_expiration: date._d,
			});
		}
	}
	onChangeExpiring = (e) => {
		let toggleexpiring = !this.state.expiring;
		this.setState({
			expiring: toggleexpiring,
		});
	}
	onChangeRequiresSignatures = (e) => {

		if (this.state.folderFiles) {
			toast.error('Signature request is disabled for bulk upload')
			return;
		}
		let toggle = !this.state.requiresSignatures;
		this.setState({
			requiresSignatures: toggle,
		});
	}
	onChangeID = (e) => {
		this.setState({
			user_defined_id: e.target.value,
			error: '',
		});
	}
	setGDrive = (folder, email) => {
		this.setState({ googleFolder: folder, email: email });
	}

	onChangeNotes = (e) => {
		this.setState({
			notes: e.target.value,
			error: '',
		});
	}
	onChangeTags = (tags) => {
		this.setState({
			tags: tags,
			error: '',
		});
	}

	onReturnNavigation = () => {
		window.location.href = '/documents/?status=accepted';
	}
	updateToast = (toastId) => {
		this.setState({ loading: false });
		toast.update(toastId, { render: `Successfully uploaded documents`, type: toast.TYPE.SUCCESS, autoClose: 5000 });
	}

	async onAttemptFolderUpload(e) {
		var date = new Date();
		if (this.state.folderFiles === null) {
			this.setState({ error: 'No file was uploaded' });
			toast.error('Please Select a File')
			return null;
		}



		if (this.state.expiring && new Date(this.state.date_expiration) < date) {
			toast.error('Expiration dates must be in the future.')
			return null
		}

		this.toastID = toast.info('Uploading documents...', { autoClose: false, progress: 0 });
		let metadata = {
			tag_names: []
		};

		if (this.state.user_defined_id) {
			metadata.user_defined_id = this.state.user_defined_id;
		}
		if (this.state.notes) {
			metadata.notes = this.state.notes;
		}
		if (this.state.expiring === true) {
			metadata.date_expiration = this.state.date_expiration;
		}

		if (this.state.tags.length > 0) {
			metadata.tag_names = TagCleaner.parseTagArray(this.state.tags);
		}
		Promise.all(this.state.folderFiles.map((file) =>
			this.onGetFolderPresignedURL(file,
				metadata, this.state.tags, this.state.selectedUsers, this.state.selectedManagerUsers))).then(response => {
					if (toast.isActive(this.toastID)) {
						if (this.state.uploadError) {
							toast.update(this.toastID, {
								render:
									"Finished attempting to upload all documents. See below for errors", type: toast.TYPE.WARNING, autoClose: 5000
							});
						} else {
							toast.update(this.toastID, {
								render:
									"Finished attempting to upload all documents.", type: toast.TYPE.SUCCESS, autoClose: 5000
							});
						}
					}
					else {
						if (this.state.uploadError) {
							toast.warning(
								"Finished attempting to upload all documents. See below for errors");
						} else {
							toast.success(
								"Finished attempting to upload all documents.");
						}
					}
					this.setState({
						loading: false, files: null, user_defined_id: '', category: '',
						notes: '', tags: [], selectedUsers: [], requiresSignatures: false, progress: 0, uploadError: false,
						totalFiles: 0, manager: false, urgent: false
					}, this.getDefaultDate());
					this.clearTagsSearch();

				}
				)
	}
	onSelectUpload = (e) => {
		this.setState({ uploadError: false, loading: true })
		if (this.state.requiresSignatures &&
			this.state.selectedUsers.length === 0 &&
			this.state.selectedManagerUsers.length === 0) {
			toast.error('Please select required signatures, or unselect approval requirements.');
			return
		}
		let totalFiles = 0;
		if (this.state.files && this.state.files.length > 0) {
			totalFiles = this.state.files.length
		}
		if (this.state.folderFiles && this.state.folderFiles.length > 0) {
			totalFiles = totalFiles + this.state.folderFiles.length
		}
		this.setState({ totalFiles: totalFiles })
		if (this.state.files) {
			this.onAttemptUpload();
		}
		if (this.state.folderFiles) {
			this.onAttemptFolderUpload();
		}
		if (this.state.googleFolder) {
			this.syncFolder();
		}
		if (!this.state.files && !this.state.folderFiles) {
			this.setState({ loading: false })
		}
	}
	syncFolder = () => {
		let toastID = toast.info('Uploading documents...', { autoClose: false });
		Request.authRequest(Routes.drive.sync(this.state.email), 'POST',
			JSON.stringify({ folder_id: this.state.googleFolder.id }), 'application/json').then(
				(response) => {
					if (response.status === 200) {
						toast.update(toastID, {
							render: `Successfully synced ${this.state.googleFolder.name} folders`,
							type: toast.TYPE.SUCCESS, autoClose: 5000
						});
					}
					else {
						toast.update(toastID, {
							render: `Failed to sync ${this.state.googleFolder.name} folders`,
							type: toast.TYPE.ERROR, autoClose: 5000
						});
					}

				})
	}
	onAttemptUpload = (e) => {
		var date = new Date();
		if (this.state.files === null) {
			this.setState({ error: 'No file was uploaded' });
			toast.error('Please Select a File')
			return null;
		}
		if (this.state.tags.length === 0) {
			toast.error('Please specify at least one tag.')
			this.setState({ loading: false });
			return;
		}
		if (this.state.expiring && new Date(this.state.date_expiration) < date) {
			toast.error('Expiration dates must be in the future.')
			return null
		}
		this.toastID = toast.info('Uploading documents...', { autoClose: false, progress: 0 });
		let metadata = {
			tag_names: []
		};

		if (this.state.user_defined_id) {
			metadata.user_defined_id = this.state.user_defined_id;
		}
		if (this.state.notes) {
			metadata.notes = this.state.notes;
		}
		if (this.state.expiring === true) {
			metadata.date_expiration = this.state.date_expiration;
		}

		if (this.state.tags) {
			metadata.tag_names = TagCleaner.parseTagArray(this.state.tags)
		}
		Promise.all(this.state.files.map((file) =>
			this.onGetPresignedURL(file, metadata,
				this.state.tags, this.state.selectedUsers, this.state.selectedManagerUsers))).then(response => {
					if (toast.isActive(this.toastID)) {
						if (this.state.uploadError) {
							toast.update(this.toastID, {
								render:
									"Finished attempting to upload all documents. See below for errors", type: toast.TYPE.WARNING, autoClose: 5000
							});
						} else {
							toast.update(this.toastID, {
								render:
									"Finished attempting to upload all documents.", type: toast.TYPE.SUCCESS, autoClose: 5000
							});
						}
					}
					else {
						if (this.state.uploadError) {
							toast.warning(
								"Finished attempting to upload all documents. See below for errors");
						} else {
							toast.success(
								"Finished attempting to upload all documents.");
						}
					}

					this.setState({
						loading: false, files: null, user_defined_id: '', category: '', notes: '', tags: [],
						selectedUsers: [], requiresSignatures: false, progress: 0,
						totalFiles: 0, manager: false, urgent: false, uploadError: false
					}, this.getDefaultDate());
					this.clearTagsSearch();
				}
				)
	}
	async onGetFolderPresignedURL(file, metadata, tags, selectedUsers, selectedManagerUsers) {
		if (file.size === 0) {
			return toast.error(`Sorry, we can't upload your files right now. Please email help@ivydocuments.com.`)

		}
		return await Request.authRequest(Routes.s3.presignedURL, 'GET').then(async (response) => {
			return await response.json().then(async (body) => {
				return Request.awsRequest(body.signed_url, 'PUT', file).then(async (response) => {
					if (response.ok) {
						return await this.uploadFolderFile(body.document_id, file, metadata, tags, selectedUsers, selectedManagerUsers);
					}
					else {

						toast.error(`Sorry, we can't upload your files right now. Please email help@ivydocuments.com.`)
					}
				})

			})
		})

	}
	async onGetPresignedURL(file, metadata, tags, selectedUsers, selectedManagerUsers) {
		if (file.size === 0) {
			return toast.error(`Sorry, we can't upload your files right now. Please email help@ivydocuments.com.`)

		}
		return await Request.authRequest(Routes.s3.presignedURL, 'GET').then(async (response) => {
			return await response.json().then(async (body) => {
				return Request.awsRequest(body.signed_url, 'PUT', file).then(async (response) => {
					if (response.ok) {
						return await this.uploadFile(body.document_id, file, metadata, tags, selectedUsers, selectedManagerUsers);
					}
					else {
						toast.error(`Sorry, we can't upload your files right now. Please email help@ivydocuments.com.`)
					}
				})

			})
		})
	}
	async uploadFile(fileID, file, metadata, tags, selectedUsers, selectedManagerUsers) {
		metadata.name = file.name;
		metadata.document_id = fileID;

		return await Request.authRequest(Routes.documents.create,
			'POST', JSON.stringify(metadata), 'application/json').then((response) => {
				if (response.status === 200) {
					response.json().then(body => {
						if (selectedUsers.length > 0 || selectedManagerUsers.length > 0) {
							return this.requestSignatures(body, selectedUsers, selectedManagerUsers);
						} else {
							this.setState({ progress: this.state.progress + 1 })
							if (this.state.progress < this.state.totalFiles - 1) {
								toast.update(this.toastID, {
									render: `Uploaded ${this.state.progress}
											out of ${this.state.totalFiles} files`
								})
							}
						}

					});
				} else {
					response.json().then((error) => {
						toast.error(error.message);
						this.setState({ uploadError: true })
						if (error.errors) {

							toast.error(error.errors[0])
							toast.error(`Your ${file.name} file could not be uploaded right now.`)
						}
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}
	clearFiles = () => {
		this.setState({ files: null, googleFolder: '', folderFiles: '' })
	}
	async uploadFolderFile(fileID, file, metadata, tags, selectedUsers, selectedManagerUsers) {
		metadata.name = file.name;
		let pathTags = file.webkitRelativePath.split('/');

		pathTags.pop();
		const fileMetadata = JSON.parse(JSON.stringify(metadata));
		fileMetadata.document_id = fileID;
		fileMetadata.tag_names = [...fileMetadata.tag_names, ...pathTags];
		let trimmedPathTags = TagCleaner.parseTagArray(fileMetadata.tag_names);
		fileMetadata.tag_names = trimmedPathTags
		return await Request.authRequest(Routes.documents.create,
			'POST', JSON.stringify(fileMetadata), 'application/json').then((response) => {
				if (response.status === 200) {
					response.json().then(body => {
						if (selectedUsers.length > 0 || selectedManagerUsers.length > 0) {
							return this.requestSignatures(body, selectedUsers, selectedManagerUsers);
						} else {
							this.setState({ progress: this.state.progress + 1 });
							if (this.state.progress < this.state.totalFiles - 1) {
								toast.update(this.toastID, {
									render: `Uploaded ${this.state.progress}
											out of ${this.state.totalFiles} files`
								})
							}
						}
					})
				} else {
					response.json().then((error) => {

						toast.error(error.message);
						this.setState({ uploadError: true })
						if (error.errors) {
							toast.error(error.errors[0])
							toast.error('Your file could not be uploaded right now.')
						}
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}
	onEscape = () => {
		return
	}
	toggleUrgent = () => {
		this.setState({ urgent: !this.state.urgent })
	}
	onSetSignatures = (users) => {
		this.setState({ selectedUsers: users })
	}
	onSetManagerSignatures = (users) => {
		this.setState({ selectedManagerUsers: users })
	}
	onRemoveUser = (user) => {
		this.setState({ selectedUsers: this.state.selectedUsers.filter(users => users !== user) })
	}
	onRemoveManagerUser = (user) => {
		this.setState({ selectedManagerUsers: this.state.selectedManagerUsers.filter(users => users !== user) })
	}
	render() {
		let fileNames;
		if (this.state.files) {
			fileNames = this.state.files.map((file, i) => {
				if (file.size === 0) {
					return <div key={i} className="error-file-upload">ERROR - {file.name} - Failed to select. Please try again. </div>;
				}
				return <div key={i}>{file.name} - {file.size / 1000} KB </div>;
			})
		}
		const fileUploadText = this.state.files ?
			fileNames :
			'Drag and drop one or multiple documents, or click to select from your computer.';
		let multipleSelect;
		if (this.state.files && this.state.files.length > 1) {
			multipleSelect = true;
		}
		/*<div className='folder-dropzone-container'>
			<GoogleDrive setGDrive = {this.setGDrive} googleFolder = {this.state.googleFolder}/>
		</div>*/
		let noSignatureMessage = "None selected";
		let signatureList;
		if (this.state.selectedUsers.length > 0) {
			signatureList = this.state.selectedUsers.map((user, i) => {
				return <SelectedUserComponent user={user} onRemoveUser={this.onRemoveUser} key={i} />
			})
		}
		let managerSignatureList = "";
		if (this.state.selectedManagerUsers.length > 0) {
			managerSignatureList = this.state.selectedManagerUsers.map((user, i) => {
				return <SelectedUserComponent user={user} onRemoveUser={this.onRemoveManagerUser} key={i} manager />
			})
		}
		if (signatureList || managerSignatureList) {
			noSignatureMessage = "";
		}
		const searchString = qs.parse(this.props.location.search.substr(1));
		let tutorial;
		if (searchString.tutorial) {
			tutorial = <Tutorial1UploadButton />
		}
		let folderFilesSelected;
		return (
			<div className='documents-container upload-container boxShadow'>
				<div className="document-upload-header"> Upload New Documents
				</div>
				<div className="document-upload-contents">
					<div className="document-required-info">
						<div className="requiredInfoText">Required Information
							<div className="helpText">Select a file, or select a folder.
							</div>
							<div className="uploadClearFiles boxShadow" onClick={this.clearFiles}> Clear selected files</div>
							{tutorial}
						</div>
						<div className='file-dropzone-container'>
							{this.state.error}
							<Dropzone className='file-dropzone' multiple={true} onDrop={this.onDropFile}>
								<span className='file-dropzone-text'> {fileUploadText} </span>
							</Dropzone>
						</div>

						<div className='folder-dropzone-container'>
							<div>Select a Folder</div>
							{this.state.error}
							<input id="folder-picker" name="fileList" type="file" webkitdirectory="true"
								onChange={this.onDropFolder} />
						</div>

					</div>
					<div className="upload-optional-info">
						<div className="upload-optional-info-header">Optional Information
						</div>
						<div>
							<div className="upload-optionalInfo-top">
								<div className='user-def-id'>
									<label className='input-label' htmlFor='user_defined_id'> User Defined ID </label>
									<div className='input-container'>
										<input type='text' value={this.state.user_defined_id} onChange={this.onChangeID} id='user_defined_id' className='input-text' />
									</div>
								</div>
								<div className='upload-date'>
									{this.state.expiring ?
										<div className="datePickerContainer">
											<div className="upload-date">
												<label className='input-label noselect' >Expires On </label>
												<SingleDatePicker
													date={moment(this.state.date_expiration)}
													onDateChange={this.onChangeDocumentDate}
													focused={this.state.focused}
													onFocusChange={({ focused }) => this.setState({ focused })}
													numberOfMonths={1}
													id="documentDateField"
												/>
											</div>
										</div>
										: null}
									<div className="upload-expiresBoolean">
										<input
											checked={!this.state.expiring}
											name='expiresBoolean'
											type='checkbox'
											id='expiresBoolean'

											onChange={this.onChangeExpiring} />
										<label htmlFor='expiresBoolean'> This document doesn't expire</label>
									</div>
								</div>
							</div>
							<div className='upload-notes'>
								<label className='upload-label' htmlFor='upload-notes'> Notes </label>
								<textarea type='text' value={this.state.notes}
									onChange={this.onChangeNotes} id='upload-notes' className='input-text' />
							</div>
						</div>
						<div className='upload-tags'>
							<label className='input-label' id={this.state.files ? "documentsUploadActive" : null}
								htmlFor='upload-notes'> Tags </label>
							<TagsSearch onSendTags={this.onChangeTags}
								onEsc={this.onEscape} tags={this.state.tags} ref={this.TagsSearch} />
							{this.state.files ? <div className="helpText" id="documentsUploadActive">If you are selecting files, at least one tag is required. </div> : null}
							<div className="upload-signatureContainer">
								<input
									checked={this.state.requiresSignatures}
									name='signatureBoolean'
									type='checkbox'
									id='signatureBoolean'
									onChange={this.onChangeRequiresSignatures} />
								<label htmlFor='signatureBoolean'> This document requires approval signatures</label>
								{this.state.requiresSignatures ?
									<div className="documentUpload-signatureSelection">
										<div className="remindConfirmationCheckbox">
											<input
												checked={this.state.urgent}
												name='expiresBoolean'
												type='checkbox'
												id='expiresBoolean'
												onChange={this.toggleUrgent} />
											<div>Mark as Urgent
											</div>
										</div>
										<SetSignatures onSendSignatures={this.onSetSignatures} onSendManagerSignatures={this.onSetManagerSignatures} />
										<div className="selectedSignatureList">
											<div>Selected Signatures
											</div>
											{noSignatureMessage}
											{signatureList}
											{managerSignatureList}
										</div>
									</div>
									: null}

							</div>
						</div>

					</div>
					{multipleSelect ? <div className="helpText">You've selected multiple files. The same information,
						including requested signatures and ids, will be applied to each
						file uploaded. You can always change the information later.</div> : null}
					<div>

						<div className="form-button primary" id="onUpload">
							<LoadingButton onClick={this.onSelectUpload} text='Upload' isLoading={this.state.loading} />
						</div>

						<button className="form-button secondary noselect uploadReturnToNav" id="onUpload"
							onClick={this.onReturnNavigation}>Return to Navigation</button>
					</div>
				</div>
			</div>
		);
	}
}

export default DocumentsUpload;
