import React from 'react';
import './Buttons.css';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import {Send} from '../Icons/IconLibrary';
import SendToSelectedDocuments from './SendToSelectedDocuments';
import ContactsSearch from '../ContactsSearch/ContactsSearch';
import './SendDocumentToButton.css';
import LoadingButton from './LoadingButton';
class SendDocumentToButton extends React.Component{
  constructor(){
    super();
    this.onSendEmails = this.onSendEmails.bind(this);
    this.timerID = null;
    this.state={
      recipients :'',
      showModal:false,
      message:'',
      urgent:false,
      subject:'',
      only_as_pdf:false,
      loading:false,
    }
  }
  toggleUrgent = () =>{
    this.setState({urgent:!this.state.urgent})
  }
  toggleOnlyAsPdf = () =>{
    this.setState({only_as_pdf:!this.state.only_as_pdf})
  }
  getDocuments = () =>{

  }
  onShowModal =()=>{
    if (this.props.docs.length === 0) {
      toast.error('Please select one or more documents');
      return;
    }
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false, only_as_pdf:false
  })}
  onSendEmails(emails){
    this.setState({recipients:emails})
  }
  onEnterRecipient = (e) => {
		this.setState({
		 recipients: e.target.value,
		});
	}
  onChangeDocumentInfo = (e) => {
		this.setState({
			message: e.target.value,
		});
	}
  onChangeSubject = (e) => {
		this.setState({
			subject: e.target.value,
		});
	}
  onSendDocument=()=>{

    let toastID = toast.info('Sending documents...',{autoClose:false});
    if (this.state.recipients === '') {
      toast.update(toastID, {render: `Please enter a recipient`, type: toast.TYPE.ERROR, autoClose: 5000 });
      return;
    }
    let recipients =this.state.recipients.concat();
    let documentIDs = this.props.docs.map((doc,i)=>{return doc.document.id});

    this.setState({loading:true});
      Request.authRequest(Routes.documents.share,
                          'POST',JSON.stringify({
                            document_ids:documentIDs,
                            email: recipients,
                            message: this.state.message,
                            urgent: this.state.urgent,
                            subject: this.state.subject,
                            only_as_pdf: this.state.only_as_pdf,
                          }), 'application/json'
                        ).then(response =>{
                          if (response.status ===200){
              toast.update(toastID, { render: `Successfully sent documents to all
                recipients.`,type: toast.TYPE.SUCCESS, autoClose: 5000 });
                this.setState({loading:false, urgent:false,message:'',recipients:'',subject:'',only_as_pdf:false, showModal:false})
              if (this.props.viewer){
                this.props.getDocument();
              }

                } else {
                  response.json().then((error) => {
                    this.setState({loading:false})
                      toast.update(toastID, {render: `Could not send some of your documents. Please try again.`
                        ,type: toast.TYPE.ERROR, autoClose: 5000 });
                      toast.error(error.message);
                      if (error.errors){
                        toast.error(error.errors[0])
                      }
                    });
                  }


            }).catch((error) => {
              toast.update(toastID, {render: `Could not send some of your documents. Please try again.`
                ,type: toast.TYPE.ERROR, autoClose: 5000 });
            }
        )
     }

  render(){
    let selectedDocuments;
    if (this.props.docs.length>0){
      selectedDocuments = this.props.docs.map((doc,i)=>{
        return <SendToSelectedDocuments key = {i} doc = {doc} sendableStatus = {this.sendAsPDFStatus}/>
      })
    }
    if (this.props.documents){
      if (this.props.docs.length>0){
      return (
        <div >
          <button className='headerButton noselect' id = "buttonSendDocumentToIcon" onClick = {this.onShowModal}
            alt='Send currently selected documents to someone' title= 'Send currently selected documents to someone' >
            <img id = "buttonSendDocumentToIcon" src = {Send} alt='Send currently selected documents to someone' />
          </button>
          <Modal show={this.state.showModal} onClose={this.onHideModal} title='Send Documents' >
            <div className = "sendDocumentsTo-modalContainer">
            <div className = "sendDocumentTo-composerContainer">

              <div className = "helpText-CreateTagButton">Enter the emails of the recipients to send
                {this.props.docs.length ===1?" this document":" these documents"} to.
              </div>

              <ContactsSearch onSendEmails= {this.onSendEmails}/>
              <div>
              <div className = "sendDocuments-subjectLine">
                <div>Optional Subject Line</div>
                <div className = "sendDocuments-urgentCheckbox">
                <input
                  checked = {this.state.urgent}
                  name = 'expiresBoolean'
                  type='checkbox'
                  id = 'expiresBoolean'
                  onChange = {this.toggleUrgent}
                  title="Adds Urgent to the subject line."/>
                <div>Mark as Urgent
                </div>
                </div>
              </div>
                <textarea
                  value={this.state.subject}
                  type='text'
                  className='send-message-field'
                  onChange={this.onChangeSubject}
                  placeholder = "Optional Subject"/>
              </div>
                <div>Optional Message</div>
              <textarea
                value={this.state.string}
                type='text'
                className='send-message-field'
                id = "send-message-message"
                onChange={this.onChangeDocumentInfo}
                placeholder = "Optional Message"/>
              </div>
              <div className = "sendDocumentTo-DocumentOptions">
                <div>Selected Documents</div>
                {selectedDocuments}
              <div className = "sendDocuments-urgentCheckbox">
                <input
                  checked = {this.state.only_as_pdf}
                  name = 'expiresBoolean'
                  type='checkbox'
                  id = 'expiresBoolean'
                  onChange = {this.toggleOnlyAsPdf}/>
                <div>Send Only As PDF
                </div>
              </div>
              </div>
                </div>
              <button className= 'modal-button primary'>
              <LoadingButton onClick={this.onSendDocument}
                  text = 'Send' isLoading = {this.state.loading}/>

              </button>

          </Modal>
        </div>
        )
      }
    return(

        <button className='headerButton noselect' id = "buttonSendDocumentToIcon" onClick = {this.onShowModal}>
            <img id = "buttonSendDocumentToIcon" src = {Send} alt='Send currently selected documents to someone' title= 'Send currently selected documents to someone' />

        </button>

    )
    }
    if (this.props.docs.length>0){

    return (
      <div >
        <button className='headerButton noselect' id = "buttonSendDocumentTo" onClick = {this.onShowModal}
          alt='Send currently selected documents to someone' title= 'Send currently selected documents to someone' >
          Send
        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Send Documents' >

          <div className = "sendDocumentsTo-modalContainer">
          <div className = "sendDocumentTo-composerContainer">

            <div className = "helpText-CreateTagButton">Enter the emails of the recipients to send
              {this.props.docs.length ===1?" this document":" these documents"} to.
            </div>

            <ContactsSearch onSendEmails= {this.onSendEmails}/>
            <div>
            <div className = "sendDocuments-subjectLine">
              <div>Optional Subject Line</div>
              <div className = "sendDocuments-urgentCheckbox">
              <input
                checked = {this.state.urgent}
                name = 'expiresBoolean'
                type='checkbox'
                id = 'expiresBoolean'
                onChange = {this.toggleUrgent}
                title="Adds Urgent to the subject line."/>
              <div>Mark as Urgent
              </div>
              </div>
            </div>
              <textarea
                value={this.state.subject}
                type='text'
                className='send-message-field'
                onChange={this.onChangeSubject}
                placeholder = "Optional Subject"/>
            </div>
              <div>Optional Message</div>
            <textarea
              value={this.state.string}
              type='text'
              className='send-message-field'
              id = "send-message-message"
              onChange={this.onChangeDocumentInfo}
              placeholder = "Optional Message"/>
            </div>
            <div className = "sendDocumentTo-DocumentOptions">
              <div>Selected Documents</div>
              {selectedDocuments}
            <div className = "sendDocuments-urgentCheckbox">
              <input
                checked = {this.state.only_as_pdf}
                name = 'expiresBoolean'
                type='checkbox'
                id = 'expiresBoolean'
                onChange = {this.toggleOnlyAsPdf}/>
              <div>Send Only As PDF
              </div>
            </div>
            </div>
              </div>
              <button className= 'modal-button primary'>
              <LoadingButton onClick={this.onSendDocument}
                  text = 'Send' isLoading = {this.state.loading}/>
              </button>

        </Modal>
      </div>
      )
    }
  return(
    <div id = "buttonSendDocumentTo">
      <button className='headerButton noselect' id = "buttonSendDocumentTo" onClick = {this.onShowModal}>
          <div alt='Send currently selected documents to someone' title= 'Send currently selected documents to someone' >
            Send </div>
      </button>

    </div>
  )
}
}
export default SendDocumentToButton;
