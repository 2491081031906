import React from 'react';
import { Lock, LockOpen } from '../../Icons/IconLibrary';
import Modal from '../../Modal/Modal';
import LoadingButton from '../../Buttons/LoadingButton';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { signature } from 'stripe/lib/Webhooks';
class RecipeForkButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
    }
  }
  onShowModal = () => {
    this.setState({ showModal: true })
  }
  onHideModal = () => {
    this.setState({
      showModal: false
    })
  }
  lockRecipe = () => {
    if (this.props.recipe.total_weight_ratio != 100) {
      return toast.error('This recipe does not add up to 100%. Incomplete recipes can not generate batch sheets.')
    }
    if (this.props.recipe.requires_signatures) {
      let signatures = this.props.doc.signatures;
      let signaturesStatuses = signatures.map(signature => { return signature.status })

      if (signaturesStatuses.includes('Pending') || signaturesStatuses.includes('Rejected')) {
        return toast.error('This recipe has not collected all approval signatures and cannot be locked.')
      }
      if (signatures == 0) {
        return toast.error('This recipe requires signatures and no signatures have been collected.')
      }
    }
    this.setState({ loading: true });
    let recipe = {
      immutable: true,
      units_per_case: this.props.recipe.units_per_case, weight_per_unit: this.props.recipe.weight_per_unit
    }

    Request.authRequest(Routes.recipes.edit(this.props.recipe.id),
      'PUT', JSON.stringify(
        recipe
      ), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.props.onGetRecipe();
          this.props.onGetLogs();
        });
      } else {
        response.json().then((error) => {
          console.log(error);
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )

  }
  render() {

    let recipe = this.props.recipe;
    if (recipe && recipe.immutable) {
      return (<div >
        <div onClick={this.onShowModal} className="recipeLock">
          <img src={Lock} className="signatureButton-text"
            alt="This recipe has been locked" title="This recipe has been locked" />
        </div>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Locked Recipe' >
          <div>This recipe has been locked. Only changes to metadata (name, tags, id, notes) can be made. Duplicate or version this recipe to make changes.</div>


        </Modal>
      </div>)
    }
    return (
      <div>
        <div onClick={this.onShowModal}>
          <img src={LockOpen} className="signatureButton-text"
            alt="Lock this recipe" title="Lock This Recipe" />
        </div>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Lock Recipe' >
          <div>Lock this recipe from any further changes.</div>

          <div className='modal-button primary'>
            <LoadingButton onClick={this.lockRecipe}
              text='Lock Recipe' isLoading={this.state.loading} />
          </div>
        </Modal>

      </div>
    )
  }
}

export default RecipeForkButton;