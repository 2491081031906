import React from 'react';
import IndividualUserTagsAccess from './IndividualUserTagsAccess';
class UserTagsAccess extends React.Component{

    render(){
      let userList = this.props.users.map((user,i)=>{
        return <IndividualUserTagsAccess user = {user}
        key = {i} sendSelectedUser = {this.props.sendSelectedUser}
        onLastSelected={this.props.onLastSelected}
          onChangeTags = {this.props.onChangeTags}
           activeTags = {this.props.activeTags}/>
      })
      return(
        <div className = "">

          {userList}
        </div>


      )
    }
  }
export default UserTagsAccess;
