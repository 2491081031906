import React from 'react';
import InfoField from '../Documents/InfoField';
import DocumentDateField from '../Documents/DocumentDateField';
import DocumentNotesField from '../Documents/DocumentNotesField';
import SolidInfoField from './IngredientViewer/SolidInfoField';
import DensityInfoField from './IngredientViewer/DensityInfoField';
import TagsField from '../Documents/TagsField';
import Request from '../../helpers/Request';
import Routes from '../../helpers/Routes';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TagCleaner from '../../helpers/TagCleaner';
import IngredientAttributeInfoField from './IngredientViewer/IngredientAttributeInfoField';
class IngredientPreview extends React.Component {
  constructor(props) {
    super(props);
    this.onSaveChanges = this.onSaveChanges.bind(this);
    this.onSaveTagsChanges = this.onSaveTagsChanges.bind(this);
    this.state = {
      error: '',
      locked: true,
      preview: false,
      doc: props.doc
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.doc !== this.props.doc) {

      this.setState({ doc: this.props.doc })

    }

  }
  onSaveChanges(contentType, content) {
    let ingredient = this.props.doc;
    let input_format = {
      name: ingredient.name,
      density: ingredient.f_density_kg_L,
      price: ingredient.f_price_kg,
      properties: ingredient.properties,
      notes: ingredient.notes,
      user_defined_id: ingredient.user_defined_id
    }
    switch (contentType) {
      case 'ID':
        input_format.user_defined_id = content;
        break;
      case 'Name':
        input_format.name = content;
        break;
      case 'Price':
        input_format.price = content;
        break;
      case 'Density':
        input_format.density = content;
        break;
      case 'Notes':
        input_format.notes = content;
        break;
      default:

    }

    Request.authRequest(Routes.ingredients.edit(this.props.doc.id),
      'PUT', JSON.stringify(
        input_format
      ), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.props.onGetDocuments();
          toast.success('Successfully edited the ingredient')
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
          console.log(error);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }
  onSaveTagsChanges = (tagsArray) => {
    /* this string, unlike other tags operations is the whole tags list */
    let doc = this.state.doc;
    if (tagsArray.length === 0) {
      toast.error('Please enter tags. All ingredients must have at least one tag.')
      return
    }
    let trimmedTags = TagCleaner.parseTagArray(tagsArray);
    Request.authRequest(Routes.ingredients.tags(this.props.doc.id),
      'PUT', JSON.stringify({
        tag_names: trimmedTags,
      }), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.props.onGetDocuments();
          this.props.onGetTags();
          toast.success('Successfully applied tags!')
          this.setState({ doc: body })
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }
  changeProperty = (originalProperty, key, value) => {
    let ingredient = this.props.doc;
    let input_format = {
      name: ingredient.name,
      density: ingredient.f_density_kg_L,
      price: ingredient.f_price_kg,
      properties: ingredient.properties,
      notes: ingredient.notes,
      user_defined_id: ingredient.user_defined_id
    }
    if (originalProperty[0] != key) {
      delete input_format.properties[originalProperty[0]]
    }
    input_format.properties[key] = value
    Request.authRequest(Routes.ingredients.edit(this.props.doc.id),
      'PUT', JSON.stringify(
        input_format
      ), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.props.onGetDocuments();
          toast.success('Successfully edited the ingredient')
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
          console.log(error);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }
  navRecipe = () => {
    window.location.href = '/recipes/' + this.state.doc.produced_from_recipe_id
  }
  render() {

    const doc = this.state.doc;
    let linkURL = `/documents/`;
    let date_created;
    let versionNumber;
    let status;
    let statusWords;
    let ingredient;
    let properties;
    let producedFromRecipe;
    if (doc) {
      ingredient = doc;
      properties = Object.entries(ingredient.properties);
      properties = properties.map((property, i) => {
        return <IngredientAttributeInfoField property={property}
          addPropertyToDelete={this.addPropertyToDelete} removePropertyToDelete={this.removePropertyToDelete}
          key={i} changeProperty={this.changeProperty} removeProperties={this.state.removeProperties} />
      })
      if (doc.version_history_with_self.length > 1) {
        versionNumber = 'Version ' + doc.version_number
        if (!doc.version_number && doc.version_history_with_self.length > 1) {
          versionNumber = 'Version 0'
        }
        if (doc.version_number == doc.version_history_with_self.length - 1) {
          versionNumber = versionNumber + " - current"
        }
      }
      if (doc.produced_from_recipe_name) {
        producedFromRecipe = <div className="helpText pointer" onClick={this.navRecipe}>
          Produced from recipe {doc.produced_from_recipe_name}</div>
      }
      linkURL = `/ingredients/` + doc.id
      date_created = new Date(doc.date_created);
      statusWords = doc.status;
      if (doc.tags && doc.tags.length > 0) {
        let tag_names = doc.tags.map((entity, i) => {
          return entity.name;
        })
        if (tag_names.indexOf("Draft") > -1) {
          statusWords = "DRAFT";
        }
        if (tag_names.indexOf("Archive") > -1) {
          statusWords = "Archive";
        }
      }

    }


    if (doc === [] || !doc) {
      return (
        <div className='documents-preview boxShadow'>
          <div className='documents-preview-header'>
            <div className='documents-preview-name'>

            </div>
            <div className="buttons-docPreview noselect">
              <span onClick={this.props.onCloseDocPreview}
                className='button-closeDocPreview'>
                X
              </span>

            </div>
          </div>
          <div className="helpText">Select an ingredient to see information</div>
        </div>
      )
    }

    return (
      <div className='documents-preview boxShadow'>
        <div className='documents-preview-header'>

          <div className="buttons-docPreview noselect">
            <span onClick={this.props.onCloseDocPreview}
              className='button-closeDocPreview'>
              X
            </span>
          </div>
        </div>
        {doc !== null ?
          <div className='documents-preview-body'>
            <InfoField content={doc.user_defined_id} contentType='ID'
              doc={doc} onClickOut={this.onSaveChanges} />
            <InfoField content={doc.name} contentType='Name' doc={doc}
              onClickOut={this.onSaveChanges} />
            <DensityInfoField contentType='Density'
              className="document-viewer-fields-content"
              ingredient={ingredient}
              onClickOut={this.onSaveChanges} />
            <SolidInfoField density={ingredient.density}
              className="document-viewer-fields-content"
              contentType="Physical State"
              ingredient={ingredient}
              onClickOut={this.onSaveChanges} />
            <DocumentNotesField className="document-preview-notes" content={doc.notes} contentType='Notes'
              doc={doc} onClickOut={this.onSaveChanges} />

            <div className="docPreview-tagscontainer">
              <TagsField content={doc.tags} contentType='Tags' doc={doc}
                onClickOut={this.onSaveTagsChanges} />

            </div>
            {properties}
            <div className="docPreview-date-created helpText">Created {date_created.toDateString()}
              <div>{versionNumber}</div>
            </div>
            <div>{producedFromRecipe}</div>
            <Link to={linkURL} className="button-documentView-footer ">
              See all information
            </Link>

          </div>
          :
          <div className="helpText">Select a document to see information</div>}
      </div>)



  }
}
export default IngredientPreview;
