import React from 'react';
import { withRouter } from 'react-router-dom';
import './DocumentRow.css';
class ShortDocumentRow extends React.Component {
	constructor(){
		super();
		this.state={
			show:false
		}
		}

		onNavigate = ()=>{
			  this.props.history.push(`/documents/${this.props.document.document_id}`);
		}
	render() {
		let doc = this.props.document;

		let docName;
		let date_created;
		if (doc){
			docName = doc.name;
			let date = new Date(doc.date_created);
			date_created = date.toLocaleDateString()

		}
		let versionNumber = doc.version_number
		if (!doc.version_number){
			versionNumber = '0'
		}
		let user_defined_id;
		if (doc.user_defined_id){
			user_defined_id='- ' + doc.user_defined_id;
		}
		return (
			<div className = 'document-row-short'
				id={this.state.isActive?'document-row-active':null} value = {doc} onClick = {this.onNavigate}>

					<div className = 'document-short-row-left'>
						<span className='short-document-row-name'> {docName} </span>

						<span className='short-document-row-name'> Version {versionNumber} {user_defined_id}</span>

					</div>
					<div className = 'short-document-row-right'>
						<span className = 'short-document-expiration-label'> Created</span>
						<span className='short-document-row-category'>  {date_created} </span>

					</div>

			</div>
		);
	}
}

export default withRouter(ShortDocumentRow);
