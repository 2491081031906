import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import ContactsSearch from '../ContactsSearch/ContactsSearch';
class InviteUser extends React.Component{
  constructor(){
    super();
    this.onSendEmails = this.onSendEmails.bind(this);
    this.state={
      recipients :'',
      showModal:false,
    }
  }
  onShowModal =()=>{
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false
  })}
  onSendEmails(emails){
    this.setState({recipients:emails})
  }
  onEnterRecipient = (e) => {
		this.setState({
		 recipients: e.target.value,
		});
	}

  onSendInvite=()=>{
    this.setState({showModal:false})
    toast.info('Sending invitation...')
    if (this.state.recipients === '') {
      toast.error('Please enter a recipient');
      return;
    }
    let recipients =this.state.recipients;

      for (let i = 0; i < recipients.length; i++){
        Request.authRequest(Routes.organization.invite,
    											  'POST',JSON.stringify({
                        			email: recipients[i],
                        		}), 'application/json').then((response) => {
                              if (response.status===200){
          			response.json().then(body => {
          				toast.success(`Successfully sent invitation to ${recipients[i]}`);

                      });
                    } else {
                      response.json().then((error) => {
                        toast.error(error.message);
                        toast.error(`Could not send an invitation to ${recipients[i]}`);
                      });
                    }
                }).catch((error) => {
                toast.error(error.message);
                }
            )
          }


  }

  render(){

    return (
      <div >
        <button className='inviteUserButton noselect' onClick = {this.onShowModal}
          alt='Invite users to join your organization' title= 'Invite users to join your organization' >
          Invite Users To Your Organization
        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Invite Users' >

          <div>
            <div className = "helpText-CreateTagButton">Enter the emails of the recipients to send
              invitations to join your organization.
            </div>

            <ContactsSearch onSendEmails= {this.onSendEmails}/>


              <button className= 'modal-button primary' onClick={this.onSendInvite}> Send </button>
            </div>
        </Modal>
      </div>
      )
    }


}
export default InviteUser;
