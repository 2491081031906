import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { Link } from 'react-router-dom';
import LoadingButton from '../Buttons/LoadingButton';
class Signup extends React.Component {
	constructor() {
		super();
		this.state = {
			email: '',
			loading: false
		};
	}



	onChangeEmail = (e) => {
		this.setState({
			email: e.target.value,
			error: '',
		});
	}
	onAttemptSignup = () => {
		this.setState({loading:true});
			Request.authRequest(Routes.auth.signup, 'POST', JSON.stringify({
				email: this.state.email,
			}), 'application/json').then((response) => {
				
				if (response.status === 200) {
					response.json().then((body) => {
						this.setState({loading:false});
						window.location.href = '/resend-confirm';
					});
				} else if
					(response.status === 409){
						this.setState({ loading:false, error: 'User with this email already exists.' });
				}
				else {
					response.json().then((error) => {
						
						this.setState({ loading:false, error: error.message, loading:false });
					});
				}
			}).catch((error) => {
				this.setState({ error: error.message , loading:false});
			});
		}
	handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAttemptSignup();
    }
  }
	render() {
		return (
			<div className='authform-body boxShadow'>
				<div className='signup-header'> Stop Pushing Paper.</div>
				<div className = "signup-emailText">Ivy Documents simplifies your organization using tags instead of folders.<br/>Get started with your email.</div>
				<div className='label-group'>
					<label className='input-label' htmlFor='email'> Email </label>
					<span className='signup-hint'> This will be your login email </span>
				</div>
				<input type='text' onChange={this.onChangeEmail} id='email' className='input-text' onKeyPress = {this.handleEnter}/>
				{this.state.error}
				
				<button className='form-button primary is-loading'>
							<LoadingButton onClick = {this.onAttemptSignup} 
				text = 'Sign Up' isLoading = {this.state.loading}/>

				</button>
				<div className = "helpText">By signing up you agree to our <Link to = '/tos'>Terms of Service</Link> and <Link to = '/privacypolicy'>Privacy Policy</Link></div>
				<div className='register-text'> Have an account already? </div>
				<Link to='/login'>
					<button className='form-button secondary'> Log In </button>
				</Link>
			</div>
		)
	}
}

export default Signup;
