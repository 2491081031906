import React from 'react';
import moment from 'moment';
import './TimeDiff.css';
class SignatureTimeDiff extends React.Component{

  // Takes in permissions and returns their average time to acceptance and time to signature
  render(){
    if (!this.props.signatures){
      return null
    }
    let signatures = this.props.signatures;
    let signatureCounter=0;
    let signatureTime = 0;
      for (let j = 0; j< signatures.length; j++){
        let individualSignatureTime = moment(signatures[j].date_modified).diff(
            moment(signatures[j].date_created), 'days');
        if (!isNaN(individualSignatureTime)){
          signatureTime +=individualSignatureTime;
          signatureCounter+=1;
        }
      }
  let averageSignatureTime = Math.round(signatureTime/signatureCounter)

  if (signatureTime === 0){
    averageSignatureTime = 0;
  }
    return(
      <div className = "smallTimeDiff-container">
            <div> Average Time to Signature: {averageSignatureTime} days</div>
      </div>
    )
  }
}

export default SignatureTimeDiff;
