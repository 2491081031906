import React from 'react';
import './TagAccess.css';
import { DropTarget } from 'react-dnd';
import SelectedUserTagAccess from './SelectedUserTagAccess';
const receiverTarget = {
  drop(props,monitor,component) {
    component.onDropCurrentUser(monitor.getItem());
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),

  };
}
class SelectedUserContainer extends React.Component{

  onDropCurrentUser= (user)=>{
    this.props.sendSelectedUser(user.user);
  }
  render(){
    let selectedUsers = <div className = "smallHelpText">Drag and drop from below or click to select</div>

    if (this.props.selectedUsers.length>0){
        selectedUsers = this.props.selectedUsers.map((user,i)=>{
        return <SelectedUserTagAccess user = {user} key = {i} removeUser = {this.props.removeUser}
          onChangeTagAccess={this.props.onChangeTagAccess}/>
      })
    }

    const {connectDropTarget} = this.props;
    return connectDropTarget(
      <div className = "tagAccess-userContainer">
        {selectedUsers}
      </div>
    )
  }
}
export default DropTarget('UserTagAccess', receiverTarget, collect)(SelectedUserContainer);
