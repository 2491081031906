import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import introJs from 'intro.js';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import HomeSteps from './Help/HomeSteps.json';
import LogoWhite from './img/logo-leaf-white.svg';
import DocumentsSteps from './Help/DocumentsSteps.json';
import TemplatesSteps from './Help/TemplatesSteps.json';
import RequestsSteps from './Help/RequestsSteps.json';
import TagsDictSteps from './Help/TagsDictSteps.json';

import LogsSteps from './Help/LogsSteps.json';
import NoHelpSteps from './Help/NoHelpSteps.json';
import SettingsSteps from './Help/SettingsSteps.json';
import DocumentsViewerSteps from './Help/DocumentsViewerSteps.json';
import TemplatesViewerSteps from './Help/TemplatesViewerSteps.json';
import ContactsSteps from './Help/ContactsSteps.json';
import PendingDocuments from './Help/PendingDocuments.json';
import SendReceiveHeader from './SendReceiveHeader';
import Cookies from '../../helpers/Cookies';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.onHandleClick = this.onHandleClick.bind(this)
		this.state = {
			unreadNotifications: 0,
		}
	}

	onHandleClick() {
		const { location } = this.props;
		let steps;
		switch (location.pathname) {
			case '/home':
				steps = HomeSteps;
				break;
			case '/recipes/':
				steps = DocumentsSteps;
				break;

			case '/tags':

				steps = TagsDictSteps;

				break
			case '/logs':
				steps = LogsSteps;
				break;
			case '/settings':
				steps = SettingsSteps;
				break;
			default:
		}
		if (!steps) {
			let path = location.pathname.split('/');
			if (path[1] === "documents") {
				steps = DocumentsViewerSteps;
			}
			else if (path[2] === 'templates-viewer') {
				steps = TemplatesViewerSteps;
			}
			if (!steps) {
				steps = NoHelpSteps;
			}
		}
		introJs().setOptions({ steps: steps, showStepNumbers: false }).start()
	}

	componentDidMount() {
		switch (this.props.location.pathname) {
			case '/':
			case '/login':
			case '/signup':
			case '/organization/join':
			case '/organization/create':
			case '/passwordreset':
			case `/features`:
			case '/production/reset-password':
			case '/staging/reset-password':
			case '/reset-password':
			case '/error':
			case '/confirm':
			case '/sendreceive/contacts':
			case `/resend-confirm`:
			case `/logout`:
			case "/privacypolicy":
			case `/termsofservice`:
			case `/pricing`:
				return;
			case `/sendreceive/requests/`:
				let loggedIn = localStorage.getItem('loggedIn');
				if (!loggedIn) {
					return;
				}
				break;
			default:
		}
	}
	logOut = () => {
		Request.authRequest(Routes.auth.logout, 'POST',).then((response) => {
			if (response.ok) {
				localStorage.setItem('loggedIn', '');
				document.cookie = 'ivy_authed=; path=/;Domain=.ivydocuments.com;expires = Thu, 01 Jan 1970 00:00:00 GMT';
				window.location.href = '/';
			}
		})
	}
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			switch (this.props.location.pathname) {
				case `/sendreceive/requests/`:
				case '/login':
				case '/signup':
				case '/':
				case '/organization/join':
				case '/organization/create':
				case '/production/reset-password':
				case '/staging/reset-password':
				case '/passwordreset':
				case '/reset-password':
				case '/error':
				case `/features`:
				case '/confirm':
				case '/sendreceive/contacts':
				case `/resend-confirm`:
				case "/privacypolicy":
				case `/termsofservice`:
				case `/pricing`:
					return;
				case `/sendreceive/requests/`:
					let loggedIn = localStorage.getItem('loggedIn');
					if (!loggedIn) {
						return;
					}
					break;
				default:
			}
		}
	}

	goToHome = () => {
		window.location.href = '/home';
	}
	render() {
		let location = this.props.location;
		let numberUnreadNotifications;
		if (this.state.unreadNotifications > 0) {
			numberUnreadNotifications = this.state.unreadNotifications;
		}
		switch (location.pathname) {

			case '/login':
			case '/signup':
			case '/':
			case '/organization/join':
			case '/organization/create':
			case '/passwordreset':
			case '/reset-password':
			case '/error':
			case '/confirm':
			case `/features`:
			case `/resend-confirm`:
			case "/privacypolicy":
			case `/termsofservice`:
			case `/pricing`:
			case '/staging/reset-password':
			case '/production/reset-password':
				return (
					<div className='header header-unauth'>
						<div className="header-unauth-left">
							<Link to='/' className="header-logo-unAuth-container"><img className='header-logo-unauth' src={LogoWhite} alt='' /></Link>
							<div className="header-email"> info@ivydocuments.com</div>
						</div>

						<div className="header-login">
							<Link to="/pricing" id="header-features-button">Pricing</Link>
							<Link to="/features" id="header-features-button">Features</Link>
							<Link to="/login" id="header-login-button">Log In</Link>

							<Link to="/signup" id="header-signup-button">Sign Up</Link>


						</div>
					</div>
				)
			case '/logout':
				return null;
			case `/sendreceive/requests/`:
				let loggedIn = localStorage.getItem('loggedIn');
				if (!loggedIn) {

					return (
						<div className='header header-unauth'>
							<div className="header-unauth-left">
								<Link to='/' className="header-logo-unAuth-container"><img className='header-logo-unauth' src={LogoWhite} alt='' /></Link>
								<div className="header-email"> info@ivydocuments.com</div>
							</div>

							<div className="header-login">
								<Link to="/pricing" id="header-features-button">Pricing</Link>
								<Link to="/features" id="header-features-button">Features</Link>

								<Link to="/login" id="header-login-button">Log In</Link>

								<Link to="/signup" id="header-signup-button">Sign Up</Link>


							</div>
						</div>
					)
				}
			default:
				const documentClass = location.pathname === '/documents/' ? 'header-link current' : 'header-link';
				const permissionsClass = location.pathname === '/sendreceive/templates' ? 'header-link current' : 'header-link';
				const tagsClass = location.pathname === '/tags' ? 'header-link current' : 'header-link';
				const notificationsClass = location.pathname === '/notifications' ? 'header-link current' : 'header-link';
				const logsClass = location.pathname === '/logs' ? 'header-link current' : 'header-link';
				const settingsClass = location.pathname === '/settings' ? 'header-link current' : 'header-link';
				const helpClass = location.pathname === '/help' ? 'header-link current' : 'header-link';
				const faqClass = location.pathname === '/faq' ? 'header-link current' : 'header-link';
				return (
					<div className='header noselect '>
						<div className='header-left'>
							<div className='header-link'>
								<div onClick={this.goToHome}><img className='header-logo' src={LogoWhite} alt='' /></div>
							</div>
							<div className={documentClass} id="introJs-header-documents"><Link to='/recipes/?page=1&per_page=20&status=accepted'>Recipes</Link></div>
							<div className={permissionsClass} id="introJs-header-permissions">
								<Link to='/ingredients'>Ingredients</Link>
							</div>
							<div className={tagsClass} id="introJs-header-tags"><Link to='/tags'>Tags</Link></div>


						</div>
						<div className='header-right'>
							<div className={logsClass} id="introJs-header-logs"><Link to='/logs'>Logs</Link></div>

							<div className={settingsClass} id="introJs-header-settings"><Link to='/settings'>Settings</Link></div>

							<div className='header-link' onClick={this.logOut}>Logout</div>
						</div>
					</div>
				);
		}
	}
}

export default Header;
