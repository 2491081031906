import React from 'react';
import './UnitSelector.css';
class UnitSelector extends React.Component {

    sendUnits = (e) => {
        this.props.changeUnits(e.target.value);
    }
    render() {
        if (this.props.ignore_density) {
            return (
                <div>
                    <select className="unitSelector" onChange={this.sendUnits} value={this.props.unit}>
                        <option value="kg">kg</option>
                        <option value="lb">lb</option>
                    </select>
                </div>
            )
        }
        return (
            <div>
                <select className="unitSelector" onChange={this.sendUnits} value={this.props.unit}>
                    <option value="kg">kg</option>
                    <option value="L">L</option>
                    <option value="lb">lb</option>
                    <option value="gal">gal</option>
                </select>
            </div>
        )
    }
}

export default UnitSelector;