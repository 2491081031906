import React from 'react';
import { toast } from 'react-toastify';
import './ToggleDensity.css';
class ToggleRequiresSignatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      requires_signatures: this.props.requires_signatures
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.requires_signatures !== this.props.requires_signatures) {
      this.setState({ requires_signatures: this.props.requires_signatures });
    }
  }
  onSaveChanges = () => {
    if (this.state.requires_signatures === this.props.requires_signatures) {
      return
    }

    else {
      this.props.onClickOut(this.state.requires_signatures);
    }
  }
  componentDidMount() {
    if (this.props.requires_signatures !== null) {
      this.setState({ requires_signatures: this.props.requires_signatures })
    }
  }
  handleDblClick = (e) => {
    if (this.node.contains(e.target)) {
      this.setState({
        editing: true
      })
    }
    else if (this.state.editing !== false) {

      this.onSaveChanges();
      this.setState({ editing: false })
    };
  }
  clickSave = () => {

    this.onSaveChanges();
    this.setState({ editing: false })

  }
  componentWillMount() {
    document.addEventListener('dblclick', this.handleDblClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('dblclick', this.handleDblClick, false);
  }
  handleEnter = (e) => {
    if (e.key === 'Enter') {

      this.onSaveChanges();

      this.setState({ editing: false })
    }

  }
  onToggleCheckBox = (e) => {
    this.setState({
      requires_signatures: !this.state.requires_signatures
    });
  }
  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.setState({ editing: false, requires_signatures: this.props.requires_signatures })
    }
  }
  onCancel = () => {
    this.setState({ editing: false })
  }

  render() {
    let title = 'This recipe requires signatures.';
    if (!this.props.requires_signatures) {
      title = 'This recipe does not require signatures.'
    }
    return (

      <div className='doc-info-field' ref={node => this.node = node}>
        <div className="density-button">{title}</div>

        {this.state.editing ?
          <div>
            <div><div className="toggleDensity-checkboxContainer "><input
              value={this.state.requires_signatures}
              type='checkbox'
              checked={this.state.requires_signatures}
              onChange={this.onToggleCheckBox}
              onKeyPress={this.handleEnter}
              onKeyDown={this.onKeyDown} />
              Requires Signatures</div>
              <div className="smallHelpText">Check this recipe requires signatures before it can be locked or sent.</div>
              <div className="toggleDensity-buttonContainer">
                <div className="toggleDensity-button primary" onClick={this.clickSave}>Save</div>
                <div className="toggleDensity-button button-secondary" onClick={this.onCancel}>Cancel</div>
              </div>
            </div> </div> :

          <div className={this.props.header ? 'document-info-header' : 'document-info wordbreak'}>{this.state.string}
          </div>
        }
      </div>)

  }
}
export default ToggleRequiresSignatures;
