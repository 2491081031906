import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import ReferralItem from './ReferralItem';
import ReferralButton from './ReferralButton';

class Referrals extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      referrals:[]
    }
  }
  componentDidMount(){
    this.getReferrals();
  }
  getReferrals=()=>{
    Request.authRequest(Routes.payments.referrals, 'GET').then((response) => {
				response.json().then(body => {
				this.setState({referrals : body

				})
      })
				});
  }

  render(){
    let referrals = "You haven't referred anyone yet! Invite your friends.";
    if (this.state.referrals.length>0){
      referrals = this.state.referrals.map((referral,i)=>{
        return <ReferralItem key = {i} referral = {referral}/>
      })
    }
    return(
      <div className='settings-section boxShadow'>
        <div>
          <div className='settings-heading'>Referrals</div>
          <hr className='settings-section-divider' />
          <ReferralButton getReferrals = {this.getReferrals}/>
        </div>
        <div>
          <div className = "referrals-subheading">Current Referrals</div>
          {referrals}
        </div>
      </div>
    )
  }
}

export default Referrals;
