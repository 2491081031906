import React from 'react';
import './AddIngredient.css';
import Modal from '../../Modal/Modal';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import IngredientNavRow from '../../Ingredients/IngredientNavRow';
import LoadingButton from '../../Buttons/LoadingButton';

class DeleteIngredientButton extends React.Component{
    render(){
        let expanded;
        if (!this.props.deleting){
        return(
            <div onClick = {this.props.startDeleting} className = "recipe-add-ingredient">
                <div >- Remove Ingredient</div>
                <div>
                    
                </div>
            </div>
        )}
        else{
            return(
                <div className = "recipe-deleteIngredient-confirm">
            <div onClick = {this.props.confirmDeleting} className = "recipe-edit-button-confirm">
                <div>Confirm</div>
                <div>
                    
                </div>
            </div>
            <div onClick = {this.props.cancelDeleting} className = "recipe-edit-button-cancel">
                <div>Cancel</div>
                <div>
                    
                </div>
            </div>
            </div>)
        }
    }
}

export default DeleteIngredientButton