import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './Auth.css';
import {toast} from 'react-toastify';
import qs from 'qs';
import LoadingButton from '../Buttons/LoadingButton';
class OrganizationJoin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			accessCode: '',
			error: '',
			firstName:'',
			lastName:'',
			jobTitle:'',
			autofilled:false,
		};
	}
	componentDidMount=()=>{
		const searchString=qs.parse(this.props.location.search.substr(1));
		if (searchString.organization){
			this.setState({accessCode:searchString.organization, autofilled:true})
		}
	}
	onChangeFirstName = (e) => {
		this.setState({
			firstName: e.target.value,
			error: '',
		});
	}

	onChangeLastName = (e) => {
		this.setState({
			lastName: e.target.value,
			error: '',
		});
	}
	onChangeJobTitle = (e) => {
		this.setState({
			jobTitle: e.target.value,
			error: '',
		});
	}

	onSaveUser = () =>{
		
		if (this.state.firstName===''){
			toast.error('Please enter a first name');
			return
		}
		if (this.state.lastName===''){
			toast.error('Please enter a last name');
			return
		}
		this.setState({loading:true});
    let user = {first_name:this.state.firstName,last_name:this.state.lastName,
		job_title:this.state.jobTitle};

      Request.authRequest(Routes.profile.view,
                        'PUT',JSON.stringify(user), 'application/json'
                      ).then((response) => {
                        response.json().then(body => {
                          this.onAttemptSubmit();})
      }
    );
  }
	onChangeAccessCode = (e) => {
		this.setState({
			accessCode: e.target.value,
			error: '',
		});
	}

	onAttemptSubmit = () => {
		Request.authRequest(Routes.organization.join, 'POST', JSON.stringify({
			access_code: this.state.accessCode,
		}), 'application/json').then((response) => {
			if (response.status === 200) {
				response.json().then((body) => {
					const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
					if (vw < 700 && !(window.location.search.indexOf('from_mobile')>-1)){
						window.location.href= 'https://m.ivydocuments.com/home';
					} else {
						window.location.href = '/home';
					}
				});
			} else {
				response.json().then((error) => {
					this.setState({ error: error.message , loading:false});
				});
			}
		}).catch((error) => {
			this.setState({ error: error.message, loading:false});
		});
	}

	render() {
		return (
			<div className='authform-large boxShadow'>
				<div className='join-org-header'>We're glad to have you. </div>
					<div className='create-org-header'> Finish your profile </div>
					<label className='input-label' htmlFor='firstName'> First Name </label>
					<input type='text' onChange={this.onChangeFirstName} id='firstName' className='input-text'/>
					<label className='input-label' htmlFor='lastName'> Last Name </label>
					<input type='text' onChange={this.onChangeLastName} id='lastName' className='input-text'/>
					<label className='input-label' htmlFor='jobTitle'> Job Title </label>
					<input type='text' onChange={this.onChangeJobTitle} id='jobTitle' className='input-text'/>

				<div className=' join-org'>
					<div className='join-org-subheader'> Join your organization </div>
					<label className='input-label' htmlFor='accessCode'> Enter your organization's access code. </label>
					<div className='input-container'>
						<input type='text' value = {this.state.accessCode} onChange={this.onChangeAccessCode} id='accessCode' className='input-text access-code-input'/>
					</div>
					{this.state.autofilled?<div className = "smallHelpText">This has been filled
						automatically by the person who invited you. If you are joining a different organization, please enter the code.</div>:null}
					{this.state.error}
					<button className='form-button primary' 
					> 
					<LoadingButton onClick={this.onSaveUser}
						text = 'Join' isLoading = {this.state.loading}/>

					</button>

					<div className='register-text'> Don't know your organization's access code? </div>
					<div className='register-text'> Please ask your admin, or contact us at help@ivydocuments.com.</div>
				</div>
				<div> or </div>
				<Link to='/organization/create'>
					<button className='form-button create-org'> Create a New Organization </button>
				</Link>
			</div>
		)
	}
}

export default OrganizationJoin;
