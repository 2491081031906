import React from 'react';

class StripeInputElement extends React.Component{
  constructor(props){
    super(props);
    this.state={
      string:'',
    }
  }
  onChangeDocumentInfo = (e) => {
		this.setState({
			string: e.target.value,
		});
	}
  onBlurInput = ()=>{
    this.props.sendInfo(this.state.string,this.props.contentType)
  }
  render(){
    return(
    <div>
      <input
        value={this.state.string}
        placeholder = {this.props.contentType}
        type='text'
        className='stripe-info-field'
        onChange={this.onChangeDocumentInfo}
        onKeyPress={this.handleEnter}
        onKeyDown = {this.onKeyDown}
        onBlur = {this.onBlurInput}/>
    </div>)
  }
}

export default StripeInputElement;
