import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import NewIngredientTemplateButton from './NewIngredientTemplateButton';
import IngredientTemplateRow from './IngredientTemplateRow';
import './IngredientTemplates.css';
class IngredientTemplateContainer extends React.Component {
  constructor(props) {
    super(props);
    this.getTemplateList = this.getTemplateList.bind(this);
    this.state = {
      templateList: [],
      currentTemplate: ''
    }
  }
  componentDidMount = () => {
    this.getTemplateList();
  }
  onSelectTemplate = (template) => {
    this.setState({ currentTemplate: template })
    this.props.selectTemplate(template);
  }
  getTemplateList = () => {
    Request.authRequest(Routes.ingredients.templateList,
      'GET').then((response) => {

        if (response.status === 200) {
          response.json().then(body => {
            this.setState({ templateList: body, loaded: true })
          })
        }
        else {
          response.json().then((error) => {
            this.setState({ loaded: true })
            toast.error(error.message);
          });
        }
      }).catch((error) => {
        toast.error(error.message);
      }
      )
  }
  clearSelected = () => {
    this.setState({ currentTemplate: '' });
    this.props.selectTemplate('');
  }
  render() {
    let currentTemplate = 'None';
    if (this.state.currentTemplate) {
      currentTemplate = this.state.currentTemplate.name
    }
    let templateList = "No templates. Press add a template to make one!"
    if (this.state.templateList.length > 0) {
      templateList = this.state.templateList.map((template, i) => {
        return <IngredientTemplateRow sendSelectedTemplate={this.onSelectTemplate}
          template={template} key={i} onGetTemplates={this.getTemplateList} currentTemplate={this.state.currentTemplate} />
      })
    }
    return (
      <div className="ingredientTemplateContainer">
        <div>Select a template to base this ingredient off of, or just continue. </div>
        <div>Currently Selected: {currentTemplate}</div>
        <div onClick={this.clearSelected} className="ingredient-clearCurrentTemplateSelection">Clear</div>
        <div className="ingredientTemplateContainer-title">
          <div>Templates</div>
          <NewIngredientTemplateButton getTemplateList={this.getTemplateList} />
        </div>
        <div>{templateList}</div>
      </div>
    )
  }
}
export default IngredientTemplateContainer