import React from 'react';

import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import CurrentTagInput from './CurrentTagInput';
import ParentTagInput from './ParentTagInput';
import ChildTagInput from './ChildTagInput';
class TagRelationshipManager extends React.Component {
  constructor(props) {
    super(props);
    this.sendParentTags = this.sendParentTags.bind(this);
    this.sendChildTags = this.sendChildTags.bind(this);
    this.state = {
      currentTagInfo: '',
      parentTags: [],
      childTags: [],
    }
  }


  sendParentTags = (tags) => {
    let currentTag = JSON.parse(JSON.stringify(this.props.tag));

    let tag_child_names = [];
    if (currentTag.child_tags.length > 0) {
      tag_child_names = currentTag.child_tags.map(x => x.name)
    }
    Request.authRequest(Routes.tags.tag(encodeURIComponent(currentTag.name)), 'PUT', JSON.stringify(
      {
        parent_tag_names: tags,
        child_tag_names: tag_child_names
      }
    ), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.props.changeTag();
          this.props.onGetNewTopTags();
        }
        )
      }

      else {
        response.json().then((error) => {
          toast.error(error.message);
          if (error.errors) {
            toast.error(error.errors[0])
          }
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )

  }

  sendChildTags = (tags) => {
    let currentTag = JSON.parse(JSON.stringify(this.props.tag));

    let tag_parent_names = [];
    if (currentTag.parent_tags.length > 0) {
      tag_parent_names = currentTag.parent_tags.map(x => x.name)
    }
    Request.authRequest(Routes.tags.tag(encodeURIComponent(currentTag.name)), 'PUT', JSON.stringify(
      {
        parent_tag_names: tag_parent_names,
        child_tag_names: tags
      }
    ), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.props.changeTag();
          this.props.onGetNewTopTags();
        }
        )
      }
      else {
        response.json().then((error) => {
          toast.error(error.message);
          if (error.errors) {
            toast.error(error.errors[0])
          }
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )

  }
  render() {
    let tag = this.props.tag;
    let parent_tags = tag.parent_tags.map((tag, i) => {
      return tag.name
    });
    let child_tags = tag.child_tags.map((tag, i) => {
      return tag.name
    })
    return (
      <div id="TagRelationshipManager">
        <ParentTagInput tags={parent_tags}
          sendTags={this.sendParentTags} />
        <CurrentTagInput currentTag={this.props.tag.name}
          changeTag={this.props.changeCurrentTag} />
        <ChildTagInput tags={child_tags}
          sendTags={this.sendChildTags} />
      </div>
    )
  }
}
export default TagRelationshipManager;
