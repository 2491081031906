import React from 'react';
import PropTypes from 'prop-types';

import './Modal.css';

class Modal extends React.Component {
  componentDidMount(){
    window.addEventListener('keyup',this.handleEscape)
  }
  componentWillUnmount(){
    window.removeEventListener('keyup',this.handleEscape)
  }
  handleEscape=(e)=>{
		if (e.key === "Escape"){
			this.props.onClose(e);
		}
	}
  render() {
    if(!this.props.show) {
      return null;
    }

    return (
      <div className='backdrop' id = {this.props.internalSelect?"backdropInternalSelect":null}>
        <div className='modal' id = {this.props.extraWide?"modalExtraWide":null}>
          <div className='modal-title'>
            {this.props.title}
          </div>
          <button className='modal-close secondary' onClick={this.props.onClose}>
            Close
          </button>
          <div className='modal-body'>
            {this.props.children}
          </div>

          <div className='modal-footer'>

          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
};

export default Modal;
