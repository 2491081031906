import React from 'react';
import TagRelationshipManager from './TagRelationshipManager';
import ShortLogsRow from '../../Logs/ShortLogsRow';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import DeleteTagButton from '../DeleteTagButton';
import PinTagButton from '../../Tags/PinTagButton';
import MergeTagsButton from './MergeTagsButton';

import { toast } from 'react-toastify';
import './TagEditor.css';
class TagEditor extends React.Component {
  constructor(props) {
    super(props);
    this.getTag = this.getTag.bind(this);
    this.state = {
      showLogs: false,
      logs: [],
      rename: false,
      tagName: this.props.currentTag.name,
    }
  }
  showRename = () => {
    this.setState({ rename: true })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentTag !== this.props.currentTag) {
      this.setState({ tagName: this.props.currentTag.name })
    }
  }
  saveRename = () => {
    this.onSaveChanges();
    this.setState({ rename: false })
  }
  onGetTagAgain(tagName) {
    this.props.getTag(tagName);
  }
  onSaveChanges() {
    let tag = JSON.parse(JSON.stringify(this.props.currentTag));
    tag.name = this.state.tagName;
    Request.authRequest(Routes.tags.edit(encodeURIComponent(this.props.currentTag.name)),
      'PUT', JSON.stringify(
        tag
      ), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          toast.success('Sucessfully edited tag')
          this.onGetTagAgain(body.name);
          this.props.onReplaceRename(this.props.currentTag.name, body.name);

        }
        )
      }
      else {
        response.json().then((error) => {
          toast.error(error.message);

        });
      }
    }).catch((error) => {
      if (error.message) {
        toast.error(error.message);
      }
    }
    )
  }
  onGetLogs() {
    Request.authRequest(Routes.logs.tag(encodeURIComponent(this.props.currentTag.name)), 'GET').then((response) => {
      response.json().then(body => {
        this.setState({ logs: body })
      })
    })
  }
  showLogs = () => {
    this.onGetLogs();
    this.setState({ showLogs: true })
  }
  showRelationships = () => {
    this.setState({ showLogs: false })
  }
  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.saveRename();
    }

  }
  componentDidMount() {
    this.onGetLogs();
  }
  onChangeTagName = (e) => {
    this.setState({
      tagName: e.target.value,
    });
  }
  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.setState({ editing: false, tagName: this.props.currentTag.name })
    }
  }
  getTag = () => {
    this.props.getTag(this.props.currentTag.name);
  }
  render() {
    let tag = this.props.currentTag;
    let logsList;

    if (this.state.logs.length > 0) {
      logsList = this.state.logs.map((entity, i) => {
        return <ShortLogsRow log={entity} key={i} />
      })
    } else {
      logsList = (<div className="helpText">No Logs</div>)
    }
    return (
      <div className="tagEditor-container">
        <div className="tagEditor-currentlySelectedHeader">
          <div className="tagEditor-currentlySelected-name">
            {this.state.rename ? <input value={this.state.tagName}
              type='text'
              className='tag-info-field'
              onChange={this.onChangeTagName}
              onKeyPress={this.handleEnter}
              onKeyDown={this.onKeyDown} /> : <div className="currentTagName">{this.state.tagName}</div>}
            <PinTagButton tag={this.props.currentTag}
              onGetPinnedTags={this.props.onGetPinnedTags}
              onGetTag={this.getTag} />
          </div>
          <div className="tagUtilityButtons">

            <div className="tagRenameButtons">
              {this.state.rename ? <div onClick={this.saveRename} className="saveTagRenameButton">Save</div>
                : <button onClick={this.showRename} className="renameTagButton">Rename</button>}
            </div>
            <MergeTagsButton tag={this.props.currentTag}
              onMergeTag={this.props.getTag}
              onDeleteMergedTag={this.props.onDeleteMergedTag}
              onGetNewTopTags={this.props.onGetNewTopTags} />
            <DeleteTagButton text tag={this.state.tagName} onDeleteTag={this.props.onDeleteTag} />

          </div>
        </div>
        <div className="tagsEditor-contentHeader">
          <div className={this.state.showLogs ? "tagsEditor-tableHeader-inactive" : "tagsEditor-tableHeader-active"}
            onClick={this.showRelationships}>Manage </div>
          <div onClick={this.showLogs} className={this.state.showLogs ? "tagsEditor-tableHeader-active" :
            "tagsEditor-tableHeader-inactive"}>Logs</div>
        </div>

        {this.state.showLogs ?
          <div className="tagsEditor-contents">{logsList}</div> :
          <TagRelationshipManager tag={tag} changeTag={this.getTag}
            changeCurrentTag={this.props.getTag}
            onGetNewTopTags={this.props.onGetNewTopTags} />}

      </div>
    )
  }
}
export default TagEditor;
