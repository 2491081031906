import React from 'react';
import './Signatures.css';
class SelectedUserComponent extends React.Component{

  removeUser = () =>{
    this.props.onRemoveUser(this.props.user)
  }
  render(){
    let user = this.props.user;
    return(
      <div onClick = {this.addUser} className = "signature-SelectedUserContainer">
          <div>{user.first_name} {user.last_name} {this.props.manager?"- Final Approval":null}</div>
          <div className = "signature-selectedUserRight" >
            {user.email}
            <div onClick = {this.removeUser}
            className = "signature-removeUserButton">x</div>
        </div>

      </div>)
  }
}

export default SelectedUserComponent;
