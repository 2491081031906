import React from 'react';

import introJs from 'intro.js';
import HomeSteps from '../Layout/Help/HomeSteps.json';
import TemplatesSteps from '../Layout/Help/TemplatesSteps.json';
import RequestsSteps from '../Layout/Help/RequestsSteps.json';
import LogsSteps from '../Layout/Help/LogsSteps.json';
import SettingsSteps from '../Layout/Help/SettingsSteps.json';
import DocumentsViewerSteps from '../Layout/Help/DocumentsViewerSteps.json';
import TemplatesViewerSteps from '../Layout/Help/TemplatesViewerSteps.json';
import PendingDocuments from '../Layout/Help/PendingDocuments.json';
import ContactsSteps from '../Layout/Help/ContactsSteps.json';

function CookieWrapper(WrappedComponent,location){
  return class extends React.Component{

  onGetIntro(cookiePath){
		let steps;
		switch(cookiePath){
			case '/home':
				steps = HomeSteps;
				break;

			case '/sendreceive/templates':
				steps = TemplatesSteps;
				break
			case '/sendreceive/requests/':
					steps = RequestsSteps;
					break
      case '/sendreceive/contacts':
        steps = ContactsSteps;
        break
      case '/sendreceive/pending/':
  		 		    steps = PendingDocuments;
  		 		   break;
			case '/logs':
				steps = LogsSteps;
				break;
			case '/settings':
				steps = SettingsSteps;
				break;
  		case 'DocumentsViewer':
				steps = DocumentsViewerSteps;
        break;
			case 'TemplatesViewer':
				steps = TemplatesViewerSteps;
        break;
        default:
			}
    localStorage.setItem(cookiePath,true);
		window.setTimeout(()=>introJs().setOptions({steps:steps, showStepNumbers:false}).start(),500);
	}
  componentDidMount(){
    let cookiePath = this.props.location.pathname;
    let isNotViewer; //isNotViewer checks if the path is a viewer ID since they're all unique.
    var visited;
    switch (cookiePath) {
      case '/':
      case '/sendreceive/templates':
      case '/sendreceive/requests/':
      case '/sendreceive/contacts':
      case '/sendreceive/pending/':
      case '/logs':
      case '/settings':

      isNotViewer = true;
        break;
      default:
    }
    if (!isNotViewer){

      let path = cookiePath.split('/');
			if (path[1] === "documents"){
				cookiePath = 'DocumentsViewer';
			}
			else if (path[2] === 'templates-viewer'){
				cookiePath = 'TemplatesViewer';
			}
    }
    visited = localStorage.getItem(cookiePath);
    if (!visited){
      this.onGetIntro(cookiePath);
    }
  }
  render(){
    return(
      <WrappedComponent {...this.props}/>
    )
  }
}
}

export default CookieWrapper;
