import React from 'react';
import TopTag from '../Tags/TopTag';
import './PinnedTagList.css';

import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
class PinnedTagList extends React.Component{
  constructor(){
    super();
    this.onGetTags=this.onGetTags.bind(this);
    this.tagDictionaryRef = React.createRef();
    this.state={
      pinnedTags:[],
      searchQuery:'',
      letter:'',
      page:1,
      has_next:true,
    }
  }


  componentDidMount(){
    this.onGetTags();
  }



  onGetTags(){
    Request.authRequest(Routes.tags.pin_tag,
											  'GET').then((response) => {
			response.json().then(body => {
				this.setState({pinnedTags:body})
      })
			});
  }
  render()
  {
    let pinnedTagsList = <div className = 'pinnedTagHelp'>No favorite tags. Click the star in an active tag to favorite a tag!</div>
    if (this.state.pinnedTags.length>0){
      pinnedTagsList = this.state.pinnedTags.map(
      (entity,i)=>{
        return  (
                  <TopTag
                    tag={entity} onAddActiveTag={this.props.onAddActiveTag}
                    onAddExcludedTag={this.props.onAddExcludedTag}
                    onLastSelected={this.props.onLastSelected}
                    key = {i}/>)
      }
    );
  }
    return(
      <div className = "pinnedTags-container">
            <div className = "pinnedTagHeader">Favorite Tags</div>
            <div className = "topTagList">
              {pinnedTagsList}
            </div>
      </div>

  )
  }
}
export default PinnedTagList;
