import React from 'react';
import './InfoField.css';
import {toast} from 'react-toastify';
class InfoField extends React.Component{
  constructor(props){
    super(props);
    this.state={
      editing:false,
      string:''
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.content!== this.props.content){
      this.setState({string:this.props.content});
    }
  }
  onSaveChanges(){
    if (this.state.string === this.props.content){
      return
    }

    if (this.state.string){
    this.props.onClickOut(this.props.contentType,this.state.string.trim());}
    else {
      this.props.onClickOut(this.props.contentType,this.state.string);
    }
  }
  componentDidMount(){
    if (this.props.content!==null){
      this.setState({string:this.props.content})
    }
  }
  handleDblClick = (e) =>{
    if (this.node.contains(e.target)){
      this.setState({
        editing:true
      })
    }
    else if(this.state.editing!==false){
      if (this.state.string.length>100){
        toast.error('This field is limited to 100 characters. Press escape to revert changes.');
        return
      }
      this.onSaveChanges();
      this.setState({editing:false})
    };
  }
  componentWillMount(){
    document.addEventListener('dblclick',this.handleDblClick,false);
  }
  componentWillUnmount(){
    document.removeEventListener('dblclick',this.handleDblClick,false);
  }
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      if (this.state.string.length>100){
        toast.error('This field is limited to 100 characters. Press escape to revert changes.');
        return
      }
      this.onSaveChanges();

      this.setState({editing:false})
    }

  }
  onChangeDocumentInfo = (e) => {
		this.setState({
			string: e.target.value,
		});
	}
  onKeyDown=(e)=>{
    if (e.key === 'Escape'){
      this.setState({editing:false,string:this.props.content})
    }
  }
  render(){

    return (

      <div className='doc-info-field' ref = {node => this.node = node}>
        {this.props.nolabel?null:<label className='document-label noselect' >
          {this.props.contentType} </label>}
        {this.state.editing?

          <input
            value={this.state.string}
            type='text'
            className='document-info-field'
            onChange={this.onChangeDocumentInfo}
            onKeyPress={this.handleEnter}
            onKeyDown = {this.onKeyDown}/>:

            <div className = {this.props.header?'document-info-header' :'document-info wordbreak'}>{this.state.string}
            </div>
          }
    </div>)

  }
}
export default InfoField;
