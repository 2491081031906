import React from 'react';
import Modal from '../../Modal/Modal';
import './BatchSheetViewer.css';
import BillOfMaterials from './BillOfMaterials';
import { BOM } from '../../Icons/IconLibrary';
class BOMButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }
    onShowModal = () => {

        this.setState({ showModal: true })
    }
    onHideModal = () => {
        this.setState({
            showModal: false
        })
    }
    goBOM = () => {
        window.location.href = window.location.href + '/bom';
    }
    render() {
        let doc = this.props.doc;
        let versionNumber;
        if (doc) {
            if (doc.version_history_with_self.length > 1) {
                versionNumber = 'Version ' + doc.version_number;
                if (doc.version_history_with_self.length > 1 && doc.version_number === doc.version_history_with_self.length - 1
                ) {
                    versionNumber = versionNumber + " - current";
                } else if (doc.version_history_with_self.length > 1 &&
                    doc.version_number > doc.version_history_with_self[0].version_number) {
                    versionNumber = versionNumber + " - current";
                }
            }
        }
        return (
            <div >
                <div className="" onClick={this.onShowModal}>
                    <img src={BOM} className=' signatureButton-text button-preview-documentViewer' />
                </div>
                <Modal show={this.state.showModal} onClose={this.onHideModal} title='Bill of Materials' extraWide>
                    <div className="recipe-title">{doc.name} </div>
                    <div>{versionNumber}</div>
                    <div>{doc.user_defined_id}</div>
                    <BillOfMaterials recipe={this.props.recipe} onGetRecipe={this.props.onGetRecipe} />
                    <div className="recipe-add-ingredient" onClick={this.goBOM}>Go to BOM Page</div>
                </Modal>
            </div>
        )
    }
}
export default BOMButton;