import React from 'react';
import './AdvancedSearchComponents.css';
class SentToOrg extends React.Component{
  setOrg=()=>{
    this.props.setOrg(this.props.org.name);
  }
  render(){
    let org = this.props.org;
    return(
      <div onClick = {this.setOrg} className = "tagLabel-container">{org.name}</div>
    )
  }
}

export default SentToOrg;
