import React from 'react';
import './Signatures.css';
import Modal from '../../Modal/Modal';
import SignatureList from './SignatureList';
import {SignaturePen} from '../../Icons/IconLibrary';
/* Use this for the document viewer components. Use the setSignatureList
for upload and version history*/
class SignatureButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showModal:false,
    }
  }
  onShowModal = () =>{
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false
  })}


  render(){
    return(
      <div>
      <img src = {SignaturePen} className = "signatureButton-text" onClick = {this.onShowModal}
        alt = "Approve and sign the document" title = "Approve and sign the document"/>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Signatures' >
          <SignatureList onCloseModal = {this.onHideModal} doc = {this.props.doc}
            onGetDocuments = {this.props.onGetDocuments}/>

        </Modal>

      </div>
    )
  }
}

export default SignatureButton;
