import React from 'react';

import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import {toast} from 'react-toastify';
import Star from '../Icons/star.svg';
import StarFilled from '../Icons/starfilled.svg';
import './PinTagButton.css';
class PinTagButton extends React.Component{
  onTogglePinTag = (e)=>{
    e.stopPropagation();
    if (this.props.tag.is_pinned){
      this.onRemovePin();
    }
    else {
      this.onPinTag();
    }
  }
  onRemovePin = () =>{
    Request.authRequest(Routes.tags.pin_tag, 'Delete',JSON.stringify({tag:this.props.tag.name}),
        'application/json').then((response)=>{
            if (response.status===200){
              toast.success(`Removed the ${this.props.tag.name} from your favorites`);
              this.props.onGetPinnedTags();
              this.props.onGetTag(this.props.tag.name);
            }
            else {
              response.json().then((error) => {
                toast.error(error.message);

              });
            }
      }).catch((error) => {
        toast.error(error.message);
      }
    )
  }
  onPinTag = ()=>{
    Request.authRequest(Routes.tags.pin_tag, 'POST',JSON.stringify({tag:this.props.tag.name}),
        'application/json').then((response)=>{
            if (response.status===200){
              toast.success(`Added the ${this.props.tag.name} to your favorites`);
              this.props.onGetPinnedTags();
              this.props.onGetTag(this.props.tag.name);
            }
            else {
              response.json().then((error) => {
                toast.error(error.message);

              });
            }
      }).catch((error) => {
        toast.error(error.message);
      }
    )
  }
  render(){
    return (<div className = "pinTagButton" onClick = {this.onTogglePinTag}>
        <img src= {this.props.tag.is_pinned?StarFilled:Star} id = 'pinnedTag'
          alt = "Favorite this tag." title= "Favorite this tag."/>
      </div>)
  }
}
export default PinTagButton;
