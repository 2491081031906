import React from 'react';
import './IngredientAttributes.css';
import {Lock} from '../../Icons/IconLibrary';
class IngredientUsedIn extends React.Component{
    constructor(props){
        super(props)
    }
    onClick = ()=>{
        window.location.href = `/recipes/`+this.props.recipe.recipe_id
    }
    render(){
        let recipe = this.props.recipe;

        return(
            <div onClick = {this.onClick} className = "ingredient-used-in is-spaced-between">
                <div className = "ingredient-used-in-recipe">{recipe.recipe_name} - v{recipe.version_number} 
                
                </div>
                <div className = "ingredient-used-in-ratio">
                    {recipe.immutable?<img src = {Lock} className = "small-lock"
                alt =  "This recipe has been locked" title = "This recipe has been locked"/>:null}
                {recipe.weight_ratio}%
                    
                </div>
            </div>
        )
    }
}

export default IngredientUsedIn