import React from 'react';
import TopTag from '../Tags/TopTag';
import './TagNavigator.css';
import isEqual from 'lodash.isequal';
import qs from 'qs';
import Search from '../Search/Search';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import TagDictionary from '../TagManager/TagDictionary';
import PinnedTagList from './PinnedTagList';
class TagNavigator extends React.Component {
  constructor() {
    super();
    this.onAddActiveTag = this.onAddActiveTag.bind(this);
    this.onAddExcludedTag = this.onAddExcludedTag.bind(this);
    this.onGetTags = this.onGetTags.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.pinnedTagListRef = React.createRef();
    this.tagDictionaryRef = React.createRef();
    this.state = {
      topTags: [],
      searchQuery: '',
      letter: '',
      perPage: 20,
      has_next: true,
      isLoaded: false,
      isNestedFiltered: false,
    }
  }
  onGetPinnedTags = () => {
    this.pinnedTagListRef.current.onGetTags();
  }

  toggleNestedSwitch = () => {
    this.setState({ isNestedFiltered: !this.state.isNestedFiltered }, this.componentDidMount)
  }
  handleSearchRequest = (updated_string) => {
    this.setState({ searchQuery: updated_string, letter: '' },
      async () => {
        this.onGetTags()
      }
    )
  }
  componentDidMount() {
    if (this.props.isFiltered || this.props.isIngredientFiltered) {
      this.getFilteredList();
    }
    else { this.onGetTags(); }
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.activeTags, this.props.activeTags) &&
      (this.props.isFiltered || this.props.isIngredientFiltered)) {
      this.getFilteredList();
    }
    else if (prevProps.isFiltered !== this.props.isFiltered) {
      this.getFilteredList();
    }
    else if (prevProps.isIngredientFiltered !== this.props.isIngredientFiltered) {
      this.getFilteredList();
    }
  }
  getFilteredList = () => {
    const searchString = {
      page: 1,
      per_page: 300,
      filter_active_tags: this.props.isFiltered,
      filter_active_ingredient_tags: this.props.filterIngredientTags,
      active_tags: [],
      nested: this.state.isNestedFiltered
    };
    let base_url;
    if (this.props.isFiltered) {
      searchString.filter_active_tags = true;
      searchString.active_tags = this.props.activeTags.join();
    }
    if (this.props.isIngredientFiltered) {
      searchString.filter_active_ingredient_tags = true;
      searchString.active_tags = this.props.activeTags.join();
    }
    if (this.state.searchQuery !== '') {
      searchString.q = this.state.searchQuery;
    }

    base_url = qs.stringify(searchString);

    base_url = '?' + base_url

    Request.authRequest(Routes.tags.search(base_url),
      'GET').then((response) => {

        response.json().then(body => {

          this.tagDictionaryRef.current.onMakeDictionary(body.results);
          this.setState({
            topTags: body.results.slice(0, 20),
            has_next: body.has_next,
            isLoaded: true
          })
        })
      });

  }

  async onAddActiveTag(tag) {
    if (Array.isArray(this.props.activeTags)) {
      await this.props.onChangeTags([...this.props.activeTags, tag]);
    }
    else if (this.props.activeTags) {
      await this.props.onChangeTags([this.props.activeTags, tag])
    } else {
      await this.props.onChangeTags([tag])
    }
    if (this.props.excludedTags && this.props.excludedTags.indexOf(tag) > -1) {
      this.props.onChangeExcludedTags(this.props.excludedTags.filter(tagnames => tagnames !== tag));
    }
  }
  async onAddExcludedTag(tag) {
    if (Array.isArray(this.props.excludedTags)) {
      await this.props.onChangeExcludedTags([...this.props.excludedTags, tag]);
    }
    else if (this.props.activeTags) {
      await this.props.onChangeExcludedTags([this.props.excludedTags, tag])
    } else {
      await this.props.onChangeExcludedTags([tag])
    }
    if (this.props.activeTags.indexOf(tag) > -1) {
      this.props.onChangeTags(this.props.activeTags.filter(tagnames => tagnames !== tag));
    }
  }
  seeMore = () => {
    if (this.state.has_next === false) {
      return
    }
    const searchString = {
      page: 1,
      per_page: this.state.perPage + 20,
    };

    let base_url;

    if (this.state.searchQuery !== '') {
      searchString.q = this.state.searchQuery;
    }

    base_url = qs.stringify(searchString);
    base_url = '?' + base_url;
    Request.authRequest(Routes.tags.search(base_url),
      'GET').then((response) => {
        response.json().then(body => {
          this.setState({ topTags: body.results, perPage: this.state.perPage + 20, has_next: body.has_next }
          )
        })
      });
  }

  onGetTags() {
    if (this.props.isFiltered || this.props.isIngredientFiltered) {

      return // this handles extra calls from isFiltered, which calls the make dictionary function in a separate function.
    }
    this.tagDictionaryRef.current.onGetTags();

    if (this.state.letter) {
      return // if a letter is selected, leave it selected rather than replacing it.
    }
    const searchString = {
      page: 1,
      per_page: 20,
      filter_active_tags: this.props.isFiltered,
      filter_active_ingredient_tags: this.props.isIngredientFiltered,
      active_tags: '',
      nested: this.state.isNestedFiltered
    };
    let base_url;
    if (this.props.isFiltered) {
      searchString.filter_active_tags = true;
      searchString.active_tags = this.props.activeTags.join();
    }
    if (this.props.isIngredientFiltered) {
      searchString.filter_active_ingredient_tags = true;
      searchString.active_tags = this.props.activeTags.join();
    }
    if (this.state.searchQuery !== '') {
      searchString.q = this.state.searchQuery;
    }
    searchString.page = this.state.page;

    base_url = qs.stringify(searchString);

    base_url = '?' + base_url

    Request.authRequest(Routes.tags.search(base_url),
      'GET').then((response) => {
        if (response.ok) {
          response.json().then(body => {
            this.setState({ topTags: body.results, has_next: body.has_next, isLoaded: true })
          })
        }
      });

  }
  setTags = (letterDict) => {
    if (!letterDict) {
      this.onGetTags();
      return;
    }
    let tags = letterDict[1].sort(function (a, b) {
      return a.name.localeCompare(b.name)
    })
    this.setState({
      topTags: tags,
      letter: letterDict[0], has_next: false
    })
  }
  onClearDictionaryLetter = () => {
    this.setState({ letter: '' }, () => this.onGetTags());

  }
  onReplaceTag = (tag, index) => {

    let replacedTags = this.props.activeTags.concat();
    replacedTags[index] = tag;
    this.props.onChangeTags(replacedTags);
    this.props.onLastSelected(tag);
  }

  render() {

    let topTagList = <div className="loaderContainer"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>;
    if (this.state.isLoaded) {
      topTagList = <div className="smallHelpText">No tags</div>;
      if (this.state.topTags.length > 0) {
        if (this.state.letter === '') {
          topTagList = this.state.topTags.filter(tags => tags.is_accessible).map(
            (entity, i) => {
              return (
                <TopTag
                  tag={entity} onAddActiveTag={this.onAddActiveTag}
                  onLastSelected={this.props.onLastSelected}
                  onAddExcludedTag={this.onAddExcludedTag}
                  key={i} />)
            }
          );
        } else {
          topTagList = this.state.topTags.map(
            (entity, i) => {
              return (
                <TopTag
                  tag={entity} onAddActiveTag={this.onAddActiveTag}
                  onAddExcludedTag={this.onAddExcludedTag}
                  onLastSelected={this.props.onLastSelected}
                  key={i} />)
            }
          );
        }
      }
    }

    return (
      <div className="TagNavigator-container">
        <Search placeholder="Search for a tag" sendSearch={this.handleSearchRequest} />
        <PinnedTagList onAddActiveTag={this.onAddActiveTag}
          onAddExcludedTag={this.onAddExcludedTag}
          onLastSelected={this.props.onLastSelected} ref={this.pinnedTagListRef} />

        <TagDictionary setTags={this.setTags} letter={this.state.letter} isFiltered={this.props.isFiltered}
          ref={this.tagDictionaryRef} onClearSelectedLetter={this.onClearDictionaryLetter} isIngredientFiltered={this.props.isIngredientFiltered}
          toggleSwitch={this.props.toggleSwitch} isFiltered={this.props.isFiltered} showFilter={this.props.showFilter}
          toggleNestedSwitch={this.toggleNestedSwitch} isNestedFiltered={this.state.isNestedFiltered} />
        <div className="topTagList" id="introJs-tagDictionary">
          {topTagList}
        </div>
        {this.state.has_next ? <div className="topTag-seeMore" onClick={this.seeMore}> See More</div> : null}
      </div>

    )
  }
}
export default TagNavigator;
