import React from 'react';
import { Upload } from '../../Icons/IconLibrary';
import Modal from '../../Modal/Modal';
import LoadingButton from '../../Buttons/LoadingButton';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
class IngredientVersionButton extends React.Component{
    constructor(props){
        super(props);
        this.state={
          showModal:false,
          loading:false,
          versionRecipes:false,
        }
      }
      onShowModal = () =>{
        this.setState({showModal:true})
      }
      onHideModal=()=>{
        this.setState({showModal: false
      })}
      versionIngredient = () =>{
        this.setState({loading:true})
        Request.authRequest(
            Routes.ingredients.version(this.props.ingredient.id),
                        'POST', JSON.stringify({version_recipes:this.state.versionRecipes}), 'application/json'
                      ).then((response) => {
                        if (response.status===200){
                        response.json().then(body => {
                            window.location.href = `/ingredients/` + body.id
                            toast.success(`Successfully versioned the ingredient!`)
                          });
                        } else {
                          response.json().then((error) => {
                            this.setState({loading:false});
                            toast.error(error.message);
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
				
      }
      changeVersionRecipes = () =>{
        this.setState({versionRecipes:!this.state.versionRecipes})
      }
      navRecipe = () =>{
        window.location.href='/recipes/'+this.props.ingredient.produced_from_recipe_id
        }
    render(){
      if (this.props.ingredient.produced_from_recipe_id){
        return(
          <div>
        <img src = {Upload} className = "signatureButton-text" onClick = {this.onShowModal}
      alt = "Create a new version of this ingredient" title = "Create a new version of this ingredient "/>
      <Modal show={this.state.showModal} onClose={this.onHideModal} title='Version Ingredient' >
      <div>
        <div>This ingredient is produced from a recipe. Version the recipe it is produced from to version this ingredient.</div>
        <div className= 'modal-button primary' onClick = {this.navRecipe}>Go to Recipe</div>
          </div>

      </Modal>

    </div>)
      }
        return(
            <div>
      <img src = {Upload} className = "signatureButton-text" onClick = {this.onShowModal}
        alt = "Create a new version of this ingredient" title = "Create a new version of this ingredient "/>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Version Ingredient' >
        <div>
          <div>Version this ingredient. </div>
          <div>If you would like to update every recipe this ingredient is used in, check the box below.
          All recipes which are locked will have a new version made using this ingredient,
           with the "Autogenerated" tag. All recipes which are unlocked will not be versioned and will have the ingredient replaced.

          <div><input type='checkbox' value = {this.state.versionRecipes} checked = {this.state.versionRecipes} 
          onChange = {this.changeVersionRecipes}/>Update Recipes</div>
          </div>
          <div>
          Otherwise, simply create a new version of this ingredient.</div>
            <div className= 'modal-button primary'>
            <LoadingButton onClick = {this.versionIngredient} 
        text = 'Create New Version' isLoading = {this.state.loading}/>
            </div>
            </div>
        </Modal>

      </div>
        )
    }
}

export default IngredientVersionButton;