import React from 'react';
import './Referrals.css';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
class ReferralItem extends React.Component{
  remind=()=>{
    Request.authRequest(Routes.payments.referrals, 'GET').then((response) => {
				response.json().then(body => {
				this.setState({referrals : body

				})
      })
				});
  }
  render(){
    let referral = this.props.referral;
    let name = referral.referred_name;
    let email = referral.referred_email;
    let status=referral.completed;
    return(
      <div className = "referralItem-borderContainer">
      <div className = "referralItem-container">
        <div className = "referralItem-attribute">{name}
        </div>
        <div className = "referralItem-attribute">{email}</div>

      </div>
      <div>Status:{status?'Completed':'Not Complete'}
      </div>
      </div>
    )
  }
}

export default ReferralItem;
