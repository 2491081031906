import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
class OnlyAsPDF extends React.Component{
  onChangeRestriction= (e) => {
    let user = this.props.user;
    let isRestricted = false;
    if (user.restricted_to_converted){
      isRestricted = false
    } else {
      isRestricted = true;
    }
    Request.authRequest(Routes.administrator.convertedRestriction(this.props.user.email),
      'PUT',JSON.stringify({restricted_to_converted:isRestricted
    }),'application/json').then(
        (response) => {
          if (response.status===200){
          response.json().then(body => {
            this.props.getUsers();
            toast.success(`Changed Only As PDF restriction for ${this.props.user.email}`)
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
          if (error.errors){
            if (typeof(error.errors)==='string'){
              toast.error(error.errors)
            } else {
            toast.error(error.errors[0])}
          }
        });
      }
    }).catch((error) => {
    toast.error(error.message);
    }
  )
	}

  render(){
    let checked = false;
    checked = this.props.user.restricted_to_converted;
    return (

      <div className = "user-list-section">
          <input checked={checked}
            type = 'checkbox'
            onChange= {this.onChangeRestriction}/>

    </div>)

  }
}
export default OnlyAsPDF;
