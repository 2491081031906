import React from 'react';
import ActiveTag from '../Tags/ActiveTag';
import './ActiveTagsNavigator.css';
import { DropTarget } from 'react-dnd';
const receiverTarget = {
  drop(props,monitor,component) {
    component.onDropCurrentTag(monitor.getItem());
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),

  };
}
class ActiveTagsNavigator extends React.Component{
  constructor(){
    super();
    this.onAddActiveTag=this.onAddActiveTag.bind(this);
    this.onRemoveTag=this.onRemoveTag.bind(this);
    this.onReplaceTag=this.onReplaceTag.bind(this);
    this.changeGetInfoAgain = this.changeGetInfoAgain.bind(this);
    this.state={
      topTags:[],
      searchQuery:'',
      page:1,
      has_next:true,
      getInfoAgain:false,
    }
  }


  changeGetInfoAgain=()=>{
    this.setState({getInfoAgain:!this.state.getInfoAgain})
  }
  onAddActiveTag = (tag)=>{
    if (Array.isArray(this.props.activeTags)){
      this.props.onChangeTags([...this.props.activeTags,tag]);
    }
    else {
      this.props.onChangeTags([this.props.activeTags,tag])
    }
    if (this.props.excludedTags.indexOf(tag)>-1){
      this.props.onChangeExcludedTags(this.props.excludedTags.filter(tagnames => tagnames!==tag));
    }
  }
  onDropCurrentTag = (tag)=>{
    this.onAddActiveTag(tag.name)
  }
  onRemoveTag=(tag,index)=>{
    let newArray = this.props.activeTags.concat();
    newArray.splice(index,1);
    this.props.onChangeTags(newArray);
  }
  onClearTags = ()=>{

      this.props.onChangeTags(null);

  }
  onReplaceTag=(tag,index)=>{

      let replacedTags= this.props.activeTags.concat();
      replacedTags[index]= tag;
      this.props.onChangeTags(replacedTags);
      this.props.onLastSelected(tag);
    }

  render()
  {
    let activeTagList = [];
    if (this.props.activeTags){
    activeTagList= this.props.activeTags.map(
      (entity,i)=>{
        return <ActiveTag key={i}
          tag={entity}
          index={i}
          onAddActiveTag={this.onAddActiveTag}
          onRemoveTag={this.onRemoveTag}
          onReplaceTag={this.onReplaceTag}
          onLastSelected={this.props.onLastSelected}
          getInfoAgain = {this.state.getInfoAgain}
          onGetPinnedTags = {this.props.onGetPinnedTags}
          />
      }
    );
    }

    const {connectDropTarget} = this.props;
    return connectDropTarget(
      <div className = "activeTags-container boxShadow">
            <div className= "sectionHeader noselect" id = "activeTags">
              <div className = "activeTagsHeader">

                  Active Tags
                <button className = "buttonClearTags" onClick = {this.onClearTags}>Clear Tags</button>
              </div>
            </div>
            <div className = "activeTagList-container">
              {activeTagList.length>0 ? activeTagList : <div className="helpText noselect activeTagsHelp">None Selected. Click or drag and drop tags.</div>}
            </div>
      </div>
  )
  }
}
export default DropTarget('tag', receiverTarget, collect)(ActiveTagsNavigator);
