import React from 'react';
import './TagAccess.css';
class SelectedTag extends React.Component{
  onRemoveTag = () =>{
    this.props.onRemoveTag(this.props.tag)
  }
  render(){
    let tag = this.props.tag
    return(
      <div className = "selectedTag-tagAccessContainer">
        <div>{tag}</div>
        <div onClick = {this.onRemoveTag} className = "tagAccess-removeTag">x</div>
      </div>
    )
  }
}
export default SelectedTag;
