import React from 'react';
import {Delete} from '../Icons/IconLibrary';

import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
/*
Delete button can take in type: contacts, documents, tags, templates. This specifies
the type for the delete call
*/
class DeleteUserButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showModal:false,
    }
  }
  onShowModal=()=>{
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false
  })}
  deleteUser=()=>{
    Request.authRequest(Routes.organization.deactivate,
                      'POST', JSON.stringify({email:this.props.user.email}),'application/json').then(
                        (response) => {
                          if(response.status === 200){
                            toast.success('Successfully deactivated the selected User')
                            this.setState({showModal:false})
                            this.props.getOrganization();
                      } else {
                        response.json().then((error) => {
                          toast.error(error.message);

                        });
                      }
                }).catch((error) => {
                  toast.error(error.message);
                }
              )
            }

  render(){

    return(
      <div style ={{display:'flex',alignItems:'center'}}>
        <button className='headerButton noselect' id = "buttonDelete" onClick = {this.onShowModal}>
          {this.props.text?<div style ={{color:'var(--fill-green)',display:'flex',alignItems:'center'}}>Delete</div>:<img className='buttonIcon'  src={Delete}
          alt='Delete currently selected items' title = 'Delete currently selected items' />}

        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Deactivate User Account' >
        <div className = "button-modalText">
          <p>
          You are about to deactivate {this.props.user.name} account. Once confirmed,
          please contact us at help@ivydocuments.com if this change was made in error.
           <br/>

        </p>
        <button className= 'modal-button primary' onClick={this.deleteUser}> Confirm Delete </button>
        </div>
      </Modal>
      </div>


    )
  }
}
export default DeleteUserButton;
