import React from 'react';
import TagNavigator from '../Tags/TagNavigator';
import Routes from '../../helpers/Routes';
import { withRouter } from 'react-router-dom';
import TagDropZone from './TagDropZone';
import Request from '../../helpers/Request';
import SendDocumentToButton from '../Buttons/SendDocumentToButton';
import TagButton from '../Buttons/TagButton';
import CreateTagButton from '../Buttons/CreateTagButton';
import './TagManager.css';
import qs from 'qs';
import { toast } from 'react-toastify';
import DocumentRecipeRow from '../Documents/DocumentRecipeRow';
import CookieWrapper from '../CookieWrapper/CookieWrapper';
import TagEditor from './TagRelationships/TagEditor';
import introJs from 'intro.js';
import Step3Tags from '../Layout/Help/Onboarding/Step3TagManagement.json';
class TagManager extends React.Component {
  constructor() {
    super();
    this.onLastSelected = this.onLastSelected.bind(this);
    this.onChangeTags = this.onChangeTags.bind(this);
    this.onGetNewTopTags = this.onGetNewTopTags.bind(this);
    this.onReplaceRename = this.onReplaceRename.bind(this);
    this.onGetTaggedDocuments = this.onGetTaggedDocuments.bind(this);
    this.replaceTagInfo = this.replaceTagInfo.bind(this);
    this.tagNavigatorRef = React.createRef();
    this.getTagInfo = this.getTagInfo.bind(this);
    this.onSingleDeleteTag = this.onSingleDeleteTag.bind(this);
    this.onClearSelected = this.onClearSelected.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.onDeleteMergedTag = this.onDeleteMergedTag.bind(this);
    this.completeTutorialReferral = this.completeTutorialReferral.bind(this);
    this.state = {
      topTags: [],
      lastSelected: '',
      tagInfo: '',
      docList: [],
      activeDocuments: [],
      logs: [],
    }
  }
  onClearTags = () => {
    this.searchStringGenerator('', 'clear');
    this.setState({ tagInfo: '' })
  }
  getTagInfo(tag) {
    let tag_name = encodeURIComponent(tag)
    Request.authRequest(Routes.tags.edit(tag_name),
      'GET').then((response) => {
        if (response.status === 200) {
          response.json().then(body => {
            this.setState({ tagInfo: body },
            )
          })
        }
        else {
          response.json().then((error) => {
            toast.error(error.message);

          });
        }
      }).catch((error) => {
        toast.error(error.message);
      }
      )
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      const searchString = qs.parse(this.props.location.search.substr(1));
      this.setState({ activeDocuments: [] })
      if (!Array.isArray(searchString.tag) && !searchString.tag) {

        this.setState({ tagInfo: '' })
      }
    }
  }
  componentDidMount() {
    window.onpopstate = () => this.onBackButtonEvent();
    const searchString = qs.parse(this.props.location.search.substr(1));
    if (searchString.tutorial) {
      this.startTutorial();
    }
  }
  onBackButtonEvent() {
    const searchString = qs.parse(this.props.location.search.substr(1));
    if (Array.isArray(searchString.tag)) {
      this.onLastSelected(searchString.tag[searchString.tag.length - 1])
    }
    else if (searchString.tag) {
      let splitTags = searchString.tag.split(',');
      this.onLastSelected(splitTags[splitTags.length - 1])

      // if (Array.isArray(searchString.tag)){
      //   this.onLastSelected(searchString.tag[searchString.tag.length-1])}
      // else if (searchString.tag){
      //   this.onLastSelected(searchString.tag)
    }
  }
  onGetNewTopTags = () => {
    this.tagNavigatorRef.current.onGetTags();
  }
  startTutorial = () => {
    let intro;
    intro = introJs().setOptions({ steps: Step3Tags, doneLabel: "Done", showStepNumbers: false }).start().onchange(function () {
      intro._introItems[2].element = document.querySelector('.tagUtilityButtons');
      intro._introItems[2].position = "bottom";
      intro._introItems[3].element = document.querySelector('#TagRelationshipManager');
      intro._introItems[3].position = "bottom";
      intro._introItems[4].element = document.querySelector('#TagRelationshipManager');
      intro._introItems[4].position = "bottom";
    }).onexit(
      this.completeTutorialReferral
    )
  }
  completeTutorialReferral = () => {
    return Request.authRequest(Routes.payments.referrals,
      'PUT').then((response) => {
        if (response.status === 200) {
          response.json().then(body => {
            toast.success('Successfully completed your referral. You have gained bonus free tags and storage space')
          })
        }
      }).catch((error) => {
        toast.error(error.message);
      }
      )
  }
  onChangeTags = (tags) => {

    if (!tags || tags.length === 0) {

      this.onClearTags();
      return
    }
    if (Array.isArray(tags)) {
      this.onLastSelected(tags[tags.length - 1])
    }
    else if (tags) {
      let splitTags = tags.split(',');
      this.onLastSelected(splitTags[splitTags.length - 1])
    }
    //this.onLastSelected(tags)


    this.searchStringGenerator(tags, 'tags');
  }
  replaceTagInfo = (tag) => {
    this.setState({ tagInfo: tag })
  }

  async onLastSelected(tag) {
    await this.getTagInfo(tag);
    await this.onGetTaggedDocuments(tag);
  }
  searchStringGenerator = (input, type) => {
    const searchString = qs.parse(this.props.location.search.substr(1));

    switch (type) {
      case 'clear': {
        delete searchString.tag;
        break;
      }
      case 'tags':
        if (input) {
          searchString.tag = input.join()
        }
        else {
          delete searchString.tag
        }
        break;
      default:
    }

    let base_url = qs.stringify(searchString, { indices: false });

    return new Promise((resolve, reject) => {
      resolve(this.props.history.push({
        search: base_url,
        state: this.state,
      })
      )
    })
  }

  onSingleDeleteTag = (tag) => {
    const searchString = qs.parse(this.props.location.search.substr(1));
    let newActiveTags;
    newActiveTags = searchString.tag.split(',')
    if (Array.isArray(newActiveTags)) {

      newActiveTags = newActiveTags.filter((tags) => tags !== tag);
    }
    this.searchStringGenerator(newActiveTags, 'tags');
    this.setState({ tagInfo: '' })
    this.onGetNewTopTags();
    this.onGetPinnedTags();
  }
  onDeleteMergedTag = (tag) => {
    const searchString = qs.parse(this.props.location.search.substr(1));
    let newActiveTags;
    newActiveTags = searchString.tag.split(',')
    if (Array.isArray(newActiveTags)) {

      newActiveTags = newActiveTags.filter((tags) => tags !== tag);
    }
    this.searchStringGenerator(newActiveTags, 'tags');

  }

  onGetTaggedDocuments(tag) {

    let base_url = `?per_page=100&tag=${tag}`;

    Request.authRequest(Routes.recipes.index(base_url),
      'GET').then((response) => {
        if (response.status === 200) {
          response.json().then(body => {
            this.setState({ docList: body.results })
          })
        }
        else {
          response.json().then((error) => {
            toast.error(error.message);

          });
        }
      }).catch((error) => {
        toast.error(error.message);
      }
      )
  }


  onReplaceRename = (oldTagName, newTagName) => {
    const searchString = qs.parse(this.props.location.search.substr(1));
    let activeTags = [];
    if (searchString.tag) {
      activeTags = searchString.tag.split(','); //comment out the split
      if (!Array.isArray(activeTags)) {
        activeTags = [activeTags]
      }
    }
    let tags = activeTags.concat();
    let result = tags.filter((tag) => tag !== oldTagName);

    if (result.length < tags.length) {
      this.searchStringGenerator([...result, newTagName], 'tags')
    }
    this.onGetNewTopTags();
  }
  onClearSelected() {
    this.setState({ activeDocuments: [] })
  }
  onSelectAll() {
    this.onClearSelected();
    this.setState({ activeDocuments: this.state.docList })
  }
  onSelectDocument = (doc) => {
    this.setState(prevState => {
      let activeDocuments = prevState.activeDocuments;

      let activeDocumentsIds = (activeDocuments.map((entity) => { return entity.recipe_id }));
      if (
        activeDocumentsIds.indexOf(doc.recipe_id) > -1
      ) {
        activeDocuments = activeDocuments.filter(docs => docs.recipe_id !== doc.recipe_id);
      }
      else {
        activeDocuments = [...activeDocuments, doc];
      }
      return { activeDocuments: activeDocuments };
    }
    );
  }


  generateDocumentList() {
    let documentRowList;
    documentRowList = this.state.docList.map((entity, i) => {
      return <DocumentRecipeRow document={entity} key={i} onSelectDoc={this.onSelectDocument}
        activeDocuments={this.state.activeDocuments}
        onGetDocuments={this.onGetTaggedDocuments} />
    })
    if (documentRowList.length < 1) {
      return <div className="helpText">No Documents</div>
    }
    return documentRowList
  }
  onGetPinnedTags = () => {
    this.tagNavigatorRef.current.onGetPinnedTags();
  }
  onChangeExcludedTags = () => {
    return
  }
  render() {
    let tag = this.state.tagInfo;

    const documentRowList = this.generateDocumentList();
    let activeDocuments = this.state.activeDocuments;

    const searchString = qs.parse(this.props.location.search.substr(1));
    let activeTags = [];
    if (searchString.tag) {
      activeTags = searchString.tag.split(','); //comment out the split
      if (!Array.isArray(activeTags)) {
        activeTags = [activeTags]
      }
    }
    let lastSelectedText;
    if (this.state.tagInfo) {
      lastSelectedText = `tagged with ${this.state.tagInfo.name}`;
    };
    return (

      <div className="columns">
        <div className="tags-left boxShadow">
          <div className="tags-header-text">Tags Management
            <CreateTagButton onGetTags={this.onGetNewTopTags} />
          </div>

          <div className="tags-left-tagsContainer">
            <div className="tags-left-navigator">

              <TagNavigator onLastSelected={this.onLastSelected}
                onChangeTags={this.onChangeTags}
                activeTags={activeTags}
                excludedTags={[]}
                onChangeExcludedTags={this.onChangeExcludedTags}
                ref={this.tagNavigatorRef} />

            </div>
            <div className="tags-left-manipulator">
              {this.state.tagInfo ?

                <TagEditor currentTag={this.state.tagInfo}
                  getTag={this.onLastSelected}
                  onDeleteTag={this.onSingleDeleteTag}
                  onReplaceRename={this.onReplaceRename}
                  onGetNewTopTags={this.onGetNewTopTags}
                  onDeleteMergedTag={this.onDeleteMergedTag}
                  onGetPinnedTags={this.onGetPinnedTags} />
                : <TagDropZone sendTags={this.onLastSelected} />}
            </div>
          </div>
        </div>
        <div className="tags-right">

          <div className="tags-middle-documents boxShadow">
            <div className="tags-middle-header-text">
              Recipes {this.state.tagInfo ? lastSelectedText : null}
            </div>
            <div className="selectedDocuments">
              <div>
                {activeDocuments.length > 0 ?
                  <div className="selectedDocText">
                    {activeDocuments.length} Documents Selected
                    <button className="clearSelectedButton"
                      onClick={this.onClearSelected}>Clear All</button>
                  </div>
                  :
                  <div className="selectedDocText-tags"
                    onClick={this.onSelectAll}>
                    Select All
                  </div>
                }
              </div>
              <TagButton docs={activeDocuments}
                onGetDocuments={this.onGetTaggedDocuments}
                tagName={tag.name}
                onGetTags={this.onGetNewTopTags} />

            </div>
            <div className="tags-middle-docs-list">
              <div className="tags-docs-table">
                <div className="tags-docs-tableheader-name">Document Name
                </div>
                <div className="tags-docs-tableheader-expires">Ingredients</div>
                <div className="tags-docs-tableheader-tags">Tags</div>
              </div>
              {documentRowList}
            </div>

          </div>
        </div>


      </div>
    )
  }
}
export default withRouter(CookieWrapper(TagManager));
