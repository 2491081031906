import React from 'react';

class EditRoles extends React.Component{
  constructor(props){
    super(props);
    this.state={
      editing:false,
      role:props.role,
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.content!== this.props.content){
      this.setState({roles:this.props.content});
    }
  }
  onSaveChanges(){
    if (this.props.role !== this.state.role){
    this.props.onClickOut(this.props.index,this.state.role);}
  }
  handleDblClick = (e) =>{
    if (this.node.contains(e.target)){
      this.setState({
        editing:true
      })
    }
    else if(this.state.editing!==false){
      this.onSaveChanges();
      this.setState({editing:false})
    };
  }
  componentWillMount(){
    document.addEventListener('dblclick',this.handleDblClick,false);
  }
  componentWillUnmount(){
    document.removeEventListener('dblclick',this.handleDblClick,false);
  }

  onChangeRole = (e) => {
		this.setState({
			role: e.target.value,
		});
	}

  render(){

    return (

      <div className='doc-info-field' id = "edit-role" ref = {node => this.node = node}>
        <label className='document-label' >{this.props.contentType} </label>
        {this.state.editing?

          <select value={this.state.role}
            onChange= {this.onChangeRole}>
            <option value ="Super Admin">Super Admin</option>
            <option value = "Admin">Admin</option>
            <option value = "User">User</option>
            <option value = "None">None</option>
          </select>:

            <div className = 'document-info' id="edit-role">{this.props.role}
            </div>
          }
    </div>)

  }
}
export default EditRoles;
