import React from 'react';
import {Upload} from '../Icons/IconLibrary';
import './Buttons.css';
import Routes from '../../helpers/Routes';
import Dropzone from 'react-dropzone';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import './ReUploadButton.css';
import LoadingButton from './LoadingButton';
import SetSignatures from '../Documents/Signatures/SetSignatures';
import SelectedUserComponent from '../Documents/Signatures/SelectedUserComponent';
class ReUploadButton extends React.Component{
  constructor(){
    super();
    this.onGetPresignedURL = this.onGetPresignedURL.bind(this);
    this.state={
      showModal:false,
      file:'',
      organizations:[],
      requiresSignatures:false,
      selectedUsers:[],
      urgent:false,
      managerStatus:false,
      selectedManagerUsers:[],
      loading:false,
    }
  }
  onSelectFromIvy = () =>{
    window.location.href = window.location.href+'/version_history';
  }
  onShowModal =()=>{
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false,selectFromIvy:false
  })}
  requestSignatures= (doc_id)=>{
    let emails = this.state.selectedUsers.map((user,i)=>{
      return user.email
    })
    const params = {user_emails:emails,urgent:this.state.urgent,
        manager_status:this.state.managerStatus};
    if (this.state.selectedUsers.length === 0){
      this.requestManagerSignatures(doc_id);
      return
    }
    Request.authRequest(Routes.documents.signature(doc_id),
      'POST', JSON.stringify(params),'application/json').then((response)=>{
    if (response.status===200){
    response.json().then(body=>{
      this.setState({ file: null, selectedUsers:[],requiresSignatures:false,
        urgent:false,manager_status:false});
        if (this.state.selectedManagerUsers.length>0){
          this.requestManagerSignatures(doc_id);
          return
        } else {
          toast.update(this.toastID, { render:
            `Successfully updated document: ${this.props.doc.name}`,type: toast.TYPE.SUCCESS, autoClose: 5000 });

          this.onHideModal();
          window.location.href = `/documents/${body.document.id}`;
          return
        }

        })
      }
      else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }
  requestManagerSignatures= (doc_id)=>{
    let emails = this.state.selectedManagerUsers.map((user,i)=>{
      return user.email
    })
    const params = {user_emails:emails,urgent:this.state.urgent,
        manager_status:true};
    Request.authRequest(Routes.documents.signature(doc_id),
      'POST', JSON.stringify(params),'application/json').then((response)=>{
    if (response.status===200){
    response.json().then(body=>{
      this.setState({ file: null, selectedUsers:[],requiresSignatures:false,
        urgent:false});
        toast.update(this.toastID, { render:
          `Successfully updated document: ${this.props.doc.name}`,type: toast.TYPE.SUCCESS, autoClose: 5000 });

        this.onHideModal();
       window.location.href = `/documents/${body.document.id}`;
        })
      }
      else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }

  onUploadDocument = (acceptedFiles) => {
		this.setState({
			file: acceptedFiles[0]
		});
	}
  onGetPresignedURL = (tags) =>{
    if (this.state.requiresSignatures&&this.state.selectedUsers.length===0&&
      this.state.selectedManagerUsers.length===0){
      toast.error('Please select required signatures, or unselect approval requirements.');
      return
    }

    if (this.state.file === ''){
      toast.error('Please select a file.');
      return
    }
    this.toastID = toast.info('Uploading documents...',{autoClose:false,progress:0});
    this.setState({showModal:false, loading:true})
		Request.authRequest(Routes.s3.presignedURL, 'GET').then((response) => {

				response.json().then(body => {
					Request.awsRequest(body.signed_url, 'PUT',this.state.file).then((response) => {
						if (response.ok){
						this.onAttemptReupload(body.document_id,this.state.file);}
						else{
							toast.error(`Sorry, we can't upload your file right now. Please email help@ivydocuments.com.`)
						}
					})

			})
		})
  }
  onAttemptReupload = (documentID,file) => {

    const fileData = {
      names_to_update:'',
      document_id:documentID,
      user_defined_id:this.props.doc.user_defined_id,
      date_expiration: this.props.doc.date_expiration,
      notes : this.props.doc.notes,
      name : file.name,
      archive:true
    }
    if (this.state.requiresSignatures){
      fileData.archive=false;
    }
		Request.authRequest(Routes.documents.update(this.props.doc.document.id),
											  'POST', JSON.stringify(fileData), 'application/json').then((response) => {
                          if (response.status===200){
			response.json().then(body => {
        if (this.state.requiresSignatures){
          this.requestSignatures(body.document.id);
        } else {
				this.setState({ file: null,loading:false});
        toast.update(this.toastID, { render:
          `Successfully updated document: ${this.props.doc.name}`,type: toast.TYPE.SUCCESS, autoClose: 5000 });

				this.onHideModal();
        window.location.href = `/documents/${body.document.id}`;
      }
      });
          } else {
            response.json().then((error) => {
              toast.update(this.toastID, { render:
                `There was a problem with updating ${this.props.doc.name}. Please refresh the page and try again.`,type: toast.TYPE.ERROR, autoClose: 5000 });
                if (error.errors){

                  toast.error(error.errors[0]);
                }
            });
          }
      }).catch((error) => {
      toast.error(error.message);
      }
      )

	}
  onSetSignatures = (users)=>{
    this.setState({selectedUsers:users})
  }
  onSetManagerSignatures = (users)=>{
    this.setState({selectedManagerUsers:users})
  }
  onChangeRequiresSignatures = (e) => {
    let toggle = !this.state.requiresSignatures;
		this.setState({
			requiresSignatures: toggle,
		});
	}
  onRemoveUser = (user) =>{
      this.setState({selectedUsers:this.state.selectedUsers.filter(users => users !== user)})
    }
  onRemoveManagerUser = (user) =>{
        this.setState({selectedUsers:this.state.selectedManagerUsers.filter(users => users !== user)})
      }
  toggleUrgent = () =>{
      this.setState({urgent:!this.state.urgent})
    }

  render(){
    const fileUploadText = this.state.file ?
												   `${this.state.file.name} - ${this.state.file.size/1000} KB` :
												   'Drag and drop a document, or click to select from your computer.';
    let signatureList;
    let noSignatureMessage = "None selected";
    if (this.state.selectedUsers.length>0){
      signatureList = this.state.selectedUsers.map((user,i)=>{
        return <SelectedUserComponent user = {user} key = {i} onRemoveUser = {this.onRemoveUser}/>
      })
    }
    let managerSignatureList = "";
    if (this.state.selectedManagerUsers.length>0){
      managerSignatureList = this.state.selectedManagerUsers.map((user,i)=>{
        return <SelectedUserComponent user = {user} key = {i} onRemoveUser = {this.onRemoveUser} manager/>
      })
    }
    if (signatureList || managerSignatureList){
      noSignatureMessage="";
    }
    return (
      <div id = {this.props.versionhistory?"buttonVersionHistoryReUpload":"buttonReUpload"}>
        <button className='headerButton noselect' id = {this.props.versionhistory?"buttonVersionHistoryReUpload":"buttonReUpload"} onClick = {this.onShowModal}>
          {this.props.versionhistory?
          <img className='document-upload-icon' src={Upload} alt='Upload a new version of this document' title = 'Upload a new version of this document'/>:
            <img className='document-upload-icon' src={Upload} alt='Upload a new version of this document' title = 'Upload a new version of this document'/>}

        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Upload a New Version'>
          <div style = {{display:'flex',flexDirection:'column',alignItems:'center'}}>
          <div>Upload a new version for this document. The document will have the tags as the old document.</div>
            <div className='file-dropzone-container' >
              <Dropzone className='reUpload-file-dropzone' id = "ReUploadButton" multiple={false} onDrop={this.onUploadDocument}>
                <span className='file-dropzone-text' id = "ReUploadButton"> {fileUploadText}
                </span>
              </Dropzone>
            </div>
            <div>or</div>
          <div className = "SelectFromExistingButton" onClick = {this.onSelectFromIvy}>  Select from Existing Documents
          </div>
          <div>
            <input
              checked = {this.state.requiresSignatures}
              name = 'signatureBoolean'
              type='checkbox'
              id = 'signatureBoolean'
              onChange={this.onChangeRequiresSignatures}/>
            <label htmlFor ='signatureBoolean'> This document requires approval signatures</label>
          </div>
          {this.state.requiresSignatures?
            <div className = "reUpload-requiresSignatures-container">
              <div className = "remindConfirmationCheckbox">
                <input
                  checked = {this.state.urgent}
                  name = 'expiresBoolean'
                  type='checkbox'
                  id = 'expiresBoolean'
                  onChange = {this.toggleUrgent}/>
                <div>Mark as Urgent
                </div>
              </div>
              <SetSignatures onSendSignatures = {this.onSetSignatures} onSendManagerSignatures = {this.onSetManagerSignatures}/>
              <div className = "selectedSignatureList">
                <div>Selected Signatures
                </div>
                {noSignatureMessage}
                {signatureList}
                {managerSignatureList}
              </div>
            </div>
            :null}
            <button className= 'modal-button primary' > 
            <LoadingButton onClick={this.onGetPresignedURL}
             text = 'Update' isLoading = {this.state.loading}/>
          </button>
          </div>
        </Modal>
      </div>


    )
  }
}
export default ReUploadButton;
