import React from 'react';

class Logout extends React.Component {

	componentDidMount() {
		localStorage.setItem('token', '');
		localStorage.setItem('loggedIn', '');
		document.cookie = 'ivy_authed=; Domain=.ivydocuments.com; path=/;expires = Thu, 01 Jan 1970 00:00:00 GMT'
		window.location.href = '/';
	}

	render() {
		return null;
	}
}

export default Logout;
