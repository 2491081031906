import React from 'react';
import { DropTarget } from 'react-dnd';
const receiverTarget = {
  drop(props,monitor,component) {
    component.onDropCurrentTag(monitor.getItem());
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),

  };
}
class CurrentTagInput extends React.Component{
  onDropCurrentTag(tag){
    this.props.changeTag(tag.name);
  }
  render(){
    const {connectDropTarget} = this.props;
    let name;
    if (this.props.currentTag){
      name =this.props.currentTag;
    }
    return connectDropTarget(
      <div>
      <div className = "tag-ancestry-label">Currently Selected</div>
      <div>{name}</div>
    </div>
    )
  }
}

export default DropTarget('tag', receiverTarget, collect)(CurrentTagInput)
