import React from 'react';
import introJs from 'intro.js';
import Step1Home from './Step1Home.json';
import './Tutorial.css';
class TutorialButton extends React.Component{
  onClickTutorial = () =>{
    introJs().exit();
    let intro = introJs().setOptions({steps:Step1Home, doneLabel: 'Next Page', showStepNumbers:false}).start().oncomplete(function() {
      window.location.href = 'http://ivydocuments.com/upload/?tutorial=true';
  });}
  render(){
    return(
      <div onClick = {this.onClickTutorial} className = "tutorialButton boxShadow"> Tutorial </div>
    )
  }
}

export default TutorialButton
