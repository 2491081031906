import React from 'react';
import './ToggleSwitch.css';
class ToggleSwitch extends React.Component {
  onClick = () => {

    this.props.toggleSwitch();

  }
  render() {
    return (
      <div className="toggleSwitchContainer" onClick={this.props.toggleSwitch}
        id={this.props.active ? "activeSwitch" : "inactiveSwitch"}>
        {this.props.active ? <div className="activeSwitch boxShadow" /> :
          <div className="inactiveSwitch boxShadow" />}
      </div>
    )
  }
}

export default ToggleSwitch;
