import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import './TagAccess.css';
class ToggleFullTagAccessButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
      tagAccess:this.props.user.full_tag_access,
    }
  }
  changeFullAccess = ()=>{
    //This also causes a bunch of redundant get org calls.
    let user = this.props.user;
      Request.authRequest(Routes.tags.fullAccess(user.email),
                          'PUT',JSON.stringify({full_tag_access:this.state.tagAccess}),
                          'application/json').then(
                            (response) => {
                              if(response.ok){
                                response.json().then((body) => {
                                  this.props.onChangeTagAccess();
                          }
                        )} else {
                            response.json().then((error) => {
                              toast.error(error.message);
                              if (error.errors){
                                toast.error(error.errors[0])
                              }
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
                }

  toggleAccess = ()=>{
    this.setState({tagAccess:!this.state.tagAccess},()=>this.changeFullAccess());
  }
  render(){
    return(

      <div className = "ToggleFullTagAccessButton" onClick = {this.toggleAccess}>
        <input checked ={this.state.tagAccess}
          type = "checkbox"/>
          <div>Grant Access to All Tags</div>
        </div>

    )
  }
}
export default ToggleFullTagAccessButton;
