import React from 'react';
import './InteractiveDemo.css';
class DemoDocumentObject extends React.Component{
  render(){

    return(
      <img src = {this.props.icon} className = {this.props.isOpaque?"opaqueDemoObject demoDoc":"transparentDemoObject demoDoc"}/>
    )
  }
}

export default DemoDocumentObject;
