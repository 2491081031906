import React from 'react';
import './ReleaseCriteria.css';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
class AddReleaseCriteriaButton extends React.Component{
    constructor(props){
        super();
        this.state = {
            show:false,
            name:'',
            min:0,
            target:0,
            max:0,
            error:''
        }
    }
    toggleShow = () =>{
        this.setState({show:!this.state.show})
    }
    onChangeName = (e)=>{
        this.setState({name:e.target.value, error:''})
    }
    onChangeMin = (e)=>{
        this.setState({min:e.target.value, error:''})
    }
    onChangeMax = (e)=>{
        this.setState({max:e.target.value, error:''})
    }
    onChangeTarget = (e)=>{
        this.setState({target:e.target.value, error:''})
    }
    onAddReleaseCriteria = () =>{
        let target= this.state.target;
        if (!target){
            target = null;
        }
        Request.authRequest(Routes.recipes.addReleaseCriteria(this.props.recipe.id),
                        'POST',JSON.stringify({
                          name: this.state.name.trim(),
                          min_tolerance: this.state.min,
                          max_tolerance: this.state.max,
                          target:  target
                        }), 'application/json'
                      ).then((response) => {
                        if (response.status===200){
                        response.json().then(body => {
                            this.props.onGetRecipe();
							this.props.onGetLogs();
                            toast.success('Successfully added release criteria!')
                          });
                        } else {
                          response.json().then((error) => {
                              console.log(error);
                            toast.error(error.message);
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
    }
    render(){
        let expanded;
        
        if (!this.state.show){
        return(
            <div>
                <div className = "recipe-add-ingredient" onClick = {this.toggleShow}>+ Release Criteria</div>
                
            </div>
        )}
        return(
            <div className = "addReleaseCriteria-container">
                <div>
                    <div>Name</div>
                    <input onChange = {this.onChangeName} value = {this.state.name}/>
                </div>
                <div>
                    <div>Min</div>
                    <input onChange = {this.onChangeMin} value = {this.state.min}/>
                </div>
                <div>
                    <div>Target</div>
                    <input onChange = {this.onChangeTarget} value = {this.state.target}/>
                </div>
                <div>
                    <div>Max</div>
                    <input onChange = {this.onChangeMax} value = {this.state.max}/>
                </div>
                <div className = "confirm-cancel-button-container">
                <div className = "recipe-edit-button-confirm"onClick = {this.onAddReleaseCriteria}>Add</div>
                <div className = "recipe-edit-button-cancel" onClick = {this.toggleShow}>Hide</div>
                </div>
                {this.state.error}
                
            </div>
        )
    }
}

export default AddReleaseCriteriaButton