import React from 'react';
import {CardElement, injectStripe, AddressElement} from 'react-stripe-elements';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import './Stripe.css';
import StripeInputElement from './StripeInputElement';
class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state={
      name:'',
      address:'',
      city:'',
      state:''}
    this.sendInfo = this.sendInfo.bind(this);
  }

  async submit(ev) {
  let {token} = await this.props.stripe.createToken({type:'card',
    name: this.state.name,
    address_line1:this.state.address,
    address_city:this.state.city,
    address_state:this.state.state});
  if(!token){
    toast.error('Error with your card. Please check the information and try again.');
    return
  }
  Request.authRequest(Routes.payments.edit,
  'POST', JSON.stringify({token: token.id, }), 'application/json').then((response) => {
    if (response.status ===200)
    {
      response.json().then(body => {
        toast.success("Payment Successful. Your information has been saved.")})
        this.props.onSaveCard();
    } else {
      response.json().then((error) => {
        toast.error(error.message);
        toast.error(error.errors[0]);

        });
    }
  }).catch((error) => {
    toast.error(error.message);
    }
  )
  }
  sendInfo = (content,contentType)=>{
    switch (contentType) {
      case "Name":
        this.setState({name:content})
        break;
      case "Address":
        this.setState({address:content})
        break;
      case "City":
        this.setState({city:content})
        break;
      case "State":
        this.setState({state:content})
        break;
      default:
        return
    }
  }
  render() {
    return (
      <div className="checkout">
        <p className = "stripeCheckout-helpText">Enter your card information.
          You will be charged $40 for the base license
          per month for a single user. For two or more users, the base price is $80 including two users, plus $30 per additional user. By entering your information,
          you consent to be charged monthly until you cancel your subscription.
           </p>

        <div className = "stripeCheckout-address">
          <StripeInputElement contentType = {"Name"} sendInfo = {this.sendInfo}/>
          <StripeInputElement contentType = {"Address"} sendInfo = {this.sendInfo}/>
          <div className = "stripeCheckout-cityState">
            <StripeInputElement contentType = {"City"} sendInfo = {this.sendInfo}/>
            <StripeInputElement contentType = {"State"} sendInfo = {this.sendInfo}/>
          </div>
        </div>
           <div className = "stripeCheckout-cardElement">

        <CardElement />
        </div>
        <button className = "addPaymentButton-submit" onClick={this.submit}>Send</button>
        <button className = "addPaymentButton-cancel" onClick = {this.props.onCancel}>Cancel</button>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
