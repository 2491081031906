class Routes {
  static get BASE_URL() {
    const hostname = window && window.location && window.location.hostname;
    if (hostname === 'recipes.ivydocuments.com') {
      return 'https://api.ivydocuments.com'
    } else if (hostname === 'recipes.staging.ivydocuments.com') {
      return 'https://api.staging.ivydocuments.com'
    }
    return 'https://apiv1.localhost.ivydocuments.com'
  }

  static get profile() {
    return {
      view: `${this.BASE_URL}/profile`,

    }
  }

  static get auth() {
    return {
      signup: `${this.BASE_URL}/signup`,
      login: `${this.BASE_URL}/get-token`,
      passwordreset: `${this.BASE_URL}/reset-password`,
      resendconfirm: `${this.BASE_URL}/confirm`,
      confirm: (token) => `${this.BASE_URL}/confirm?token=${token}`,
      reset: (token) => `${this.BASE_URL}/reset-password?token=${token}`,
      confirmCode: `${this.BASE_URL}/confirm-with-code`,
      logout: `${this.BASE_URL}/logout`
    }
  }
  static get tags() {
    return {
      list: `${this.BASE_URL}/tags`,
      search: (query) => `${this.BASE_URL}/tags${query}`,
      edit: (name) => `${this.BASE_URL}/tags/${name}`,
      tag: (name) => `${this.BASE_URL}/tags/${name}/tag`,
      pin_tag: `${this.BASE_URL}/tags/pin_tag`,
      merge: (tag_merge_into, tag_to_merge) => `${this.BASE_URL}/tags/merge/${tag_merge_into}/${tag_to_merge}`,
      access: (name) => `${this.BASE_URL}/tags/${name}/access`,
      fullAccess: (email) => `${this.BASE_URL}/tags/${email}/full_access`
    }
  }
  static get documents() {
    return {
      create: `${this.BASE_URL}/documents`,
      edit: (id) => `${this.BASE_URL}/documents/${id}`,
      search: (query) => `${this.BASE_URL}/documents${query}`,
      update: (id) => `${this.BASE_URL}/documents/${id}/update`,
      tag: (id) => `${this.BASE_URL}/documents/${id}/tag`,
      share: `${this.BASE_URL}/share-documents`,
      versionHistory: (newDoc) => `${this.BASE_URL}/documents/${newDoc}/version-history`,
      signature: (id) => `${this.BASE_URL}/documents/${id}/sign`,
      sign: (id) => `${this.BASE_URL}/signature/${id}`,
      signatureList: `${this.BASE_URL}/signature`,
      allSignatures: `${this.BASE_URL}/all-signature`,
      versionReport: (id) => `${this.BASE_URL}/documents/${id}/version_report`,
      signatureRemind: `${this.BASE_URL}/signature/remind`,
      approval: (id) => `${this.BASE_URL}/documents/approval/${id}`,
      documentRemind: (id) => `${this.BASE_URL}/documents/remind/${id}`,
      markOriginalSender: (id) => `${this.BASE_URL}/documents/original-owner/${id}`
    }
  }
  static get reports() {
    return {
      dashboard: `${this.BASE_URL}/dashboard`,
      recentlyShared: (query) => `${this.BASE_URL}/documents/recently-shared-report${query}`,
      recentlySigned: (query) => `${this.BASE_URL}/documents/recently-signed-report${query}`
    }
  }
  static get payments() {
    return {
      edit: `${this.BASE_URL}/payments`,
      referrals: `${this.BASE_URL}/referrals`
    }
  }
  static get templateGroups() {
    return {
      groups: `${this.BASE_URL}/template-groups`,
      edit: (id) => `${this.BASE_URL}/template-groups/${id}`,
      tag: (id) => `${this.BASE_URL}/template-groups/${id}/tag`,
      sendTemplate: (id) => `${this.BASE_URL}/template-groups/${id}/send`
    }
  }
  static get templates() {
    return {
      templates: `${this.BASE_URL}/templates`,
      edit: (id) => `${this.BASE_URL}/templates/${id}`,
      tag: (id) => `${this.BASE_URL}/templates/${id}/tag`,
    }
  }
  static get questions() {
    return {
      questions: (template_id) => `${this.BASE_URL}/requests/${template_id}/question`,
      group_questions: (template_id) => `${this.BASE_URL}/requests/${template_id}/group_question`,
      edit: (question_id) => `${this.BASE_URL}/requests/questions/${question_id}`,
      groupEdit: (question_id) => `${this.BASE_URL}/requests/group_questions/${question_id}`
    }
  }
  static get answers() {
    return {
      edit: (answer_id) => `${this.BASE_URL}/requests/answers/${answer_id}`,
      tokenEdit: (answer_id, token) => `${this.BASE_URL}/requests/answers/${answer_id}?token=${token}`,
      groupEdit: (answer_id) => `${this.BASE_URL}/requests/group_answers/${answer_id}`,
      tokenGroupEdit: (answer_id, token) => `${this.BASE_URL}/requests/group_answers/${answer_id}?token=${token}`

    }
  }
  static get requests() {
    return {
      requests: `${this.BASE_URL}/request-groups`,
      group: (id) => `${this.BASE_URL}/request-groups/${id}`,
      add: (id) => `${this.BASE_URL}/requests/${id}`,
      remind: `${this.BASE_URL}/request-reminders`,
      sentList: (query) => `${this.BASE_URL}/requests/sent_requests${query}`,
      addExisting: (id) => `${this.BASE_URL}/requests/owner/${id}`,
    }
  }
  static get tokenRequests() {
    return {
      group: (id, token) => `${this.BASE_URL}/request-groups/${id}?token=${token}`,
      add: (id, token) => `${this.BASE_URL}/requests/${id}?token=${token}`,
      s3: (token) => `${this.BASE_URL}/document-upload?token=${token}`,
      upload: (token) => `${this.BASE_URL}/documents?token=${token}`,
      user: (token) => `${this.BASE_URL}/profile?token=${token}`,

    }
  }

  static get organization() {
    return {
      join: `${this.BASE_URL}/organization/join`,
      create: `${this.BASE_URL}/organization`,
      index: `${this.BASE_URL}/organization`,
      invite: `${this.BASE_URL}/organization/invite`,
      info: (params) => `${this.BASE_URL}/organization/info${params}`,
      deactivate: `${this.BASE_URL}/organization/deactivate`,
      recipeRole: `${this.BASE_URL}/organization/recipe_role`,
      recipeSettings: `${this.BASE_URL}/organization/recipe_settings`
    }
  }

  static get administrator() {
    // tools for the superadmins of an organization to manage other users
    return {
      convertedRestriction: (email) => `${this.BASE_URL}/converted-restriction/${email}`
    }
  }

  static get logs() {
    return {
      index: (query) => `${this.BASE_URL}/logs${query}`,
      document: (id) => `${this.BASE_URL}/logs/document/${id}`,
      template: (id) => `${this.BASE_URL}/logs/template/${id}`,
      tag: (name) => `${this.BASE_URL}/logs/tag/${name}`,
      user: (email) => `${this.BASE_URL}/logs/user/${email}`,
      organization: `${this.BASE_URL}/logs/organization/`,
      log: (id) => `${this.BASE_URL}/logs/${id}`,
      ingredients: (id) => `${this.BASE_URL}/logs/ingredients/${id}`,
      recipeAppList: (query) => `${this.BASE_URL}/logs/recipe_ingredient_tags${query}`,
      billOfMaterials: (id) => `${this.BASE_URL}/logs/recipe_bom/${id}`,
    }
  }
  static get notifications() {
    return {
      index: (query) => `${this.BASE_URL}/notifications${query}`,
      unread: `${this.BASE_URL}/notifications/unread`,
      read: `${this.BASE_URL}/notifications`,
      settings: `${this.BASE_URL}/profile/settings`
    }
  }
  static get contacts() {
    return {
      create: `${this.BASE_URL}/contacts`,
      index: (query) => `${this.BASE_URL}/contacts${query}`,
      suggest: (query) => `${this.BASE_URL}/contacts?q=${query}&per_page=5`,
      edit: (id) => `${this.BASE_URL}/contacts/${id}`,
      import: `${this.BASE_URL}/import-contacts`,
    }
  }
  static get recipes() {
    return {
      index: (query) => `${this.BASE_URL}/recipes${query}`,
      edit: (id) => `${this.BASE_URL}/recipes/${id}`,
      addIngredient: (id) => `${this.BASE_URL}/recipes/${id}/ingredients`,
      relatedTags: (id) => `${this.BASE_URL}/recipes/${id}/related_tag`,
      changeIngredient: (recipe_id, ingredient_id) => `${this.BASE_URL}/recipes/${recipe_id}/ingredients/${ingredient_id}`,
      sendBatchSheet: (recipe_id) => `${this.BASE_URL}/recipes/${recipe_id}/batch_sheet_send`,
      addReleaseCriteria: (recipe_id) => `${this.BASE_URL}/recipes/${recipe_id}/release_criteria`,
      batchSheetList: (query) => `${this.BASE_URL}/batch_sheet${query}`,
      batchSheet: (batch_id) => `${this.BASE_URL}/batch_sheet/${batch_id}`,
      releaseCriteria: (release_criteria_id) => `${this.BASE_URL}/release_criteria/${release_criteria_id}`,
      fork: (id) => `${this.BASE_URL}/recipes/${id}/fork`,
      version: (id) => `${this.BASE_URL}/recipes/${id}/version`,
      signatureList: `${this.BASE_URL}/recipes/signature`,
      allSignatures: `${this.BASE_URL}/recipes/all-signature`,
      batchSheetPendingList: `${this.BASE_URL}/batch_sheet/pending/`,
      batchSheetApproval: (batch_id) => `${this.BASE_URL}/batch_sheet/approval/${batch_id}`,
      previewBatchSheet: (recipe_id) => `${this.BASE_URL}/recipes/${recipe_id}/batch_sheet_preview`,
      ingredientFromRecipe: (recipe_id) => `${this.BASE_URL}/recipes/${recipe_id}/ingredient_from_recipe`,
      billOfMaterialsSend: (recipe_id) => `${this.BASE_URL}/recipes/${recipe_id}/bom_send`,
      billOfMaterialsCalculator: (recipe_id) => `${this.BASE_URL}/recipes/${recipe_id}/bom/calculator`,
    }
  }
  static get ingredients() {
    return {
      index: (query) => `${this.BASE_URL}/ingredients${query}`,
      edit: (id) => `${this.BASE_URL}/ingredients/${id}`,
      tags: (id) => `${this.BASE_URL}/ingredients/${id}/tag`,
      relatedTags: (id) => `${this.BASE_URL}/ingredients/${id}/related_tag`,
      templateList: `${this.BASE_URL}/ingredient_templates`,
      template: (id) => `${this.BASE_URL}/ingredient_templates/${id}`,
      version: (id) => `${this.BASE_URL}/ingredients/${id}/version`,
    }
  }
  static get s3() {
    return {
      presignedURL: `${this.BASE_URL}/document-upload`
    }
  }
  static get access() {
    return {
      access: `${this.BASE_URL}/access`
    }
  }
  static get drive() {
    return {
      auth: `${this.BASE_URL}/drive/auth`,
      token: (email) => `${this.BASE_URL}/drive/token/${email}`,
      sync: (folder_id) => `${this.BASE_URL}/drive/sync/${folder_id}`,
      list: `${this.BASE_URL}/drive`,
      drive: (email) => `${this.BASE_URL}/drive/drive/${email}`
    }
  }
  static get errorReporting() {
    return {
      frontEnd: `${this.BASE_URL}/errors/front_end`,
    }
  }
}

export default Routes;
