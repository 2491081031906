import React from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import qs from 'qs';
import RelatedDocumentRow from './RelatedDocumentRow';
import PageButtons from '../PageButtons/PageButtons';
import TagNavigatorRelatedTags from './TagNavigatorRelatedTags';
import { Tag } from '../Icons/IconLibrary';
import ActiveTagsNavigator from '../Tags/ActiveTagsNavigator';
import './RelatedTagDocuments.css';
class RelatedTagDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            relatedTags: [],
            show: true,
            page: 1,
            perPage: 10,
            query: '',
            activeTags: [],
            excludedTags: []
        }
    }
    componentDidMount = () => {
        if (this.props.related_tags.length > 0) {

            this.getRelatedDocuments();
        }
    }

    onChangeTags = (tags) => {
        this.setState({ activeTags: tags }, this.getRelatedDocuments)
    }
    onChangeExcludedTags = (tags) => {
        this.setState({ excludedTags: tags })
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.related_tags != prevProps.related_tags) {
            this.getRelatedDocuments();
        }
    }
    getRelatedDocuments = () => {
        if (this.props.related_tags.length < 1) {
            return
        }
        let searchString = qs.parse(this.state.query);
        let related_tags_names = this.props.related_tags.map((tag) => { return tag.name });
        searchString.related_tags = related_tags_names.join()
        searchString.page = this.state.page;
        if (this.state.activeTags && this.state.activeTags.length > 0) {
            searchString.tag = this.state.activeTags.join();
        }
        searchString.per_page = this.state.perPage
        let base_url = qs.stringify(searchString, { indices: false });
        Request.authRequest(Routes.documents.search('?' + base_url),
            'GET').then((response) => {
                if (response.status === 200) {
                    response.json().then(body => {
                        this.setState({
                            documents: body.results,
                            numResults: body.total_results,
                            docListLoaded: true
                        })
                    }
                    )
                }

                else {
                    response.json().then((error) => {
                        toast.error(error.message);
                    });
                }
            }).catch((error) => {
                toast.error(error.message);
            }
            )
    }
    onChangePage = (pageNumber) => {
        this.setState({ page: pageNumber }, () => { this.getRelatedDocuments(); })
    }
    onChangePageSize = (e) => {
        this.setState({ perPage: e.target.value }, () => { this.getRelatedDocuments(); })
    }
    render() {
        let documents;
        if (this.state.documents.length > 0) {
            documents = this.state.documents.map((doc, i) => {
                return <RelatedDocumentRow document={doc} key={i} />
            })
        }
        let perPage = this.state.perPage;
        let page = this.state.page;
        if (this.props.related_tags.length < 1) {
            return (
                <div className="helpText">
                    No documents. Add a related tag to associate documents!
                </div>
            )
        }
        let activeTags = [];
        let excludedTags = [];
        if (this.state.activeTags) {
            activeTags = this.state.activeTags;
            if (!Array.isArray(activeTags)) {
                activeTags = [activeTags]
            }
        }

        return (
            <div>
                <div>
                    {this.props.showTagNavigator ? <div className="relatedTagDocuments-searchContainer">
                        <TagNavigatorRelatedTags
                            onChangeTags={this.onChangeTags}
                            onChangeExcludedTags={this.onChangeExcludedTags}
                            activeTags={activeTags}
                            related_tags={this.props.related_tags}
                            excludedTags={[]}
                            toggleSwitch={null}
                            isFiltered={this.props.isFiltered}
                            isIngredientFiltered={this.props.isIngredientFiltered}
                            ref={this.tagNavigatorRef}
                            showFilter={false}
                        />
                        <ActiveTagsNavigator activeTags={activeTags} onChangeTags={this.onChangeTags}
                            onLastSelected={null}
                            excludedTags={excludedTags}
                            onChangeExcludedTags={this.onChangeExcludedTags}
                            onGetPinnedTags={() => { return null }} />
                    </div> : null}
                    <div>
                        <select className="resultsShown-selector"
                            value={perPage}
                            onChange={this.onChangePageSize}>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                        </select> Results Shown
                    </div>
                </div>
                {documents}
                <PageButtons page={page} perPage={perPage}
                    numResults={this.state.numResults} onChangePage={this.onChangePage} />
            </div>
        )
    }
}

export default RelatedTagDocuments