import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import Modal from '../../Modal/Modal';
import './Referrals.css';
class ReferralButton extends React.Component{
  constructor(){
    super();
    this.state={
      name: '',
      email :'',
    }
  }
  onShowReferrals =()=>{
    this.setState({showReferrals:true})
  }
  onSendInvite = (name,email) => {
    this.toastID = toast.info('Sending invitation...');
      Request.authRequest(Routes.payments.referrals,
    											  'POST',JSON.stringify({
                        			email: email.trim(), name:name
                        		}), 'application/json').then((response) => {
                              if (response.status===200){
          			response.json().then(body => {
                  this.props.getReferrals();
                  toast.update(this.toastID, { render:
                    `Successfully sent invitation to ${name}`,type: toast.TYPE.SUCCESS, autoClose: 5000 });
                      });
                    } else {
                      response.json().then((error) => {
                        toast.error(error.message);
                        toast.update(this.toastID, { render:
                          `Could not send an invitation to ${name} - that user already has an account`,type: toast.TYPE.ERROR, autoClose: 5000 });
                      })
                      }

                }).catch((error) => {
                toast.error(error.message);
                }
            )



  }
  onValidateFields = ()=>{
    if (this.state.name === ''){
      return toast.error('Please enter a name')
    }
    if (this.state.email === ''){
      if (this.state.email.indexOf('@') === -1 || this.state.email.indexOf('.') === -1)
      return toast.error('Please enter a valid email')
    }
    this.onSendInvite(this.state.name,this.state.email);
    this.setState({name:'',email:''})
  }

  onChangeName= (e) => {
      this.setState({
        name: e.target.value,
      });
    }
  onChangeEmail = (e) => {
      this.setState({
        email: e.target.value,
      });
    }
    handleFirstEnter = (e)=>{
      if (e.key ==='Enter'){
        this.onValidateFields();
      }
    }
  render(){
    return(
          <div>
            <div className = "helpText-CreateTagButton">Recommend Ivy to your friends!
              Free users get 20 bonus free tags to help keep you organized.
              A referral is counted as complete once the user completes the tutorial. A free account can have a maximum of 10 completed referrals.
            </div>
            <div className = "referralInputs">
            <div className = "referralInputs-item">
                <div>Name</div>
                  <input
                    value={this.state.name}
                    type='text'
                    className='template-info-field'
                    onChange={this.onChangeName}
                    />
              </div>
                <div className = "referralInputs-item">
                  <div className = "newTemplateRow-label"> Email
                  </div>

                  <input
                    value={this.state.email}
                    type='text'
                    className='template-info-field'
                    onChange={this.onChangeEmail}
                    onKeyPress = {this.handleFirstEnter}
                    />
                </div>
                  <button className = "smallButton primarybutton "
                    onClick = {this.onValidateFields} id = "referralButton"> Send </button>

              </div>
          </div>

      )
    }


}

export default ReferralButton;
