import React from 'react';
import RecipeBatchSheetPreviewer from './RecipeBatchSheetPreviewer';
import Modal from '../../Modal/Modal';
import { Eye } from '../../Icons/IconLibrary';
class RecipeBatchSheetPreviewButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        }
    }
    onShowModal = () => {

        this.setState({ showModal: true })
    }
    onHideModal = () => {
        this.setState({
            showModal: false
        })
    }
    render() {
        if (!this.props.recipe) {
            return <div >

                <div className="" onClick={this.onShowModal}>
                    <img src={Eye} className=' signatureButton-text ' /></div>
                <Modal show={this.state.showModal} onClose={this.onHideModal} title='Batch Sheet Preview' extraWide>
                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

                </Modal >
            </div >
        }
        if (this.props.recipe && this.props.recipe.m_total_weight_per_unit_kg == 0) {
            return (
                <div >

                    <div className="" onClick={this.onShowModal}>
                        <img src={Eye} className=' signatureButton-text ' /></div>
                    <Modal show={this.state.showModal} onClose={this.onHideModal} title='Batch Sheet Preview' extraWide>
                        <div>No ingredients have been added to this recipe. Check weight percents.</div>
                    </Modal>
                </div>
            )
        }

        return (
            <div >

                <div className="" onClick={this.onShowModal}>
                    <img src={Eye} className=' signatureButton-text ' /></div>
                <Modal show={this.state.showModal} onClose={this.onHideModal} title='Batch Sheet Preview' extraWide>
                    <RecipeBatchSheetPreviewer recipe={this.props.recipe} ignore_density={this.props.ignore_density} />
                </Modal>
            </div>
        )
    }
}
export default RecipeBatchSheetPreviewButton;