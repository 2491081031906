import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import './RecipeSettings.css';
import TagCleaner from '../../../helpers/TagCleaner';
import TagsSearch from '../../TagsSearch/TagsSearch';
class BatchingredientAttributes extends React.Component {
    constructor() {
        super();
        this.state = {
            ingredientAttributes: [],
            editing: false,
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.recipeSettings !== this.props.recipeSettings) {
            this.setState({ ingredientAttributes: this.props.recipeSettings.batch_ingredient_attributes });
        }
    }
    onEnterTagName = (attributes) => {
        this.setState({
            ingredientAttributes: attributes,
        });
    }
    onEscape = () => {
        return
    }
    onCancel = () => {
        this.setState({ editing: false })
    }
    onEdit = () => {
        this.setState({ editing: true })
    }
    onSave = () => {
        let recipeSettings = this.props.recipeSettings;
        let cleaned_tag_names = TagCleaner.parseTagArray(this.state.ingredientAttributes);
        if (cleaned_tag_names.length > 0) {

            recipeSettings.batch_ingredient_attributes = cleaned_tag_names
        } else {
            recipeSettings.batch_ingredient_attributes = null;
        }

        Request.authRequest(Routes.organization.recipeSettings,
            'PUT', JSON.stringify(recipeSettings),
            'application/json').then(
                (response) => {
                    if (response.ok) {
                        response.json().then((body) => {
                            this.props.onGetRecipeSettings();
                            this.setState({ editing: false })
                        }
                        )
                    } else {
                        response.json().then((error) => {
                            toast.error(error.message);
                            if (error.errors) {
                                toast.error(error.errors[0])
                            }
                        });
                    }
                }).catch((error) => {
                    toast.error(error.message);
                }
                )


    }
    render() {
        let batchIngredientAttributes;
        if (this.state.ingredientAttributes && this.state.ingredientAttributes.length > 0) {
            batchIngredientAttributes = this.state.ingredientAttributes.map((attribute, i) => {
                return (<div className="tagLabel-container">{attribute}</div>)
            })
        }
        return (
            <div className="recipeSetting-container">
                <div className="recipeSetting-title">Ingredient Attributes to Include in Batch Sheets</div>
                {this.state.editing ?
                    <div>
                        <div>Enter a list of ingredient attributes to be ingredient in batch sheets in addition to default ones.
                            Names will be matched, e.g. Supplier, SAP Code.</div>

                        <div className="recipeSettings-inputContainer">
                            <TagsSearch onSendTags={this.onEnterTagName}
                                onEsc={this.onEscape}
                                tags={this.state.ingredientAttributes} noSearch placeholder="Enter ingredient attribute names" />
                        </div>
                        <div className="smallButtonContainer">
                            <div onClick={this.onSave} className="smallButton primary"> Save</div>
                            <div onClick={this.onCancel} className="smallButton smallButton-secondary">Cancel</div>
                        </div>
                    </div> :
                    <div>
                        <div>Enter a list of ingredient attributes to be ingredient in batch sheets
                            in addition to default ones. Names will be matched, e.g. Supplier, SAP Code.</div>
                        {batchIngredientAttributes}
                        <div onClick={this.onEdit} className="smallButton primary"> Edit</div>
                    </div>
                }
            </div>
        )
    }


}

export default BatchingredientAttributes;
