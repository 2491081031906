import React from 'react';
import './InteractiveDemo.css';
class DemoTagObject extends React.Component{
  onClick = ()=>{
    this.props.onFirstClick();
    if (this.props.isOpaque){
      this.props.removeTag(this.props.type);
    }
    else {
      this.props.addTag(this.props.type);
    }
  }
  render(){
      let type = `demo${this.props.type}Tag`;
    return(
      <div className = {type} onClick = {this.onClick}>
        <img src = {this.props.icon} className = {this.props.isOpaque?"opaqueDemoObject demoTag":"transparentDemoObject demoTag"}/>
      </div>
    )
  }
}

export default DemoTagObject;
