import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
class EnterReset extends React.Component{
  constructor(){
    super()
    this.state={
      password:'',
      verifyPassword:'',
      error:'',
      success:false,
    }
  }
  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
      error: '',
    });
  }

  onChangeVerifyPassword = (e) => {
    this.setState({
      verifyPassword: e.target.value,
      error: '',
    });
  }
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onResetPassword();
    }
  }
  onResetPassword = () => {
    if (this.state.password.length < 6){
      this.setState({error:'Please enter a password at least 6 characters long.'});
      return
    }
    if (this.state.password.length > 100){
      this.setState({error:'Please enter a password less than 100 characters long.'});
      return
    }
    
		if (this.state.password === this.state.verifyPassword) {
      let token = this.props.location.search.slice(7);

			Request.authRequest(Routes.auth.reset(token), 'PUT', JSON.stringify({
				new_password: this.state.password,
			}), 'application/json').then((response) => {
				if (response.status === 200) {
          localStorage.removeItem('loggedIn');
          this.setState({success:true});
				} else {
					response.json().then((error) => {
						this.setState({ error: error.message });
					});
				}
			}).catch((error) => {
				this.setState({ error: error.message });
			});
		} else {
			this.setState({ error: 'Password verification does not match provided password.' });
		}
	}
  onNavigate = () =>{
    window.location.href='/login';
  }
  render(){
    return (
      <div className = 'enterreset-container boxShadow'>
        <div className = "reset-header"> Reset your Password</div>
        Enter your new password.
        <br/>
        <label className='input-label' htmlFor='password'> New Password </label>
				<input type='password' onChange={this.onChangePassword} id='password' className='input-text'/>
				<label className='input-label' htmlFor='verifyPassword'> Verify New Password </label>
				<input type='password' onChange={this.onChangeVerifyPassword} onKeyPress = {this.handleEnter} id='verifyPassword' className='input-text'/>
		    <button className = "form-button primary" id = 'email-reset' onClick = {this.onResetPassword}>Reset</button>
        {this.state.success?
          <div>Thanks. Your password has been reset
            <button className ="form-button secondary" id ='email-reset' onClick ={this.onNavigate}>Return</button>
          </div>
          :this.state.error}
      </div>
    );
  }
}
export default EnterReset;
