import React from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import InfoField from './InfoField';
import DocumentDateField from './DocumentDateField';
import PreviewButton from '../Buttons/PreviewButton';
import DocumentNotesField from './DocumentNotesField';
import ReUploadButton from '../Buttons/ReUploadButton';
import TagsField from './TagsField';
import DeleteButton from '../Buttons/DeleteButton';
import DownloadButton from '../Buttons/DownloadButton';
import TagCleaner from '../../helpers/TagCleaner';
import RemindDocumentAccept from './RemindDocumentAccept';
import SendDocumentToButton from '../Buttons/SendDocumentToButton';
import ShortDocRow from './ShortDocRow';
import ShortLogsRow from '../Logs/ShortLogsRow';
import './DocumentViewer.css';
import CookieWrapper from '../CookieWrapper/CookieWrapper';
import SignatureButton from './Signatures/SignatureButton';
import VersionHistoryReportButton from './VersionHistory/VersionHistoryReportButton';
import MarkOriginalSender from './MarkOriginalSender';
class DocumentViewer extends React.Component {
	constructor(props) {
		super(props);
		this.onSaveChanges = this.onSaveChanges.bind(this);
		this.onSaveTagsChanges = this.onSaveTagsChanges.bind(this);
		this.onShowVersionHistory = this.onShowVersionHistory.bind(this);
		this.onShowLogs = this.onShowLogs.bind(this);
		this.onGetDocuments = this.onGetDocuments.bind(this);
		this.state = {
			error: '',
			doc: '',
			preview: false,
			logs: [],
			showVersionHistory: false,
			loaded: false,
		}
	}

	componentDidMount() {
		this.onGetDocuments();
		this.onGetLogs();
	}
	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.onGetDocuments();
		}
	}
	onGetLogs() {
		Request.authRequest(Routes.logs.document(this.props.match.params.id), 'GET').then((response) => {
			response.json().then(body => {
				this.setState({ logs: body })
			})
		})
	}
	onGetDocuments() {
		Request.authRequest(Routes.documents.edit(this.props.match.params.id),
			'GET').then((response) => {

				if (response.status === 200) {
					response.json().then(body => {
						this.setState({ doc: body, loaded: true })
						this.onGetLogs();
					})
				}
				else {
					response.json().then((error) => {
						this.setState({ loaded: true })
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}
	onSaveChanges(contentType, content) {
		let doc = JSON.parse(JSON.stringify(this.state.doc));
		switch (contentType) {
			case 'ID':
				doc.user_defined_id = content;
				break;
			case 'Name':
				doc.name = content;
				break;
			case 'Category':
				doc.category = content;
				break;
			case 'Notes':
				doc.notes = content;
				break;
			case 'Date':
				if (content === null) {
					doc.date_expiration = null;
				}
				else {
					doc.date_expiration = content
				}
				break;
			default:
				return;
		}
		Request.authRequest(Routes.documents.edit(this.state.doc.document.id),
			'PUT', JSON.stringify(
				doc
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetDocuments();
					this.onGetLogs();
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)

	}
	onSaveTagsChanges(tagsArray) {
		/* this string, unlike other tags operations is the whole tags list */
		let doc = this.state.doc;
		if (tagsArray.length === 0) {
			toast.error('Please enter tags. All documents must have at least one tag.')
			return
		}
		let trimmedTags = TagCleaner.parseTagArray(tagsArray);
		Request.authRequest(Routes.documents.tag(doc.document.id),
			'PUT', JSON.stringify({
				tag_names: trimmedTags,
			}), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetDocuments();
					this.onGetLogs();
					toast.success('Successfully applied tags!')
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}

	onReturnNavigation = () => {
		this.props.history.goBack();
	}
	onShowLogs = () => {
		this.setState({ showVersionHistory: false });
	}
	onShowVersionHistory = () => {
		this.setState({ showVersionHistory: true });
	}
	render() {

		const doc = this.state.doc;
		let date_expiration;
		let versionHistory;
		let docLoader = <div className="loaderContainer"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>

		let sharedWithList = (<div className="helpText">This document has not been sent to anyone</div>);
		let fromOrganization;
		let versionNumber;
		let status;
		let draft;
		if (doc) {

			if (!doc.date_expiration) {
				date_expiration = null;
			}
			else {
				date_expiration = doc.date_expiration.substring(0, 10);
			}
			let statusWords = doc.status;
			if (doc.tags && doc.tags.length > 0) {
				let tag_names = doc.tags.map((entity, i) => {
					return entity.name;
				})
				if (tag_names.indexOf("Draft") > -1) {
					draft = "DRAFT";
					statusWords = "DRAFT";
				}
				if (tag_names.indexOf("Archive") > -1) {
					statusWords = "Archive";
				}
			}

			status = <div className="documentViewer-uneditableHelpText noselect">Status: {statusWords}</div>
			if (doc.version_number) {
				versionNumber = 'Version ' + doc.version_number;
				if (doc.version_history.length > 0 && doc.version_number === doc.version_history.length + doc.version_history[doc.version_history.length - 1].version_number
				) {
					versionNumber = versionNumber + " - current";
				} else if (doc.version_history && doc.version_history.length > 0 &&
					doc.version_number > doc.version_history[0].version_number) {
					versionNumber = versionNumber + " - current";
				}
			}

			if (doc.version_history && doc.version_history.length > 0) {
				versionHistory = doc.version_history.map((entity, i) => {
					return <ShortDocRow document={entity} key={i} />
				});
			}
			else {
				versionHistory = (<div className="helpText">No other versions</div>)
			}
			if (doc.shared_with.length > 0) {
				sharedWithList = doc.shared_with.map((receiver, i) => {
					let date_sent = new Date(receiver.date);
					date_sent = date_sent.toDateString();
					let date_approved;
					let only_as_pdf = receiver.only_as_pdf;
					let reminderCounter;
					if (receiver.reminder_counter) {
						reminderCounter = <div className="reminderCounter-text">Reminded {receiver.reminder_counter} times</div>
					}
					if (receiver.date_approved) {
						let date_approved_object = new Date(receiver.date_approved)
						date_approved = date_approved_object.toDateString();

					}
					if (!receiver.name) {
						return
					}
					let reminderButton;
					if (receiver.status === 'Pending') {
						if (!receiver.name.indexOf('@') > -1 && !receiver.name.indexOf('.') > -1) {
							reminderButton = <RemindDocumentAccept receiver={receiver}
								doc={doc} />
						}
					}
					return (
						<div key={i} className="template-toOrganizationContainer">
							<div className="template-toOrganizationContainer-row">
								<div>
									<div className="document-toOrganization">{receiver.name}
									</div>


								</div>
								<div className="document-toOrganization-date">
									{date_sent}
								</div>
							</div>
							<div className="document-status-sentToContainer">
								<div className="receiverStatus">Status: {receiver.status} {date_approved} {only_as_pdf ? '- PDF Only' : null} </div>
								<div className="reminderButton-container"> {reminderButton}
									{reminderCounter}</div>
							</div>
						</div>
					)
				})


			}

			if (doc.from_organization) {
				fromOrganization = doc.from_organization.name
			}
		}

		let logsList;
		if (this.state.logs) {
			logsList = this.state.logs.map((entity, i) => {
				return <ShortLogsRow log={entity} key={i} />
			})
		}
		return (
			<div className='document-viewer'>



				<div className="document-viewer-middle boxShadow">

					<button className='button-ReturnViewer' onClick={this.onReturnNavigation}> Go Back </button>
					<div className="document-viewer-header">
						<div className="document-viewer-header-text wordbreak">
							{doc.name}
							{this.state.loaded ? null : docLoader}
						</div>
						<div className='tab-top-rightButtons'>
							<PreviewButton doc={doc} documentViewer />
							<div className="viewer-textButtons">

								{doc ? <SignatureButton doc={doc} onGetDocuments={this.onGetDocuments} /> : null}
							</div>
							<ReUploadButton doc={doc} />
							<DownloadButton docs={[doc]} />
							<DeleteButton items={[doc]}
								type="documents"
								onGetDocuments={this.onReturnNavigation} onClear={this.onReturnNavigation} />
						</div>
					</div>

					<div className='document-viewer-fields'>

						<div className='document-viewer-fields-first'>

							<InfoField content={doc.name} contentType='Name'
								className="document-viewer-fields-content"
								doc={doc}
								onClickOut={this.onSaveChanges} />

							<InfoField content={doc.user_defined_id}
								className="document-viewer-fields-content"
								contentType='ID'
								doc={doc} onClickOut={this.onSaveChanges} />

							<DocumentDateField content={date_expiration} contentType='Date'
								doc={doc} onClickOut={this.onSaveChanges} />
							<TagsField content={doc.tags} contentType='Tags' doc={doc}
								onClickOut={this.onSaveTagsChanges} />
							<DocumentNotesField content={doc.notes} contentType='Notes'
								doc={doc} onClickOut={this.onSaveChanges} />
							<div className="documentViewer-uneditableSection">
								<div className="documentViewer-uneditableHelpText noselect">{versionNumber}</div>

								{status}

								{fromOrganization ? <div className="documentViewer-uneditableHelpText noselect">
									This document is originally from {fromOrganization}.</div> :
									<MarkOriginalSender docs={[doc]} fetchDocuments={this.onGetDocuments} />}
							</div>
						</div>
					</div>
				</div>
				<div className='document-viewer-midRightColumn boxShadow'>
					<div className='document-viewer-column-header-text'>
						Sent to
						<SendDocumentToButton docs={[doc]} viewer getDocument={this.onGetDocuments} />
					</div>
					<div className="sentTo-tableHeader">
						<div >
							Sent To</div>
						<div>Sent On
						</div>
					</div>
					<div className='document-viewer-permissions '>
						{sharedWithList}
					</div>
				</div>

				<div className='document-viewer-rightColumn boxShadow'>
					<div className="document-viewer-rightColumn-header">
						{this.state.showVersionHistory ?
							<div className="document-viewer-column-header-text" >
								<div onClick={this.onShowLogs}
									className="document-viewer-tabInactive">Logs</div>
								<div className="document-viewer-tabActive">
									Version History

								</div>
							</div> :
							<div className="document-viewer-column-header-text" >
								<div className="document-viewer-tabActive">Logs</div>
								<div onClick={this.onShowVersionHistory}
									className="document-viewer-tabInactive" id="versionHistory">
									Version History
								</div>

							</div>}
					</div>

					{this.state.showVersionHistory ?
						<div className='versionHistory'>

							<div className="document-versionHistoryContents">
								<VersionHistoryReportButton doc={doc} />
								{versionHistory}
							</div>
						</div>
						:
						<div className='logSummary'>

							<div className="logSummary-contents">
								{logsList}
							</div>

						</div>}


				</div>





				<div>
					{this.state.error}

				</div>
			</div>
		);
	}
}

export default withRouter(CookieWrapper(DocumentViewer));
