import React from 'react';

import './ChildTag.css';
class ChildTag extends React.Component{
  onSelectTag=()=>{
    if (this.props.tag.is_accessible){
    this.props.onReplaceTag(this.props.tag.name,this.props.parentKey)
    this.props.onLastSelected(this.props.tag.name)
  }
  }
  render(){
    let tag = this.props.tag;
    return(
      <div className = "tagLabel-container" onClick ={this.onSelectTag}
        	id = {this.props.tag.is_accessible?null:"notAccessibleTag"}>

          {tag.name}

      </div>)
  }
}

export default ChildTag;
