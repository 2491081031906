import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './Settings.css';
import InfoField from '../Documents/InfoField';
import EditRoles from './EditRoles';
import CookieWrapper from '../CookieWrapper/CookieWrapper';
import ChangeNotificationSettings from './NotificationSettings/NotificationFrequency';
import IndividualNotificationSettings from './NotificationSettings/IndividualNotificationSettings';
import ChangeNotificationExpiration from './NotificationSettings/ChangeNotificationExpiration';
import EditReceivedNotifications from './EditReceivedNotifications';
import BillingComponent from '../Stripe/BillingComponent';
import TagAccessButton from './TagAccess/TagAccessButton';
import InviteUser from './InviteUser';
import DeleteUser from './DeleteUser';
import OnlyAsPDF from './OnlyAsPdf';
import EditTagsAccessLevel from './EditTagsAccessLevel';
import Referrals from './Referrals/Referrals';
import RecipeSettings from './RecipesSettings/RecipeSettings';
class Settings extends React.Component {
	constructor() {
		super();
		this.onSaveChanges = this.onSaveChanges.bind(this);
		this.onGetNotificationsSettings = this.onGetNotificationsSettings.bind(this);
		this.editUserInfo = this.editUserInfo.bind(this);
		this.editRecipeRoleInfo = this.editRecipeRoleInfo.bind(this);
		this.state = {
			user: {},
			organization: {},
			errors: [],
			showOrganizationCode: false,
			showModal: false,
			notificationSettings: {},
			editingNotifications: false,
			editingExpirationNotifications: false,
			showDeactivatedUsers: false,
		}
	}

	getOrganizationCode = () => {
		if (this.state.user.role === "Super Admin") {
			this.setState({ showOrganizationCode: true })
		}
	}
	componentDidMount() {
		this.getUser();
		this.getOrganization();
		this.onGetNotificationsSettings();

	}
	getUser = () => {
		Request.authRequest(Routes.profile.view, 'GET').then((response) => {
			response.json().then(body => {
				this.setState({
					user: body

				})
			})
		});
	}
	getOrganization = () => {
		Request.authRequest(Routes.organization.index, 'GET').then((response) => {
			response.json().then(body => {
				this.setState({
					organization: body

				})
			})
		});
	}
	onSaveChanges = (contentType, content) => {
		let user = this.state.user;
		switch (contentType) {
			case 'First Name':
				user.first_name = content;
				break;
			case 'Last Name':
				user.last_name = content;
				break;
			case 'Job Title':
				user.job_title = content;
				break;
			case 'Phone':
				user.phone = content;
				break;
			default:
				return;
		}
		Request.authRequest(Routes.profile.view,
			'PUT', JSON.stringify(user), 'application/json'
		).then((response) => {
			response.json().then(body => {
				toast.success('Changed user info');

			}
			)
		}
		);

	}
	editUserInfo(i, role) {
		let organization = this.state.organization;
		let member = organization.members[i];
		member.role = role;
		organization.members[i] = member;
		Request.authRequest(Routes.organization.index, 'PUT', JSON.stringify(
			organization
		), 'application/json'
		).then((response) => {
			response.json().then(body => {
				this.getOrganization();
				toast.success('Changed role')
			}
			)
		}
		);
	}
	editRecipeRoleInfo(i, role) {
		let organization = this.state.organization;
		let member = organization.members[i];
		member.recipe_role = role;
		organization.members[i] = member;
		Request.authRequest(Routes.organization.recipeRole, 'PUT', JSON.stringify(
			organization
		), 'application/json'
		).then((response) => {
			response.json().then(body => {
				this.getOrganization();
				toast.success('Changed role')
			}
			)
		}
		);
	}
	onEditTagAccessLevel(i, access) {

	}
	onGetNotificationsSettings = () => {
		Request.authRequest(Routes.notifications.settings, 'GET').then((response) => {

			if (response.status === 200) {
				response.json().then(body => {
					this.setState({ notificationSettings: body })
				})
			}
		})
	}
	onEditNotifications = () => {
		this.setState({ editingNotifications: true })
	}
	onCancelNotificationEditing = () => {
		this.setState({ editingNotifications: false })
		this.onGetNotificationsSettings();
	}
	onEditExpirationNotifications = () => {
		this.setState({ editingExpirationNotifications: true })
	}
	onCancelExpirationNotificationEditing = () => {
		this.setState({ editingExpirationNotifications: false })
	}
	showDeactivatedUsers = () => {
		this.setState({ showDeactivatedUsers: !this.state.showDeactivatedUsers });
	}
	render() {
		const { user, organization, notificationSettings } = this.state;
		let userSettings;
		if (notificationSettings && notificationSettings.receive_notifications) {
			userSettings = notificationSettings.receive_notifications;
		};
		const access_code = organization.access_code;
		let userList = [];
		let deactivedUsers = [];
		if (organization.members) {
			for (let index in organization.members) {
				let accountInfo;
				let member = organization.members[index];
				if (member.notification_settings && !member.deactivated) {
					let accessibleTags = <div className="UserTagsAccess-helpText">Cannot access any tags. Set tags in management.</div>
					if (member.accessible_tags.length > 0) {
						accessibleTags = <div className="UserTagsAccess-helpText">Can access {member.accessible_tags.length} tags </div>;
					}
					if (member.role == "Super Admin" || member.full_tag_access) {
						accessibleTags = <div className="UserTagsAccess-helpText">Can access all tags.</div>
					}
					let excludedTags;
					if (member.inaccessible_tags.length > 0) {
						excludedTags = <div className="UserTagsAccess-helpText"> &nbsp; excluding {member.inaccessible_tags.length} tags</div>
					}


					let onlyAsPDF;
					if ((member.allowed_formats) && (member.allowed_formats.indexOf('.pdf') > -1)) {
						onlyAsPDF = true;
					}
					accountInfo = (
						<div className='user-list-row' id={member.deactivated ? 'deactivatedUser' : null}>
							<span className='user-list-section'>
								{`${member.first_name} ${member.last_name}`}
							</span>
							<span className='user-list-section'>
								{member.email}
							</span>

							<div className='user-list-section'>
								{member.deactivated ? 'Deactivated' :
									<EditRoles onClickOut={this.editUserInfo} index={index}
										className='user-list-section' role={member.role}
									/>}
							</div>
							<div className='user-list-section'>
								{member.deactivated ? 'Deactivated' :
									<EditRoles onClickOut={this.editRecipeRoleInfo} index={index}
										className='user-list-section' role={member.recipe_role}
									/>}
							</div>
							<div className='user-list-section'>
								{member.deactivated ? 'Deactivated' :
									<EditTagsAccessLevel className='user-list-section'
										user={member} getOrganization={this.getOrganization} />}
							</div>
							<div className='user-list-section'>
								{member.deactivated ? 'Deactivated' :
									<EditReceivedNotifications className='user-list-section' receives={member.notification_settings.receive_notifications}
										user={member}
										onGetNotificationsSettings={this.onGetNotificationsSettings} />}
							</div>
							<div >
								<OnlyAsPDF user={member} getUsers={this.getOrganization} onlyAsPDF={onlyAsPDF} />
							</div>
							<div className="settingsTags-container">{accessibleTags} {excludedTags}
							</div>
							<DeleteUser user={member} getOrganization={this.getOrganization} text />
						</div>
					);
				}
				else if (user.recipe_role == 'Super Admin') {
					accountInfo = (
						<div className='user-list-row' id={member.deactivated ? 'deactivatedUser' : null}>
							<span className='user-list-section'>
								{`${member.first_name} ${member.last_name}`}
							</span>
							<span className='user-list-section'>
								{member.email}
							</span>

							<div className='user-list-section'>
								{member.deactivated ? 'Deactivated' :
									<EditRoles onClickOut={this.editUserInfo} index={index}
										className='user-list-section' role={member.role}
									/>}
							</div>
							<div className='user-list-section'>
								{member.deactivated ? 'Deactivated' :
									<EditRoles onClickOut={this.editRecipeRoleInfo} index={index}
										className='user-list-section' role={member.recipe_role}
									/>}
							</div>
						</div>)
				}
				else {
					accountInfo = (
						<div className='user-list-row' >
							<span className='user-list-section'>
								{`${member.first_name} ${member.last_name}`}
							</span>
							<span className='user-list-section'>
								{member.email}
							</span>
							<span className='user-list-section'>
								{member.role}
							</span>
						</div>
					)
				}
				if (member.deactivated) {
					deactivedUsers.push(accountInfo)
				}
				else {
					userList.push(accountInfo)
				}
			}
		}
		let deactivatedUsersList;
		let TableHeaders;
		if (user.recipe_role == 'Super Admin' && organization.members && !organization.members[0].notification_settings) {
			TableHeaders = (<div className='user-list-header is-spaced-between'>
				<span className='user-list-header-field noselect'> Name </span>
				<span className='user-list-header-field noselect'> Email </span>
				<span className='user-list-header-field noselect'> Role </span>
				<span className='user-list-header-field noselect'> Recipe Role </span>
			</div>);
		}
		else if (organization.members && !organization.members[0].notification_settings) {
			TableHeaders = (<div className='user-list-header is-spaced-between'>
				<span className='user-list-header-field noselect'> Name </span>
				<span className='user-list-header-field noselect'> Email </span>
				<span className='user-list-header-field noselect'> Role </span>
			</div>);
		}
		else {
			TableHeaders = (<div className='user-list-header'>
				<span className='user-list-header-field noselect'> Name </span>
				<span className='user-list-header-field noselect'> Email </span>
				<span className='user-list-header-field noselect'> Role </span>
				<span className='user-list-header-field noselect'> Recipe Role </span>
				<span className='user-list-header-field noselect'> Tag Access Level </span>
				<span className='user-list-header-field noselect'> Receives Notifications </span>
				<span className='user-list-header-field noselect'> Restricted to PDF </span>
				<span className='user-list-header-field-tags noselect'> Tags <TagAccessButton users={organization.members}
					getOrganizationUsers={this.getOrganization} /></span>

			</div>)

			deactivatedUsersList = (
				<div className="deactivatedUserContainer">
					<div onClick={this.showDeactivatedUsers} className="showDeactivatedUserButton">Show Deactivated User List
					</div>
				</div>
			)
			if (this.state.showDeactivatedUsers === true) {
				deactivatedUsersList = (
					<div className="deactivatedUserContainer">
						<div onClick={this.showDeactivatedUsers} className="showDeactivatedUserButton">Show Deactivated User List
						</div>
						To reactivate a user, please email support.
						<div className="deactivatedUsersList-Container">

							{deactivedUsers}
						</div>
					</div>)
			}
		}
		let expirationString;
		if (organization.notification_of_expiration) {
			let lengthExpiration = organization.notification_of_expiration.length;
			if (lengthExpiration > 1) {
				expirationString = organization.notification_of_expiration.slice(0, -1).join(', ');
				expirationString = expirationString + ' and ' + organization.notification_of_expiration[lengthExpiration - 1];
			}
			else {
				expirationString = organization.notification_of_expiration;
			}
		}
		let organizationButtons;
		if (this.state.user.role === "Super Admin") {
			if (this.state.showOrganizationCode) {
				organizationButtons = (
					<div className="organizationButtons">
						<InviteUser /> or<div>
							<span className='user-list-header-field noselect'
								onClick={() => this.getOrganizationCode()} id="getAccessCode">
								Get Access Code </span>
							<div className='accessCode'> The access code to join this organization is {access_code}. <br />
								Enter this code when joining an organization upon signup.
							</div>
						</div>
					</div>)
			} else {
				organizationButtons = (<div className="organizationButtons">
					<InviteUser /> or
					<span className='user-list-header-field noselect'
						onClick={() => this.getOrganizationCode()} id="getAccessCode">
						Get Access Code </span>

				</div>)
			}
		}
		return (
			<div className='settings'>
				<div className='settings-section boxShadow' id="account-info">
					<div className='settings-heading'> Account Info </div>
					<hr className='settings-section-divider' />
					<div className='account-info-row'>
						<div className='account-info-row-first'>
							<InfoField content={user.first_name} contentType='First Name'
								onClickOut={this.onSaveChanges} />
							<InfoField content={user.last_name} contentType='Last Name'
								onClickOut={this.onSaveChanges} />
							<InfoField content={user.job_title} contentType='Job Title'
								onClickOut={this.onSaveChanges} />
							<InfoField content={user.phone} contentType='Phone'
								onClickOut={this.onSaveChanges} />
						</div>
						<div className='account-info-row-second'>
							<div className='account-info-section'>
								<span className='account-field noselect'> Email </span>
								{user.email}
							</div>
							<div className='account-info-section'>
								<span className='account-field noselect' > Role </span>
								{user.role}
							</div>
							<div className='account-info-section'>
								<span className='account-field noselect' > Recipe Role </span>
								{user.recipe_role}
							</div>
						</div>

					</div>
				</div>
				<div className='settings-section boxShadow' id="organization-info">

					<div className='settings-heading'> Organization Info for {organization.name}</div>

					<hr className='settings-section-divider' />
					{organizationButtons}
					<div className='settings-users-contents'>

						<div className='user-list'>
							{TableHeaders}
							{userList}
						</div>


					</div>
					<div className='helpText-users noselect'>

						Superadmins have full control of user management in addition to admin abilities,
						including creating, deleting or changing user roles, and assigning notifications to other users.<br />
						Admins can fully modify documents, tags and send or request documents
						but cannot change other user information. <br />

						Tag Creation and Deletion access can be controlled for individual admins.
						Admins with READ access cannot create new tags or delete tags. Admins with WRITE access can create or delete new tags.
						<br />
						Users can view and download documents but cannot modify documents. <br />
						Restricted to pdf means that a User cannot download original versions
						of Word, PPT or Excel documents (any document that is supported by our autoconverter service.)
						They will only be able to download the pdf version.
						They can still download other documents.
					</div>
					{deactivatedUsersList}
				</div>
				<div className='settings-section boxShadow' id="introJS-settings-Alerts">
					<div className='settings-heading'> Alerts </div>
					<hr className='settings-section-divider' />

					{userSettings ?
						<div>
							<div> You currently receive notifications via email every {notificationSettings.notification_frequency} days.</div>
							<div onClick={this.onEditNotifications} className="editNotifications">Edit Notification Settings</div>
						</div> :
						<div> You currently don't receive notifications. Contact your superadmin to have this changed.</div>}
					{this.state.editingNotifications ? <ChangeNotificationSettings
						frequency={notificationSettings.notification_frequency} organization={organization}
						user={user} onCancel={this.onCancelNotificationEditing} />
						: null}

					<div> Your organization currently sends out expiration notifications {expirationString} days in advance of the expiration date.</div>
					{user.role === 'Super Admin' ? <div onClick={this.onEditExpirationNotifications} className="editNotifications">Edit Expiration Notification Settings</div>
						:
						null}
					{this.state.editingExpirationNotifications ? <ChangeNotificationExpiration
						organization={organization}
						onCancel={this.onCancelExpirationNotificationEditing} />
						: null}
					<IndividualNotificationSettings user={user} />
				</div>


				<RecipeSettings />
				<div className='settings-section boxShadow' id="organization-info">
					<div className='settings-heading'> Billing </div>
					<hr className='settings-section-divider' />
					{this.state.organization ? <BillingComponent /> : null}
				</div>
				<Referrals />
			</div>
		);
	}
}

export default CookieWrapper(Settings);
