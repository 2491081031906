import React from 'react';
import './TagAccess.css';
import ToggleFullTagAccessButton from './ToggleFullTagAccessButton';
class SelectedUserTagAccess extends React.Component{
  onRemoveUser = () =>{
    this.props.removeUser(this.props.user);
  }

  render(){
    let user = this.props.user
    return(
      <div>
      <div className = "SelectedUserTagAccess-info">
        <div>{user.first_name} {user.last_name}
        </div>
        <div className = "SelectedUserTagAccess-rightSide">
          <div>{user.email}</div>
          <div onClick = {this.onRemoveUser}
            className = "tagAccess-removeSelectedUser">x</div>
        </div>

      </div>
      <ToggleFullTagAccessButton user = {this.props.user}
        onChangeTagAccess={this.props.onChangeTagAccess}/>
      </div>
    )
  }
}

export default SelectedUserTagAccess;
