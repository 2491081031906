import React from 'react';
   
const ErrorPage = (props) => {
	return (
		<div className='page404'>
			<h1> Uh Oh! </h1>
			<img src='https://cdn.drawception.com/images/panels/2017/5-5/tLOm6ZB2KO-2.png' alt=''/>
			<h2> We're sorry you encountered a problem. </h2>
			<h2> Email us at help@ivydocuments.com and we’ll get back to you as soon as possible. </h2>
			<h2> Please include a description of your problem, the browser version and any screenshots. </h2>
			<h2> You can also request new features by emailing us at help@ivydocuments.com </h2>
		</div>
	);
}

export default ErrorPage;
