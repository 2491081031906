import React from 'react';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import InfoField from '../InfoField';
import DocumentNotesField from '../DocumentNotesField';
import TagsField from '../TagsField';
import ArchiveButton from '../../Buttons/ArchiveButton';
import TagCleaner from '../../../helpers/TagCleaner';
import BatchSheetViewer from '../../Recipes/BatchSheetViewer/BatchSheetViewer';
import './BatchSheetNavViewer.css';
import '../DocumentViewer.css';
import CookieWrapper from '../../CookieWrapper/CookieWrapper';
import PendingApprovalButtons from './PendingApprovalButtons';
class DocumentViewer extends React.Component {
	constructor(props) {
		super(props);
		this.onSaveChanges = this.onSaveChanges.bind(this);
		this.onSaveTagsChanges = this.onSaveTagsChanges.bind(this);
		this.onShowVersionHistory = this.onShowVersionHistory.bind(this);
		this.onShowLogs = this.onShowLogs.bind(this);
		this.onGetDocuments = this.onGetDocuments.bind(this);
		this.state = {
			error: '',
			doc: '',
			preview: false,
			logs: [],
			showVersionHistory: false,
			loaded: false,
		}
	}

	componentDidMount() {
		this.onGetDocuments();
		this.onGetLogs();
	}
	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.onGetDocuments();
		}
	}
	onGetLogs() {
		Request.authRequest(Routes.logs.document(this.props.match.params.id), 'GET').then((response) => {
			response.json().then(body => {
				this.setState({ logs: body })
			})
		})
	}
	onGetDocuments() {
		Request.authRequest(Routes.recipes.batchSheet(this.props.match.params.id),
			'GET').then((response) => {

				if (response.status === 200) {
					response.json().then(body => {
						this.setState({ doc: body, loaded: true })
						this.onGetLogs();
					})
				}
				else {
					response.json().then((error) => {
						this.setState({ loaded: true })
						toast.error(error.message);
					});
				}
			}).catch((error) => {
				toast.error(error.message);
			}
			)
	}
	onSaveChanges(contentType, content) {
		let doc = JSON.parse(JSON.stringify(this.state.doc));
		switch (contentType) {
			case 'ID':
				doc.user_defined_id = content;
				break;
			case 'Name':
				doc.name = content;
				break;
			case 'Category':
				doc.category = content;
				break;
			case 'Notes':
				doc.notes = content;
				break;
			case 'Date':
				if (content === null) {
					doc.date_expiration = null;
				}
				else {
					doc.date_expiration = content
				}
				break;
			default:
				return;
		}
		Request.authRequest(Routes.documents.edit(this.state.doc.batch_sheet_id),
			'PUT', JSON.stringify(
				doc
			), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetDocuments();
					this.onGetLogs();
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)

	}
	onSaveTagsChanges(tagsArray) {
		/* this string, unlike other tags operations is the whole tags list */
		let doc = this.state.doc;
		if (tagsArray.length === 0) {
			toast.error('Please enter tags. All documents must have at least one tag.')
			return
		}
		let trimmedTags = TagCleaner.parseTagArray(tagsArray);
		Request.authRequest(Routes.documents.tag(doc.batch_sheet_id),
			'PUT', JSON.stringify({
				tag_names: trimmedTags,
			}), 'application/json'
		).then((response) => {
			if (response.status === 200) {
				response.json().then(body => {
					this.onGetDocuments();
					this.onGetLogs();
					toast.success('Successfully applied tags!')
				});
			} else {
				response.json().then((error) => {
					toast.error(error.message);
				});
			}
		}).catch((error) => {
			toast.error(error.message);
		}
		)
	}

	onReturnNavigation = () => {
		this.props.history.push(`/recipes/?status=accepted`)
	}
	onShowLogs = () => {
		this.setState({ showVersionHistory: false });
	}
	onShowVersionHistory = () => {
		this.setState({ showVersionHistory: true });
	}
	goToRecipe = () => {
		window.location.href = '/recipes/' + this.state.doc.batch_sheet.recipe_id;
	}
	render() {

		const doc = this.state.doc;
		let date_expiration;
		let versionHistory;
		let docLoader = <div className="loaderContainer"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>

		let sharedWithList = (<div className="helpText">This document has not been sent to anyone</div>);
		let fromOrganization;
		let versionNumber;
		let status;
		let draft;
		let send_received;
		let is_sender;
		let pendingApprovalButtons;
		let is_active;
		if (doc) {
			let batch_sheet = doc.batch_sheet;
			let date_sent = new Date(batch_sheet.date_created);
			date_sent = date_sent.toDateString();
			let receiving_status = batch_sheet.status;
			if (batch_sheet.is_active === false) {
				is_active = <div className="helpText">This recipe has been archived by the sender. This batch sheet may be out of date. Contact the sender for a new batch sheet.</div>
			}
			if (!batch_sheet.is_receiver) {
				is_sender = true;
				send_received = <div>Sent to {batch_sheet.receiving_organization_name} on {date_sent}. Their status: {receiving_status}</div>
			}
			else {
				if (doc.status == 'Pending') {
					pendingApprovalButtons = <PendingApprovalButtons onGetDocuments={this.onGetDocuments} activeDocuments={[doc]} />
				}
				send_received = <div>Received from {batch_sheet.sending_organization_name} on {date_sent}.</div>
			}
			let statusWords = doc.status;
			if (doc.tags && doc.tags.length > 0) {
				let tag_names = doc.tags.map((entity, i) => {
					return entity.name;
				})
				if (tag_names.indexOf("Draft") > -1) {
					draft = "DRAFT";
					statusWords = "DRAFT";
				}
				if (tag_names.indexOf("Archive") > -1) {
					statusWords = "Archive";
				}
			}
			status = <div className="documentViewer-uneditableHelpText noselect">Status: {statusWords}</div>





			if (doc.from_organization) {
				fromOrganization = doc.from_organization.name
			}
		}
		let batchSheet;
		if (this.state.doc) {
			batchSheet = <BatchSheetViewer batch_sheet={this.state.doc.batch_sheet} recipe={this.state.recipe} doc={this.state.doc} />
		}

		return (
			<div className='document-viewer'>



				<div className="document-viewer-middle boxShadow">
					<div className="is-spaced-between batchSheetNavViewer-navbuttonContainer">
						<button className='button-ReturnViewer' onClick={this.onReturnNavigation}> Back to Recipes </button>
						{is_sender ? <div className='button-ReturnViewer' onClick={this.goToRecipe}>Go To Recipe</div> : null}
					</div>
					<div className="document-viewer-header">
						<div className="is-spaced-between">
							<div className="document-viewer-header-text wordbreak">
								{doc.name}
								{this.state.loaded ? null : docLoader}

							</div>
							<div className="batchSheetNavViewer-rightButtons-container">
								{pendingApprovalButtons}
								<ArchiveButton
									docs={[]} onGetDocuments={this.onGetDocuments}
									activeBatchSheets={[doc]} onGetTags /></div>
						</div>
					</div>
					<div className='document-viewer-fields'>
						{send_received}
						<div className='document-viewer-fields-first'>

							<InfoField content={doc.name} contentType='Name'
								className="document-viewer-fields-content"
								doc={doc}
								onClickOut={this.onSaveChanges} />

							<InfoField content={doc.user_defined_id}
								className="document-viewer-fields-content"
								contentType='ID'
								doc={doc} onClickOut={this.onSaveChanges} />

							<TagsField content={doc.tags} contentType='Tags' doc={doc}
								onClickOut={this.onSaveTagsChanges} />
							<DocumentNotesField content={doc.notes} contentType='Notes'
								doc={doc} onClickOut={this.onSaveChanges} />
							<div className="documentViewer-uneditableSection">
								<div className="documentViewer-uneditableHelpText noselect">{versionNumber}</div>

								{status}


							</div>
							{is_active}
						</div>
					</div>


				</div>

				<div className="batchSheetNav-viewerContainer boxShadow">
					{batchSheet}
				</div>
				<div>
					{this.state.error}

				</div>
			</div>
		);
	}
}

export default withRouter(CookieWrapper(DocumentViewer));
