import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../../Modal/Modal';
import { Send } from '../../Icons/IconLibrary';
import ContactsSearch from '../../ContactsSearch/ContactsSearch';
import LoadingButton from '../../Buttons/LoadingButton';
class SendBOMToButton extends React.Component {
  constructor() {
    super();
    this.onSendEmails = this.onSendEmails.bind(this);
    this.timerID = null;
    this.state = {
      recipients: '',
      showModal: false,
      message: '',
      urgent: false,
      subject: '',
      loading: false,
    }
  }
  toggleUrgent = () => {
    this.setState({ urgent: !this.state.urgent })
  }
  getDocuments = () => {

  }
  onShowModal = () => {
    this.setState({ showModal: true })
  }
  onHideModal = () => {
    this.setState({
      showModal: false, only_as_pdf: false
    })
  }
  onSendEmails(emails) {
    this.setState({ recipients: emails })
  }
  onEnterRecipient = (e) => {
    this.setState({
      recipients: e.target.value,
    });
  }
  onChangeDocumentInfo = (e) => {
    this.setState({
      message: e.target.value,
    });
  }
  onChangeSubject = (e) => {
    this.setState({
      subject: e.target.value,
    });
  }
  onSendDocument = () => {

    let toastID = toast.info('Sending documents...', { autoClose: false });
    if (this.state.recipients === '') {
      toast.update(toastID, { render: `Please enter a recipient`, type: toast.TYPE.ERROR, autoClose: 5000 });
      return;
    }
    let recipients = this.state.recipients.concat();
    this.setState({ loading: true });
    let recipe = this.props.recipe;
    Request.authRequest(Routes.recipes.billOfMaterialsSend(recipe.id),
      'POST', JSON.stringify({
        email: recipients,
        message: this.state.message,
        urgent: this.state.urgent,
        subject: this.state.subject,
      }), 'application/json'
    ).then(response => {
      if (response.status === 200) {
        toast.update(toastID, {
          render: `Successfully sent BOM- to all
                recipients.`, type: toast.TYPE.SUCCESS, autoClose: 5000
        });
        this.setState({ loading: false, urgent: false, message: '', recipients: '', subject: '', only_as_pdf: false, showModal: false })

        this.props.onGetLogs();


      } else {
        response.json().then((error) => {
          this.setState({ loading: false })

          toast.update(toastID, {
            render: `Could not send some of your documents. Please try again.`
            , type: toast.TYPE.ERROR, autoClose: 5000
          });
          toast.error(error.message);
          console.log(error)
          if (error.errors) {
            toast.error(error.errors[0])
          }
        });
      }


    }).catch((error) => {
      toast.update(toastID, {
        render: `Could not send some of your documents. Please try again.`
        , type: toast.TYPE.ERROR, autoClose: 5000
      });
    }
    )
  }

  render() {
    return (
      <div >
        <button className='headerButton noselect' id="buttonSendDocumentTo" onClick={this.onShowModal}
          alt='Send currently selected documents to someone' title='Send currently selected documents to someone' >
          Send
        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Send Bill of Materials' >

          <div className="sendDocumentsTo-modalContainer" id="bom-send">

            <div className="helpText-CreateTagButton">Enter the emails of the recipients to send
              this bill of materials to. Bill of materials can only be sent internally.
            </div>

            <ContactsSearch onSendEmails={this.onSendEmails} />
            <div>
              <div>
                <div>Optional Subject Line</div>
                {/* <div className="sendDocuments-urgentCheckbox">
                  <input
                    checked={this.state.urgent}
                    name='expiresBoolean'
                    type='checkbox'
                    id='expiresBoolean'
                    onChange={this.toggleUrgent}
                    title="Adds Urgent to the subject line." />
                  <div>Mark as Urgent
                  </div>
                </div> */}
              </div>
              <textarea
                value={this.state.subject}
                type='text'
                className='send-message-field'
                onChange={this.onChangeSubject}
                placeholder="Optional Subject" />
            </div>
            <div>Optional Message</div>
            <textarea
              value={this.state.string}
              type='text'
              className='send-message-field'
              id="send-message-message"
              onChange={this.onChangeDocumentInfo}
              placeholder="Optional Message" />
          </div>

          <button className='modal-button primary'>
            <LoadingButton onClick={this.onSendDocument}
              text='Send' isLoading={this.state.loading} />
          </button>

        </Modal>
      </div>
    )

  }
}
export default SendBOMToButton;
