import React from 'react';

import './IngredientRow.css';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import IngredientDocumentRow from './IngredientViewer/IngredientDocumentRow';
import RelatedTagDocuments from '../RelatedTagDocuments/RelatedTagDocuments';
class IngredientRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      deleting: false,
      editing: false,
      percentValue: this.props.recipe_ingredient.weight_ratio
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.deleting != this.props.deleting && this.props.deleting == false) {
      this.setState({ deleting: false })
    }
  }
  onSaveChanges() {
    Request.authRequest(
      Routes.recipes.changeIngredient(this.props.recipe.id,
        this.props.recipe_ingredient.ingredient.id),
      'PUT', JSON.stringify({
        weight_ratio: this.state.percentValue
      }), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.props.onGetRecipe();
          this.props.onGetLogs();
          toast.success(`Successfully changed weight ratio of ${this.props.recipe_ingredient.ingredient.name} to ${this.state.percentValue}!`)
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )
  }

  handleDblClick = (e) => {
    if (this.node.contains(e.target)) {
      this.setState({
        editing: true
      })
    }
    else if (this.state.editing !== false) {

      this.onSaveChanges();
      this.setState({ editing: false })
    };
  }
  componentWillMount() {
    document.addEventListener('dblclick', this.handleDblClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('dblclick', this.handleDblClick, false);
  }
  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.setState({
        editing: false,
        editingValue: this.props.recipe_ingredient.weight_ratio
      })
    }
  }
  navIngredient = () => {
    window.location.href = `/ingredients/${this.props.recipe_ingredient.ingredient.id}`;
  }
  onToggleExpand = () => {
    this.setState({ expanded: !this.state.expanded })
  }
  onChange = (e) => {
    this.setState({ percentValue: e.target.value });
  }
  onToggleCheckbox = () => {
    this.setState({ deleting: !this.state.deleting }, this.sendDelete())

  }
  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.onSaveChanges();

      this.setState({ editing: false })
    }

  }
  sendDelete = () => {
    if (this.state.deleting != true) {
      this.props.addIngredientToDelete(this.props.recipe_ingredient);
    }
    else {
      this.props.removeIngredientToDelete(this.props.recipe_ingredient);
    }
  }
  render() {
    let recipe_ingredient = this.props.recipe_ingredient;
    let ingredient = recipe_ingredient.ingredient;
    let documents = <div className="helpText">No documents. Add a related tag to associate documents!</div>;
    if (ingredient && ingredient.related_tags && ingredient.related_tags.length > 0) {
      documents = <RelatedTagDocuments related_tags={ingredient.related_tags} />
    }
    let versionNumber;
    if (ingredient.version_history_with_self.length > 1) {
      versionNumber = '- Version ' + ingredient.version_number
      if (!ingredient.version_number && ingredient.version_history_with_self.length > 1) {
        versionNumber = '- Version 0'
      }
      if (ingredient.version_number == ingredient.version_history_with_self.length - 1) {
        versionNumber = versionNumber + " - current"
      }
    }
    let price;
    switch (this.props.currentUnits) {
      case 'kg':
        price = ingredient.f_price_kg
        break
      case 'lb':
        price = ingredient.f_price_lb
        break
      case 'L':
        price = ingredient.f_price_L
        break
      case 'gal':
        price = ingredient.f_price_gal
        break
      default:
    }
    return (
      <div className="ingredientRow-container">

        <tr className="ingredientRow-info">

          {this.props.deleting ?
            <td><div>
              <input type="checkbox" checked={this.state.deleting} value={this.state.deleting} onChange={this.onToggleCheckbox} />
            </div></td> : null}
          <td className="ingredient-name ingredient-recipe-viewer-name" onClick={this.navIngredient}>{ingredient.name} {versionNumber}
          </td>
          {this.state.editing ? <td ref={node => this.node = node}>
            <input value={this.state.percentValue} type="float" onChange={this.onChange} onKeyPress={this.handleEnter} />
          </td> :
            <td className="ingredient-percent" ref={node => this.node = node}>{recipe_ingredient.weight_ratio}%</td>}

          <td className="ingredient-cost">${price}</td>
          <td className="ingredient-recipe-documents" onClick={this.onToggleExpand}> View documents {this.state.expanded ? <td className="arrow-down"></td> : <td className="arrow-right"></td>}</td>

        </tr>
        {this.state.expanded ? <div className="ingredient-expanded">
          {documents}
        </div> : null}
      </div>
    );
  }
}

export default IngredientRow;
