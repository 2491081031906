import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import './IngredientTemplates.css';
class NewIngredientTemplateButton extends React.Component{
    constructor(){
        super();
        this.state = {
            show: false,
            name:''
        }
    }
    changeName = (e) =>{
        this.setState({name:e.target.value})
    }
    onMakeNewTemplate = () =>{
        Request.authRequest(Routes.ingredients.templateList,
                          'POST',JSON.stringify({
                                    name:this.state.name.trim(),
                    			}), 'application/json'
                        ).then((response) => {
                          if (response.status===200){
                          response.json().then(body => {
                            this.props.getTemplateList()
                            });
                          } else {
                            response.json().then((error) => {
                              toast.error(error.message);
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
    }
    toggleButtons = () =>{
        this.setState({show:!this.state.show})
    }
    render(){
        if (!this.state.show){
            return(
                <div className = "ingredient-template-button-new make-new-template-title" onClick = {this.toggleButtons}> Make New Template</div>
            )
        }
        return (
            <div className = "ingredient-template-button-new make-new-template-title">
                <div onClick = {this.toggleButtons} > Make New Template</div>
                <input onChange = {this.changeName} value = {this.state.name} className = "new-template-input"
                placeholder = {'Enter Template Name'}/>
                
                <div className = "confirm-cancel-button-container">
                <div className = "recipe-edit-button-confirm" onClick = {this.onMakeNewTemplate}>Add</div>
                <div className = "recipe-edit-button-cancel" onClick = {this.toggleButtons}>Hide</div>
                </div>
            </div>
        )
    }
}

export default NewIngredientTemplateButton