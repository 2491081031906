import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import {toast} from 'react-toastify';
import './RemindSignatureButton.css';
class RemindSignatureButton extends React.Component{
  constructor(props){
    super(props);
    this.clearTimer = this.clearTimer.bind(this);
    this.timerID = null;
    this.state={
      showConfirmation:false,
      urgent:false,
    }
  }
  clearTimer = ()=>{
    this.timerID = null;
  }
  showConfirmation=()=>{
    this.setState({showConfirmation:true})
  }
  hideConfirmation=()=>{
    this.setState({showConfirmation:false})
  }
  onRemindUsers = (e) => {
    e.stopPropagation();
    if (this.timerID) {
      return;
    }
    this.timerID = window.setTimeout(() => {this.clearTimer()},5000);
    Request.authRequest(Routes.documents.signatureRemind,'POST',
    JSON.stringify({signature_requests:this.props.signatures, urgent:this.state.urgent}),
    'application/json').then(
      response=>{
        if (response.status ===200){
          response.json().then(body=>{
            toast.success('Successfully sent reminder');
            this.setState({showConfirmation:false,urgent:false})
          })

        }else(
          response.json().then(body=>{
            toast.error(body.errors)
          })
        )
      })
  }
  toggleUrgent = () =>{
    this.setState({urgent:!this.state.urgent})
  }
  render(){
    return (
      <div className = "button-remindSignatureRequest-container">
      <div className = {this.props.multiple?"button-remindSignatureRequest":
        "button-remindSignature-single"} onClick = {this.showConfirmation}>
        {this.props.multiple? "Remind All Unsigned" : "Remind"}
      </div>
      {this.state.showConfirmation?
        <div className = "button-remindSignatureConfirmationBox boxShadow">
          <div className = "remindConfirmationCheckbox">
          <input
            checked = {this.state.urgent}
            name = 'expiresBoolean'
            type='checkbox'
            id = 'expiresBoolean'
            onChange = {this.toggleUrgent}/>
          <div>Mark as Urgent
          </div>
          </div>
          <div onClick = {this.onRemindUsers} className = "sendReminderSignatureButton">Send Reminder
          </div>
          <div onClick = {this.hideConfirmation} className = "sendReminderSignatureButton-Cancel"> Cancel
          </div>
        </div>
        :null}

    </div>)
  }
}
export default RemindSignatureButton;
