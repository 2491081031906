import React from 'react';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';

import './App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './ReactDatePicker.css';
import { ToastContainer } from 'react-toastify';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import Home from './components/Home/Home';
import Signup from './components/Auth/Signup';
import LandingPage from './components/LandingPage/LandingPage';
import FeaturesPage from './components/LandingPage/FeaturesPage';
import PricingPage from './components/LandingPage/PricingPage';
import ErrorBoundary from './ErrorBoundary';
import Login from './components/Auth/Login';
import Logout from './components/Auth/Logout';
import Confirm from './components/Auth/Confirm/Confirm';
import ResendConfirm from './components/Auth/Confirm/ResendConfirm';
import Settings from './components/Settings/Settings';
import Navigation from './components/Navigation/Navigation';
import RecipeViewer from './components/Recipes/RecipeViewer/RecipeViewer';
import BillofMaterialsViewer from './components/Recipes/RecipeViewer/BillofMaterialsViewer';
import IngredientsViewer from './components/Ingredients/IngredientViewer/IngredientsViewer';
import IngredientsNavigation from './components/Ingredients/IngredientsNavigation';
import Logs from './components/Logs/Logs';
import DownTimePage from './components/Layout/DownTimePage';
import OrganizationJoin from './components/Auth/OrganizationJoin';
import OrganizationCreate from './components/Auth/OrganizationCreate';
import Notifications from './components/Notifications/Notifications'
import ErrorPage from './components/Layout/ErrorPage';
import PageNotFound from './components/Layout/PageNotFound';
import ToS from './components/Layout/ToS';
import FAQ from './components/FAQ/FAQ';
import PrivacyPolicy from './components/Layout/PrivacyPolicy';
import TagManager from './components/TagManager/TagManager';
import PasswordReset from './components/PasswordReset/PasswordReset';
import Upload from './components/Documents/DocumentsUpload';
import UploadFolder from './components/Documents/DocumentsFolderUpload';
import EnterReset from './components/PasswordReset/EnterReset';
import PatchNotes from './components/LandingPage/PatchNotes';
import Cookies from './helpers/Cookies';
import BatchSheetViewer from './components/Documents/BatchSheetNav/BatchSheetViewer';
import DocumentViewer from './components/Documents/DocumentViewer';
const HeaderWithRouter = withRouter(Header);
const FooterWithRouter = withRouter(Footer);

class App extends React.Component {
  render() {
    let loggedIn = localStorage.getItem('loggedIn');
    let authenticatedPage = true;
    if (window.location.pathname === '/downtime') {
      window.location.href = '/'
    }


    switch (window.location.pathname) {
      case '/login':
      case '/':
      case '/signup':
      case '/organization/join':
      case '/organization/create':
      case '/passwordreset':
      case '/reset-password':
      case '/staging/reset-password':
      case '/production/reset-password':
      case '/error':
      case '/confirm':
      case `/resend-confirm`:
      case `/logout`:
      case `/features`:
      case `/pricing`:
        authenticatedPage = false;
        break;
      case `/sendreceive/requests/`:
        if (!loggedIn) {
          authenticatedPage = false;
        }
        break;
      default:
    }
    let authenticated = false;
    const hostname = window && window.location && window.location.hostname;
    if (loggedIn || hostname === 'localhost') {
      authenticated = true;
    }
    if (!authenticated && authenticatedPage) {
      window.location.href = '/login';
      return (
        <Router>
          <div className='content'>

            <ToastContainer />
            <div className='content-body'>
              <Route exact path='/' component={LandingPage} />
              <Route path='/login' component={Login} />
            </div>
            <FooterWithRouter />
          </div>
        </Router>
      )
    }

    return (
      <DragDropContextProvider backend={HTML5Backend}>
        <Router>
          <div className='content'>
            <HeaderWithRouter />
            <ToastContainer className='toast-container' />
            <ErrorBoundary>
              <div className='content-body'>
                <Switch>
                  <Route exact path='/home' component={Home} />
                  <Route exact path='/recipes' component={Navigation} />
                  <Route exact path='/' component={LandingPage} />
                  <Route exact path='/features' component={FeaturesPage} />
                  <Route exact path='/pricing' component={PricingPage} />
                  <Route exact path='/recipes/:id' component={RecipeViewer} />
                  <Route exact path='/recipes/:id/bom' component={BillofMaterialsViewer} />
                  <Route exact path='/documents/:id' component={DocumentViewer} />
                  <Route exact path='/ingredients' component={IngredientsNavigation} />
                  <Route exact path='/ingredients/:id' component={IngredientsViewer} />
                  <Route exact path='/batch_sheet/:id' component={BatchSheetViewer} />
                  <Route path='/signup' component={Signup} />
                  <Route path='/login' component={Login} />
                  <Route path='/logout' component={Logout} />
                  <Route path='/confirm' component={Confirm} />
                  <Route path='/resend-confirm' component={ResendConfirm} />
                  <Route path='/organization/join' component={OrganizationJoin} />
                  <Route path='/organization/create' component={OrganizationCreate} />
                  <Route path='/upload' component={Upload} />
                  <Route path='/upload-folder' component={UploadFolder} />
                  <Route path='/settings' component={Settings} />
                  <Route path='/logs' component={Logs} />
                  <Route path='/help' component={ErrorPage} />
                  <Route path='/tags' component={TagManager} />
                  <Route path='/error' component={ErrorPage} />
                  <Route path='/termsofservice' component={ToS} />
                  <Route path='/privacypolicy' component={PrivacyPolicy} />
                  <Route path='/patchnotes' component={PatchNotes} />
                  <Route path='/passwordreset' component={PasswordReset} />

                  <Route path='/reset-password' component={EnterReset} />
                  <Route path='/staging/reset-password' component={EnterReset} />
                  <Route path='/production/reset-password' component={EnterReset} />
                  <Route component={PageNotFound} />
                </Switch>
              </div>
            </ErrorBoundary>
            <FooterWithRouter />
          </div>
        </Router>
      </DragDropContextProvider >
    );
  }
}

export default App;
