import React from 'react';
import './FeaturesList.css';
class FeaturesList extends React.Component {
  render() {
    return (<div className = "featuresList-container boxShadow">
      <div className = "featuresList-title">Ivy's Complete Feature List</div>

      <div className = "featuresList-featureTitle">Tags Based Filing</div>
      <div className = "featuresList-featureText">Tags are the core organizational system in Ivy. Tags allow you to create multiple virtual paths to any document, allowing you to group, filter and search documents by any dimension. Our tags are hierarchical, meaning you can nest tags inside of other tags. One tag can also have multiple parents, allowing you to access a specific tag from two or more different angles. The only restriction on tags is a tag cannot be nested within itself, forming a loop. Any number of tags can be added to a document. Tags are customizable and flexible, allowing you to set up your organization however you want. Tags apply additively when searching, i.e. “Client 1” + “2019” would return all documents that are tagged with both tags or a child tag of either Client 1 or 2019. Some example use cases are listed below. Filter by Supplier, Ingredient, SKU Many companies maintain a master ingredient spreadsheet, mapping suppliers to ingredients to SKUs. This many to many mapping is solved easily by using tags, which allow you to sort by any dimension. Add Audit Related Tags The audit structure doesn’t fit well within your regular organizational methods. By tagging documents with audit control points, you can quickly retrieve relevant documents, reducing labor spent on preparing for an audit. Sort by Production Runs, or Batch/Lot Codes Production run and shipment documents are difficult to sort, as they often need to correspond to different times. Use tags to sort by production run dates. We recommend putting the lot codes as child tags of the production run tag, so you can filter down into the individual lots used in the production run.
      </div>
      <div className = "featuresList-featureTitle">Sent Document Tracking</div>
      <div className = "featuresList-featureText">Ivy tracks who you have sent documents to and when the document was sent. This can be viewed on the document viewer page.
      </div>
      <div className = "featuresList-featureTitle">Version Management</div>
      <div className = "featuresList-featureText">Documents can be versioned at any point, either by uploading a new document to replace the existing document, or selecting another document already uploaded to Ivy. New versions inherit the tags of the old versions. Old versions are automatically archived.
      </div>

      <div className = "featuresList-featureTitle">Automatic Versioning</div>
      <div className = "featuresList-featureText">If another organization uses version history in Ivy and sends you a document that is a new version of a document you have previously received, you will be prompted that the document is a new version and asked if you would like to mark it as such.
      </div>
      <div className = "featuresList-featureTitle">Internal Document Approval Workflow</div>
      <div className = "featuresList-featureText">An admin can request approval from any other admin or superadmin. Documents that require signatures will be marked as such, and notifications will be sent out to the requested users. The requestor can also specify a document to version once all signatures are approved.
      </div>
      <div className = "featuresList-featureTitle">Document Metadata and Search</div>
      <div className = "featuresList-featureText">Any document can also have a set of optional metadata (the only required one is a filename), including an ID, expiration date, and notes. These fields are fully searchable. We do not currently support full text search of the actual document.
      </div>
      <div className = "featuresList-featureTitle">Document Upload and Mass/Folder Import</div>
      <div className = "featuresList-featureText">Documents can be imported individually, as a batch, or entire folder systems can be dumped into the Ivy platform. At least one tag must be specified if documents are not contained within a folder. If a folder is selected, our system will process the folder names into tags and apply them to the relevant documents.
      </div>
      <div className = "featuresList-featureTitle">Expiration Tracking and Search</div>
      <div className = "featuresList-featureText">Expiration dates on documents can be specified. Upon upload, documents expire after 1 year by default, although this can be specified. Users can filter by expiration date, searching by expires before or expires after specific dates.
      </div>
      <div className = "featuresList-featureTitle">Expiration Notifications</div>
      <div className = "featuresList-featureText">An organization can specify how many days in advance of a document expiration they want to send a notification. You can set multiple values i.e. 60, 30, 15 days before expiration. If the document was originally uploaded through Ivy from an external organization with an account, they will also receive the notification of expiration. See Notifications for an explanation of how the notifications system works.
      </div>
      <div className = "featuresList-featureTitle">Document Archiving</div>
      <div className = "featuresList-featureText">Documents can be archived, which will hide them from the default view. At any time, you can select “Show Archived” or “Show Both Archived and Active” to retrieve archived documents.
      </div>
      <div className = "featuresList-featureTitle">Supported File Types and In Application Preview</div>
      <div className = "featuresList-featureText">Any file type can be stored in Ivy, up to a 5 GB per file limit. For in application preview, we only support .docx, .doc, .jpg, .png, and .pdf filetypes. .doc and .docx may appear differently formatted than viewed in Microsoft Word. All others must be viewed outside of the Ivy platform.
      </div>
      <div className = "featuresList-featureTitle">Tags Management</div>
      <div className = "featuresList-featureText">Our tags management page allows you to quickly establish relationships between tags, delete, rename or pin tags. You can also merge a tag into another tag, which inherits all documents, child and parent tags and tagged templates of the merged tag.
      </div>
      <div className = "featuresList-featureTitle">Pinned Tags</div>
      <div className = "featuresList-featureText">Favorite tags the same way you favorite folders for fast access.
      </div>
      <div className = "featuresList-featureTitle">Templates</div>
      <div className = "featuresList-featureText">Templates help you speed up regular collection of documents from your vendors, copackers or customers. Templates allow you to specify document types which convert into a form for the people sending documents to you to fill out. Senders do not need to have an account – they receive an authorized link to their email to fill out the form. You can then track completion progress in a dashboard, and reuse the template for regular document requests. Partial completion is allowed, and progress is saved.
      </div>
      <div className = "featuresList-featureTitle">Automated Tagging - Templates</div>
      <div className = "featuresList-featureText">Documents received through the template system are fully integrated with the tags system, allowing you to prespecify tags for automatic filing upon approval. There are four levels of tags which you can specify, allowing for full customization – tags that apply to all documents received through the template, tags that apply to specific line items or a specific document received, and tags to apply to the specific request sent out (i.e. all documents sent to the Milk supplier get tagged with Milk). We also automatically tag documents with the sender’s organization if they have an Ivy account, or email otherwise. The fourth level of tags allow you to reuse templates based off information from the supplier end by adding questions to the templates. The answers to the questions become tags which apply to the documents. You can specify questions whose answers apply for all documents, or questions for specific document slots. This helps particularly automate the production run or batch/lot code management, as your suppliers/comanufacturers can specify that information.
      </div>
      <div className = "featuresList-featureTitle">Messaging</div>
      <div className = "featuresList-featureText">Our templates have a built in messaging system to allow conversation on a specific template request.
      </div>
      <div className = "featuresList-featureTitle">External Document Approval/Rejection</div>
      <div className = "featuresList-featureText">If you send documents to another organization with an account on Ivy, you will get a notification when they have accepted or rejected the document.
      </div>
      <div className = "featuresList-featureTitle">Contacts Management, Search and Autosuggest</div>
      <div className = "featuresList-featureText">Contacts can be imported from your Gmail or Outlook account for autosuggest when sending documents or templates to other people through Ivy. If a contact doesn’t already exist, it will be created automatically. You can also further specify searchable information for contacts, including: first name, last name, email, job title, phone, organization, address and notes.
      </div>
      <div className = "featuresList-featureTitle">User Roles</div>
      <div className = "featuresList-featureText">We support three primary user roles: superadmins, which can modify any document including other users, admins, which can modify documents/templates, and users which are read only. Further permissioning is controlled by tag restrictions.
      </div>
      <div className = "featuresList-featureTitle">Access Control through Tags</div>
      <div className = "featuresList-featureText">Since we do not use folders, access control is controlled through granting users tags. A user has access to a tag and all of its descendant tags. In order to see a document, a user must have access to at least one tag on the document. Tags which a user does not have access to are visible, but not accessible.
      </div>
      <div className = "featuresList-featureTitle">System Logs</div>
      <div className = "featuresList-featureText">Modifications to any item stored in Ivy generate a system log with the user name, timestamp, the action, and the ability to append a note. These logs can be accessed in the log screen, or on the screen for the specific viewer.
      </div>
      <div className = "featuresList-featureTitle">Notifications</div>
      <div className = "featuresList-featureText">Notifications are bundled into a batch email, which is sent out according to user preferences (maximum daily). Notifications track interactions with other organizations, or document expiration. A superadmin can adjust which users receive notifications. For more detail, log into Ivy and go to the notifications tab.
      </div>
      <div className = "featuresList-featureTitle">Backups/Export</div>
      <div className = "featuresList-featureText">We support export of all documents and include their metadata into a csv file, zipped into a folder. This feature is by request emailing nathan@ivydocuments.com whenever a backup is requested.
      </div>
      <div className = "featuresList-featureTitle">Interface and Access</div>
      <div className = "featuresList-featureText">Ivy is currently a web based application. We support Firefox, Chrome, and Safari browsers, although features may appear differently on each browser. We recommend using Chrome.
      </div>
      <div className = "featuresList-featureTitle">Assisted Onboarding</div>
      <div className = "featuresList-featureText">We offer assisted onboarding, including account setup, contacts import, folder system import, and tag hierarchy setup and import. This is conducted on an individual client basis, as everyone’s file systems are different.
      </div>
    </div>)
  }
}
export default FeaturesList
