import React from 'react';
import TopTag from '../Tags/TopTag';
import qs from 'qs';
import Search from '../Search/Search';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
class TagNavigatorRelatedTags extends React.Component {
  constructor() {
    super();
    this.onAddActiveTag = this.onAddActiveTag.bind(this);
    this.onAddExcludedTag = this.onAddExcludedTag.bind(this);
    this.handleSearchRequest = this.handleSearchRequest.bind(this);
    this.state = {
      topTags: [],
      searchQuery: '',
      letter: '',
      perPage: 20,
      has_next: true,
      isLoaded: false,
      isNestedFiltered: false,
    }
  }
  handleSearchRequest = (updated_string) => {
    this.setState({ searchQuery: updated_string, letter: '' },
      async () => {
        this.getFilteredList()
      }
    )
  }
  componentDidMount() {
    this.getFilteredList();
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.related_tags != prevProps.related_tags) {

      this.getFilteredList();
    }
  }
  getFilteredList = () => {
    let related_tags_names = this.props.related_tags.map((tag) => { return tag.name });
    /* so this is kinda weird in that active tags arent actually active, and we aren't sending 
    the active list. Instead we're sending the filtered tag list. The backend processes this as an OR operator.
    We don't end up sending the active tag list because currently the expected number of tags is small. */
    const searchString = {
      page: 1,
      per_page: 20,
      nested: this.state.isNestedFiltered,
    };
    searchString.related_tags = related_tags_names.join()
    let base_url;

    if (this.state.searchQuery !== '') {
      searchString.q = this.state.searchQuery;
    }

    base_url = qs.stringify(searchString);
    base_url = '?' + base_url
    Request.authRequest(Routes.tags.search(base_url),
      'GET').then((response) => {

        response.json().then(body => {
          this.setState({
            topTags: body.results.slice(0, 20),
            has_next: body.has_next,
            isLoaded: true
          })
        })
      });

  }

  async onAddActiveTag(tag) {
    if (Array.isArray(this.props.activeTags)) {
      await this.props.onChangeTags([...this.props.activeTags, tag]);
    }
    else if (this.props.activeTags) {
      await this.props.onChangeTags([this.props.activeTags, tag])
    } else {
      await this.props.onChangeTags([tag])
    }
    if (this.props.excludedTags && this.props.excludedTags.indexOf(tag) > -1) {
      this.props.onChangeExcludedTags(this.props.excludedTags.filter(tagnames => tagnames !== tag));
    }
  }
  async onAddExcludedTag(tag) {
    if (Array.isArray(this.props.excludedTags)) {
      await this.props.onChangeExcludedTags([...this.props.excludedTags, tag]);
    }
    else if (this.props.activeTags) {
      await this.props.onChangeExcludedTags([this.props.excludedTags, tag])
    } else {
      await this.props.onChangeExcludedTags([tag])
    }
    if (this.props.activeTags.indexOf(tag) > -1) {
      this.props.onChangeTags(this.props.activeTags.filter(tagnames => tagnames !== tag));
    }
  }
  seeMore = () => {
    if (this.state.has_next === false) {
      return
    }
    const searchString = {
      page: 1,
      per_page: this.state.perPage + 20,
    };

    let base_url;

    if (this.state.searchQuery !== '') {
      searchString.q = this.state.searchQuery;
    }

    base_url = qs.stringify(searchString);
    base_url = '?' + base_url;
    Request.authRequest(Routes.tags.search(base_url),
      'GET').then((response) => {
        response.json().then(body => {
          this.setState({ topTags: body.results, perPage: this.state.perPage + 20, has_next: body.has_next }
          )
        })
      });
  }

  onLastSelected = () => {
    //empty intentionally
  }
  render() {
    let topTagList = <div className="loaderContainer"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>;
    if (this.state.isLoaded) {
      topTagList = <div className="smallHelpText">No tags</div>;
      if (this.state.topTags.length > 0) {
        if (this.state.letter === '') {
          topTagList = this.state.topTags.filter(tags => tags.is_accessible).map(
            (entity, i) => {
              return (
                <TopTag
                  tag={entity} onAddActiveTag={this.onAddActiveTag}
                  onLastSelected={this.onLastSelected}
                  onAddExcludedTag={this.onAddExcludedTag}
                  key={i} />)
            }
          );
        } else {
          topTagList = this.state.topTags.map(
            (entity, i) => {
              return (
                <TopTag
                  tag={entity} onAddActiveTag={this.onAddActiveTag}
                  onAddExcludedTag={this.onAddExcludedTag}
                  onLastSelected={this.onLastSelected}
                  key={i} />)
            }
          );
        }
      }
    }

    return (
      <div className="relatedTagNavigator-container boxShadow">
        <div className="relatedTags-header noselect">Tags Bank

        </div>
        <Search placeholder="Search for a tag" sendSearch={this.handleSearchRequest} />
        <div className="topTagList" id="introJs-tagDictionary">
          {topTagList}
        </div>
        {this.state.has_next ? <div className="topTag-seeMore" onClick={this.seeMore}> See More</div> : null}
      </div>

    )
  }
}
export default TagNavigatorRelatedTags;
