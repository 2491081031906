import React from 'react';
import moment from 'moment';
import './TimeDiff.css';
class OverallTimeDiff extends React.Component{

  // Takes in permissions and returns their average time to acceptance and time to signature
  render(){
    if (!this.props.docs){
      return null
    }
    let documents = this.props.docs;
    let signatureCounter=0;
    let sentCounter=0;
    let signatureTime = 0;
    let sentTime = 0;
    let unsignedCounter=0;
    let unacceptedCounter=0;
    for (let i = 0; i < documents.length; i++){
      for (let j = 0; j< documents[i].signatures.length; j++){
        let individualSignatureTime = moment(documents[i].signatures[j].date_modified).diff(
            moment(documents[i].signatures[j].date_created), 'days');
        if (!isNaN(individualSignatureTime)){
          signatureTime +=individualSignatureTime;
          signatureCounter+=1;
        } else {
          unsignedCounter +=1;
        }

      }
      for (let k = 0; k< documents[i].shared_with.length; k++){
        let individualAcceptedTime = moment(documents[i].shared_with[k].date_approved).diff(
            moment(documents[i].shared_with[k].date), 'days');
        if (!isNaN(individualAcceptedTime)){
          sentTime += individualAcceptedTime;
          sentCounter += 1;
      } else{
        unacceptedCounter += 1;
      }
    }
  }
  let averageSignatureTime = Math.round(signatureTime/signatureCounter)
  let averageAcceptanceTime = Math.round(sentTime/sentCounter)

  if (signatureTime === 0){
    averageSignatureTime = 0
  }
  if (sentTime === 0){
    averageAcceptanceTime = 0
  }
    return(
      <div className = "TimeDiff-container">
      <div className = "TimeDiff-title">Statistics</div>
      <div>Documents Unsigned: {unsignedCounter}</div>
        <div>Documents Unaccepted: {unacceptedCounter}</div>
          <div> Average Time To Acceptance: {averageAcceptanceTime} days</div>
            <div> Average Time to Signature: {averageSignatureTime} days</div>
              <div></div>
      </div>
    )
  }
}

export default OverallTimeDiff;
