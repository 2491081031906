import React from 'react';
import moment from 'moment';
import './TimeDiff.css';
class AcceptanceTimeDiff extends React.Component{

  // Takes in permissions and returns their average time to acceptance and time to signature
  render(){
    if (!this.props.docs){
      return null
    }
    let documents = this.props.docs;
    let sentCounter=0;
    let sentTime = 0;
    for (let i = 0; i < documents.length; i++){
      for (let k = 0; k< documents[i].shared_with.length; k++){
        let individualAcceptedTime = moment(documents[i].shared_with[k].date_approved).diff(
            moment(documents[i].shared_with[k].date), 'days');
        if (!isNaN(individualAcceptedTime)){
          sentTime += individualAcceptedTime;
          sentCounter += 1;
      }
    }
  }
  let averageAcceptanceTime = Math.round(sentTime/sentCounter)

  if (sentTime === 0){
    averageAcceptanceTime = 0
  }
    return(
      <div className = "smallTimeDiff-container">
          <div> Average Time To Acceptance: {averageAcceptanceTime} days</div>
      </div>
    )
  }
}

export default AcceptanceTimeDiff;
