import React from 'react';
import introJs from 'intro.js';
import Step2Documents from './Step2Documents.json'
class Tutorial2Element extends React.Component{
  constructor(props){
    super(props);
    this.state={
      completedPage:false,
      showContinue:false,
    }
  }
  completeTutorial=()=>{
    this.setState({completedPage:true});
  }
  startTutorial = () =>{
    let intro;
    intro = introJs().setOptions({steps:Step2Documents, doneLabel: "Done", showStepNumbers:false}).start().onchange(function(){
      intro._introItems[2].element = document.querySelector('.tagLabel-container');
      intro._introItems[2].position = "bottom";
      intro._introItems[3].element = document.querySelector('.activeTags-container');
      intro._introItems[3].position = "bottom";
      intro._introItems[4].element = document.querySelector('#excludeTags-container');
      intro._introItems[4].position = "bottom";
      intro._introItems[6].element = document.querySelector('.document-row');
      intro._introItems[6].position = "bottom";

    }).oncomplete(this.completeTutorial
  );
  }
  componentDidMount(){
    this.startTutorial();
  }
  showContinue=()=>{
    this.setState({showContinue:true});
  }
  continueToTags=()=>{
    window.location.href = 'http://ivydocuments.com/tags?tutorial=true';
  }
  hideContinue=()=>{
    this.setState({showContinue:false});
  }
  render(){
    if (this.state.showContinue){
      return(
        <div className = "tutorial2Container">
          <div onClick = {this.hideContinue} className = "tutorialButton boxShadow">Tutorial</div>
          <div className = "tutorialOptions boxShadow">
            <div className = "tutorialOption" onClick = {this.startTutorial}>Show Steps</div>
            <div className = "tutorialOption" onClick = {this.continueToTags}> Continue</div>
          </div>
          </div>
      )
    }
    return(
      <div className = "tutorial2Container">
        <div onClick = {this.showContinue} className = "tutorialButton boxShadow">Tutorial</div>

        </div>
    )
  }
}

export default Tutorial2Element;
