import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './PricingPage.css';
import PricingBanner from './imgs/PricingPage/pricingbanner.svg';
import iconTB from './imgs/PricingPage/icon1TB.svg';
import iconGB from './imgs/PricingPage/icon10GB.svg';
import icon30 from './imgs/PricingPage/icon30.svg';
import iconCustomizable from './imgs/PricingPage/iconcustomizable.svg';
import iconStorage from './imgs/PricingPage/iconstorage.svg';
import iconUnrestricted from './imgs/PricingPage/iconunrestricted.svg';
class PricingPage extends React.Component{
  constructor(props){
    super(props);
    this.state={
      firstEmail:'',
      secondEmail:'',
    }
  }
  onChangeFirstEmail = (e) => {
		this.setState({
			firstEmail: e.target.value,
			error: '',
		});
	}
  onAttemptFirstEmailSignup = () => {
			Request.authRequest(Routes.auth.signup, 'POST', JSON.stringify({
				email: this.state.firstEmail,
			}), 'application/json').then((response) => {
				if (response.status === 200) {
					response.json().then((body) => {
						window.location.href = '/resend-confirm';
					});
				} else if
					(response.status === 409){
						this.setState({ error: 'User with this email already exists.' });
				}
				else {
					response.json().then((error) => {
						this.setState({ error: error.message });
					});
				}
			}).catch((error) => {
				this.setState({ error: error.message });
			});
		}
	handleFirstEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAttemptFirstEmailSignup();
    }
  }

  render(){
    return(
      <div className = "pricingPage-container">
        <div className = "pricingPage-header boxShadow">Choose What's Right For You
        <img src = {PricingBanner} alt = "Get started with Ivy's tag based filing today"/>
          <a href = "https://meetings.hubspot.com/nathan220"
            className = "book-demo-link">Book a Demo</a>
        </div>

        <div>
        </div>
        <div className = "pricing-planContainer">
          <div className = "pricingPlan-container boxShadow">
            <div className = "pricingPlan-header">Free</div>
            <div className = "pricingPlan-terms">Free</div>
            <div className = "pricingPlan-features"> Try out tags based file management!</div>
            <div className = "pricingPlan-details">
              <div className = "pricingPlan-features">
                <img className = "pricingPlan-iconBullet" src = {iconUnrestricted} alt = ""/>
                50 free tags</div>
              <div className = "pricingPlan-features">
                <img className = "pricingPlan-iconBullet" src = {iconGB} alt = ""/>
                1 GB Storage</div>
              <div className = "pricingPlan-features">
                  <img className = "pricingPlan-iconBullet" src = {iconGB} alt = ""/>
                  Limited Feature Access</div>
            </div>
          </div>
          <div className = "pricingPlan-container boxShadow">
            <div className = "pricingPlan-header">Individual</div>
            <div className = "pricingPlan-terms">
              <div className = "dollarSign">$</div>
              <div className = "pricingPlan-amount">40</div></div>
            <div className = "pricingPlan-features"> per month</div>
            <div className = "pricingPlan-details">
              <div className = "pricingPlan-features">
                <img className = "pricingPlan-iconBullet" src = {iconUnrestricted} alt = ""/>
                Tag, filter and sort files </div>
                <div className = "pricingPlan-features">
                  <img className = "pricingPlan-iconBullet" src = {iconGB} alt = ""/>
                  100 GB Storage</div>
                  <div className = "pricingPlan-features">
                    <img className = "pricingPlan-iconBullet" src = {iconUnrestricted} alt = ""/>
                    Version History, Expiration Tracking</div>
              </div>


          </div>
          <div className = "pricingPlan-container boxShadow">
            <div className = "pricingPlan-header">Team</div>
            <div className = "pricingPlan-terms">
              <div className = "dollarSign">$</div>
              <div className = "pricingPlan-amount">80</div>
            </div>
            <div className = "pricingPlan-features"> /mo, includes 2 users + onboarding</div>
            <div className = "pricingPlan-details">
              <div className = "pricingPlan-features">
                <img className = "pricingPlan-iconBullet" src = {icon30} alt = ""/>
                30 / mo / additional user</div>
              <div className = "pricingPlan-features">
                <img className = "pricingPlan-iconBullet" src = {iconTB} alt = ""/>
                1 TB Storage</div>
                <div className = "pricingPlan-features">
                  <img className = "pricingPlan-iconBullet" src = {iconUnrestricted} alt = ""/>
                  Access Control, Document Approval</div>
            </div>
          </div>

        </div>
        <div className = "pricingPlan-section boxShadow">
          <div className = "pricingPlan-review">
            <div className = "pricingPlan-customerQuote">&ldquo;We are a small company and keeping track of documents and organizing
            them efficiently is always a chore. Ivy has made it really simple and
             easy to keep track of documents with their innovative organization system.
            Most recently we used their service to consolidate and organize our Certificate of Analysis (COA’s).
            When we had an auditor come they were very happy with how easy it was to see
            we had all our paperwork in order and could verify that all our
            ingredients had COA’s... Any small- medium
             size business would benefit from Ivy to keep all important documents in an easy to find format. &rdquo;
             </div>
             <div className = "pricingPlan-author">- Nana Joes Granola </div>

          </div>
          <div className = "features-sectionTitle">Get Started Today
            </div>
          <div className = "pricing-signup ">

            <input type='text' onChange={this.onChangeFirstEmail}
              id='email' className='landing-input-text'
              placeholder = {'Enter your email'}
              onEnter = {this.handleFirstEnter}/>
            {this.state.error}
            <button className='landing-form-button primary'
              onClick={this.onAttemptFirstEmailSignup}> Get Started</button>

          </div>
          <div>
            <div className = "pricing-table-title">Compare Plans</div>
            <div className = "pricing-table-categoryHeaders">Document Storage, Management and Information</div>
            <table className = "pricing-table">

              <tr>
                <th className = "pricing-table-header">Feature
                </th>
                <th className = "pricing-table-header">Free
                </th>
                <th className = "pricing-table-header">Individual
                </th>
                <th className = "pricing-table-header">Team
                </th>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Storage
                </td>
                <td className = "pricing-table-cell">1 GB
                </td>
                <td className = "pricing-table-cell">100 GB
                </td>
                <td className = "pricing-table-cell">1 TB
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Tags Based Filing
                </td>
                <td className = "pricing-table-cell"> &#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Number of Tags
                </td>
                <td className = "pricing-table-cell"> 50
                </td>
                <td className = "pricing-table-cell"> Unlimited
                </td>
                <td className = "pricing-table-cell"> Unlimited
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Nested Tags
                </td>
                <td className = "pricing-table-cell"> &#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Multitag Filtering
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Exclude Tag Filtering
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Sent-to Filtering
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Send and Receive Documents
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Sent Document Tracking
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Document Approval/Rejection
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Document Metadata
                </td>
                <td className = "pricing-table-cell"> &#10003;
                </td>
                <td className = "pricing-table-cell"> &#10003;
                </td>
                <td className = "pricing-table-cell"> &#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                  <td className = "pricing-table-cell">Document Information Reports
                  </td>
                  <td className = "pricing-table-cell">
                  </td>
                  <td className = "pricing-table-cell">&#10003;
                  </td>
                  <td className = "pricing-table-cell"> &#10003;
                  </td>
                </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Search
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Batch Upload
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Folder Upload
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Expiration Tracking
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Expiration Filtering
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Expiration Notifications
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Expiration Timeline
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Document Archiving
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Version Management
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Version History Reports
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">In-App Preview
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">PDF Autoconversion
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              </table>
              <div className = "pricing-table-categoryHeaders">Workflow Tools</div>
              <table className = "pricing-table">
                <tr>
                  <th className = "pricing-table-header">Feature
                  </th>
                  <th className = "pricing-table-header">Free
                  </th>
                  <th className = "pricing-table-header">Individual
                  </th>
                  <th className = "pricing-table-header">Team
                  </th>
                </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Pinned Tags
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Document Signature Workflow
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Document Receiving Templates
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Auto-tag Templates
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">File Name-based Tagging
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Template Messaging
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>

              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Contacts Management
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
            </table>
              <div className = "pricing-table-categoryHeaders">User Management and Administration</div>
              <table className = "pricing-table">
                <tr>
                  <th className = "pricing-table-header">Feature
                  </th>
                  <th className = "pricing-table-header">Free
                  </th>
                  <th className = "pricing-table-header">Individual
                  </th>
                  <th className = "pricing-table-header">Team
                  </th>
                </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Multiple Users
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">User Roles
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Restricted Tag Access
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Tag Creation/Deletion Control
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Notification Control
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Notification Emails
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">PDF Only Access
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
              <tr className = "pricing-table-row">
                <td className = "pricing-table-cell">Logs
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
                <td className = "pricing-table-cell">&#10003;
                </td>
              </tr>
            </table>
          </div>





        </div>
      </div>
    )
  }
}

export default PricingPage;
