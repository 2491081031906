import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import FixedModal from '../Modal/FixedModal';
import ContactsSearch from '../ContactsSearch/ContactsSearch';
class MarkOriginalSender extends React.Component{
  constructor(){
    super();
    this.onSendEmails = this.onSendEmails.bind(this);
    this.state={
      recipients :'',
      showModal:false,
    }
  }
  onSendEmails=(emails)=>{
    this.setState({recipients:emails[0]})
  }
  onShowModal =()=>{

    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false,message:''
  })}

  onMarkDocument=()=>{
      for (let j = 0; j < this.props.docs.length; j++){
      Request.authRequest(Routes.documents.markOriginalSender(this.props.docs[j].document_id),
  											  'POST',JSON.stringify({
                      			email:this.state.recipients
                      		}), 'application/json').then((response) => {
                            if (response.status===200){
        			response.json().then(body => {
        				toast.success(`Successfully marked document`);
                this.setState({showModal:false, recipients:''})
                this.props.fetchDocuments();
                    });
                  } else {
                    response.json().then((error) => {
                      toast.error(error.message);
                      if (error.errors){
                        toast.error(error.errors[0]);
                      }
                    });
                  }
              }).catch((error) => {
              toast.error(error.message);
              }
          )

        }
      }
  render(){
    if (this.props.docs){
    return (
      <div>
        <button className="markOriginalSender" onClick = {this.onShowModal}>
            Mark Original Sender
        </button>
        <FixedModal show={this.state.showModal} onClose={this.onHideModal} title='Mark Original Sender' >

          <div >
            <div className = "helpText-CreateTagButton">Enter the email of the recipient who originally sent this document.
              This will share a copy of the document with them, as well as allow
              them to receive expiration notifications for this document from you. This can't be changed once set.
            </div>
            <ContactsSearch onSendEmails= {this.onSendEmails}/>
            <button className= 'modal-button primary' onClick={this.onMarkDocument}> Mark Original Sender </button>
            </div>
        </FixedModal>
      </div>
    )
  }
  return(
    <div>
      <button className="markOriginalSender" onClick = {this.onShowModal}>
          Mark Original Sender
      </button>

    </div>
  )
}
}
export default MarkOriginalSender;
