import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import {toast} from 'react-toastify';
import './Notifications.css';
import {Link} from 'react-router-dom';
import moment from 'moment';
import qs from 'qs';
import PageButtons from '../PageButtons/PageButtons';
import DocumentRow from '../Documents/DocumentRow';
import NotificationsRow from './NotificationsRow';
import PendingExternalDocs from './PendingExternalDocs';
class Notifications extends React.Component {
	constructor(props) {
		super(props);
		this.onGetNotifications = this.onGetNotifications.bind(this);
		this.state = {
			notifications:[],
			total_notifications:0,
			permissions_to_sign:[],
			activeDocuments:[],
			expiringDocuments:[],
			showAllDocuments:false,
			isLoaded:false,
		}
	}
	onChangePage = (pageNumber) =>{
		this.searchStringGenerator(pageNumber,'page');
	}
	onGetDocuments=()=>{
		var date = moment();
		let isoDate = moment().add(60,'days').toISOString();;

		Request.authRequest(Routes.documents.search(`?per_page=50&expires_before=${isoDate}&expires_after=${date.toISOString()}&order_by=date_expiration_asc`),
												'GET').then((response) => {
				if (response.status===200){
			response.json().then(body => {
				this.setState({expiringDocuments:body.results})
					}
				)
			}
			else {
				response.json().then((error) => {

					toast.error(error.message);
				});
			}
		}).catch((error) => {
		toast.error(error.message);
		}
		)
	}
	componentDidUpdate(prevProps){
    if (prevProps.location.search !== this.props.location.search){
      this.onGetNotifications();
    }
  }
	searchStringGenerator(input,type){

    const searchString=qs.parse(this.props.location.search.substr(1));

    if (!searchString.page){
      searchString.page = 1;
    }
    if (!searchString.per_page){
      searchString.notifications_per_page = 10;
    }
    switch (type) {
      case 'pageSize':
        searchString.notifications_per_page = input;
        break;
      case 'page':
        searchString.page = input;
        break;
      default:
		}

		let base_url = qs.stringify(searchString);
		return new Promise((resolve, reject) => {
			resolve(this.props.history.push({
				search:base_url,
				state:this.state,
				})
			)
		})

	}
	componentDidMount(){
		this.onGetNotifications();
		if (!this.props.summary){
		this.getSignatures();
		this.onGetDocuments();}
	}
	onGetNotifications(){
		let searchString = '';
		if (!this.props.summary){
			searchString = this.props.location.search;
		}
		Request.authRequest(Routes.notifications.index(searchString),'GET').then((response) => {
			if (response.status ===200)
			{
				response.json().then(body => {
			this.setState({notifications:body.results,total_notifications:body.total_results,isLoaded:true});
				})
			} else {
				response.json().then((error) => {
					toast.error(error.message);

					});
			}
		}).catch((error) => {
			toast.error(error.message);
			}
		)
	}
	setAllNotificationRead = () =>{
    Request.authRequest(Routes.notifications.unread, 'POST').then(
          (response) => {
            if(response.ok){
              this.onGetNotifications();
        } else {
          response.json().then((error) => {
            toast.error(error.message);

          });
        }
    }).catch((error) => {
    toast.error(error.message);
    }
    )
    }
	getSignatures = ()=>{
	    Request.authRequest(Routes.documents.signatureList, 'GET').then((response)=>{
	  		if (response.status===200){
	  		response.json().then(body=>{
	       	this.setState({permissions_to_sign:body,showAllDocuments:false})
	  				})
	  			}
	  			else {
	  				response.json().then((error) => {
	  					toast.error(error.message);
	  				});
	  			}
	  		}).catch((error) => {
	  			toast.error(error.message);
	  		}
	  		)
	  }
		getAllUnsignedSignatures = ()=>{
		    Request.authRequest(Routes.documents.allSignatures, 'GET').then((response)=>{
		  		if (response.status===200){
		  		response.json().then(body=>{
		       	this.setState({permissions_to_sign:body,showAllDocuments:true})
		  				})
		  			}
		  			else {
		  				response.json().then((error) => {
		  					toast.error(error.message);
		  				});
		  			}
		  		}).catch((error) => {
		  			toast.error(error.message);
		  		}
		  		)
		  }
	onSelectDocument = (doc) =>{
		window.location.href=`/documents/${doc.document.id}`;
	}
	render() {
		let notificationsComponent = <div className = "loaderContainer"><div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
		if (this.state.isLoaded){
		notificationsComponent = <div className = "helpText">No new notifications</div>}
		if (this.state.notifications.length>0){
		 notificationsComponent = this.state.notifications.map((entity,i)=>{
			return <NotificationsRow key = {i} summary = {this.props.summary}
				notification = {entity} onGetNotifications = {this.onGetNotifications}/>
		})}
		if (this.props.summary){
			return(
				<div >
					<div className='notificationsheader noselect'> Recent Notifications </div>

					<div className='notifications-body'>
						{notificationsComponent}
						<Link  className = "summaryNotifications-seeMore" to ='/notifications'> See More </Link>
					</div>

				</div>
			)
		}

		let perPage = 10;
    let page = 1;
		const searchString = qs.parse(this.props.location.search.substr(1));
		if (searchString.logs_per_page){
			perPage = searchString.logs_per_page;
		}
		if (searchString.page){
			page = searchString.page
		}
		let permissionsToSign = <div className = "helpText">No Documents Pending.</div>
		if (this.state.permissions_to_sign.length>0){
			permissionsToSign = this.state.permissions_to_sign.map((permission,i)=>{
				return <DocumentRow document = {permission} key = {i} onSelectDoc={this.onSelectDocument}
          activeDocuments= {this.state.activeDocuments}
          onGetDocuments = {this.onGetSignatures}/>
			})
		}
		let documentsExpiring= <div className = "helpText">No Documents Expiring Soon</div>

		if (this.state.expiringDocuments.length>0){
			documentsExpiring = this.state.expiringDocuments.map((permission,i)=>{
				return <DocumentRow document = {permission} key = {i} onSelectDoc={this.onSelectDocument}
          activeDocuments= {this.state.activeDocuments}
          onGetDocuments = {this.onGetSignatures}/>
			})
		}
		return (
			<div className = "notifications-masterContainer">
				<div className = "notifications-left">
					<div className = "notifications-container boxShadow">
						<div className='notificationsheader noselect'> Notifications
							<div className = "button-markAllRead" onClick = {this.setAllNotificationRead}> Mark all as read</div>
						</div>
						<div className='notifications-body'>
							{notificationsComponent}
						</div>
					</div>
					<PageButtons page = {page} perPage = {perPage}
						numResults = {this.state.total_notifications} onChangePage = {this.onChangePage}/>
				</div>
				<div className = "notifications-rightContainer">
					<div className = "notifications-container boxShadow">
						<div className='notificationsheader noselect'>
							{this.state.showAllDocuments?"All Docs Awaiting Approval":"Your Docs Awaiting Approval"}
							{this.state.showAllDocuments?
								<div className = "button-allUnsignedSignatures"
									onClick = {this.getSignatures}>
									See Your Unsigned Docs
								</div>
								:<div className = "button-allUnsignedSignatures"
								onClick = {this.getAllUnsignedSignatures}>
								See All Unsigned Docs
							</div>}
						</div>
						<div >
							<div className = "tags-docs-table">
								<div className = "tags-docs-tableheader-name">Document Name
								</div>
								<div className = "notifs-docs-tableheader-expires">Expires On</div>
								<div className = "tags-docs-tableheader-tags">Tags</div>
							</div>
							<div className='notifications-permissions'>
						{permissionsToSign}
						</div>
					</div>
					</div>
					<div className = "notifications-container notifications-permissions boxShadow">
						<div className='notificationsheader noselect'>Documents Expiring Soon</div>
						<div className='notifications-permissions-body'>
							<div className = "tags-docs-table">
								<div className = "tags-docs-tableheader-name">Document Name
								</div>
								<div className = "notifs-docs-tableheader-expires">Expires On</div>
								<div className = "tags-docs-tableheader-tags">Tags</div>
							</div>

						{documentsExpiring}
					</div>
					</div>
					<PendingExternalDocs/>
			</div>
		</div>
		);
	}
}

export default Notifications;
