import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
class EditTagsAccessLevel extends React.Component{
  constructor(props){
    super(props);
    this.state={
      editing:false,
      accessLevel:this.props.user.tags_access,
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.user!== this.props.user){
      this.setState({accessLevel:this.props.user.tags_access});
    }
  }
  onSaveChanges(){
    let user = this.props.user;
    user.tags_access = this.state.accessLevel;
		Request.authRequest(Routes.access.access, 'PUT',JSON.stringify(
					user
				), 'application/json'
				).then((response) => {
          if (response.status ===200){
            response.json().then(body => {
  						toast.success('Changed tag access permission');
              this.props.getOrganization();
  									}
  								)
  							}

              else {
                  response.json().then((error) => {
                    toast.error(error.message);
                    if (error.errors){
                      toast.error(error.errors[0])
                    }
                  });
                }
          }).catch((error) => {
            toast.error(error.message);
          }
        )
  }
  handleDblClick = (e) =>{
    if (this.node.contains(e.target)){
      this.setState({
        editing:true
      })
    }
    else if(this.state.editing!==false){
      this.onSaveChanges();
      this.setState({editing:false})
    };
  }
  componentWillMount(){
    document.addEventListener('dblclick',this.handleDblClick,false);
  }
  componentWillUnmount(){
    document.removeEventListener('dblclick',this.handleDblClick,false);
  }
  onChangeAccessLevel = (e) => {
		this.setState({
			accessLevel: e.target.value,
		});
	}
  render(){
    let accessLevel = this.props.user.tags_access;
    let accessText;
    if (this.props.user.role ==="User"){
      accessText = "Read";
    } else if (this.props.user.role ==="SuperAdmin"){
      accessText = "Write"
    } else if (!accessLevel){
      accessText = "Write";}
      else {
        accessText = this.props.user.tags_access;
      }
    return (

      <div className='doc-info-field' id = "edit-access.tags" ref = {node => this.node = node}>
        {this.state.editing?

          <select value={this.state.accessLevel}
            onChange= {this.onChangeAccessLevel}>
            <option value ="Write">Write</option>
            <option value = "Read">Read</option>
          </select>:

            <div className = 'document-info' id="edit-access.tags">{accessText}
            </div>
          }
    </div>)

  }
}
export default EditTagsAccessLevel;
