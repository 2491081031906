import React from 'react';
import {SingleDatePicker} from 'react-dates';
import moment from 'moment';
import './AdvancedSearchComponents.css';
class ExpirationSearch extends React.Component{
  constructor(props){
    super(props);
    this.state={
      beforeFocused:false,
      afterFocused:false,
    }
  }

  onChangeBeforeDocumentDate = (date) => {
    if (date){
    this.props.sendDate(date._d,'beforeDate');}
  }
  clearExpiration=()=>{
    this.props.sendDate('','clearDate');
  }

  onChangeAfterDocumentDate = (date) => {
    if (date){
    this.props.sendDate(date._d,'afterDate');}
  }
  onChangeBeforeFocus = ()=>{
    this.setState({beforeFocused:!this.state.beforeFocused})
  }
  onChangeAfterFocus = ()=>{
    this.setState({afterFocused:!this.state.afterFocused})
  }
  render(){
    return (
      <div>

        <div className = "expirationSearchMenu">
          <div className = "expirationSearchManagementButtons">
            <div className = "noselect">Expiration Filters</div>
            <div onClick = {this.clearExpiration} className = "expirationSearch-label"> Clear</div>

          </div>
          <div className = "expirationSearch-label"> Expires Before

        <SingleDatePicker
          date={moment(this.props.expiresBefore)}
          onDateChange={this.onChangeBeforeDocumentDate}
          focused={this.state.beforeFocused}
          onFocusChange={this.onChangeBeforeFocus}
          numberOfMonths= {1}
          isOutsideRange={() => false}
          id="documentDateField"
        />
      </div>
        <div className = "expirationSearch-label"> Expires After
          <SingleDatePicker
            date={moment(this.props.expiresAfter)}
            onDateChange={this.onChangeAfterDocumentDate}
            focused={this.state.afterFocused}
            isOutsideRange={() => false}
            onFocusChange={this.onChangeAfterFocus}
            numberOfMonths= {1}
            id="documentDateField"
          />

      </div>
      </div>
      </div>
    )
  }
}
export default ExpirationSearch;
