import React from 'react';
import Routes from '../../helpers/Routes';

import Request from '../../helpers/Request';
import './PasswordReset.css';
class PasswordReset extends React.Component{
  constructor(){
    super()
    this.state={
      email:'',
      error:'',
      success:false,
    }
  }
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onSendReset();
    }
  }
  onChangeEmail = (e) => {
		this.setState({
			email: e.target.value,
			error: '',
		});
	}
  onSendReset = () => {
		Request.authRequest(Routes.auth.passwordreset, 'POST', JSON.stringify({
			email: this.state.email,
		}), 'application/json').then((response) => {
			if (response.status===204) {

					this.setState({success:true})

			}
      else {
        this.setState({ success: true });
      }
		}).catch((error) => {
      //success anyway so you dont fish for email
			this.setState({ success: true });
		});
	}
  render(){
    return (
      <div className = 'passwordreset-container boxShadow'>
        <div className = "reset-header"> Reset your Password</div>
        Enter the email associated with your account in order to reset your password.
        <input type='text' onChange={this.onChangeEmail} id='email-reset' className='input-text' onKeyPress = {this.handleEnter}/>
        <button className = "form-button primary" id = 'email-reset' onClick = {this.onSendReset}>Submit</button>
        {this.state.success?<div>Thanks. If the email above corresponds to an account,
          you will receive an email with instructions to reset your password
          <button className ="form-button secondary" id ='email-reset' onClick ={()=>window.location.href='/login'}>Return</button>
        </div>
          :this.state.error}
      </div>
    );
  }
}
export default PasswordReset;
