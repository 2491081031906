import React from 'react';
import './FAQ.css';
class FAQ extends React.Component{
  render(){
    return(
      <div className = "faq-container">
        <div className = "faq-quickNav boxShadow">
          <div className = "faq-quickNav-header">Quick Navigation
          </div>
          <ul>
            <li><a className = "faq-quickNav-link" href = "#top">Top of Page</a></li>
            <li><a className = "faq-quickNav-link" href = "#general">General</a></li>
            <li><a className = "faq-quickNav-link" href = "#documents">Documents</a></li>
            <li><a className = "faq-quickNav-link" href = "#search">Search</a></li>
            <li><a className = "faq-quickNav-link" href = "#tags">Tags</a></li>
            <li><a className = "faq-quickNav-link" href = "#templates">Templates</a></li>
            <li><a className = "faq-quickNav-link" href = "#requests">Requests</a></li>
            <li><a className = "faq-quickNav-link" href = "#contacts">Contacts</a></li>
            <li><a className = "faq-quickNav-link" href = "#notifications">Notifications</a></li>
            <li><a className = "faq-quickNav-link" href = "#about">About</a></li>

          </ul>

        </div>

        <div className = "faq-container-contents boxShadow">
          <div className = "faq-welcome"><h1>Welcome to the FAQ.</h1>
            If you can't find the answer you need here,
            email us at help@ivydocuments.com and we'll get back to you as soon as possible.
          </div>
          <div className = "faq-questions">
            <h2 className = "faq-section-header" id = "general"> General</h2>
              <h4 className = "question">Do you have a template email I can send to my suppliers to onboard them?</h4>
                  Yes! The text is below. You can send this in the first message of your template requests, or in a separate email. Please
                  modify as you see fit.
                  <br/>
                  <br/>
                  Hi,
                  <br/>

                  We are using Ivy Documents to manage our documents. Please make an free account so
                   we can send you document requests- it only takes 2 minutes. You'll need to make a
                   new organization for your company if you don't use Ivy already- please use your
                   company name. Once you join, you'll see helper text directing you to fill out a document request from us.
                 <br/>
                   Thanks!

              <h4 className = "question">How do I edit information?</h4>
                Editable fields are generally double click to edit. If you can't edit something on a specific page,
                like the templates, you may need to go into the specific template viewer to edit its information. This is marked by a pencil icon.

              <h4 className = "question">I got a message that says Resource requires admin or superadmin privileges.</h4>
                Users are only allowed to view, preview and download documents. All other changes require admin privileges.

              <h4 className = "question">How do I enter multiple tags at once?</h4>
                You can use commas to separate multiple tags on any button or in the tags editing field. For example, you can do "Tag1, Example Tag, Tag 3". A tag can have spaces.
              <h4 className = "question">I got a message that says Resource requires admin or superadmin privileges but the information still changed.</h4>
                  Editable fields will be changeable while you are on the screen, but changes will not be
                  saved without appropriate permissions. Once you leave the page and come back, the information will revert.

            <h2 className = "faq-section-header" id = "documents">Documents</h2>
              <h4 className = "question">Where is the Category field?</h4>
                    We've decided to remove categories in favor of requiring at least one tag per document.

              <h4 className = "question">I want to upload a document that has already expired but it says expiration must be in the future.</h4>
                   Expiration is tied to our notification- please mark the document as not expiring.

            <h4 className = "question">I clicked download but nothings happening.</h4>
                    Downloads open in another window. Please add Ivy to your trusted sites, or allow popups from ivydocuments.com.

              <h4 className = "question">I get an error that says the file is unsupported by preview.</h4>
                  We currently only support doc, docx, jpg, jpeg, png and pdf type
                  files. If you are still getting this error with a supported filetype, please email us at help@ivydocuments.com.

              <h4 className = "question">Can I edit a document I've received in Ivy?</h4>
                  No, we currently do not support in-app text editing. You will have to download it, edit it and reupload it to Ivy.

              <h2 className = "faq-section-header" id = "search">Search</h2>
              <h4 className = "question">I searched for a document and it didn't show up?</h4>
              We currently only support exact word matching. If you type in "Spe" and are trying to search for "Spec" then there currently will not be a match.
              We are working on improving our search algorithm. You can search for things in ID, name and category currently.


              <h2 className = "faq-section-header" id = "tags">Tags</h2>
              <h4 className = "question">How does the tags system work?</h4>
                  Tags do everything a folder, filter and tag do. You can apply
                  multiple tags at once and the documents shown will have all of the tags
                  or one of the tags children applied to them. Tags are also hierarchical,
                   allowing you to create nested tags like you have nested folders.
              <h4 className = "question">Basic Tagging Rules</h4>
              <ul>
                <li>A document can have any number of tags
                </li><li>Tags cannot form a cycle - a child tag cannot be the parent of itself (or grandparent/grandchild).
                </li><li>One tag can have multiple parents.
                </li><li>When a tag is applied, all documents with that tag or any of its child tags will be returned.
                </li>
              </ul>
              <h4 className = "question">Do I need to apply every tag inside a nested tag for a document to show up when a tag is applied?</h4>
                No, by default all documents which have a tag which is a child of the applied tag will also show up. For example, if I have a baked goods tag which is the parent of
                a cookie tag which is the parent of a chocolate chip cookie tag, applying the baked goods tag will show all documents tagged with chocolate chip cookie, even if the documents
                only have the chocolate chip cookie tag applied.
              <h4 className = "question">Best Practices for Tags</h4>
                Best Practices for Tag Hierarchies
                  Tags work slightly differently than folders. Tags let you apply multiple dimensions of filtering at once, so you no longer have to nest unrelated concepts. For example, you may have individual supplier folders, and nest their certifications inside the supplier folder. Instead, you should have a separate Certification tag, with certification types as children of the Certification tag, and a Supplier tag with Supplier names as children of the supplier tag. Dimensions should be kept separate. If you want your system to behave closer to folders, use nesting more. If you want your system to be closer to filtering, split up your tags more. If only one tag is applied to each document then it is equivalent to a folder system.

                  Some of our users like these practices to help organize their tag system (your naming may differ):
                  <ul>
                    <li>
                  Make a Document Category tag and make different types of documents children of that tag.</li>
                  <li>Make department tags e.g. finance, QA, supply chain etc.</li>
                  <li>Make a Regulatory tag, and put the different schemas as children under the Regulatory tag. For more rigid standards like SQF, some users go as far as to put the control point numbers on their documents for fast retrieval in an audit.</li>
                  <li>When using tags to track lots of ingredients or batches of product, we recommend you use some sort of parent tag to group up the batch or lot codes (dates, production runs etc).</li>
                  </ul>
              <h4 className = "question">What are the up and down arrows on my active tags?</h4>
                The up and down arrows let you navigate to your parent and child tags respectively, which help you broaden your search, or filter down to specific subtags.

              <h4 className = "question">How do I favorite a tag?</h4>
              Favorite tags let you access your frequently used tags quickly. To favorite a tag on the navigation page, click on an active tag, and click either the up or down arrow. Inside the pop-up, there will be a star. Click the star to favorite the tag.
              On the tags management page, select a tag. To the right of the name, there is a star. Click the star to favorite the tag.

              <h4 className = "question">Why do documents appear that don’t have the currently active tag applied?</h4>
                  Documents that have child tags of your currently applied tag will also show up, in addition to the directly active tags. For example, if I have a baked goods tag which is the parent of
                  a cookie tag which is the parent of a chocolate chip cookie tag, applying the baked goods tag will show all documents tagged with chocolate chip cookie, even if the documents
                  only have the chocolate chip cookie tag applied.

              <h4 className = "question">What is the Organization tag?</h4>
                  Organization is a default tags. Every time you
                  receive a document from another organization, the document will
                  be automatically tagged with that Organization’s name.
              <h4 className = "question">What is the Archive tag?</h4>
              Documents which you archive get the Archive tag applied to them. Any document with the Archive tag is default hidden from view, unless Archive is actively selected.
              Documents which version other documents will also automatically have archive added to the old version.
              <h4 className = "question">Do I need to create a tag before I apply it?</h4>

                  No. Tags will be created if they don’t already exist for you. Tags are not case sensitive.

              <h4 className = "question">I’m getting an error that says Tag Cycle created. What does this mean?</h4>

                  Tags are hierarchical in order to allow us to filter your documents.
                   A tag cycle means you are trying to make a tag’s child also its parent, and is not allowed.
            <h4 className = "question">How does tag access control work?</h4>
                          Tag access control allows a superadmin to set restrictions on what tags, and therefore documents, are accessible by other users.
                          Tag access control follows the rules below.
                                <ol>
                                  <li> A superadmin will always have access to all tags.</li>
                                  <li> Admins and users can have restricted access.</li>
                                  <li> A new user without any tags set up has default access to no tags. Apply tags in the management console.</li>
                                  <li> If a user has access to a tag, it has access to any descendant tag (child, or child of child etc).</li>
                                  <li> An admin that makes a new tag by default has access to the tag they made. Superadmins can adjust this later.</li>
                                  <li> A user can still add or remove tags to documents,
                                    templates and tags that they do not have access to, in order to "share" objects with other users.</li>
                                </ol>
              <h4 className = "question">What happens when I merge a tag?</h4>
                       Merging a tag will cause the new tag to inherit all objects.
                           <ol>
                             <li> The new tag will add all of the parent and child tags of the old tag to its list.</li>
                             <li> All documents that are tagged with the old tag will be tagged with the new tag.</li>
                             <li> All templates that are tagged with the old tag will be tagged with the new tag.</li>
                           </ol>
              <h4 className = "question">What happens when I delete a tag?</h4>
                  Deleting a tag can cause your file organization to mess up, so be
                  sure you want to delete a tag before you do so. When a tag is deleted a few things happen.
                      <ol>
                        <li> All items that have that tag applied remove the tag. This means the tag will be removed from all documents.</li>
                        <li> Templates will also have the tag removed. However, if the tag is the category tag of a template, the tag will reappear the next time a document is sent under that tag.</li>
                        <li> All tags that are parents or children of the deleted tag will have the deleted tag removed from their respective list.</li>
                        <li> If the deleted tag is the parent tag of another tag, the child tag will become a top level tag if it does not have any other parents.</li>
                      </ol>


              <h2 className = "faq-section-header" id = "templates">Templates</h2>

              <h4 className = "question">What are templates and how do they work?</h4>
                  Templates allow you to simplify repeated requests for the same set of documents from one or multiple people.
                  Each template has a name, a set of tags and individual template slots.
                  You can receive multiple documents per template slot, and approve them individually.

              <h4 className = "question">Does the entire template need to be filled out by the sender?</h4>

                  No, partial submission is fine and you can approve them as they send the documents to you. Just make sure to hit send!

              <h4 className = "question">How does the tagging system for templates work?</h4>
                  You can specify tags for templates beforehand, so every document
                  that comes in can be filed automatically upon approval.
                  Every document that comes in to a template is tagged with:
                    <ol>
                      <li>The organization that sent it</li>
                      <li>The template name</li>
                      <li>The set of tags that apply to all documents received through the template.</li>
                      <li>The individual tags for that template slot.</li>
                      <li>Any additional tags that you specify when sending out a document.</li>
                    </ol>
                  Documents that are pending can be modified in the pending documents page prior to being accepted.

              <h4>How do questions on templates work? </h4>
              Questions turn into input fields for the senders to fill out. Once answered, the answers will
              become tags which apply to the documents, either to all documents in the template or to the specific template slot.
              Questions only work for templates sent to external organizations.

              <h4 className = "question">What happens if I change a template I already sent out?</h4>
                  Templates that you already have sent out will retain their original form- if you send out a template that originally had 6 documents and you later change it to 3, the original person you sent that template to
                  can fill out all 6 slots. If you want to remove a request and resend it, use the remove button to delete access to that specific request.

              <h2 className = "faq-section-header" id = "requests">Requests</h2>
                <h4 className = "question">I received a request. What is this and what do I do?</h4>
                  You've received a request from another organization that has your email that is requesting a certain set of documents. Check the messages they sent for additional explanation.

                  <h4 className = "question">I received a request that isn't relevant to me. How do I get rid of it?</h4>
                  Sometimes you may receive a request in error. When your request is selected, press the remove button on the top right to remove it from your list.



              <h2 className = "faq-section-header" id = "contacts">Contacts</h2>
                <h4 className = "question">Do I need to create a new contact every time I interact with someone?</h4>
                    No. Contacts are created automatically for you whenever you directly enter an email. However, the automatically created contacts will only consist of an email. Other information must be manually added or imported.

                  <h4 className = "question">How can I search my contacts?</h4>
                    You can search by name, organization name, email or notes in the contacts search bar or when sending documents or templates.

              <h4 className = "question">I’m having issues importing my contacts.</h4>
                  Ensure your file is a csv file. Occasionally, files will save as csvs but will not be comma separated. Also ensure there is a email column titled email.
                  All other columns are optional. If you need help formatting your contacts file, email help@ivydocuments.com

              <h2 className = "faq-section-header" id = "notifications">Notifications</h2>
                <h4 className = "question">How do I turn off notifications?</h4>
                    Have your superadmin go into settings and uncheck your box that says receives notifications. If you’re just getting too many emails you can change your own notification frequency preference.

                <h4 className = "question">Why do I get a separate email requesting documents from my regular notifications?</h4>
                    Document requests are not bundled notifications as these are high priority notifications. All other notifications are bundled into a single email.


              <h2 className = "faq-section-header" id ="about">About</h2>
                <h4 className = "question">Who are you?</h4>
                  We are a team of engineers who came from implementing one of the first blockchains for food supply chain. We now intend to bridge the gap from today's technology to the blockchains of the future.
                  Our CEO, Nathan Jin, was a cofounder of ripe.io, known as the people behind the blockchain of Tomatoes project. Read more about us at <a href = "https://www.ivydocuments.com">ivydocuments.com</a>
                <h4 className = "question">Do you use blockchain?</h4>
                  We currently do not implement blockchain in our product, although we intend to in the near future. If you're interested in document traceability through blockchain, send us a message at help@ivydocuments.com.
          </div>
        </div>
      </div>
    )
  }
}

export default FAQ;
