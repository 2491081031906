import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import {DocumentRejected,DocumentAccepted} from '../../Icons/IconLibrary';

import './PendingApprovalButtons.css';
class PendingApprovalButtons extends React.Component{

  acceptPermissions = () =>{
    let activeDocuments = this.props.activeDocuments;
    for (let i = 0 ; i < activeDocuments.length ; i ++){
    activeDocuments[i].status ="Accepted";

    Request.authRequest(Routes.recipes.batchSheetApproval(activeDocuments[i].batch_sheet_id),'POST',
      JSON.stringify(
        {permission: activeDocuments[i]}),'application/json'
      ).then((response)=>{
          if (response.status===200){
          response.json().then(body => {
            this.props.onGetDocuments();
          toast.success(`Accepted the ${activeDocuments[i].name} batch sheet.`)
        })} else {
            response.json().then((error) => {
              toast.error(error.message);
            });
          }
    }).catch((error) => {
      toast.error(error.message);
    }
  )
    }
  }

  rejectPermissions = () =>{
    let activeDocuments = this.props.activeDocuments;
    for (let i = 0 ; i < activeDocuments.length ; i ++){
    activeDocuments[i].status = "Rejected"
    Request.authRequest(Routes.documents.approval(activeDocuments[i].batch_sheet_id),'POST',
      JSON.stringify(
        {permission: activeDocuments[i]}),'application/json'
      ).then((response)=>{
          if (response.status===200){
          response.json().then(body => {
            this.props.onGetDocuments();
          toast.success(`Rejected the ${activeDocuments[i].name} batch sheet.`)
        })} else {
            response.json().then((error) => {
              toast.error(error.message);
            });
          }
    }).catch((error) => {
      toast.error(error.message);
    }
  )
  }
  }
  showPreview=()=>{
    this.props.showPreview(this.props.permission);
  }
  render(){
      return(
        <div className = "pending-buttonContainer">

            <button onClick = {this.acceptPermissions} className = "pending-button">
              <img src = {DocumentAccepted} alt = "Accept selected documents" title = "Approve selected documents"/>
            </button>
            <button  onClick = {this.rejectPermissions} className = "pending-button">
              <img src = {DocumentRejected} alt = "Reject selected documents" title = "Reject selected documents"/>
            </button>
        </div>
      )

  }
}
export default PendingApprovalButtons;
