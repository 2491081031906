import {toast} from 'react-toastify';
class Request {
	static authRequest(route, method='GET', params={}, contentType) {
		const reqParams = {
			method,
			credentials:'include',
			headers: {
			},
		};

		if (method !== 'GET') {
			reqParams.body = params;
		}

		if (contentType) {
			reqParams.headers['Content-Type'] = contentType;
		}

		return new Promise((resolve, reject) => {
			fetch(route, reqParams).then(response => {
	    	if (response.status === 401) {
				localStorage.setItem('loggedIn', '');
				document.cookie = 'ivy_authed=; path=/; Domain=.ivydocuments.com;expires = Thu, 01 Jan 1970 00:00:00 GMT';
	    		window.location.href = '/login';
	    	}

	    	if (response.status === 500) {
					toast.error('Something went wrong. Please let us know by emailing at nathan@ivydocuments.com and we will get it resolved as soon as possible.');
	    		//window.location.href = '/error';
	    	}
	      return response
	    }).then(result => {
				if(result.bodyUsed){
					return
				}
	    	resolve(result);
	    }).catch(error => {

	    	reject(error);
	    })
	  });
	}
	static awsRequest(route, method='GET', params={}, contentType) {
		const reqParams = {
			method,
			headers: {
			},
		};

		if (method !== 'GET') {
			reqParams.body = params;
			reqParams.headers['Content-Type'] = 'text/plain';
			reqParams.headers['Content-Encoding'] = 'utf-8';
		}

		if (contentType) {
			reqParams.headers['Content-Type'] = contentType;
		}
		return new Promise((resolve, reject) => {
			fetch(route, reqParams).then(response => {
	    	if (response.status === 401) {
				localStorage.setItem('loggedIn', '');
				document.cookie = 'ivy_authed=; path=/;Domain=.ivydocuments.com;expires = Thu, 01 Jan 1970 00:00:00 GMT';
	    		window.location.href = '/login';
	    	}

	    	if (response.status === 500) {
					toast.error('Something went wrong. Please try again later, and email us at help@ivydocuments.com.');
	    		//window.location.href = '/error';
	    	}
	      return response
	    }).then(result => {
				if(result.bodyUsed){
					return
				}
	    	resolve(result);
	    }).catch(err => {
				if (err.message = 'Failed to fetch'){
					toast.error('Connection error. Please refresh and try again, or check your internet connection.')
				}
	    	reject(err);
	    })
	  });
	}

}

export default Request;
