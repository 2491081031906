import React from 'react';
import './PriceTable.css';
import ReleaseCriteriaRow from './ReleaseCriteriaRow';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import AddReleaseCriteriaButton from './AddReleaseCriteriaButton';
import DeleteReleaseCriteriaButton from './DeleteReleaseCriteriaButton';
class ReleaseCriteriaTable extends React.Component{
    constructor(props){
        super(props);
        this.state = { 
            showTable:true,
            addCriteria:false,
            deleting:false,
            criteriaToRemove:[]
        }
    }
    toggleTable = () =>{
        this.setState({showTable: !this.state.showTable});
    }
    addCriteriaToDelete = (criteria) =>{
		this.setState({criteriaToRemove:
				[...this.state.criteriaToRemove, criteria]})
				
	}
	removeCriteriaToDelete = (ingredient) =>{
		this.setState({criteriaToRemove:
				this.state.criteriaToRemove.filter(criteria => criteria != criteria)})
	}
	startDeleting = () =>{
		this.setState({deleting:true})
	}
	cancelDeleting = () =>{
		this.setState({deleting:false, criteriaToRemove :[]})
    }
    confirmDeleting = () =>{
		this.setState({deleting:false})
		
		for(let i =0; i < this.state.criteriaToRemove.length;i++){
		Request.authRequest(
            Routes.recipes.releaseCriteria(this.state.criteriaToRemove[i].id),
                        'DELETE'
                      ).then((response) => {
                        if (response.status===200){
                        response.json().then(body => {
                            this.props.onGetRecipe();
							this.props.onGetLogs();
                            toast.success(`Successfully removed the selected release criteria!`)
                            this.setState({criteriaToRemove:[], deleting:false})
                          });
                        } else {
                          response.json().then((error) => {
                            toast.error(error.message);
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
				}
	}
    render(){
        let releaseCriteriaRows = <tr className = "helpText">Add release criteria for this recipe!</tr>
        if (this.props.batchSheet){
            releaseCriteriaRows = <tr className = "helpText">No release criteria for this recipe.</tr>
        }
        if (this.props.releaseCriteria && this.props.releaseCriteria.length >0){
            releaseCriteriaRows = this.props.releaseCriteria.map((releaseCriteria,i)=>{
            return <ReleaseCriteriaRow key = {i} releaseCriteria={releaseCriteria} recipe = {this.props.recipe} 
            currentUnits = {this.state.currentUnits} deleting = {this.state.deleting}
            addCriteriaToDelete = {this.addCriteriaToDelete} batchSheet = {this.props.batchSheet}
			removeCriteriaToDelete = {this.removeCriteriaToDelete} onGetRecipe={this.props.onGetRecipe}
            onGetLogs={this.props.onGetLogs}/>
        });}
        let recipe = this.props.recipe;
        
        if (this.props.batchSheet){
            
            return(
            <div>
                <div className = "showPriceTable" onClick = {this.toggleTable}>
                    {this.state.showTable?'- Hide':'+ Show'} Release Criteria</div> 
                
            {this.state.showTable?<table className = "priceTable">
                <thead className = "batchSheetHeader">
                    
                    <th>Criteria</th>
                    <th>Min</th>
                    <th>Target</th>
                    <th>Max</th>
                    
                </thead>
                <tbody> {releaseCriteriaRows}</tbody>
               
                
            </table>:null}
            
            </div>)

        }
        return(
            <div>
                <div className = "showPriceTable" onClick = {this.toggleTable}>
                    {this.state.showTable?'- Hide':'+ Show'} Release Criteria</div> 
                
            {this.state.showTable?<table className = "priceTable">
                <thead className = "batchSheetHeader">
                    {this.state.deleting?<th>Delete</th>:null}
                    <th>Criteria</th>
                    <th>Min</th>
                    <th>Target</th>
                    <th>Max</th>
                    
                </thead>
                <tbody> {releaseCriteriaRows}</tbody>
               
                
            </table>:null}
            <div className = "is-spaced-between">
                <AddReleaseCriteriaButton recipe = {recipe} onGetRecipe = {this.props.onGetRecipe}
                    onGetLogs = {this.props.onGetLogs}/>
                <DeleteReleaseCriteriaButton startDeleting = {this.startDeleting}
							cancelDeleting = {this.cancelDeleting}
							confirmDeleting = {this.confirmDeleting}
							deleting = {this.state.deleting}
							recipe = {recipe}/>
            </div>
            </div>
        )
    }
}

export default ReleaseCriteriaTable;