import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import './TagAccess.css';
class AddTagAccessButton extends React.Component{
    addTag = ()=>{
      // doesn't loop through the entire array properly.
      //This also causes a bunch of redundant get org calls.
      let tags = this.props.tags.concat();
      let users = this.props.users.concat();
      for (let i = 0; i < tags.length; i ++){
        for (let j = 0; j < users.length ; j ++){
          Request.authRequest(Routes.tags.access(tags[i]),
    											  'POST',JSON.stringify({email:users[j].email}),
                          'application/json').then(
                              (response) => {
                                if(response.ok){
                                  response.json().then((body) => {
                                    this.props.onChangeAccess();
                            }
                          )} else {
                              response.json().then((error) => {
                                toast.error(error.message);
                                if (error.errors){
                                  toast.error(error.errors[0])
                                }
                              });
                            }
                      }).catch((error) => {
                        toast.error(error.message);
                      }
                    )
                  }
                }
    }
    render(){
      return(
          <button className= 'changeTagAccessButton greenButton' onClick={this.addTag}> Add Tags to Users</button>



      )
    }
  }
export default AddTagAccessButton;
