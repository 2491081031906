import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './DocumentsUpload.css';
import { toast } from 'react-toastify';
import TagCleaner from '../../helpers/TagCleaner';
import TagsSearch from '../TagsSearch/TagsSearch';
import {Link} from 'react-router-dom';
class DocumentsFolderUpload extends React.Component {
	constructor() {
		super();
		this.onAttemptUpload = this.onAttemptUpload.bind(this);
		this.onChangeTags = this.onChangeTags.bind(this);
		this.state = {
			files: '',
			error: '',
			category:'',
			user_defined_id:'',
			date_expiration:'',
			expiring:true,
			notes:'',
			tags:'',

		}
	}

	componentDidMount(){
		this.getDefaultDate();

	}
	getDefaultDate = ()=>{
		var date = new Date();
		date.setUTCFullYear(date.getFullYear()+1);
		this.setState({date_expiration:date.toISOString()})

	}
	onDropFile = (e) => {
    this.setState({
    	files: [...e.target.files],
    	error: '',
    });
	}
	onChangeDocumentDate = (e) => {
		this.setState({
			date_expiration: e.target.value,
		});
	}
  onChangeExpiring = (e) => {
    let toggleexpiring = !this.state.expiring;
		this.setState({
			expiring: toggleexpiring,
		});
	}
	onChangeID = (e) => {
		this.setState({
			user_defined_id : e.target.value,
			error: '',
		});
	}

	onChangeCategory = (e) => {
		this.setState({
			category : e.target.value,
			error: '',
		});
	}
	onChangeNotes= (e) => {
		this.setState({
			notes : e.target.value,
			error: '',
		});
	}
	onChangeTags= (tags) => {
		this.setState({
			tags : tags,
			error: '',
		});
	}
	addTagsToDocument=(doc,trimmedPathTags,tags)=>{
		let trimmedTags = [];
		if (tags){
			trimmedTags = TagCleaner.parseTagArray(tags)
		}

		let oldTagNames = doc.tag_names;
      Request.authRequest(Routes.documents.tag(doc.document.id),
                          'PUT',JSON.stringify({
                    				tag_names: [...oldTagNames,...trimmedPathTags,...trimmedTags],
                    			}), 'application/json'
                        ).then((response) => {
                          if (response.status===200){
                          response.json().then(body => {
														toast.success(`Successfully uploaded ${body.name}`);
														return body
                            });
                          } else {
                            response.json().then((error) => {
                              toast.error(error.message);
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
    }

	onReturnNavigation = ()=>{
		 window.location.href='/documents/?status=accepted';
	}
	updateToast = (toastId) =>{
		toast.update(toastId, {render: `Successfully uploaded documents`, type: toast.TYPE.SUCCESS, autoClose: 5000 });
	}


	async makeCategoryTag(){
		let response = await Request.authRequest(Routes.tags.edit('Category'),'GET');
		let body = await response.json();
				let tag = body;
		    let tag_child_names = tag.child_tags.map(x => x.name)
		    let tag_parent_names= [];
		    if (tag.parent_tags){
		      if (tag.parent_tags.length > 0) {
		      tag_parent_names = tag.parent_tags.map(x => x.name)}
				}
		await	Request.authRequest(Routes.tags.tag('Category'),
													'PUT',JSON.stringify({
														child_tag_names:[...tag_child_names,this.state.category.trim()],
														parent_tag_names:tag_parent_names
													}), 'application/json'
												).catch((error) => {
													toast.error(error.message);
												}
											)
	}

		async onAttemptUpload(e){
			var date = new Date();
			if (this.state.files === null) {
				this.setState({ error: 'No file was uploaded' });
				toast.error('Please Select a File')
				return null;
			}
			if (this.state.category === '') {
				this.setState({error: 'No category specified'});
				toast.error('Please Specify a Document Category')
				return null;
			}
			if (this.state.category.indexOf(',') > -1) {
				toast.error('Categories cant contain commas')
				return null
			}


			if (this.state.expiring && new Date(this.state.date_expiration)< date){
				toast.error('Expiration dates must be in the future.')
				return null
			}
			await this.makeCategoryTag();
			let toastID = toast.info('Uploading documents...',{autoClose:false});
			let metadata = {category:this.state.category,
				tag_names:[]};

			if (this.state.user_defined_id) {
				metadata.user_defined_id = this.state.user_defined_id;
			}
			if (this.state.notes) {
				metadata.notes = this.state.notes;
			}
			if (this.state.expiring===true) {
				metadata.date_expiration = this.state.date_expiration;
			}

			if (this.state.tags){
				metadata.tag_names = this.state.tags
			}
			Promise.all(this.state.files.map((file)=>this.onGetPresignedURL(file,metadata,this.state.tags))).then(response =>{
				toast.update(toastID, { render: "Successfully uploaded all documents",type: toast.TYPE.SUCCESS, autoClose: 5000 });

				this.setState({files: null ,user_defined_id:'',category:'',notes:'',tags:''},this.getDefaultDate());

				}
			)
		}
	 async onGetPresignedURL(file,metadata,tags){
			return await Request.authRequest(Routes.s3.presignedURL, 'GET').then(async (response) => {
					return await response.json().then(async (body) => {
						return Request.awsRequest(body.signed_url, 'PUT',file).then(async (response) => {
							if (response.ok){
							return await this.uploadFile(body.document_id,file,metadata,tags);}
							else{
								toast.error(`Sorry, we can't upload your file right now. Please email help@ivydocuments.com.`)
							}
						})

					})
				})

			}

		async uploadFile (fileID,file,metadata,tags){
			metadata.name = file.name;
			let pathTags = file.webkitRelativePath.split('/');

			pathTags.pop();
			const fileMetadata = JSON.parse(JSON.stringify(metadata));
			fileMetadata.document_id = fileID;
			fileMetadata.tag_names = [...fileMetadata.tag_names,...pathTags];
			let trimmedPathTags = TagCleaner.parseTagArray(fileMetadata.tag_names);
			fileMetadata.tag_names = trimmedPathTags
			return await Request.authRequest(Routes.documents.create,
												  'POST', JSON.stringify(fileMetadata),'application/json').then((response) => {
														if (response.status===200){
									response.json().then(body => {
										/*if (tags !==''){
											return this.addTagsToDocument(body,tags);

										}*/
										toast.success(`Successfully uploaded ${body.name}`);
													});
							} else {
								response.json().then((error) => {
									if (error.errors){
										toast.error(error.errors[0])
										toast.error('Your file could not be uploaded right now.')
									}
								});
							}
					}).catch((error) => {
					toast.error(error.message);
					}
			 )
		}
	onEscape=()=>{
		return
	}
	render() {

		let multipleSelect;
		if (this.state.files && this.state.files.length>1){
			multipleSelect = true;
		}
		return (
		  <div className='documents-container upload-container'>
				<div className = "document-upload-header"> Upload Folder
					<Link className = "uploadToggleButton noselect" to='/upload/'>Upload Documents</Link>
				</div>
				<div className = "helpText">This feature is only supported on Chrome, Firefox and Edge.</div>
				<div className = "document-upload-contents">
				<div className='document-category'>
					<label className='input-label' htmlFor='category'> Document Category <div className = "helpText-docCategory">(required)</div></label>
					<div className='input-container'>
						<input type='text' value = {this.state.category} onChange={this.onChangeCategory} id='category' className='input-text'/>
					</div>
				</div>
				<div className='file-dropzone-container'>
					{this.state.error}
					<input id="folder-picker" name="fileList" type = "file" webkitdirectory="true"
						onChange = {this.onDropFile}/>
				</div>
				<div className = "upload-optional-info">
					<div className = "upload-optional-info-header">Optional Information
					</div>
					<div className='user-def-id'>
						<label className='input-label' htmlFor='user_defined_id'> User Defined ID </label>
						<div className='input-container'>
							<input type='text' value = {this.state.user_defined_id} onChange={this.onChangeID} id='user_defined_id' className='input-text'/>
						</div>
					</div>
					<div className='upload-notes'>
						<label className='upload-label' htmlFor='upload-notes'> Notes </label>
							<textarea type='text' value = {this.state.notes}
								onChange={this.onChangeNotes} id='upload-notes' className='input-text'/>
					</div>
					<div className='upload-tags'>
						<label className='upload-label' htmlFor='upload-notes'> Tags </label>
							<TagsSearch onSendTags = {this.onChangeTags}
	            onEsc = {this.onEscape}/>
						<div className = "helpText">Your folder names will automatically be converted to tags and applied to the documents. You don't need to enter them here.</div>
					</div>
					<div className='upload-date'>
						{this.state.expiring?
							<div className = "upload-date">
								<label className='document-label noselect' >Expires On </label>
								<input
		              value={this.state.date_expiration.substring(0,10)}
		              type='date'
		              className='document-info-field'
		              onChange={this.onChangeDocumentDate}
		              onKeyPress={this.handleEnter}/>
							</div>:null}
            <div>
              <input
                checked = {!this.state.expiring}
                name = 'expiresBoolean'
                type='checkbox'
                id = 'expiresBoolean'
                onChange={this.onChangeExpiring}/>
              <label htmlFor ='expiresBoolean'> This document doesn't expire</label>
            </div>
					</div>
				</div>
				{multipleSelect?<div className = "helpText">You've selected multiple files. Any optional information will be applied to every
					file uploaded. You can always change the information later.</div>:null}
				<div>
					<button className = "form-button primary" onClick={this.onAttemptUpload} id = "onUpload"> Upload </button>
					<button className = "form-button secondary noselect" id = "onUpload"
						onClick ={this.onReturnNavigation}>Return to Navigation</button>
				</div>
				</div>
			</div>
		);
	}
}

export default DocumentsFolderUpload;
