import React from 'react';
import ReleaseCriteriaTable from '../RecipeViewer/ReleaseCriteriaTable';
import BatchSheetRow from './BatchSheetRow';
import UnitSelector from '../../UnitSelector/UnitSelector';
import './BatchSheetViewer.css';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import BillOfMaterials from './BillOfMaterials';
class BatchSheetViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            weightBasis: 100,
            currentUnits: 'kg',
            batchSheet: ''
        }
    }
    changeUnits = (units) => {
        this.setState({ currentUnits: units });
    }
    onChangeWeightBasis = (e) => {
        this.setState({ weightBasis: e.target.value });
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.batch_sheet && prevProps.batch_sheet.weight_basis_kg != this.props.batch_sheet.weight_basis_kg) {
            this.setState({ weightBasis: this.props.batch_sheet.weight_basis_kg })
        }
    }
    componentDidMount = () => {
        if (this.props.batch_sheet) {
            this.setState({ weightBasis: this.props.batch_sheet.weight_basis_kg });
        }
    }
    onCalculateNewValues = () => {
        let batch_sheet = this.state.batchSheet
        if (this.props.batch_sheet) {
            batch_sheet = this.props.batch_sheet
        }
        if (this.props.recipe) {
            batch_sheet = this.props.recipe.batch_sheets;
        }
        if (this.state.batchSheet) {
            batch_sheet = this.state.batchSheet;
        }
        let weight_basis = this.state.weightBasis + ' ' + this.state.currentUnits

        Request.authRequest(Routes.recipes.batchSheet(batch_sheet.id),
            'PUT', JSON.stringify(
                { weight_basis: weight_basis }
            ), 'application/json'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.setState({
                        batchSheet: body,
                        weightBasis: body.weight_basis_kg
                    })
                });
            } else {
                response.json().then((error) => {
                    toast.error(error.message);
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )

    }
    render() {
        let batch_sheet;
        let id;
        if (this.props.batch_sheet) {
            batch_sheet = this.props.batch_sheet
        }
        if (this.props.recipe) {
            id = this.props.recipe.user_defined_id;
            batch_sheet = this.props.recipe.batch_sheets;
        }
        if (this.state.batchSheet) {
            batch_sheet = this.state.batchSheet;
        }
        let ingredients = batch_sheet.batch_ingredients.map((ingredient, i) => {
            return <BatchSheetRow ingredient={ingredient} key={i} ignore_density={batch_sheet.ignore_density} />
        })
        let ignore_density;
        let batch_sheet_name;

        let releaseCriteria;
        if (batch_sheet) {
            ignore_density = batch_sheet.ignore_density;
            releaseCriteria = batch_sheet.batch_release_criteria;
        }
        let recipe = this.props.recipe;

        if (this.props.doc) {
            id = this.props.doc.user_defined_id;
            batch_sheet_name = this.props.doc.name;
        }
        return (
            <div>
                <div>
                    <div className="batchSheet-sectionContainer">
                        <div className="batchSheet-sectionHeader">
                            <div>{batch_sheet_name}</div>
                            <div>{id}</div></div>
                        <div className="batchSheet-sectionHeader">Batch Size</div>
                        <div className="batchSheet-weightBasis">
                            <input type="float" onChange={this.onChangeWeightBasis} value={this.state.weightBasis} placeholder={batch_sheet.weight_basis_kg} />
                            <UnitSelector changeUnits={this.changeUnits} unit={this.state.currentUnits} ignore_density={ignore_density} />
                        </div>
                        <div className="batchSheet-calculateWeightBasis" onClick={this.onCalculateNewValues}>Calculate</div>
                    </div>
                    <div className="batchSheet-overall ">
                        <table className="batchSheetTable">
                            <tbody className="batchSheetTable batchSheetWeightBasis">
                                <tr className="batchSheetTable-row-headers batchSheetHeader">
                                    <th>Kilograms</th>
                                    <th>Pounds</th>
                                    {ignore_density ? null : <th>Liters</th>}
                                    {ignore_density ? null : <th>Gallons</th>}
                                </tr>

                                <tr className="batchSheetTable-row-headers">
                                    <td>{batch_sheet.f_weight_kg}</td>
                                    <td>{batch_sheet.f_weight_lb}</td>
                                    {ignore_density ? null : <td>{batch_sheet.f_vol_L}</td>}
                                    {ignore_density ? null : <td>{batch_sheet.f_vol_gal}</td>}
                                </tr>
                            </tbody>
                        </table>
                        {ignore_density ? null : <div>Total Density: {batch_sheet.f_density_kg_L} {batch_sheet.f_density_lb_gal}</div>}
                    </div>
                </div>
                <div className="batchSheet-sectionContainer">
                    <div className="batchSheet-sectionHeader">Ingredients</div>
                    <table className="batchSheetTable">
                        <tr className="batchSheetTable-row-headers">
                            <th className="ingredient-name">Ingredient</th>
                            <th className="ingredient-percent">%</th>

                            <th className="batchSheet-field">Kilograms</th>
                            <th className="batchSheet-field">Pounds</th>
                            {ignore_density ? null : <th className="batchSheet-field">Liters</th>}
                            {ignore_density ? null : <th className="batchSheet-field">Gallons</th>}
                            {ignore_density ? null : <th className="batchSheet-minor-field">kg/L</th>}
                            {ignore_density ? null : <th className="batchSheet-minor-field">lb/gal</th>}
                            <th className="batchSheet-properties">Properties</th>
                        </tr>

                        {ingredients}
                    </table>
                </div>
                <div>
                    <ReleaseCriteriaTable releaseCriteria={releaseCriteria} recipe={recipe}
                        onGetRecipe={this.onGetRecipe} batchSheet
                        onGetLogs={this.onGetLogs} />
                </div>
            </div>
        )
    }
}
export default BatchSheetViewer;