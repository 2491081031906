import React from 'react';
import './ShortLogsRow.css';

import {toast} from 'react-toastify';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
class ShortLogsRow extends React.Component{
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state={
      editing:false,
      string:this.props.log.notes ,
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.content!== this.props.content){
      this.setState({string:this.props.content});
    }
  }
  componentDidMount(){
    if (this.props.content){
    this.setState({string:this.props.content})}
  }
  onSaveChanges(){
    if (this.state.string === this.props.log.notes){
      return
    }
    Request.authRequest(Routes.logs.log(this.props.log.id),'PUT',
    JSON.stringify({notes:this.state.string}),
    'application/json').then((response) => {
      if (response.status===200){
      response.json().then(body => {
                toast.success('Added note to a log')
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
      }).catch((error) => {
      toast.error(error.message);
      }
      )

  }

  handleClick = (e) =>{
    if (this.state.editing!==true){
      this.setState({
        editing:true
      })
    }
    else if(this.state.editing!==false){
      if (this.state.string.length>2000){
        toast.error('This field is limited to 2000 characters. Press escape to revert changes.');
        return
      }
      this.onSaveChanges();
      this.setState({editing:false})
    };
  }
  componentWillMount(){
    document.addEventListener('dblclick',this.handleDblClick,false);
  }
  componentWillUnmount(){
    document.removeEventListener('dblclick',this.handleDblClick,false);
  }
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      if (this.state.string.length>2000){
        toast.error('This field is limited to 2000 characters. Press escape to revert changes.');
        return
      }
      this.onSaveChanges();
      this.setState({editing:false})
    }
  }
  onChangeLogInfo = (e) => {
		this.setState({
			string: e.target.value,
		});
	}
  onKeyDown=(e)=>{
    if (e.key === 'Escape'){
      this.setState({editing:false,string:this.props.content})
    }
  }
  render(){
    let log = this.props.log;
    let date = new Date(log.date_created);
    let dateString;
    dateString = date.toDateString();
    let string = this.state.string;
    return (
      <div className = "summaryLogsRow-container">
        <div className = "log-decoration">
          <div className = "log-line-short"></div>
          <div className = "log-circle">
          </div>
          <div className = "log-line"></div>
        </div>
      <div className='ShortLogsRow-container'>
        <span className='shortLogs-description'>
          <span className='shortlog-text'>{log.description}</span>
        </span>
        <span className='shortLogs-timestamp'>
          <span className='log-text-info'>{log.username}</span>
          <span className='log-text-info'>{dateString}</span>
          {this.state.editing?<div className = "log-text-noteButton" onClick = {this.handleClick}>Save</div>
        :<div className='log-text-noteButton' onClick = {this.handleClick} >Edit Notes</div>}
        </span>
          {this.state.editing?

          <textarea
            value={this.state.string}
            type='text'
            className='log-notes-field'
            onChange={this.onChangeLogInfo}
            onKeyPress={this.handleEnter}
            onKeyDown={this.onKeyDown}/>:
            <div className = 'log-notes wordbreak'>{string}
          </div>

          }

      </div>
      </div>
    );
  }
}
export default ShortLogsRow;
