import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
class EditReceivedNotifications extends React.Component{
  constructor(props){
    super(props);
    this.state={
      receives:props.receives,
    }
  }
  onChangeReceives = (e) => {

    Request.authRequest(Routes.notifications.settings,'POST',JSON.stringify({email:this.props.user.email,
      receive_notifications:!this.state.receives}),'application/json').then(
        (response) => {
          if (response.status===200){
          response.json().then(body => {
            this.props.onGetNotificationsSettings();
            toast.success('Changed Notification Settings')
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
    toast.error(error.message);
    }
  )

  this.setState({
    receives: !this.state.receives,
  });
	}

  render(){

    return (

      <div className = "user-list-section">
          <input checked={this.state.receives}
            type = 'checkbox'

            onChange= {this.onChangeReceives}/>

    </div>)

  }
}
export default EditReceivedNotifications;
