import React from 'react';
import { Tag } from '../Icons/IconLibrary';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import TagCleaner from '../../helpers/TagCleaner';
import TagsSearch from '../TagsSearch/TagsSearch';
import LoadingButton from '../Buttons/LoadingButton';
import './Navigation.css';
class AddRecipeButton extends React.Component {
  constructor(props) {
    super(props);
    this.onEnterTagName = this.onEnterTagName.bind(this);
    this.state = {
      tagNames: '',
      showModal: false,
      loading: false,
      recipeName: '',
      user_defined_id: '',
      ignore_density: false,
      requires_signatures: false,
    }
  }
  onShowModal = () => {
    this.setState({ showModal: true })
  }
  onHideModal = () => {
    this.setState({
      showModal: false
    })
  }

  onEnterTagName = (tags) => {
    this.setState({
      tagNames: tags,
    });
  }
  handleEnter = (e) => {
    if (e.key === 'Enter') {
      this.onAddTag();
    }
  }
  onEscape = () => {
    return
  }
  onCreateRecipe = () => {
    if (!this.state.tagNames) {
      toast.error('Please enter at least one tag. Empty tag names are not allowed.')
      return
    }
    let trimmedTags = TagCleaner.parseTagArray(this.state.tagNames);
    if (trimmedTags.length === 0) {
      toast.error('Please enter at least one tag. Empty tag names are not allowed.')
      return
    }
    if (this.state.tagNames.length > 500) {
      toast.error('This field is limited to 500 characters. Please trim your entry.')
      return
    }
    this.setState({ loading: true });
    Request.authRequest(Routes.recipes.index(''),
      'POST', JSON.stringify({
        name: this.state.recipeName.trim(),
        user_defined_id: this.state.user_defined_id.trim(),
        tag_names: trimmedTags,
        ignore_density: this.state.ignore_density,
        requires_signatures: this.state.requires_signatures
      }), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          window.location.href = `/recipes/${body.recipe_id}`;
        });
      } else {
        response.json().then((error) => {

          console.log(error)

          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )

  }

  changeRecipeName = (e) => {
    this.setState({ recipeName: e.target.value })
  }
  changeUserId = (e) => {
    this.setState({ user_defined_id: e.target.value })
  }
  checkBox = () => {
    this.setState({ ignore_density: !this.state.ignore_density })
  }
  checkSignatureBox = () => {
    this.setState({ requires_signatures: !this.state.requires_signatures })
  }
  render() {

    return (
      <div id="buttonTag-container">
        <button className='buttonUpload noselect' onClick={this.onShowModal}>
          Create
        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Create a New Recipe' >

          <div className="button-modalText">
            <div className="addRecipe-spacer">
              <div>Enter a name for the new recipe.
              </div>
              <div>
                <input value={this.state.recipeName} onChange={this.changeRecipeName} />
              </div>
              <div className="addRecipe-spacer">
                <div>Enter an id for the new recipe (optional).</div>
                <div>
                  <input value={this.state.user_defined_id} onChange={this.changeUserId} />
                </div>
              </div>
              <div className="addRecipe-spacer">
                <div>
                  Enter the name of the tags you'd like to apply, separated by commas.</div>
                <div>
                  <TagsSearch onSendTags={this.onEnterTagName}
                    onEsc={this.onEscape} />
                </div>
              </div>
              <div className="addRecipe-spacer">
                <div>
                  Check if this recipe makes a solid, or to hide density calculations.</div>
                <div>
                  <input type="checkbox" value={this.state.ignore_density} onClick={this.checkBox}
                    checked={this.state.ignore_density} />
                </div>
              </div>
              <div className="addRecipe-spacer">
                <div>
                  Check if this recipe requires signatures prior to being locked.</div>
                <div>
                  <input type="checkbox" value={this.state.requires_signatures} onClick={this.checkSignatureBox}
                    checked={this.state.requires_signatures} />
                </div>
              </div>
            </div>
            <button className='modal-button primary'>
              <LoadingButton onClick={this.onCreateRecipe}
                text='Create' isLoading={this.state.loading} />
            </button>
          </div>
        </Modal>
      </div>
    )
  }
}
export default AddRecipeButton;
