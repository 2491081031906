import React from 'react';
import {SingleDatePicker} from 'react-dates';
import moment from 'moment';
import './SentDatePickers.css';
class SentDatePickers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      beforeFocused: false,
      afterFocused: false
    }
  }

  onChangeBeforeDocumentDate = (date) => {
    if (date) {
      this.props.sendDate(date._d, 'beforeDate');
    }
  }
  clearDate = () => {
    this.props.sendDate('', 'clearDate');
  }

  onChangeAfterDocumentDate = (date) => {
    if (date) {
      this.props.sendDate(date._d, 'afterDate');
    }
  }
  onChangeBeforeFocus = () => {
    this.setState({
      beforeFocused: !this.state.beforeFocused
    })
  }
  onChangeAfterFocus = () => {
    this.setState({
      afterFocused: !this.state.afterFocused
    })
  }
  render() {
    return (
      <div>

        <div className="sentDatePicker-menu">
          <div className="sentDatePicker-header">


          </div>
          <div className = "sentDatePicker-content">
            <div>
              <div className="expirationSearch-label">
                {this.props.signed?'Signed Before':'Sent Before'}

                <SingleDatePicker
                  date={moment(this.props.sentBefore)}
                  onDateChange={this.onChangeBeforeDocumentDate}
                  focused={this.state.beforeFocused}
                  onFocusChange={this.onChangeBeforeFocus}
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  id="documentDateField"/>
              </div>
            </div>
            <div>
              <div className="expirationSearch-label">
                {this.props.signed?'Signed Before':'Sent After'}
                <SingleDatePicker
                  date={moment(this.props.sentAfter)}
                  onDateChange={this.onChangeAfterDocumentDate}
                  focused={this.state.afterFocused}
                  onFocusChange={this.onChangeAfterFocus}
                  numberOfMonths={1}
                  isOutsideRange={() => false}
                  id="documentDateField"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default SentDatePickers;
