import React, { version } from 'react';

import './IngredientRow.css';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import TagLabel from '../Documents/TagLabel';
import IngredientDocumentRow from './IngredientViewer/IngredientDocumentRow';
class IngredientNavRow extends React.Component {
	constructor(){
		super();
		this.state={
		expanded:false,
        percentValue:30
		}
		}
        componentDidUpdate(prevProps){
            if (!this.props.selectedIngredient && 
                prevProps.activeDocuments!==this.props.activeDocuments){
                let activeDocumentsIds =(this.props.activeDocuments.map((entity)=>{return entity.id}));
                if (activeDocumentsIds.indexOf(this.props.ingredient.id)>-1){
                    this.setState({isActive:true})
                }
                else{
                    this.setState({isActive:false})
                }}
            
        }
	onToggleExpand = () =>{
        this.setState({expanded:!this.state.expanded})
    }
    onChange = (e) =>{
        this.setState({percentValue:e.target.value});
        this.props.sendChangedPercent(this.props.ingredient,e.target.value);
    }
    selectIngredient = () =>{
        this.props.onSelectDoc(this.props.ingredient)
    }
    onGoToViewer = (e) =>{
        e.stopPropagation();
        window.location.href='/ingredients/'+this.props.ingredient.id
    }
	render() {
        let ingredient = this.props.ingredient;
        
        let base_cost_kg = "None";
        let base_cost_lb = "None";
        let isActive = this.state.isActive;
        if (this.props.selectedIngredient && this.props.selectedIngredient.id == ingredient.id){
            isActive = true;
        }
        else if (this.props.selectedIngredient && this.props.selectedIngredient!= ingredient.id){
            isActive = false
        }
        let tagList;
		if (ingredient.tags){
		 tagList = ingredient.tags.map((entity,i)=>{
			return <TagLabel tag={entity} key ={i}/>
        })}
        let version_number;
        if (!ingredient.version_number && ingredient.version_history_with_self.length>1){
            version_number = 'v0';
        }
        if (ingredient.version_number){
            version_number = 'v' + ingredient.version_number;
            if (ingredient.is_current){
                version_number = version_number + " - current"
            }
        }
        let user_defined_id;
		if (ingredient.user_defined_id !== 'null'&& ingredient.user_defined_id && ingredient.user_defined_id!=='undefined'){
			user_defined_id = ingredient.user_defined_id
		}
        return(
        <div className = "ingredientNavRow-container " id={isActive?'document-row-active':null} 
            onClick = {this.selectIngredient}>
      
            <div className = "ingredient-name">
                <div>{ingredient.name} </div>
            <span className='document-row-id wordbreak'>{user_defined_id} {version_number}</span>
            </div>
            <div className = "ingredient-price">${ingredient.f_price_kg}</div>
            <div className = "ingredient-tags"> {tagList}</div>
       
                <div className = "button-document-row-infoViewer" onClick = {this.onGoToViewer}>Details</div>
            
        </div>
            
		);
	}
}

export default IngredientNavRow;
