import React from 'react';
import './BatchSheetViewer.css';
class BatchSheetDataDownload extends React.Component {
    downloadData = () => {
        let recipe = this.props.recipe
        let name = [this.props.recipe_name]
        let batch_size = ['Batch Size', this.props.input_batch_size, this.props.input_size_units]
        let recipe_unit_headers = ['Kilograms', 'Pounds', 'Liters', 'Gallons']
        let batch_amounts = [recipe.f_weight_kg, recipe.f_weight_lb, recipe.f_vol_L, recipe.f_vol_gal]
        let ingredientHeader = ['Ingredients']
        let ingredient_labels = ['Ingredient', '%', 'Kilograms', 'Pounds', 'Liters', 'Gallons']
        let ingredients = recipe.recipe_ingredients.map(ingredient => {
            return [ingredient.ingredient_name, ingredient.weight_ratio, ingredient.f_weight_basis_kg, ingredient.f_weight_basis_lb, ingredient.f_vol_basis_L,
            ingredient.f_vol_basis_gal, ingredient.f_density_kg_L, ingredient.f_density_lb_gal]
        })

        let rows = [
            name,
            batch_size,
            recipe_unit_headers,
            batch_amounts,
            [' '],
            ingredientHeader,
            ingredient_labels,
            ingredients,
            // '',
            // release_criteria_labels,
            // release_criteria,
        ]
        let csvContent = "data:text/csv;charset=utf-8,"
        rows.forEach(function (rowArray) {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }
    render() {
        return (
            <div onClick={this.downloadData} className="showPriceTable">Download</div>
        )
    }
}

export default BatchSheetDataDownload;
