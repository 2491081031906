import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import DefaultRecipeTags from './DefaultRecipeTags';
import BatchIncludedAttributes from './BatchIncludedAttributes';
class RecipeSettings extends React.Component {
    constructor() {
        super();
        this.state = {
            settings: ''
        }
    }
    componentDidMount = () => {
        this.onGetSettings();
    }
    onGetSettings = () => {
        Request.authRequest(Routes.organization.recipeSettings,
            'GET').then(
                (response) => {
                    if (response.ok) {
                        response.json().then((body) => {
                            this.setState({ settings: body })
                        }
                        )
                    } else {
                        response.json().then((error) => {
                            toast.error(error.message);
                            if (error.errors) {
                                toast.error(error.errors[0])
                            }
                        });
                    }
                }).catch((error) => {
                    toast.error(error.message);
                }
                )


    }
    render() {
        return (
            <div className='settings-section boxShadow' >
                <div className='settings-heading'> Recipe Settings </div>
                <hr className='settings-section-divider' />
                <div className="recipeSetting-container">
                    <BatchIncludedAttributes recipeSettings={this.state.settings} onGetRecipeSettings={this.onGetSettings} />
                    <DefaultRecipeTags recipeSettings={this.state.settings} onGetRecipeSettings={this.onGetSettings} />
                </div>
            </div>
        )
    }


}

export default RecipeSettings;
