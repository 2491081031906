import React from 'react';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import {toast} from 'react-toastify';
import PendingExternalDocRow from './PendingExternalDocRow';
import './PendingExternalDocs.css';
import moment from 'moment';
import qs from 'qs';
import SentDatePickers from './SentDatePickers';
class PendingExternalDocs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingDocs: [],
      sentBefore: moment(),
      sentAfter: moment().subtract(30, 'days'),
      organization: '',
      selectedUser: 'All'
    }
  }
  componentDidMount = () => {
    this.onGetDocuments();
    this.getOrganization();
  }
  
  getOrganization = () => {
    Request.authRequest(Routes.organization.index, 'GET').then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.setState({organization: body})

        })
      } else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    })
  }
  setDate = (date, type) => {
    if (type === 'beforeDate') {
      this.setState({
        sentBefore: date
      }, () => this.onGetDocuments())
    }
    if (type === 'afterDate') {
      this.setState({
        sentAfter: date
      }, () => this.onGetDocuments())
    }
    if (type === 'clearDate') {
      this.setState({
        sentBefore: moment(),
        sentAfter: moment().subtract(30, 'days'),
        selectedUser: 'All'
      }, () => this.onGetDocuments())
    }

  }
  clearInputs = () => {
    this.setState({
      sentBefore: moment(),
      sentAfter: moment().subtract(30, 'days'),
      selectedUser: 'All'
    }, () => this.onGetDocuments())
  }
  onGetDocuments = () => {
    let afterIsoDate = this.state.sentAfter.toISOString();
    let beforeIsoDate = this.state.sentBefore.toISOString();
    let searchString = `?sent_after=${afterIsoDate}&sent_before=${beforeIsoDate}`
    if (this.state.selectedUser !== 'All') {
      searchString += `&email=`+encodeURIComponent(this.state.selectedUser);
    }
    Request.authRequest(Routes.reports.recentlyShared(searchString), 'GET').then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          this.setState({pendingDocs: body})
        })
      } else {
        response.json().then((error) => {

          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    })
  }
  onSelectUser = (e) => {
    this.setState({
      selectedUser: e.target.value
    }, () => {
      this.onGetDocuments()
    })
  }
  render() {
    let externalDocs = <div className="helpText">All the documents you've sent have been reviewed! Great job.</div>
    if (this.state.pendingDocs.length > 0) {
      externalDocs = this.state.pendingDocs.map((doc, i) => {
        return <PendingExternalDocRow doc={doc} key={i}/>
      })
    }
    let users;
    if (this.state.organization) {
      users = this.state.organization.members.map((user, i) => {
        return <option value={user.email} key={i}>{user.first_name} {user.last_name} {user.email}</option>
      })
    }

    return (
      <div className="externalDocs-container boxShadow">
        <div className = "is-spaced-between">
        <div className="notificationsheader">Recently Sent Docs Awaiting Review</div>
        <div onClick={this.clearInputs} className="sentDatePicker-clear">
          Clear Filters</div>
        </div>
        <div >
          <SentDatePickers
            sendDate={this.setDate}
            sentAfter={this.state.sentAfter}
            sentBefore={this.state.sentBefore}/>
          <div>
            <div className="expirationSearch-label">Sent By</div>
            <select
              value={this.state.selectedUser}
              onChange={this.onSelectUser}
              className="externalDocs-selectUser">
              <option value="All">All users</option>
              {users}
            </select>
          </div>
        </div>
        <div className="externalDocs-content">{externalDocs}</div>
      </div>
    )
  }
}

export default PendingExternalDocs;
