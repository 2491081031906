import React from 'react';
import { Duplicate } from '../../Icons/IconLibrary';
import Modal from '../../Modal/Modal';
import LoadingButton from '../../Buttons/LoadingButton';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
class RecipeForkButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      name: 'Copy of ' + this.props.recipe.name,
      loading: false,
    }
  }
  onShowModal = () => {
    this.setState({ showModal: true })
  }
  onHideModal = () => {
    this.setState({
      showModal: false
    })
  }
  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }
  forkRecipe = () => {
    Request.authRequest(
      Routes.recipes.fork(this.props.recipe.recipe_id),
      'POST', JSON.stringify({ name: this.state.name }), 'application/json'
    ).then((response) => {
      if (response.status === 200) {
        response.json().then(body => {
          window.location.href = `/recipes/` + body.recipe_id
          toast.success(`Successfully duplicated the recipe!`)
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
    }).catch((error) => {
      toast.error(error.message);
    }
    )

  }
  render() {
    return (
      <div>
        <img src={Duplicate} className="signatureButton-text" onClick={this.onShowModal}
          alt="Duplicate this recipe into a new recipe" title="Duplicate this recipe into a new recipe" />
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Duplicate' >
          <div>Duplicate this recipe. This recipe will copy all tags, ingredients,
            weight ratios and release criteria but will not copy any recipients of batch sheets nor version history.
            It will be considered as a separate recipe for version history purposes.</div>
          <div>Enter a name for the copy.</div>
          <input value={this.state.name} onChange={this.onChangeName}
            type='text' placeholder={'Enter a name for the copy'} />
          <div className='modal-button primary'>
            <LoadingButton onClick={this.forkRecipe}
              text='Create Duplicate' isLoading={this.state.loading} />
          </div>
        </Modal>

      </div>
    )
  }
}

export default RecipeForkButton;