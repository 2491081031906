import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../../Modal/Modal';
import TagNavigator from '../../Tags/TagNavigator';
import AddTagAccessButton from './AddTagAccessButton';
import ExcludeTagAccessButton from './ExcludeTagAccessButton';
import RemoveTagAccessButton from './RemoveTagAccessButton';
import RemoveExcludedTagAccessButton from './RemoveExcludedTagAccessButton';
import UserTagsAccess from './UserTagsAccess';
import ActiveTagsNavigator from '../../Tags/ActiveTagsNavigator';
import SelectedTagContainer from './SelectedTagContainer';
import SelectedUserContainer from './SelectedUserContainer';

import './TagAccess.css';
class TagAccessButton extends React.Component{
    constructor(){
      super();
      this.onChangeTags = this.onChangeTags.bind(this);
      this.onShowModal = this.onShowModal.bind(this);
      this.state={
        showModal:false,
        activeTags:[],
        fromDictionary:true,
        selectedUsers:[]
      }
      this.sendSelectedUser = this.sendSelectedUser.bind(this);
    }
    async onShowModal(){
      if (!this.props.users){
        await this.props.getOrganization();
        this.setState({showModal:true})
      }
      this.setState({showModal:true})
    }

    onHideModal=()=>{
      this.setState({showModal: false, activeTags:[],selectedUsers:[]
    })}

    addTag = ()=>{

          Request.authRequest(Routes.tags.access(this.state.accessTag),
    											  'POST',JSON.stringify({email:this.props.user.email}),
                          'application/json').then(
                              (response) => {
                                if(response.ok){
                                  response.json().then((body) => {

                                  this.setState({showModal:false, accessTag:''});
                            }
                          )} else {
                              response.json().then((error) => {
                                toast.error(error.message);
                                if (error.errors){
                                  toast.error(error.errors[0])
                                }
                              });
                            }
                      }).catch((error) => {
                        toast.error(error.message);
                      }
                    )

    }
    sendSelectedUser = (user) =>{
      this.setState({selectedUsers:[...this.state.selectedUsers, user]}
      )
    }
    removeUser = (user) =>{
      let userList = this.state.selectedUsers.filter(users => users.email !== user.email)
      this.setState({selectedUsers:userList}
      )
    }
    onChangeAccess = ()=>{
        this.props.getOrganizationUsers();
        this.setState({activeTags:[],selectedUsers:[]})
    }

    onRemoveTag = (tag) =>{
      let tagList = this.state.activeTags.filter(tags => tags !== tag)
      this.setState({activeTags:tagList}
      )
    }
    onAddTag = (tag)=>{
      this.setState({activeTags:[...this.state.activeTags,tag]})
    }
    onChangeExcludedTags=()=>{
      return
    }
    onChangeTags = (tags)=>{
      this.setState({activeTags:tags})
      return
    }
    onLastSelected = (tag)=>{
      return
    }
    clearActiveTags = () =>{
      this.setState({activeTags:[]})
    }
    clearSelectedUsers = () =>{
      this.setState({selectedUsers:[]})
    }
    render(){
      let activeTags = this.state.activeTags;

      return(
        <div className = "buttonMergeTag">
          <button className='mergeButton noselect' id = "buttonMergeTag" onClick = {this.onShowModal}>
            Manage Tag Access

          </button>
          <Modal show={this.state.showModal} onClose={this.onHideModal} title='Tag Access Control' >
            <div>
              <div className = "tagAccess-activeManipulation">
                <div className = "tagAccess-activeTagsContainer">
                  <div className = "tagAccess-activeHeaderContainer">Selected Tags
                    <div onClick = {this.clearActiveTags}
                      className = "tagAccess-clearButton">Clear</div>
                  </div>
                  <SelectedTagContainer activeTags = {this.state.activeTags}
                    onAddTag = {this.onAddTag} onRemoveTag = {this.onRemoveTag}/>
                </div>
                <div className = "tagAccess-buttonsContainer">
                  <AddTagAccessButton tags= {this.state.activeTags}
                    users = {this.state.selectedUsers}
                     onChangeAccess = {this.onChangeAccess}/>
                  <RemoveTagAccessButton tags= {this.state.activeTags}
                    users = {this.state.selectedUsers} onChangeAccess = {this.onChangeAccess}/>

                  <ExcludeTagAccessButton tags= {this.state.activeTags}
                        users = {this.state.selectedUsers}
                         onChangeAccess = {this.onChangeAccess}/>
                       <RemoveExcludedTagAccessButton tags= {this.state.activeTags}
                        users = {this.state.selectedUsers} onChangeAccess = {this.onChangeAccess}/>
                </div>
                <div className = "tagAccess-activeUsers">
                  <div className = "tagAccess-activeHeaderContainer">Selected Users
                    <div onClick = {this.clearSelectedUsers}
                      className = "tagAccess-clearButton">Clear
                    </div>
                  </div>
                    <SelectedUserContainer selectedUsers = {this.state.selectedUsers}
                      sendSelectedUser = {this.sendSelectedUser} removeUser={this.removeUser}
                      onChangeTagAccess={this.props.getOrganizationUsers}/>
                </div>
              </div>
              <div className = "TagAccessModalContents">
                <div className = "TagAccessModalContents-tagsDictionary">
                  <div>All Available Tags</div>
                  <div className = "TagAccess-tagsDictionaryContainer">
                  <TagNavigator onLastSelected={this.onLastSelected}
                    onChangeTags = {this.onChangeTags}
                    excludedTags={[]}
                    onChangeExcludedTags = {this.onChangeExcludedTags}
                    activeTags={this.state.activeTags}/>
                  </div>
                </div>
                <div className = "TagAccessModalContents-availableUsers">
                  <div> Accessible Tags By User </div>
                  <UserTagsAccess users = {this.props.users}
                    sendSelectedUser = {this.sendSelectedUser}
                    onLastSelected={this.onLastSelected}
                      onChangeTags = {this.onChangeTags}
                      activeTags={this.state.activeTags}
                    />
                </div>
              </div>

            </div>
        </Modal>
        </div>


      )
    }
  }
export default TagAccessButton;
