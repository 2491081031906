import React from 'react';
import Banana from './imgs/banana.png';
import Flower from './imgs/flower.png';
import Fruit from './imgs/fruit.png';
import Grass from './imgs/grass.png';
import Green from './imgs/green.png';
import Plants from './imgs/plants.png';
import Red from './imgs/red.png';
import Strawberry from './imgs/strawberry.png';
import Tree from './imgs/tree.png';
import Watermelon from './imgs/watermelon.png';
import DemoTagObject from './DemoTagObject';
import DemoDocumentObject from './DemoDocumentObject';

import './InteractiveDemo.css';

class InteractiveDemo extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeTagList :[],
      hasClicked:false,
    }
  }
  onFirstClick = ()=>{
    if (this.state.hasClicked ===false){
      this.setState({hasClicked:true});
    }
  }
  addTag = (tagName)=>{
    this.setState({activeTagList:[...this.state.activeTagList, tagName]})
  }
  removeTag = (tagName)=>{
    let removedList = this.state.activeTagList.filter(tag => tagName != tag);
    this.setState({activeTagList:removedList})
  }
  goFeatures = ()=>{
    window.location.href='/features';
  }

  render(){
    let isOpaqueRed, isOpaqueGreen, isOpaqueFruit, isOpaquePlants = false;
    let isOpaqueStrawberry = false;
    let isOpaqueTree = false;
    let isOpaqueGrass  = false;
    let isOpaqueBanana = false;
    let isOpaqueWatermelon = false;
    let isOpaqueFlower = false;

    let demoText = "Tags help you filter documents however you want.";
    let instructions = "Click any of the tags above."
    if (this.state.activeTagList.indexOf('Green')>-1){
      isOpaqueGreen = true;
    }
    if (this.state.activeTagList.indexOf('Red')>-1){
      isOpaqueRed = true;
    }
    if (this.state.activeTagList.indexOf('Plants')>-1){
      isOpaquePlants = true;
    }
    if (this.state.activeTagList.indexOf('Fruit')>-1){
      isOpaqueFruit = true;
    }
    if (this.state.activeTagList.length === 1){
      if (this.state.activeTagList.indexOf('Green')>-1){
        isOpaqueGrass = true;
        isOpaqueTree = true;
        isOpaqueWatermelon = true;
      }
      if (this.state.activeTagList.indexOf('Red')>-1){
        isOpaqueStrawberry = true;
        isOpaqueWatermelon = true;
      }
      if (this.state.activeTagList.indexOf('Plants')>-1){
        isOpaqueGrass = true;
        isOpaqueTree = true;
        isOpaqueFlower = true;
      }
      if (this.state.activeTagList.indexOf('Fruit')>-1){
        isOpaqueStrawberry = true;
        isOpaqueWatermelon = true;
        isOpaqueBanana = true;
      }
      demoText = "You can combine multiple tags to filter down even more."
      instructions = "Try clicking another tag!"

    } else if (this.state.activeTagList.length ===2){
      demoText = "Find what you need faster with nested tags, exclude tags and advanced search."
      if (this.state.activeTagList.indexOf('Green')>-1){
        if (this.state.activeTagList.indexOf('Red') >-1|| this.state.activeTagList.indexOf('Fruit') >-1){

          isOpaqueWatermelon = true;
        }
        if (this.state.activeTagList.indexOf('Plants')>-1){
          isOpaqueGrass = true;
          isOpaqueTree = true;
        }
      }
      if (this.state.activeTagList.indexOf('Red')>-1){
        if (this.state.activeTagList.indexOf('Fruit')>-1){
        isOpaqueStrawberry = true;
        isOpaqueWatermelon = true;
      }
      }
    } else if (this.state.activeTagList.length ===3){
      if (this.state.activeTagList.indexOf('Red')>-1){
        if (this.state.activeTagList.indexOf('Fruit')>-1){
          if (this.state.activeTagList.indexOf('Green')>-1){
            isOpaqueWatermelon = true;
          }
        }
      }
    }
    if (this.state.activeTagList.length >=2){
      demoText = "Find what you need faster with nested tags, exclude tags and advanced search."
      instructions = <div className = "landing-form-button primary"
        onClick = {this.goFeatures}>Learn More</div>
    }
    return(
      <div>
      <div className = "interactiveDemoContainer-container boxShadow">
        <div className = "interactiveDemo-sectionTitle">How Tags Work</div>
        <div className = "interactiveDemoContainer">
        <div className = "interactiveDemo-topTags">
          {this.state.hasClicked?null:
            <div className = "popupClickTag"> Click a tag!</div>
          }
          <DemoTagObject onFirstClick = {this.onFirstClick}
            type = {'Red'} isOpaque = {isOpaqueRed} icon = {Red}
            addTag = {this.addTag} removeTag = {this.removeTag}/>
          <DemoTagObject onFirstClick = {this.onFirstClick}
            type = {'Green'} isOpaque = {isOpaqueGreen}
            icon = {Green} addTag = {this.addTag} removeTag = {this.removeTag}/>
        </div>
        <div className = "interactiveDemo-docs">
          <DemoDocumentObject type = {'Strawberry'} isOpaque = {isOpaqueStrawberry} icon = {Strawberry}/>
          <DemoDocumentObject type = {'Grass'} isOpaque = {isOpaqueGrass} icon = {Grass}/>
          <DemoDocumentObject type = {'Watermelon'} isOpaque = {isOpaqueWatermelon} icon = {Watermelon}/>
          <DemoDocumentObject type = {'Banana'} isOpaque = {isOpaqueBanana} icon = {Banana}/>
          <DemoDocumentObject type = {'Tree'} isOpaque = {isOpaqueTree} icon = {Tree}/>
          <DemoDocumentObject type = {'Flower'} isOpaque = {isOpaqueFlower} icon = {Flower}/>
        </div>
        <div className = "interactiveDemo-bottomTags">
          <DemoTagObject onFirstClick = {this.onFirstClick}
            type = {'Plants'} isOpaque = {isOpaquePlants}
            icon = {Plants} addTag = {this.addTag} removeTag = {this.removeTag}/>
          <DemoTagObject onFirstClick = {this.onFirstClick}
            type = {'Fruit'} isOpaque = {isOpaqueFruit}
            icon = {Fruit} addTag = {this.addTag} removeTag = {this.removeTag}/>
        </div>

        </div>
        <div className = "interactiveDemo-textContainer">
          <div className = "interactiveDemo-text">{demoText}</div>
          <div className = "interactiveDemo-text-instructions">{instructions}</div>
        </div>

      </div>

      <div className = "interactiveDemo-useCases">

        <div className = "interactiveDemo-useCase-container boxShadow">
          <div className = "interactiveDemo-useCase-header">Save Time Searching
          </div>
          <div className = "interactiveDemo-useCase-text">One file might
              correspond to multiple clients, products and locations at the same time.
              Putting these documents into folders forces you to remember exactly where it is.
              Save time by filtering with tags to exactly what you want.
          </div>
        </div>
        <div className = "interactiveDemo-useCase-container boxShadow">
          <div className = "interactiveDemo-useCase-header">No More Version Headaches
          </div>
          <div className = "interactiveDemo-useCase-text">
            Duplicating documents in different folders wastes a lot of time
            searching to update every copy
            that exists when you have a new version, without a guarantee that you've found them all. Ivy
            lets you update a single copy, saving you headache.
          </div>
        </div>
        <div className = "interactiveDemo-useCase-container boxShadow">
          <div className = "interactiveDemo-useCase-header"> Stay Up to Date
          </div>
          <div className = "interactiveDemo-useCase-text">No need to track file locations or expiration
            in Excel. Know that you're using the most recent version with version management,
            expiration notifications,
             document signatures and archiving functions, giving you peace of mind.
          </div>
        </div>
      </div>
      </div>

    )
  }
}

export default InteractiveDemo;
