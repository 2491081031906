import React from 'react';
import { Link } from 'react-router-dom';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import './Auth.css';
import Cookies from '../../helpers/Cookies';
import FullLogo from '../Layout/img/logo-leaf-white.svg';

class Login extends React.Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
			error: '',
			showGoMobile:false
		};
	}
	goMobile = () =>{
		window.location.href= 'https://m.ivydocuments.com/?from_web=true';
	}
	componentDidMount(){
		const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (vw < 700 && !(window.location.search.indexOf('from_mobile')>-1)){
      this.setState({showGoMobile:true})
    }
		let loggedIn = localStorage.getItem('loggedIn');
		if (loggedIn){
			window.location.href = '/home';
		}
	}
	checkOrganizationMembership = (user_info) => {
		localStorage.setItem('loggedIn', 'True');
		if (user_info.organization_name === '') {
			window.location.href= '/organization/create'
		} else {
			window.location.href = '/home';
		}
	}
	onChangeEmail = (e) => {
		this.setState({
			email: e.target.value,
			error: '',
		});
	}

	onChangePassword = (e) => {
		this.setState({
			password: e.target.value,
			error: '',
		});
	}
	handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAttemptLogin();
    }
  }
	onAttemptLogin = () => {
		Request.authRequest(Routes.auth.login, 'POST', JSON.stringify({
			email: this.state.email,
			password: this.state.password,
		}), 'application/json').then((response) => {
			if (response.status === 200) {
				response.json().then((body) => {
					
					this.checkOrganizationMembership(body)
				});
			} else {
				response.json().then((error) => {
					this.setState({ error: error.message });
				});
			}
		}).catch((error) => {

			this.setState({ error: error.message });

		});
	}

	render() {
		return (
			<div>
			<div className='authform-body boxShadow'>
				<img className='login-logo' src={FullLogo} alt='Welcome to Ivy Documents' title = "Welcome to Ivy Documents"/>
				<div className = "helpText">tags based file management</div>
				<label className='input-label' htmlFor='email'> Email </label>

				<input type='text' onChange={this.onChangeEmail} id='email' className='input-text'/>

				<label className='input-label' htmlFor='password'> Password </label>

				<input type='password' onChange={this.onChangePassword} id='password' className='input-text' onKeyPress={this.handleEnter}/>

				{this.state.error}
				<button className='form-button primary' onClick={this.onAttemptLogin}> Log In </button>
				<Link to ='/passwordreset'>
					<div className = 'password-reset'>Forgot Password?</div>
				</Link>
				<div className='register-text'> Don't have an account? </div>
				<Link to='/signup'>
					<button className='form-button secondary'> Join </button>
				</Link>
			</div>
				<div onClick = {this.goMobile} className = "helpText is-clickable">Go to Mobile Version</div>
			</div>

		)
	}
}

export default Login;
