import React from 'react';
import './PriceTable.css';
import PriceRow from './PriceRow';
import UnitSelector from '../../UnitSelector/UnitSelector';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
class PriceTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTable: true,
            currentUnits: 'kg',
            displayedUnits: 'metric_g_mL',
            magnitudePerUnit: this.props.recipe.m_total_weight_per_unit_kg,
            unitsPerCase: this.props.recipe.units_per_case,
        }
    }
    toggleTable = () => {
        this.setState({ showTable: !this.state.showTable });
    }

    changeMagnitudePerUnit = (e) => {
        this.setState({ magnitudePerUnit: e.target.value })
    }
    changeUnits = (units) => {
        this.setState({ currentUnits: units });
    }
    changeDisplayedUnits = (e) => {
        this.setState({ displayedUnits: e.target.value });
    }
    changeUnitsPerCase = (e) => {
        this.setState({ unitsPerCase: e.target.value })
    }
    saveUnitsPerCase = () => {
        let recipe = {
            immutable: this.props.recipe.immutable,
            requires_signatures: this.props.recipe.requires_signatures,
            ignore_density: false
        }
        recipe.units_per_case = this.state.unitsPerCase
        recipe.weight_per_unit = this.state.magnitudePerUnit + ' ' + this.state.currentUnits
        if (recipe.ignore_density) {
            recipe.ignore_density = recipe.ignore_density;
        }
        Request.authRequest(Routes.recipes.edit(this.props.recipe.id),
            'PUT', JSON.stringify(
                recipe
            ), 'application/json'
        ).then((response) => {
            if (response.status === 200) {
                response.json().then(body => {
                    this.props.onGetRecipe();
                    this.props.onGetLogs();
                });
            } else {
                response.json().then((error) => {
                    console.log(error);
                    toast.error(error.message);
                    if (error.errors) {
                        toast.error(error.errors[0])
                    }
                });
            }
        }).catch((error) => {
            toast.error(error.message);
        }
        )
    }
    render() {
        if (!this.props.ingredients) {
            return (


                <div className="helpText">Add ingredients to see the price table!</div>

            )
        }
        let priceRows = this.props.ingredients.map((ingredient, i) => {
            return <PriceRow key={i} ingredient={ingredient} recipe={this.props.recipe}
                displayedUnits={this.state.displayedUnits} ignore_density={this.props.ignore_density} />
        });

        let recipe = this.props.recipe;
        let ignore_density = this.props.ignore_density;
        let total_percent = 0;
        let units = false;
        let totalVolume = 0;
        let weightPerUnit = 0;
        let costPerUnit = 0;
        let costPerCase = 0;
        let weightPerCase = 0;
        let volPerCase = 0;
        if (recipe) {
            total_percent = recipe.total_weight_ratio;
            costPerCase = recipe.m_total_price_per_case.toFixed(3);

            costPerUnit = recipe.m_total_price_per_unit.toFixed(3);
            units = this.state.displayedUnits;
            if (units == 'imperial_lb_gal') {
                totalVolume = recipe.f_total_vol_per_unit_gal;
                weightPerUnit = recipe.f_total_weight_per_unit_lb;
                weightPerCase = recipe.f_total_weight_per_case_lb;
                volPerCase = recipe.f_total_vol_per_case_gal;
            }
            else if (units == 'metric_kg_L') {
                totalVolume = recipe.f_total_vol_per_unit_L;
                weightPerUnit = recipe.f_total_weight_per_unit_kg;
                weightPerCase = recipe.f_total_weight_per_case_kg;
                volPerCase = recipe.f_total_vol_per_case_L;
            }
            else {

                totalVolume = recipe.f_total_vol_per_unit_mL
                weightPerUnit = recipe.f_total_weight_per_unit_g
                weightPerCase = recipe.f_total_weight_per_case_kg;
                volPerCase = recipe.f_total_vol_per_case_L;
            }
        }

        return (
            <div className="priceTable-container">
                <div className="priceTable-header">
                    <div className="showPriceTable" onClick={this.toggleTable}>
                        {this.state.showTable ? '- Hide' : '+ Show'} Full Price Table</div>
                    <div className="priceTable-header-right">
                        <div>
                            Units:
                            <select className="unitSelector" onChange={this.changeDisplayedUnits} value={this.state.displayedUnit}>
                                <option value="metric_g_mL">Metric (g, mL)</option>
                                <option value="metric_kg_L">Metric (kg, L)</option>
                                <option value="imperial_lb_gal">Imperial (lb, kg)</option>
                            </select>
                        </div>
                        <div className="recipes-unitChanger-container">
                            <div onClick={this.saveUnitsPerCase} className="recipes-unitChanger-button">Change</div>
                            <div className="recipes-unitChanger-individual"><input value={this.state.unitsPerCase} onChange={this.changeUnitsPerCase}
                                className="recipes-unitChangerInput" /> units per case</div>

                            <div className="recipes-unitChanger-individual">
                                <input value={this.state.magnitudePerUnit} onChange={this.changeMagnitudePerUnit} type="number"
                                    className="recipes-unitChangerInput" />
                                <UnitSelector changeUnits={this.changeUnits} unit={this.state.currentUnits} ignore_density={ignore_density} />
                                <div>per unit</div></div>


                        </div>
                    </div>
                </div>
                {this.state.showTable ? <table className="priceTable">
                    <thead className="batchSheetHeader">
                        <tr>
                            <th>Ingredient</th>
                            <th>$/kg</th>
                            <th>$/lb</th>
                            <th>Wt %</th>
                            {ignore_density ? null : <th>Vol Per Unit</th>}
                            <th>Wt per Unit</th>
                            {ignore_density ? null : <th>Vol Per Case</th>}
                            <th>Wt per Case</th>
                            <th>Cost per Unit</th>
                            <th>Cost per Case</th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceRows}
                        <tr className="price-totalRow">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{total_percent}%</td>
                            {ignore_density ? null : <td>{totalVolume}</td>}
                            <td>{weightPerUnit}</td>
                            {ignore_density ? null : <td>{volPerCase}</td>}
                            <td>{weightPerCase}</td>
                            <td>${costPerUnit}</td>
                            <td>${costPerCase}</td>
                        </tr>
                    </tbody>

                </table> : null}
            </div>
        )
    }
}

export default PriceTable;