import React from 'react';
import { toast } from 'react-toastify';
import './ToggleDensity.css';
class ToggleDensity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      ignore_density: this.props.ignore_density
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.ignore_density !== this.props.ignore_density) {
      this.setState({ ignore_density: this.props.ignore_density });
    }
  }
  onSaveChanges = () => {
    if (this.state.ignore_density === this.props.ignore_density) {
      return
    }

    else {
      this.props.onClickOut(this.state.ignore_density);
    }
  }
  componentDidMount() {
    if (this.props.ignore_density !== null) {
      this.setState({ ignore_density: this.props.ignore_density })
    }
  }
  handleDblClick = (e) => {
    if (this.node.contains(e.target)) {
      this.setState({
        editing: true
      })
    }
    else if (this.state.editing !== false) {

      this.onSaveChanges();
      this.setState({ editing: false })
    };
  }
  clickSave = () => {

    this.onSaveChanges();
    this.setState({ editing: false })

  }
  componentWillMount() {
    document.addEventListener('dblclick', this.handleDblClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener('dblclick', this.handleDblClick, false);
  }
  handleEnter = (e) => {
    if (e.key === 'Enter') {

      this.onSaveChanges();

      this.setState({ editing: false })
    }

  }
  onToggleCheckBox = (e) => {
    this.setState({
      ignore_density: !this.state.ignore_density
    });
  }
  onKeyDown = (e) => {
    if (e.key === 'Escape') {
      this.setState({ editing: false, ignore_density: this.props.ignore_density })
    }
  }
  onCancel = () => {
    this.setState({ editing: false })
  }

  render() {
    let title = 'Change to Liquid Recipe';
    if (!this.props.ignore_density) {
      title = 'Change to Solid Recipe'
    }
    return (

      <div className='doc-info-field' ref={node => this.node = node}>
        <div className="density-button">{title}</div>

        {this.state.editing ?
          <div>
            <div><div className="toggleDensity-checkboxContainer "><input
              value={this.state.ignore_density}
              type='checkbox'
              checked={this.state.ignore_density}
              onChange={this.onToggleCheckBox}
              onKeyPress={this.handleEnter}
              onKeyDown={this.onKeyDown} />
              Solid Recipe</div>
              <div className="smallHelpText">Check if the recipe uses all solid ingredients and produces a solid product.
                Density calculations will be ignored and only weight will be used. Uncheck for liquids to include density.</div>
              <div className="toggleDensity-buttonContainer">
                <div className="toggleDensity-button primary" onClick={this.clickSave}>Save</div>
                <div className="toggleDensity-button button-secondary" onClick={this.onCancel}>Cancel</div>
              </div>
            </div> </div> :

          <div className={this.props.header ? 'document-info-header' : 'document-info wordbreak'}>{this.state.string}
          </div>
        }
      </div>)

  }
}
export default ToggleDensity;
