import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import TagsSearch from '../../TagsSearch/TagsSearch';
import TagCleaner from '../../../helpers/TagCleaner';
import TagLabel from '../../Documents/TagLabel';
import './RecipeSettings.css';
class DefaultRecipeTags extends React.Component {
    constructor() {
        super();
        this.state = {
            tagNames: [],
            editing: false,
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.recipeSettings !== this.props.recipeSettings) {
            this.setState({ tagNames: this.props.recipeSettings.recipe_default_tags });
        }
    }
    onEnterTagName = (tags) => {
        this.setState({
            tagNames: tags,
        });
    }
    onEscape = () => {
        return
    }
    onCancel = () => {
        this.setState({ editing: false })
    }
    onEdit = () => {
        this.setState({ editing: true })
    }
    onSave = () => {
        let recipeSettings = this.props.recipeSettings;
        let cleaned_tag_names = TagCleaner.parseTagArray(this.state.tagNames);
        if (cleaned_tag_names.length > 0) {

            recipeSettings.recipe_default_tags = cleaned_tag_names
        } else {
            recipeSettings.recipe_default_tags = null;
        }

        Request.authRequest(Routes.organization.recipeSettings,
            'PUT', JSON.stringify(recipeSettings),
            'application/json').then(
                (response) => {
                    if (response.ok) {
                        response.json().then((body) => {
                            this.props.onGetRecipeSettings();
                            this.setState({ editing: false })
                        }
                        )
                    } else {
                        response.json().then((error) => {
                            toast.error(error.message);
                            if (error.errors) {
                                toast.error(error.errors[0])
                            }
                        });
                    }
                }).catch((error) => {
                    toast.error(error.message);
                }
                )


    }
    render() {
        let tagsList;
        if (this.state.tagNames && this.state.tagNames.length > 0) {
            tagsList = this.state.tagNames.map((tag, i) => {
                return (<div className="tagLabel-container">{tag}</div>)
            })
        }
        return (
            <div className="recipeSetting-container">
                <div className="recipeSetting-title">Default Tags for Recipe Documents</div>
                {this.state.editing ?
                    <div>
                        <div>Enter tags to be added by default to tags uploaded on the recipes side of Ivy Documents.
                            These can be adjusted on the upload screen.</div>

                        <div className="recipeSettings-inputContainer"><TagsSearch onSendTags={this.onEnterTagName}
                            onEsc={this.onEscape}
                            tags={this.state.tagNames} />
                        </div>
                        <div className="smallButtonContainer">
                            <div onClick={this.onSave} className="smallButton primary"> Save</div>
                            <div onClick={this.onCancel} className="smallButton smallButton-secondary">Cancel</div>
                        </div>
                    </div> :
                    <div>
                        <div>Enter tags to be added by default to tags uploaded on the recipes side of Ivy Documents.
                            These can be adjusted on the upload screen.</div>
                        {tagsList}
                        <div onClick={this.onEdit} className="smallButton primary"> Edit</div>
                    </div>
                }
            </div>
        )
    }


}

export default DefaultRecipeTags;
