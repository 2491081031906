import React from 'react';
import RemindDocumentAccept from '../Documents/RemindDocumentAccept';
import './PendingExternalDocs.css';
class PendingExternalDocRow extends React.Component{
  goDoc = ()=>{
    window.location.href=`/documents/${this.props.doc.document_id}`;
  }
  render(){
    let doc = this.props.doc;
    let sharedWith = doc.shared_with.map((receiver,i)=>{
      let reminderCounter;
      if (receiver.reminder_counter){
        reminderCounter = <div className = "reminderCounter-text">Reminded {receiver.reminder_counter} times</div>
      }
      let reminderButton;
      if (receiver.status==='Pending'){
        if (!receiver.name.indexOf('@') >-1 && !receiver.name.indexOf('.') >-1){
        reminderButton = <RemindDocumentAccept receiver = {receiver}
          doc = {doc}/>
      }
      }
      let date_sent=new Date(receiver.date);
      date_sent = date_sent.toDateString();
      return <div className = "pendingExternalRow-container">
        <div>Sent to {receiver.name} on {date_sent}</div>
        {reminderCounter}
        <div> {reminderButton}</div>
        </div>
    })

    return(
      <div className = "externalDoc-container">
        <div onClick = {this.goDoc} className = "externalDoc-name">{doc.name}</div>
        <div className = "external-sharedWith">
          {sharedWith}
        </div>
      </div>
    )
  }
}

export default PendingExternalDocRow;
