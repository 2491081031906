import React from 'react';
import './PageNotFound.css';
   
const PageNotFound = (props) => {

	//TODO: CHANGE THE PICTURE
	return (
		<div className='page404'>
			<h1> Sorry, the page you requested could not be found. </h1>
			<img src='https://cdn.drawception.com/images/panels/2017/5-5/tLOm6ZB2KO-2.png' alt=''/>
		</div>
	);
}

export default PageNotFound;
