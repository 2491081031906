import React from 'react';
import './PageButtons.css';
class PageButtons extends React.Component{
  render(){
    let page = parseInt(this.props.page,10);
    let total_pages = Math.ceil(this.props.numResults/this.props.perPage);
    let lowerPage = Math.max(1, page-3);
    let upperPage = Math.min(total_pages,page+3);
    let pageNumbers = [];
    for (let i = 0; i < upperPage-lowerPage+1; i++){
      pageNumbers.push(lowerPage + i)
    }
    const pages = pageNumbers.map((pageNumber)=>{
      return(
      <button
        className = 'pageButton boxShadow noselect'
        id = {pageNumber === page? 'selectedPage':null}
        key = {pageNumber}
        onClick = {()=>this.props.onChangePage(pageNumber)}>
          {pageNumber}
      </button>)
    });
    return(
      <div>
        <div className='pageButtons'>
            {pages}
          </div>
        <div className = "helpText"> Showing page {page} out of {total_pages}.
        </div>
      </div>

    )
  }
}
export default PageButtons;
