import React from 'react'
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import Autosuggest from 'react-autosuggest';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import ContactsSuggestionRow from './ContactsSuggestionRow';

class ContactsSearch extends React.Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.autocompleteRenderInput = this.autocompleteRenderInput.bind(this);
    this.timerID = null;
    this.state = {
      emails: [],
      suggestions: []
    }
  }

  handleChange(emails) {
    this.setState({ emails })
    this.props.onSendEmails(emails)
  }

  autocompleteRenderInput = ({ addTag, ...props }) => {
    let suggestions = [];

    const handleOnChange = (e, { newValue, method }) => {
      if (method === 'enter') {
        e.preventDefault()
      } else {
        props.onChange(e)
      }
    }
    const fetchRequest = (inputValue) => {

      if (this.timerID) {
        window.clearTimeout(this.timerID);
      }
      this.timerID = window.setTimeout(() =>
        Request.authRequest(Routes.contacts.suggest(inputValue.value),
          'GET').then((response) => {
            response.json().then(body => {

              this.setState({suggestions:body.results})
              return body.results
            })
          }
        ), 400)
    }

    const getValue = (suggestion) => {
      if (suggestion) {
        return suggestion.email
      }
    }
    const inputValue = (props.value && props.value.trim().toLowerCase()) || ''
    const inputLength = inputValue.length;

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={this.state.suggestions}
        shouldRenderSuggestions={(value) => value && value.trim().length > 0}
        getSuggestionValue={(suggestion) => getValue(suggestion)}
        renderSuggestion={(suggestion) => <ContactsSuggestionRow suggestion={suggestion} />}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.email)
        }}
        onSuggestionsClearRequested={() => suggestions = []}
        onSuggestionsFetchRequested={fetchRequest}
        highlightFirstSuggestion={true}
      />
    )
  }

  render() {
    var defaultprops = {
      className: 'react-tagsinput-input',
      placeholder: 'Enter emails or search for contacts by name, email or organization'
    }

    return (
      <TagsInput
        value={this.state.emails}
        addKeys={[9, 13, 32, 186, 188]} // tab, enter, space, semicolon, comma
        inputProps={defaultprops}
        onlyUnique
        addOnPaste
        pasteSplit={data => {
          return data.replace(/[\r\n,;]/g, ' ').split(' ').map(d => d.trim())
        }}
        onChange={this.handleChange}
        renderInput={this.autocompleteRenderInput}
        addOnBlur = {true}
      />
    )
  }
}

export default ContactsSearch;
