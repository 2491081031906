import React from 'react';
import TopTag from '../../Tags/TopTag';
import './TagAccess.css';
import {
	DragSource,
} from 'react-dnd';

const userTagAccessSource = {
  beginDrag(props,monitor,component){
    return {user:props.user}
  }
}
function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),

  }
}
class IndividualUserTagsAccess extends React.Component{
  onClick = () =>{
    this.props.sendSelectedUser(this.props.user)
  }
  onAddActiveTag = (tag)=>{
    if (Array.isArray(this.props.activeTags)){
      this.props.onChangeTags([...this.props.activeTags,tag]);
    }
    else {
      this.props.onChangeTags([this.props.activeTags,tag])
    }
  }
    render(){
      let user = this.props.user;
      let tags = <div className = "UserTagsAccess-helpText">Cannot access any tags.</div>
      if (user.accessible_tags.length>0){
        tags = user.accessible_tags.map((tag,i)=>{
        return <TopTag tag={tag} onAddActiveTag={this.onAddActiveTag}
        onLastSelected={this.props.onLastSelected}
        key = {i}/>
      })
      }
			if (user.role == "Super Admin" || user.full_tag_access){
				tags = <div className = "UserTagsAccess-helpText">Can access all tags.</div>
			}
      const { connectDragSource} = this.props;
			let excludedTags;
			let excludedTagsText;
			if (user.inaccessible_tags.length>0){
				excludedTagsText = <div className = "UserTagsAccess-helpText">Except for excluded tags: </div>;
        excludedTags = user.inaccessible_tags.map((tag,i)=>{
        return <TopTag tag={tag} onAddActiveTag={this.onAddActiveTag}
        onLastSelected={this.props.onLastSelected}
        key = {i}/>
      })
      }
      return connectDragSource(

        <div className = "IndividualUserTagsAccess-container" onClick = {this.onClick}>
          <div className = "IndividualUserTagsAccess-information">
            <div> {user.first_name} {user.last_name} </div>
            <div>{user.email}</div>

          </div>
          {tags}

					{excludedTagsText}
					{excludedTags}
        </div>


      )
    }
  }

export default DragSource('UserTagAccess', userTagAccessSource, collect)(IndividualUserTagsAccess)
