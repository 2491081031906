import React from 'react';
import {Tag} from '../Icons/IconLibrary';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import TagCleaner from '../../helpers/TagCleaner';
import TagsSearch from '../TagsSearch/TagsSearch';
import LoadingButton from '../Buttons/LoadingButton';

class IngredientTagButton extends React.Component{
  constructor(props){
    super(props);
    this.onEnterTagName= this.onEnterTagName.bind(this);
    this.state={
      tagNames :'',
      showModal:false,
      loading:false,
    }
  }
  onShowModal=()=>{
    if (this.props.docs.length === 0){
      toast.error('Please select one or more documents.')
      return;
    }
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false
  })}

  onEnterTagName = (tags) => {
		this.setState({
			tagNames: tags,
		});
	}
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAddTag();
    }
  }
  onEscape = ()=>{
    return
  }
  onAddTag=()=>{
    if (!this.state.tagNames){
      toast.error('Please enter tags. Empty tag names are not allowed.')
      return
    }
    let trimmedTags = TagCleaner.parseTagArray(this.state.tagNames);
    if (trimmedTags.length === 0){
      toast.error('Please enter tags. Empty tag names are not allowed.')
      return
    }
    if (this.state.tagNames.length>500){
      toast.error('This field is limited to 500 characters. Please trim your entry.')
      return
    }
    this.setState({loading:true});
    for (let i =0; i < this.props.docs.length;i++){
      let doc = this.props.docs[i];
      let oldTagNames = doc.tags.map((tag)=>{return tag.name});
      if (this.arraysEqual(oldTagNames,trimmedTags)){
        toast.info(`The ${doc.name} document already has these tags`)
        continue;
      }
      let id = doc.id;
      if (doc.document){
        id = doc.document.id;
      }
      if (doc.recipe_id){
        id = doc.recipe_id
      }
      if (doc.batch_sheet_id){
        id = doc.batch_sheet_id
      }
      Request.authRequest(Routes.ingredients.tags(id),
                          'PUT',JSON.stringify({
                    				tag_names: [...oldTagNames, ...trimmedTags],
                    			}), 'application/json'
                        ).then((response) => {
                          if (response.status===200){
                          response.json().then(body => {
                              this.props.onGetDocuments(this.props.tagName);
                              this.props.onGetTags();
                              toast.success('Successfully applied tags!')
                              this.setState({showModal:false,tagNames:'', loading:false,});
                            });
                          } else {
                            response.json().then((error) => {
                              toast.error(error.message);
                              this.setState({loading:false});
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                      this.setState({loading:false});
                    }
                  )
    }
  }
  arraysEqual(oldTags, newTags) {
    if (oldTags.length < newTags.length)
      return false;
    if (oldTags.length > newTags.length){
      for (let i = 0; i < newTags.length; i ++){
        let index = oldTags.indexOf(newTags[i])
        if (index === -1){
          return false
        }
      }
    } else {
      //equals case
          var oldTagscopy = oldTags.concat().sort();
          var newTagscopy = newTags.concat().sort();

          for (var i = 0; i < oldTagscopy.length; i++) {

              if (oldTagscopy[i] !== newTagscopy[i])
                  return false;
          }
        }
    return true;

  }
  render(){

    return (
      <div id = "buttonTag-container">
        <button className='headerButton noselect' id = "buttonTag" onClick = {this.onShowModal}>
            <img className='buttonIcon'  src={Tag} alt='Tag currently selected documents' title = 'Tag currently selected documents'/>
        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Add Tag' >

          {this.props.docs.length>0?
          <div className = "button-modalText">
            You currently have {this.props.docs.length} documents selected.<br/>
            Enter the name of the tags you'd like to apply, separated by commas.
            <TagsSearch onSendTags = {this.onEnterTagName}
            onEsc = {this.onEscape}/>
          
          <button className= 'modal-button primary'>
                  <LoadingButton onClick = {this.onAddTag} 
        text = 'Tag Documents' isLoading = {this.state.loading}/>
        </button>
          </div>
          :
          null}
        </Modal>
      </div>
    )
  }
}
export default IngredientTagButton;
