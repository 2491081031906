import React from 'react';
import { Link } from 'react-router-dom';
class PatchNotes extends React.Component {
	render() {
		return (
			<div>
				<h4>v1.1.4</h4>
				Added a Bill of Materials as a separate page.
				<h4>v1.1.3</h4>
				<ul>
					<li>Adjusted formatting on batch sheet previews.</li>
					<li>Allows prepopulization of recipe name in batch sheets as well as adds an id field.</li>
					<li>Adds decimals to weight percents.</li>

				</ul>
				<h4>v1.1.2</h4>
				<ul>
					<li>Batch sheets have been reworked. All existing batch sheets have been removed.</li>
					<li>You no longer make a copy of a batch sheet when sending it to multiple people.</li>
					<li>Only batch sheets that you receive will appear in the main navigation. Batch sheets that you sent are now tied to the recipe.</li>
					<li>When you send a batch sheet, you make a link for your own organization and the receiving organization.</li>
					<li>If you archive a recipe, it will reflect on the batch sheet that the recipe has been archived and the batch sheet may no longer be up to date.</li>
				</ul>
				<h4>v1.1.1</h4>
				<ul>
					<li>Made some ui improvements to various menus. </li>
					<li>Fixed alignment of columns on batch sheets for ingredient properties. </li>
					<li>Batch Sheet info is downloadable into a csv for superadmins only. Find the button in the preview on a recipe.</li>
					<li>Add an optional requires signature field to recipes. Recipes that require signatures cannot be locked or sent.</li>
				</ul>
				<h4>v1.1.0</h4>
				<ul>
					<li>All solids always have a density of 0. Changing the density of a solid to not 0 will change it to a liquid.</li>
					<li>Default price of newly created ingredients is now 0 instead of 1. </li>
					<li>Recipes cannot be locked if they do not add up to 100%</li>
					<li>Fixed a naming issue when creating a duplicate recipe</li>
					<li>Density and state are added to the main ingredient navigation for convenience.</li>
					<li>Added a state indicator on ingredients. Only liquids and solids are supported.</li>
					<li>Logs on the recipes application are filtered to recipes, ingredients and tags only.</li>
				</ul>
				<h4>v1.0.9</h4>
				<ul>
					<li>Ingredients used in recipes are now ordered by weight percent. </li>
					<li>Fixed an issue where pending batch sheets would show up on the main search.</li>
					<li>You can now specify ingredient attributes to be included in batch sheets. This is a organizational level setting
						on the settings page. Names will be matched to ingredient attributes and included as additional columns in the sent batch sheets.</li>
				</ul>
				<h4>v1.0.8</h4>
				<ul>
					<li>Fixed an issue when removing the last related tag from a recipe or ingredient.</li>
					<li>Added a toggle filter top level (parent) tags only.</li>
					<li>Fixed an issue using the filter toggle on ingredient tags</li>
					<li>Fixed an issue returning to previous page when accessing documents on recipe side.</li>
					<li>Added filtering on documents pulled in through related tags on recipes and ingredients. </li>
				</ul>
				<h4>v1.0.7</h4>
				<ul>
					<li>Fixed an issue with the density of liquid and solid recipes.</li>
					<li>Clarified the help text on solid and liquid recipes. </li>
					<li>Cleaned up the storage of batch sheets. You now will only see received batch sheets on the main
						navigation and sent batch sheets can be viewed on the recipe they correspond to.</li>
					<li>Added a default tag setting for documents added on the recipes and ingredients pages.</li>
					<li>Fixed an issue where batch sheets sent to yourself do not accept properly.</li>
				</ul>
				<h4>v1.0.6</h4>
				<ul>
					<li>Fixed an issue when batch sheets are sent to yourself. </li>
					<li>Clarified help text on naming batch sheets. </li>
					<li>Added upload to recipes and ingredients side.</li>
				</ul>
				<h4>v1.0.5</h4>
				<ul>
					<li>Fixed an issue where solids were not contributing to cost correctly. </li>
					<li>Fixed an issue when deleting tags used as related tags on recipes and ingredients. </li>
					<li>You can now press enter when changing weight percents to save.</li>
					<li>Made order of units consistent (kg, lb, L, gal)</li>
					<li>Added an option to ignore/hide density. Batch sheets sent with recipes that hide density will also have their volume and density values hidden.</li>
					<li>You can now override and change the price of ingredients used in locked recipes. </li>

				</ul>

				<h4>v1.0.4</h4>
				Added handling for solids (set density = 0). Solids are currently calculated as not affecting the total volume or density of the resultant recipe mixture.
				Fixed a few missing volume calculations.

				<h4>v1.0.3</h4>
				<ul>
					<li>Fixes an issue where ingredients created from empty recipes couldn't be added to other recipes.</li>
					<li>Added tolerance to total weight percent (0.005% tolerance) when creating and sending batch sheets</li>
					<li>Fixed an issue calculating batch sheet previews on a volume basis</li>
					<li>Target release criteria is now an optional parameter. Also, made it easier to edit.</li>
					<li>Volume per Unit default has been changed to mL on recipes. Added a toggle to show volume per unit per L.</li>
				</ul>
				<h4>v1.0.2</h4>
				<ul>
					<li>Fixed an issue where creating new contacts would error if the contact already existed.</li>
					<li>Fixed an issue where recipients could not change batch size</li>
					<li>Added total density on recipes.</li>
					<li>Added an icon to the bill of materials</li>
					<li>You can now preview batch sheets of recipes prior to sending them.</li>
					<li>You can now create an ingredient as a product of a recipe. </li>
					<li>Fixed some errors when trying to delete ingredients and recipes that are in use or have versions.</li>
				</ul>
				<h4>v1.0.1</h4>
				<ul><li>Vol per case has been added to the price table and BOM.</li>
					<li>Bill of materials now shows only for the recipe creator and is split from the batch sheet. </li>
					<li>Fixed an issue where ingredient. template attributes and ingredient attributes wouldn't save.</li>
					<li>Fixed an issue where the wrong recipe name would display after editing the name. </li>
					<li>Density can no longer be changed on ingredients that are already used in locked recipes. Version the ingredient to change density.</li></ul>
				<h4>v1.0.0</h4>
				Initial release of Ivy Recipes.
				<br />
			</div>
		)
	}
}
export default PatchNotes;
