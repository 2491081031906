import React from 'react';
import './FeaturesPage.css';
import DocumentFeatures from './imgs/FeaturesPage/DocumentFeatures.png';
import PermissionsandControls from './imgs/FeaturesPage/PermissionsandControls.jpg';
import FeaturesIcons from './imgs/FeaturesPage/FeaturesIcons';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import PublicFAQ from './PublicFaq';
import Banner from './imgs/FeaturesPage/banner-01.png';
import FeaturesList from './FeaturesList';
class FeaturesPage extends React.Component{
  constructor(props){
    super(props);
    this.state={
      firstEmail:'',
      secondEmail:'',
    }
  }
  onChangeFirstEmail = (e) => {
		this.setState({
			firstEmail: e.target.value,
			error: '',
		});
	}
  onAttemptFirstEmailSignup = () => {
			Request.authRequest(Routes.auth.signup, 'POST', JSON.stringify({
				email: this.state.firstEmail,
			}), 'application/json').then((response) => {
				if (response.status === 200) {
					response.json().then((body) => {
						window.location.href = '/resend-confirm';
					});
				} else if
					(response.status === 409){
						this.setState({ error: 'User with this email already exists.' });
				}
				else {
					response.json().then((error) => {
						this.setState({ error: error.message });
					});
				}
			}).catch((error) => {
				this.setState({ error: error.message });
			});
		}
	handleFirstEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAttemptFirstEmailSignup();
    }
  }

  render(){
    return(
      <div className = "pageContainer">
        <div className = "features-pageContents boxShadow">
        <div className = "features-sectionTitle">Save the Headache
        </div>
        <div> Save time, headache and frustration with better file management tools.</div>
          <a href = "https://meetings.hubspot.com/nathan220"
            className = "book-demo-link">Book a Demo</a>
          <div className = "features-documentFeaturesGraphic">

            <img src = {DocumentFeatures}  alt = "Ivy lets you keep all the information about your files you need in one place."
              title = "Ivy lets you keep all the information you need about your files in one place."
            className = "features-documentFeatures"/>
          <div className = "features-largeGraphic-textContainer">
            <div className = "features-largeGraphic-header">All the Information You Need</div>
            <div className = "features-largeGraphic-text">No need to remember crucial information about your documents.
              Write down notes about the document, approve documents without
              messy email attachments chains, and automatically track who you've
               sent documents to or received documents from. Keep a clean system
                with archived files, and logged changes.</div>
              </div>
        </div>
      </div>
    <div className = "features-pageContents boxShadow">
        <div className="features-tagsSection-header"> Stay Organized with Tags</div>
        <div className = "features-sectionContainer">

            <div className = "features-section">

              <img src = {FeaturesIcons.YourWay}  alt = "Find information your way."
                className = "features-Icon"/>
              <div className = "features-sectionTextItem">
                <div className = "features-headerText">Tags Based Organization
                </div>
                <div className = "features-paragraphText">Tags let you create multiple paths
                  to any file. Group your files in based off of
                  context, rather than being forced to pick with folders.
                </div>
              </div>
            </div>
            <div className = "features-section">
              <img src = {FeaturesIcons.AnyAngle} alt = "Information from any angle"
                className = "features-Icon"/>
              <div className = "features-sectionTextItem">
                <div className = "features-headerText">Multidimensional Filtering
                </div>
                <div className = "features-paragraphText">
                  Apply multiple tags to filter documents from
                  different angles.  No more digging through folders.
                </div>
              </div>
          </div>
            <div className = "features-section">
              <img src = {FeaturesIcons.Duplicates} alt = "No duplicate file management"
                className = "features-Icon"/>
              <div className = "features-sectionTextItem">
                <div className = "features-headerText">No Duplicates
                </div>
                <div className = "features-paragraphText">
                  Maintain a single, up to date version across
                  your organiation, no matter how you access it.
                </div>
              </div>
          </div>

          <div className = "features-section">
            <img src = {FeaturesIcons.Sharing} alt = "Designed for sharing and sending files"
              className = "features-Icon"/>
            <div className = "features-sectionTextItem">
              <div className = "features-headerText">Designed for Sharing
              </div>
              <div className = "features-paragraphText">
                No more individually attaching documents.
                Quickly share with multiple other people.
              </div>
            </div>
          </div>
          <div className = "features-section">
            <img src = {FeaturesIcons.Supplier} alt = "Supplier management dashboards"
              className = "features-Icon"/>
            <div className = "features-sectionTextItem">
              <div className = "features-headerText">Supplier Management
              </div>
              <div className = "features-paragraphText">
              Templatize your document requests,
              review received documents, and track completion.
                </div>
            </div>
          </div>
          <div className = "features-section">
            <img src = {FeaturesIcons.Expiration} alt = "Automated expiration tracking and notifications"
              className = "features-Icon"/>
            <div className = "features-sectionTextItem">
              <div className = "features-headerText"> Expiration Tracking
              </div>
              <div className = "features-paragraphText">
                Set email alerts on document
                requests, expirations and rejected documents.
                </div>
            </div>
          </div>
          <div className = "features-section">
            <img src = {FeaturesIcons.Search} alt = "Search to find what you need."
              className = "features-Icon"/>
            <div className = "features-sectionTextItem">
              <div className = "features-headerText"> Search
              </div>
              <div className = "features-paragraphText">
                Powerful search to find
                what you need, when you need it.</div>
            </div>
          </div>
          <div className = "features-section">
            <img src = {FeaturesIcons.Filing}  alt = "Automatic filing - stop pushing paper."
              className = "features-Icon"/>
            <div className = "features-sectionTextItem">
              <div className = "features-headerText"> Automatic Filing
              </div>
              <div className = "features-paragraphText">
                Stop pushing paper. Set your preferences
                once and automatically tag received documents. </div>
            </div>
          </div>
        </div>
      </div>
      <div className = "features-pageContents boxShadow">

        <div className = "features-documentFeaturesGraphic">
          <div className = "features-largeGraphic-textContainer">
            <div className = "features-largeGraphic-header">Permissions and Controls</div>
            <div className = "features-largeGraphic-text">No need to duplicate documents to grant access to someone.
              Use tags to have fine-grained control over document permissions for your team or company,
              including exclude tags, and cascading tag permissions.
              Manage your users with edit, read only permissions, or restricted to pdf.
            </div>
          </div>
          <img src = {PermissionsandControls}  alt = "Find information your way."
            className = "features-documentFeatures"/>

        </div>
        <div className = "features-wantMore">Want more? Have something in mind?
          We'd love to hear. Talk to us at info@ivydocuments.com</div>
        <div className = "features-sectionTitle">Get Started Today
          </div>
        <div className = "features-signup boxShadow">
          <input type='text' onChange={this.onChangeFirstEmail}
            id='email' className='landing-input-text'
            placeholder = {'Enter your email'}
            onEnter = {this.handleFirstEnter}/>
    			{this.state.error}
    			<button className='landing-form-button primary'
            onClick={this.onAttemptFirstEmailSignup}> Get Started</button>

        </div>

        </div>

        <PublicFAQ/>
          <div className = "features-banner-container boxShadow">
            <img className = "features-banner-image"
              src = {Banner} alt = 'Get started with better file management using Ivys tags based file management'
              title = 'Get started with better file management using Ivys tags based file management'/>
          </div>
      </div>
    )
  }
}

export default FeaturesPage;
