import React from 'react';
import {Document,Tag,User,Organization,Template,RequestReceived, Ingredient, Recipe} from '../Icons/IconLibrary';
import {toast} from 'react-toastify';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
class LogsRow extends React.Component{
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state={
      editing:false,
      string:this.props.log.notes ,
    }
  }
  componentDidUpdate(prevProps){
    if (prevProps.content!== this.props.content){
      this.setState({string:this.props.content});
    }
  }
  componentDidMount(){
    if (this.props.content){
    this.setState({string:this.props.content})}
  }
  onSaveChanges(){
    if (this.state.string === this.props.log.notes){
      return
    }
    Request.authRequest(Routes.logs.log(this.props.log.id),'PUT',
    JSON.stringify({notes:this.state.string}),
    'application/json').then((response) => {
      if (response.status===200){
      response.json().then(body => {
                toast.success('Added note to a log');
        });
      } else {
        response.json().then((error) => {
          toast.error(error.message);
        });
      }
      }).catch((error) => {
      toast.error(error.message);
      }
      )

  }

  handleClick = (e) =>{
    if (this.state.editing!==true){
      this.setState({
        editing:true
      })
    }
    else if(this.state.editing!==false){
      if (this.state.string.length>2000){
        toast.error('This field is limited to 2000 characters. Press escape to revert changes.');
        return
      }
      this.onSaveChanges();
      this.setState({editing:false})
    };
  }
  componentWillMount(){
    document.addEventListener('dblclick',this.handleDblClick,false);
  }
  componentWillUnmount(){
    document.removeEventListener('dblclick',this.handleDblClick,false);
  }
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      if (this.state.string.length>2000){
        toast.error('This field is limited to 2000 characters. Press escape to revert changes.');
        return
      }
      this.onSaveChanges();
      this.setState({editing:false})
    }
  }
  onChangeLogInfo = (e) => {
		this.setState({
			string: e.target.value,
		});
	}
  onKeyDown=(e)=>{
    if (e.key === 'Escape'){
      this.setState({editing:false,string:this.props.content})
    }
  }
  getLogIcon(category) {
		let logIcon;
    switch(category) {
      case 'DOCUMENT':
        logIcon = Document;
        break;
      case 'TAG':
        logIcon = Tag;
        break;
      case 'USER':
        logIcon = User;
        break;
      case 'ORGANIZATION':
        logIcon = Organization
        break;
      case 'TEMPLATE':
        logIcon = Template
        break;
      case 'REQUEST':
        logIcon = RequestReceived;
        break;
      case 'INGREDIENT':
          logIcon = Ingredient
          break;
      case 'RECIPE':
          logIcon = Recipe;
          break;
      default:
        logIcon = null;
        break;
    }
    return logIcon;
	}
  render(){
    let log = this.props.log;
    const LogIcon = this.getLogIcon(log.category);
    let date = new Date(log.date_created);
    let dateString;
    dateString = date.toLocaleString();
    let string;

       string = this.state.string;
    return (
      <div className = "log-row-container">
      <div className = "log-row">
          <img className='log-icon' src={LogIcon} alt='' />

          <span className='log-text'>{log.description}</span>

          <span className='log-timestamp' id="logDate">{dateString}</span>

          <span className='log-user' id="logUser">{log.username}</span>

            {this.state.editing?<div className = "log-text-noteButton" onClick = {this.handleClick}>Save</div>
          :<div className='log-text-noteButton' onClick = {this.handleClick} >Edit Notes</div>}




      </div>
      {this.state.editing?

      <textarea
        value={this.state.string}
        type='text'
        className='log-info-field'
        onChange={this.onChangeLogInfo}
        onKeyPress={this.handleEnter}
        onKeyDown={this.onKeyDown}/>:
        <div className = 'log-notes wordbreak'>{string}
      </div>

      }
  </div>
    );
  }
}
export default LogsRow;
