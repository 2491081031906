import React from 'react';
import './RecentlySent.css';
class AcceptedExternalDocRow extends React.Component{
  goDoc = ()=>{
    window.location.href=`/documents/${this.props.doc.document_id}`;
  }
  render(){
    let doc = this.props.doc;
    let sharedWith = doc.shared_with.map((receiver,i)=>{
      let receiverName = receiver.receiving_user + ' at '
      let date_sent=new Date(receiver.date);
      date_sent = date_sent.toDateString();
      let date_approved = new Date(receiver.date_approved).toDateString();
      return <div className = "acceptedExternalRow-container">
              <div>Sent to {receiverName} {receiver.name} on {date_sent}</div>
              <div >{receiver.status} on {date_approved}</div>
        </div>
    })

    return(
      <div className = "externalDoc-container">
        <div onClick = {this.goDoc} className = "externalDoc-name">{doc.name}</div>
        <div className = "external-sharedWith">
          {sharedWith}
        </div>
      </div>
    )
  }
}

export default AcceptedExternalDocRow;
