import React from 'react';
import { toast } from 'react-toastify';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import './ReleaseCriteria.css';
class ReleaseCriteriaRow extends React.Component{
    constructor(props){
        super(props);
        let target=this.props.releaseCriteria.target;
        if (!this.props.releaseCriteria.target){
          target = null
        }
        this.state={
            deleting:false,
            editing:false,
            name:this.props.releaseCriteria.name,
            min:this.props.releaseCriteria.min_tolerance,
            max:this.props.releaseCriteria.max_tolerance,
            target:target,
        }
    }
    onToggleCheckbox= () =>{
        this.setState({deleting:!this.state.deleting}, this.sendDelete())
        
    }
    componentDidUpdate=(prevProps)=>{
      if (this.props.releaseCriteria && this.props.releaseCriteria!=prevProps.releaseCriteria){
        let target=this.props.releaseCriteria.target;
        if (!this.props.releaseCriteria.target){
          target = null
        }
        this.setState({
          deleting:false,
          editing:false,
          name:this.props.releaseCriteria.name,
          min:this.props.releaseCriteria.min_tolerance,
          max:this.props.releaseCriteria.max_tolerance,
          target:target,
        })
      }
    }
    sendDelete = ()=>{
        if (this.state.deleting!=true){
            this.props.addCriteriaToDelete(this.props.releaseCriteria);
        }
        else{
            this.props.removeCriteriaToDelete(this.props.releaseCriteria);
        }
    }
        componentWillMount(){
        document.addEventListener('dblclick',this.handleDblClick,false);
      }
      componentWillUnmount(){
        document.removeEventListener('dblclick',this.handleDblClick,false);
      }
      handleEnter = (e)=>{
        if (e.key ==='Enter'){
          this.onSaveChanges();
    
          this.setState({editing:false})
        }
    
      }
      onChangePriceInfo = (e) => {
            this.setState({
                price: e.target.value,
            });
        }
      onKeyDown=(e)=>{
        if (e.key === 'Escape'){
          this.setState({editing:false})
        }
      }
      handleDblClick = (e) =>{
        if (this.node.contains(e.target)){
          this.setState({
            editing:true
          })
        }
        else if(this.state.editing!==false){
          this.onSaveChanges();
          this.setState({editing:false})
        };
      }
    onChangeName = (e)=>{
        this.setState({name:e.target.value, error:''})
    }
    onChangeMin = (e)=>{
        this.setState({min:e.target.value, error:''})
    }
    onChangeMax = (e)=>{
        this.setState({max:e.target.value, error:''})
    }
    onChangeTarget = (e)=>{
        this.setState({target:e.target.value, error:''})
    }
    onSaveChanges = () =>{
      let target= this.state.target;
      if (!target){
          target = null;
      }
        Request.authRequest(Routes.recipes.releaseCriteria(this.props.releaseCriteria.id),
                        'PUT',JSON.stringify({
                          name: this.state.name.trim(),
                          min_tolerance: this.state.min,
                          max_tolerance: this.state.max,
                          target:  target
                        }), 'application/json'
                      ).then((response) => {
                        if (response.status===200){
                        response.json().then(body => {
                            this.props.onGetRecipe();
							this.props.onGetLogs();
                            toast.success('Successfully added release criteria!')
                          });
                        } else {
                          response.json().then((error) => {
                            console.log(error);
                            toast.error(error.message);
                            if (error.errors){
                              toast.error(error.errors[0])
                            }
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
    }
    render(){
        let releaseCriteria = this.props.releaseCriteria;
        if (this.state.editing && !this.props.batchSheet){
            return(
                <tr className = "batchSheetRow" ref = {node => this.node = node}>
                {this.props.deleting?
                <td>
                    <input type = "checkbox" checked = {this.state.deleting} value = {this.state.deleting} onChange = {this.onToggleCheckbox}/>
                </td>:null}
                <td className = "release-criteria-cell"><input
                    value={this.state.name}
                    type='string'
                    className=' centered-item release-criteria-edit'
                    onChange={this.onChangeName}
                    onKeyPress={this.handleEnter}
                    onKeyDown = {this.onKeyDown}/></td>
                <td className = "release-criteria-cell"><input
                    value={this.state.min}
                    type='float'
                    className='centered-item release-criteria-edit'
                    onChange={this.onChangeMin}
                    onKeyPress={this.handleEnter}
                    onKeyDown = {this.onKeyDown}/></td>
                <td className = "release-criteria-cell"><input
                        value={this.state.target}
                        type='float'
                        className='centered-item release-criteria-edit'
                        onChange={this.onChangeTarget}
                        onKeyPress={this.handleEnter}
                        onKeyDown = {this.onKeyDown}/></td>
                <td className = "release-criteria-cell"><input
                        value={this.state.max}
                        type='float'
                        className='centered-item release-criteria-edit'
                        onChange={this.onChangeMax}
                        onKeyPress={this.handleEnter}
                        onKeyDown = {this.onKeyDown}/></td>
                
            </tr>
            )
        }

        return(
            
            <tr className = "batchSheetRow" ref = {node => this.node = node}>
                {this.props.deleting?
                <td>
                    <input type = "checkbox" checked = {this.state.deleting} value = {this.state.deleting} onChange = {this.onToggleCheckbox}/>
                </td>:null}
                <td className = "release-criteria-cell">{releaseCriteria.name}</td>
                <td className = "release-criteria-cell">{releaseCriteria.min_tolerance}</td>
                <td className = "release-criteria-cell">{releaseCriteria.target}</td>
                <td className = "release-criteria-cell">{releaseCriteria.max_tolerance}</td>
                
            </tr>
        )
    }
}

export default ReleaseCriteriaRow;