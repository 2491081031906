import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import SentToDictionary from './SentToDictionary';
import SentToOrg from './SentToOrg';
class SentToFilter extends React.Component{
  constructor(){
    super();
    this.state={
      allContacts:[],
      letter:'',
      currentContacts:[],
    }
  }

  setContacts=(contacts)=>{
    this.setState({letter:contacts[0],currentContacts:contacts[1]})
  }
  componentDidMount(){
    this.getOrganizations();
  }
  getOrganizations = ()=>{
    let onlyConfirmedString = '';
    if (this.props.onlyConfirmed){
      onlyConfirmedString = '?only_confirmed=true'
    }
    Request.authRequest(Routes.organization.info(onlyConfirmedString),
                        'GET'
                      ).then((response) => {
                        if (response.status===200){
                        response.json().then(body => {
                          let currentContacts = [];
                          if (body.length>0){
                            currentContacts = body.slice(0,10);
                          }
                            this.setState({allContacts:body,
                               currentContacts: currentContacts});
                          });
                        } else {
                          response.json().then((error) => {
                            toast.error(error.message);
                          });
                        }
                  }).catch((error) => {
                    toast.error(error.message);
                  }
                )
  }
  setOrg = (org)=>{
    this.props.setSelectedOrg(org,'orgName');
  }
  onClearSelectedLetter = ()=>{
    this.setState({currentContacts:this.state.allContacts.slice(0,10), letter:''})
  }
  clearSelectedOrg=()=>{
    this.setOrg('');
  }
  render(){
    let currentContacts;
    if (this.state.currentContacts.length>0){
      currentContacts = this.state.currentContacts.map((entity,i)=>{
        return <SentToOrg org = {entity} key ={i} setOrg = {this.setOrg}/>
      })
    }
    let currentSelected = "None";
    if (this.props.sentTo){
      currentSelected = this.props.sentTo;
    }
  return(
    <div className = "sentToFilter-container">

      <div className = "expirationSearchManagementButtons">
        <div className = "noselect">Filter by Sent To</div>
        <div onClick = {this.clearSelectedOrg} className = "expirationSearch-label"> Clear</div>

      </div>
      <div>Currently Selected: {currentSelected}</div>
      <SentToDictionary setContacts = {this.setContacts}
        letter = {this.state.letter} contacts = {this.state.allContacts}
        onClearSelectedLetter = {this.onClearSelectedLetter}/>
      {currentContacts}
    </div>
  )
}
}
export default SentToFilter;
