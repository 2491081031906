import React from 'react';
import RemindDocumentAccept from '../RemindDocumentAccept';
class DocumentReportSentTo extends React.Component{
  render(){
    let sentTo = this.props.sentTo;
    let statusDotColor;
    let status = "Pending";
    let reminderButton;
    if (sentTo.status ==="Accepted"){
      statusDotColor = {backgroundColor: "#18A54D"};
      status = "Accepted"
    } else if (sentTo.status ==="Rejected"){
      statusDotColor = {backgroundColor:"#E65531"};
      status = "Rejected";
    }
    else {
      if (!sentTo.name.indexOf('@')>-1 && !sentTo.name.indexOf('.') >-1){
      reminderButton = <RemindDocumentAccept receiver = {this.props.sentTo}
        doc = {this.props.doc}/>
    }
      statusDotColor = {backgroundColor:"#FFB50B"}
    }
    let date;
    date = new Date(sentTo.date).toDateString();
    let approved_date;
    if (sentTo.date_approved){
      approved_date = new Date(sentTo.date_approved).toDateString();
    }
    let only_as_pdf = sentTo.only_as_pdf;
    let reminderCounter;
    if (sentTo.reminder_counter && sentTo.reminder_counter>0){
      reminderCounter = <div className = "reminderCounter-text">Reminded {sentTo.reminder_counter} times</div>
    }
    return(
      <div className = "versionReport-signature">
      <div className = "versionReport-signatureComponent">
      <div className = "signature-signatureInfo">
        <div>{sentTo.name}
        </div>
        <div className = "sentTo-date">
          Sent: {date} {only_as_pdf?'- PDF Only':null}
        </div>
      </div>
      <div className = "signature-componentRightSide">
      <div className = "signature-signatureStatus">
      <div className = "statusDot" style = {statusDotColor}></div>
        {status}
      </div>
      <div className = "reminderButton-container"> {reminderButton}
      {reminderCounter}</div>
      {approved_date}
      </div>
    </div>

  </div>)
  }
}
export default DocumentReportSentTo;
