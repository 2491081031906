import React from 'react';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';

import { toast } from 'react-toastify';
import CurrentCardComponent from './CurrentCardComponent';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import PaymentModal from './PaymentModal';
import './Stripe.css';
class BillingComponent extends React.Component {
  constructor(props){
    super(props);
    this.state={
      inputNewCard:false,
      currentCard:'',
    }
    this.getCurrentCard = this.getCurrentCard.bind(this);
    this.onSaveCard = this.onSaveCard.bind(this);
    this.onCancelCardInput = this.onHideModal.bind(this);
  }
  componentDidMount(){
    this.getCurrentCard();
  }
  onShowModal=()=>{
    this.setState({inputNewCard:true})
  }
  onHideModal=()=>{
    this.setState({inputNewCard: false
    })
  }
  getCurrentCard=()=>{
      Request.authRequest(Routes.payments.edit,'GET').then((response) => {
          if (response.status ===200){
          response.json().then(body => {
            this.setState({currentCard:body})
          })
        } else if (response.status ===400){
          response.json().then(body => {
            this.setState({currentCard:''})
          })

        }
      }
        )
      }

  onSaveCard=()=>{
    this.onHideModal();
    this.getCurrentCard();
  }
  render() {
    let currentCard;
    let inputNewCard;
    let daysLeft;
    if (this.state.currentCard){
      daysLeft = this.state.currentCard.DaysLeft;
    }
    if (this.state.currentCard && this.state.currentCard.Type){
      return(
        <div>
          <CurrentCardComponent
            card={this.state.currentCard}
            onDeleteCard = {this.getCurrentCard}
            />
        </div>
      )
    }

    let apiKey = 'pk_test_0qww08th9PjFPWfl04HpdXRj';
    const hostname = window && window.location && window.location.hostname;
      if (hostname === 'ivydocuments.com') {
        apiKey = "pk_live_8Qm4DrWCJXdR0iWoIyjW6C1q";
      }
    if (this.state.inputNewCard===true){
      inputNewCard = (
        <PaymentModal show={this.state.inputNewCard}
          onClose={this.onHideModal} title='Add Payment Information'
           >
        <StripeProvider apiKey={apiKey}>

            <Elements>
              <CheckoutForm onSaveCard={this.onSaveCard}
                onCancel = {this.onCancelCardInput}/>
            </Elements>
        </StripeProvider>
      </PaymentModal>
      )
    }

    return (
      <div> <div>No Payment Information Entered.</div>
        <div>
        {this.state.inputNewCard? inputNewCard
           :<button onClick = {this.onShowModal}
            className = "addPaymentButton">Upgrade To Premium!</button>}</div>
      </div>


    );
  }
}

export default BillingComponent;
