import React from 'react';
import moment from 'moment';
import TimelineItem from './TimelineItem';
import './Timeline.css';
class Timeline extends React.Component {
  render() {
    let docs = this.props.docs;
    if (!docs || docs.length === 0) {
      return (null)
    }
    let expiringDocs = docs.filter(doc => doc.date_expiration);
    let nonExpiringDocs = docs.filter(doc => !doc.date_expiration);
    let nonExpiringNames = 'None';
    if (nonExpiringDocs.length>0){
      nonExpiringNames = nonExpiringDocs.map((doc, i)=> {
      return <div className = "timelineItem-name">{doc.name}</div>})
    }
    let expiredDocs = expiringDocs.filter(doc => moment(doc.date_expiration) < moment());
    let futureDocs = expiringDocs.filter(doc => moment(doc.date_expiration) > moment().add(1, 'Y').subtract(1,'M'));
    let futureExpiringNames = 'None';
    if (futureDocs.length>0){
      futureExpiringNames = futureDocs.map((doc, i)=> {
      return (<div className = "timelineItem-name" key= {i}>
        <div className="timelineItem-name">{doc.name}</div>
        <div className = "timelineItem-date"> {moment(doc.date_expiration).format('ll')}</div>
      </div>)})
    }
    let currentlyExpiringDocs = expiringDocs.filter(doc => (moment(doc.date_expiration) < moment().add(1, 'Y').subtract(1,'M'))
        && (moment(doc.date_expiration) > moment()));

    let sortedDocs = currentlyExpiringDocs.sort(function(a, b) {
      return moment(a.date_expiration) - moment(b.date_expiration)
    })
    let currentTime = moment();
    let dict = sortedDocs.reduce((accumulator, currentValue) => {
      // c[0] is the month of the array
      let month = moment(currentValue.date_expiration).month();
      // either push to an existing dict entry or create one
      if (accumulator[month] !== undefined) {
        accumulator[month].push(currentValue)
      } else {
        accumulator[month] = [currentValue]
      }
      return accumulator
    }, {})
    let flattenedDict = Object.entries(dict);
    // flatten the dictionary to an array
    let currentMonth = moment().month();
    let firstMonthIndex = 0;
    for (let i = 0; i < flattenedDict.length; i++) {
      if (flattenedDict[i][0] >= currentMonth){
        firstMonthIndex = i;
        break
      }
    }
    let resortedMonthDocArray = flattenedDict;
    if (firstMonthIndex){
      resortedMonthDocArray = flattenedDict.slice(firstMonthIndex).concat(flattenedDict.slice(0,firstMonthIndex))
    }
    let topRow = [];
    let bottomRow = [];
    let width = 100/resortedMonthDocArray.length;
    for (let i = 0; i < resortedMonthDocArray.length; i++) {
      if (i % 2 ===0) {
        topRow.push(resortedMonthDocArray[i]);
      } else {
        bottomRow.push(resortedMonthDocArray[i])
      }
    }
    let hiddenSpacer;
    if (resortedMonthDocArray.length>0){
    topRow = topRow.map((monthDoc, i) => {
      return <TimelineItem monthDocs={monthDoc} now={currentTime} key={i} topRow="topRow" width = {width}/>
    })
    bottomRow = bottomRow.map((monthDoc, i) => {
      return <TimelineItem monthDocs={monthDoc} now={currentTime} key={i} width = {width}/>
    })
    let longestIndex = 0;
    let longestLength = 0;
    for (let i = 0; i < resortedMonthDocArray.length; i++){
      let length = resortedMonthDocArray[i][1].length;
      if (longestLength < length){
        longestIndex = i;
        longestLength = length;
      }
    }
    let longestArray = resortedMonthDocArray[longestIndex];
      hiddenSpacer = <TimelineItem monthDocs={longestArray} now={currentTime} width = {width} hidden/>;
    }
    return (<div className="timeline-container">
      <div className="timeline-header">Expiration Timeline</div>
      <div className="timeline-timelineContainer">
        <div className="timeline-topRow">
          {topRow}
          {hiddenSpacer}
        </div>
        <div className="timeline-timeline"/>
        <div className="timeline-bottomRow">
          {bottomRow}
          {hiddenSpacer}
        </div>

      </div>
      <div className="timeline-OtherDocs">
        <div>
          <div>Nonexpiring Docs</div>
          <div>{nonExpiringNames}</div>
        </div>

        <div>
          <div>Expiring Further In the Future</div>
          <div>{futureExpiringNames}</div>
        </div>
      </div>
    </div>)
  }
}

export default Timeline;
