import React from 'react';
import ActiveTag from '../Tags/ActiveTag';
import './ActiveTagsNavigator.css';
import { DropTarget } from 'react-dnd';
const receiverTarget = {
  drop(props,monitor,component) {
    component.onDropCurrentTag(monitor.getItem());
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),

  };
}
// this is the same as activeTagsNavigator except for filtering on this.onchangetags
class ExcludedTagsNavigator extends React.Component{
  constructor(){
    super();
    this.onAddActiveTag=this.onAddActiveTag.bind(this);
    this.onRemoveTag=this.onRemoveTag.bind(this);
    this.onReplaceTag=this.onReplaceTag.bind(this);
    this.changeGetInfoAgain = this.changeGetInfoAgain.bind(this);
    this.state={
      topTags:[],
      searchQuery:'',
      page:1,
      has_next:true,
      getInfoAgain:false,
    }
  }


  changeGetInfoAgain=()=>{
    this.setState({getInfoAgain:!this.state.getInfoAgain})
  }
  onAddActiveTag = (tag)=>{
    if (Array.isArray(this.props.excludedTags)){
      this.props.onChangeExcludedTags([...this.props.excludedTags,tag]);
    }
    else {
      this.props.onChangeExcludedTags([this.props.excludedTags,tag])
    }
    if (this.props.activeTags.indexOf(tag)>-1){
      this.props.onChangeTags(this.props.activeTags.filter(tagnames => tagnames!=tag));
    }
  }
  onDropCurrentTag = (tag)=>{
    this.onAddActiveTag(tag.name)
  }
  onRemoveTag=(tag,index)=>{
    let newArray = this.props.excludedTags.concat();
    newArray.splice(index,1);
    this.props.onChangeExcludedTags(newArray);
  }
  onClearTags = ()=>{

      this.props.onChangeExcludedTags(null);

  }
  onReplaceTag=(tag,index)=>{

      let replacedTags= this.props.excludedTags.concat();
      replacedTags[index]= tag;
      this.props.onChangeExcludedTags(replacedTags);
      this.props.onLastSelected(tag);
    }

  render()
  {
    let activeTagList = [];
    if (this.props.excludedTags){
    activeTagList= this.props.excludedTags.map(
      (entity,i)=>{
        return <ActiveTag key={i}
          tag={entity}
          index={i}
          onAddActiveTag={this.onAddActiveTag}
          onRemoveTag={this.onRemoveTag}
          onReplaceTag={this.onReplaceTag}
          onLastSelected={this.props.onLastSelected}
          getInfoAgain = {this.state.getInfoAgain}
          onGetPinnedTags = {this.props.onGetPinnedTags}
          />
      }
    );
    }

    const {connectDropTarget} = this.props;
    return connectDropTarget(
      <div className = "activeTags-container boxShadow" id = "excludeTags-container">
            <div className= "sectionHeader noselect" id = "activeTags">
              <div className = "activeTagsHeader">

                  Excluded Tags
                <button className = "buttonClearTags" onClick = {this.onClearTags}>Clear Tags</button>
              </div>
            </div>
            <div className = "activeTagList-container">
              {activeTagList.length>0 ? activeTagList : <div className="helpText noselect activeTagsHelp">
                None Selected. Shift click or drag and drop tags.</div>}
            </div>
      </div>
  )
  }
}
export default DropTarget('tag', receiverTarget, collect)(ExcludedTagsNavigator);
