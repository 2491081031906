import React from 'react';
import './Signatures.css';
class UserComponent extends React.Component{
  constructor(props){
    super(props);
    this.state = {

    }
  }
  addUser = () =>{
    this.props.onAddUser(this.props.user)
  }
  render(){
    let user = this.props.user;
    return(<div onClick = {this.addUser} className = "signature-userComponentContainer">
      <div className = "signatures-userComponentContainer-names">{user.first_name} {user.last_name}</div>
      <div>{user.email}</div>
      </div>)
  }
}

export default UserComponent;
