import Filing from './automaticfiling.png';
import Expiration from './expiration.png';
import AnyAngle from './infofromanyangle.png';
import YourWay from './infoyourway.png';
import Duplicates from './noduplicates.png';
import Search from './search.png';
import Sharing from './sharing.png';
import Supplier from './suppliermanagement.png';
import Updates from './unifiedupdates.png';
export default {
 Filing,
 Expiration,
 AnyAngle,
 YourWay,
 Duplicates,
 Search,
 Sharing,
 Supplier,
 Updates
}
