import React from 'react';
import {Tag, Ingredient} from '../Icons/IconLibrary';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
import TagCleaner from '../../helpers/TagCleaner';
import TagsSearch from '../TagsSearch/TagsSearch';
import LoadingButton from '../Buttons/LoadingButton';
import IngredientTemplateContainer from '../Ingredients/IngredientTemplates/IngredientTemplateContainer';
class IngredientFromRecipeButton extends React.Component{
  constructor(props){
    super(props);
    this.onEnterTagName= this.onEnterTagName.bind(this);
    this.state={
      tagNames :'',
      showModal:false,
      loading:false,
      ingredientName:'',
      user_defined_id:'',
      tag_names:[],
      selected_template:'',
     
    }
  }
  onShowModal=()=>{
    this.setState({showModal:true});
  }
  onHideModal=()=>{
    this.setState({showModal: false
  })}
  
  onEnterTagName = (tags) => {
		this.setState({   
			tag_names: tags,
		});
	}
  handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onAddTag();
    }
  }
  onEscape = ()=>{
    return
  }
  onSelectTemplate = (template) =>{
    this.setState({selected_template:template})
  }
  onCreateIngredient=()=>{
    // if (!this.state.tagNames){
    //   toast.error('Please enter at least one tag. Empty tag names are not allowed.')
    //   return
    // }
    let trimmedTags = TagCleaner.parseTagArray(this.state.tag_names);
    if (trimmedTags.length === 0){
      toast.error('Please enter at least one tag. Empty tag names are not allowed.')
      return
    }
    let ingredient_input = {
      name:this.state.ingredientName.trim(),
      user_defined_id: this.state.user_defined_id.trim(),
      tag_names: trimmedTags,
    }
    if (this.state.selected_template){
      ingredient_input.template_id = this.state.selected_template.id
    }
    this.setState({loading:true});
    Request.authRequest(Routes.recipes.ingredientFromRecipe(this.props.recipe.id),
                          'POST',JSON.stringify(
                                    ingredient_input
                    			), 'application/json'
                        ).then((response) => {
                          if (response.status===200){
                          response.json().then(body => {
                            window.location.href= `/ingredients/${body.id}`;
                            });
                          } else {
                            response.json().then((error) => {
                              toast.error(error.message);
                            });
                          }
                    }).catch((error) => {
                      toast.error(error.message);
                    }
                  )
    
  }
  
  changeRecipeName = (e) =>{
      this.setState({ingredientName:e.target.value})
  }
  changeUserId = (e) =>{
    this.setState({user_defined_id:e.target.value})
    }
    onNavigateIngredient = () =>{
        window.location.href = '/ingredients/'+this.props.recipe.produced_ingredient.id
    }
  render(){
    if (this.props.recipe && this.props.recipe.produced_ingredient){
        return(
            <div>
                <div className='noselect'  onClick = {this.onShowModal}>
                <img src = {Ingredient} className = "signatureButton-text"
                    alt =  "Ingredient from Recipe" title = "Ingredient From Recipe"/>
            </div>
                <Modal show={this.state.showModal} onClose={this.onHideModal} title='Ingredient From Recipe' >
                    <div>This recipe produces the {this.props.recipe.produced_ingredient.name} ingredient.</div>
                    <button className= 'modal-button primary'>
                            <LoadingButton onClick = {this.onNavigateIngredient} 
                    text = 'Navigate To Ingredient' isLoading = {this.state.loading}/>
                    </button> 
                </Modal>
            </div>
        )
    }
    return (
      <div id = "buttonTag-container">
        <div className='noselect'  onClick = {this.onShowModal}>
        <img src = {Ingredient} className = "signatureButton-text"
            alt =  "Create Ingredient from Recipe" title = "Create Ingredient from Recipe"/>
        </div>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Create Ingredient From Recipe' >
            <div>
                    Create an ingredient as the product of this recipe that can be used in other recipes. Density will automatically update to match this recipe's final density.
                    Future versions of this recipe will spawn a related ingredient, and recipes the ingredient is used in will be versioned if locked.
                    </div>        
          <div className = "button-modalText">
            <div className = "ingredientCreate-container">
              <div className = "left-box-ingredientButton">
                <div>Enter a name for the new ingredient.
                </div>
                <div>
                <input value = {this.state.recipeName} onChange = {this.changeRecipeName}/>
                </div>
            <div>
                <div>Enter an id for the new ingredient (optional).</div>
                <div>
                    <input value = {this.state.user_defined_id} onChange = {this.changeUserId}/>
                </div>
            </div>
            <div>
                <div>
                Enter the name of the tags you'd like to apply, separated by commas.</div>
                <div>
                <TagsSearch onSendTags = {this.onEnterTagName}
                onEsc = {this.onEscape}/>
                </div>
            </div>
            </div>
            <IngredientTemplateContainer selectTemplate = {this.onSelectTemplate}/>
            </div>
          <button className= 'modal-button primary'>
                  <LoadingButton onClick = {this.onCreateIngredient} 
        text = 'Create' isLoading = {this.state.loading}/>
        </button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default IngredientFromRecipeButton;