import React from 'react';
import { Archive } from '../Icons/IconLibrary';
import './Buttons.css';
import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import { toast } from 'react-toastify';
import Modal from '../Modal/Modal';
class ArchiveButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    }
  }
  onShowModal = () => {
    if (this.props.docs.length === 0 && (this.props.activeBatchSheets && this.props.activeBatchSheets.length === 0)) {
      toast.error('Please select one or more documents.')
      return;
    }
    this.setState({ showModal: true })
  }
  onHideModal = () => {
    this.setState({
      showModal: false
    })
  }

  onArchive = () => {
    if (this.props.ingredients) {
      Promise.all(this.props.docs.map((file) => {
        let oldTagNames = file.tags.map((tag) => { return tag.name });
        return Request.authRequest(Routes.ingredients.tags(file.id),
          'PUT', JSON.stringify({
            tag_names: [...oldTagNames, 'Archive'],
          }), 'application/json'
        ).then((response) => {
          if (response.status === 200) {
            response.json().then(body => {
              this.props.onGetDocuments(this.props.tagName);
              this.setState({ showModal: false })
              toast.success('Successfully applied archived ingredient!')
            });
          } else {
            response.json().then((error) => {
              toast.error(error.message);

              this.setState({ loading: false });
            });
          }
        }).catch((error) => {
          toast.error(error.message);
          this.setState({ loading: false });
        }
        )
      }));
      return
    }
    let toastID = toast.info('Archiving documents...', { autoClose: false })
    Promise.all(this.props.docs.map((file) => {
      let oldTagNames = file.tags.map((tag) => { return tag.name });
      let id;
      if (file.document) {
        id = file.document.id;
      }
      if (file.recipe) {
        id = file.recipe.id;
      }
      if (file.batch_sheet_id) {
        id = file.batch_sheet_id
      }
      return Request.authRequest(Routes.documents.tag(id),
        'PUT', JSON.stringify({
          tag_names: [...oldTagNames, 'Archive'],
        }), 'application/json'
      )
    })).then((response) => {
      toast.update(toastID, { render: `Successfully archived documents`, type: toast.TYPE.SUCCESS, autoClose: 5000 });
      this.props.onGetDocuments();
      this.setState({ showModal: false })
    }).catch((error) => {
      this.setState({ error: error.message });
    });
    Promise.all(this.props.activeBatchSheets.map((file) => {
      let oldTagNames = file.tags.map((tag) => { return tag.name });
      let id;
      if (file.document) {
        id = file.document.id;
      }
      if (file.recipe) {
        id = file.recipe.id;
      }
      if (file.batch_sheet_id) {
        id = file.batch_sheet_id
      }
      return Request.authRequest(Routes.documents.tag(id),
        'PUT', JSON.stringify({
          tag_names: [...oldTagNames, 'Archive'],
        }), 'application/json'
      )
    })).then((response) => {
      toast.update(toastID, { render: `Successfully archived documents`, type: toast.TYPE.SUCCESS, autoClose: 5000 });
      this.props.onGetDocuments();
      this.setState({ showModal: false })
    }).catch((error) => {
      this.setState({ error: error.message });
    });
  }
  render() {
    let activeBatchSheetsNum = 0;
    if (this.props.activeBatchSheets) {
      activeBatchSheetsNum = this.props.activeBatchSheets.length
    }
    return (
      <div id="buttonTag-container">
        <button className='headerButton noselect' id="buttonArchive" onClick={this.onShowModal}>
          <img className='buttonIcon' src={Archive} alt='Archive currently selected documents' title='Archive currently selected documents' />
        </button>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Archive Documents' >

          {this.props.docs.length > 0 || (this.props.activeBatchSheets && this.props.activeBatchSheets.length > 0) ?
            <div className="button-modalText">
              You currently have {this.props.docs.length + activeBatchSheetsNum} documents selected.<br />

              <button className='modal-button primary' onClick={this.onArchive}> Archive All </button>
            </div>
            :
            null}
        </Modal>
      </div>
    )
  }
}
export default ArchiveButton;
