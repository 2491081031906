import React from 'react';
import {Document,DocumentExpiring,DocumentExpired,
  DocumentRejected,Template,Message,DocumentAccepted,DocumentReceived,Signature} from '../Icons/IconLibrary';
import './NotificationsRow.css';

import Routes from '../../helpers/Routes';
import Request from '../../helpers/Request';
import {toast} from 'react-toastify';
class NotificationsRow extends React.Component{
  setNotificationRead = () =>{
    if (this.props.notification.read_status===true){
      let notificationLink = this.getNotificationLink(this.props.notification.category);

      window.location.href = notificationLink;
      return
    }
    Request.authRequest(Routes.notifications.read, 'POST', JSON.stringify({ids:[this.props.notification.id]}),
    'application/json').then(
          (response) => {
            if(response.ok){
              let notificationLink = this.getNotificationLink(this.props.notification.category);

              window.location.href = notificationLink;
              return
        } else {
          response.json().then((error) => {
            toast.error(error.message);

          });
        }
    }).catch((error) => {
    toast.error(error.message);
    }
    )
    }
  getNotificationIcon(category){
    let notificationIcon;
    switch(category){
      case 'DOCUMENT_RECEIVED':
      case 'DOCUMENT_UPDATED':
      case 'DOCUMENT_RECEIVED_INTERNAL':
        notificationIcon = DocumentReceived;
        break;
      case 'MESSAGE':
      case 'MESSAGE_AND_DOCUMENT':
      case 'MESSAGE_AND_DOCUMENT_INTERNAL':
        notificationIcon = Document;
        break;
      case 'DOCUMENT_EXPIRED':
      case 'DOCUMENT_EXPIRING':
        notificationIcon = DocumentExpired;
        break;
      case 'SENT_DOCUMENT_EXPIRING':
        notificationIcon = DocumentExpiring;
        break;
      case 'DOCUMENT_REJECTED':
        notificationIcon = DocumentRejected;
        break;
      case 'RECEIVED_REQUEST':
      case 'RECEIVED_TEMPLATE_DUE':
      case 'SENT_TEMPLATE_DUE':
        notificationIcon = Template;
        break;
      case `MESSAGE_RECEIVED`:
        notificationIcon = Message;
        break;
      case `DOCUMENT_ACCEPTED`:
        notificationIcon = DocumentAccepted;
        break;
      case `SIGNATURE`:
        notificationIcon = Signature;
        break;
      default:
        notificationIcon = null;
        break;
    }
    return notificationIcon;
  }
  getNotificationType(category){
    let notificationType;
    switch(category){
      case 'DOCUMENT_RECEIVED':
      case 'DOCUMENT_RECEIVED_INTERNAL':
        notificationType ='New Document Received';
        break;
      case 'MESSAGE':
      case 'MESSAGE_AND_DOCUMENT':
      case 'MESSAGE_AND_DOCUMENT_INTERNAL':
        notificationType = 'New Message and Document Received';
        break;
      case 'DOCUMENT_EXPIRED':
        notificationType = 'Document Expired';
        break;
      case 'DOCUMENT_EXPIRING':
        notificationType = 'Document Expiring';
        break;
      case 'DOCUMENT_UPDATED':
        notificationType = 'Document Updated';
        break;
      case 'SENT_DOCUMENT_EXPIRING':
        notificationType = 'Document Expiring Soon';
        break;
      case 'SIGNATURE':
        notificationType = 'Document Needs Approval';
        break;
      case 'DOCUMENT_REJECTED':
        notificationType = 'Rejected Document';
        break;
      case 'RECEIVED_REQUEST':
        notificationType = 'New Request for Documents';
        break;
        case `MESSAGE_RECEIVED`:
        notificationType = 'New Message Received';
        break;
      case `DOCUMENT_ACCEPTED`:
        notificationType = 'Document Accepted';
        break;
      case 'SENT_TEMPLATE_DUE':
        notificationType = 'Sent Document Request is Overdue';
        break;
      case 'RECEIVED_TEMPLATE_DUE':
        notificationType = 'Received Document Request is Overdue';
        break;
      default:
        notificationType = null;
        break;
    }
    return notificationType;
  }
  getNotificationLink(category){
    let notificationLink;
    let notification = this.props.notification;

    const searchString = {
      request: null,
      template: null,
      document: null,
    };
    if (notification.request_id){
      searchString.request = notification.request_id}
    if (notification.template_id){
      searchString.template = notification.template_id}
    if (notification.document_id){
      searchString.document = notification.document_id}

    switch(category){
      case 'DOCUMENT_RECEIVED':
      case 'MESSAGE':
      case 'MESSAGE_AND_DOCUMENT':
        if (notification.template_id){
        notificationLink = `/sendreceive/templates/${notification.template_id}/${notification.request_id}`}
        else{
          notificationLink = '/sendreceive/pending/?document='+notification.document_id;
        }
        break;
      case 'SIGNATURE':
      case 'DOCUMENT_EXPIRED':
      case 'SENT_DOCUMENT_EXPIRING':
      case 'DOCUMENT_EXPIRING':
      case 'DOCUMENT_UPDATED':
      case 'MESSAGE_AND_DOCUMENT_INTERNAL':
      case 'DOCUMENT_RECEIVED_INTERNAL':
        notificationLink = '/documents/'+notification.document_id;
        break;
      case 'DOCUMENT_REJECTED':
      case `DOCUMENT_ACCEPTED`:
        if (notification.request_id){
        notificationLink = `/sendreceive/requests/?request=${notification.request_id}`;}
        else {
          notificationLink = '/documents/'+notification.document_id;
        }
        break;
      case 'MESSAGE_RECEIVED':
        if (notification.template_id){
        notificationLink = `/sendreceive/templates/${notification.template_id}/${notification.request_id}`}
        else {
          notificationLink = `/sendreceive/requests?request=${notification.request_id}`;}
          break;
      case 'RECEIVED_REQUEST':
      case 'RECEIVED_TEMPLATE_DUE':
        notificationLink = `/sendreceive/requests?request=${notification.request_id}`;
        break;
      case 'SENT_TEMPLATE_DUE':
        notificationLink = `/sendreceive/templates/${notification.template_id}/${notification.request_id}`
        break;
      default:
        notificationLink = '/';
        break;
    }
    return notificationLink
  }
  async onClick(){
    await this.setNotificationRead();

  }
  render(){
    let notification = this.props.notification;
      const notificationIcon = this.getNotificationIcon(notification.category);
      let notificationType = this.getNotificationType(notification.category);
      let date = new Date(notification.date_created);
      let dateString;
      let description = notification.description;
      if (description.length > 200 && this.props.summary){
        description = description.substring(0,200);
      }
      dateString = date.toLocaleString();
      return (
        <div
          className={this.props.summary?'notification-item-summary':'notification-item'} onClick = {()=>this.onClick()}>
          <div className = "notification-dateIcon">
            <img className='notification-icon' src={notificationIcon} alt='' />

          </div>
          <div>
          <div className = "notification-row-header">
            <div className = "notification-row-name">{notificationType}</div>
            <div className = "notification-row-date">{dateString}</div>
            <div className = {notification.read_status?'notification-read':'notification-unread'}/>
          </div>
          <div className = "notification-description">{description}
          </div>
          </div>
        </div>
      );
    }
  }

export default NotificationsRow;
