import React from 'react';
import SentToFilter from './SentToFilter';
import ExpirationSearch from './ExpirationSearch';
import './AdvancedSearchComponents.css';
import MarkOriginalSender from '../../Documents/MarkOriginalSender';
class AdvancedSearchButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showAdvanced:false,
      highlightAdvanced:false,
      paid:false,
    }
  }

  showAdvanced = ()=>{
    this.setState({showAdvanced:true})
  }
  hideAdvanced=()=>{
    this.setState({showAdvanced:false})
  }
  toggleAdvanced = ()=>{
    this.setState({showAdvanced:!this.state.showAdvanced})
  }
  render(){
    let greenHighlight = false;
    if (this.props.sentTo || this.props.expiresAfter || this.props.expiresBefore){
      greenHighlight = true;
    }
    if (this.state.showAdvanced){
      return(
        <div className = "advancedSearchMenu-container">
          <div onClick = {this.toggleAdvanced}
            className = "advancedSearchButton-text" id = {greenHighlight?"selectedAdvancedSearch":null}> Advanced</div>
          <div className = "advancedSearchMenu boxShadow">
            {/* <div className = "advancedSearchMenu-left">
              <MarkOriginalSender docs = {this.props.docs} fetchDocuments = {this.props.getDocuments}/>
              <div className = "originalSenderSeparator"></div>
            <ExpirationSearch sendDate = {this.props.searchStringGenerator}
              expiresAfter = {this.props.expiresAfter} expiresBefore = {this.props.expiresBefore}/>
          </div> */}
            <SentToFilter setSelectedOrg = {this.props.searchStringGenerator} sentTo = {this.props.sentTo}/>
            <div onClick = {this.hideAdvanced} className = "button-closeAdvanced">x</div>
          </div>
        </div>
      )
    } else{
    return(
      <div className = "advancedSearchMenu-container">
        <div onClick = {this.toggleAdvanced}
          className = "advancedSearchButton-text" id = {greenHighlight?"selectedAdvancedSearch":null}>Advanced</div>

      </div>
    )
  }
  }
}

export default AdvancedSearchButton
