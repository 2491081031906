import React from 'react';
import './Signatures.css';
import Modal from '../../Modal/Modal';
import SetSignatureList from './SetSignatureList';
/* Use this for the version history and upload components. Use the regular signatureList
after a doc is uploaded*/
class SignatureButton extends React.Component{
  constructor(props){
    super(props);
    this.state={
      showModal:false,
      urgent:false,
    }
  }
  onShowModal = () =>{
    this.setState({showModal:true})
  }
  onHideModal=()=>{
    this.setState({showModal: false
  })}

  sendSignatures = (users,managerStatus)=>{
    if (managerStatus){
      this.props.onSendManagerSignatures(users);
    } else {
    this.props.onSendSignatures(users);
    }
    this.setState({showModal:false});
  }
  render(){
    return(
      <div>
      <div onClick = {this.onShowModal} className = "setSignaturesButton">Set Signatures</div>
        <Modal show={this.state.showModal} onClose={this.onHideModal} title='Signatures' >
          <SetSignatureList onCloseModal = {this.onHideModal}
            sendSignatures = {this.sendSignatures}/>

        </Modal>

      </div>
    )
  }
}

export default SignatureButton;
