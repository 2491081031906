import React from 'react';
import Routes from '../../../helpers/Routes';
import Request from '../../../helpers/Request';
import { toast } from 'react-toastify';
import qs from 'qs';
import LoadingButton from '../../Buttons/LoadingButton';
class Confirm extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      email:'',
      password: '',
      confirmCode : '',
      verifyPassword: '',
    }
  }
  onSubmitConfirm=(token)=>{
    
    if (this.state.password.length < 5){
      toast.error('Password must be at least 4 characters');
      return;
    }
    if (this.state.password === this.state.verifyPassword) {
      const searchString=qs.parse(this.props.location.search.substr(1));
      if (!searchString.token){
        toast.error('You are missing an confirmation token. Please retry the link in the confirmation email.');
        window.setTimeout(() =>{window.location.href = '/resend-confirm'}, 3000);
        return
      }
      let token = searchString.token;
      this.setState({loading:true});
    Request.authRequest(Routes.auth.confirm(token), 'PUT',
    JSON.stringify({password:this.state.password}),'application/json').then((response) => {
      if (response.status===200){
        response.json().then(body => {
          localStorage.setItem('loggedIn', 'True');
          Request.authRequest(Routes.profile.view,'GET').then((response) => {
              response.json().then(body => {
                if (searchString.organization){
                  window.location.href= `/organization/join?organization=${searchString.organization}`
                } else{
            			window.location.href= '/organization/create'
                }
              })
            })
            })
      }    else {
        response.json().then((error) => {
          this.setState({ error: error.message });
          if (response.status ===409){
            toast.error('User already exists. Redirecting...');
            window.setTimeout(() =>{window.location.href = '/'}, 3000);

          }
        });
      }
    }).catch((error) => {
      this.setState({ error: error.message , loading:false});
    })
  }
  else{
    this.setState({error:'Passwords do not match.'})
  }
}
onChangeVerifyPassword = (e) => {
  this.setState({
    verifyPassword: e.target.value,
    error: '',
  });
}
  onChangeEmail= (e) => {
		this.setState({
			email: e.target.value,
			error: '',
		});
	}
  onChangePassword= (e) => {
		this.setState({
			password: e.target.value,
			error: '',
		});
	}
	handleEnter = (e)=>{
    if (e.key ==='Enter'){
      this.onSubmitConfirm();
    }
  }
  render(){
    const searchString=qs.parse(this.props.location.search.substr(1));

      return (
      <div className='helpText-confirmation boxShadow'>
        <h3>Finish setting up your account by creating a password. If you have any issues, please send us an email at help@ivydocuments.com.</h3>
          <label className='input-label' htmlFor='password'> Password </label>

      <input type='password' onChange={this.onChangePassword} id='password' className='input-text'/>
				<label className='input-label' htmlFor='verifyPassword'> Verify Password </label>
				<input type='password' onChange={this.onChangeVerifyPassword} onKeyPress = {this.handleEnter} id='verifyPassword' className='input-text'/>
				{this.state.error}

        <button className = "form-button primary"> 
        <LoadingButton onClick = {this.onSubmitConfirm}
        text = 'Confirm' isLoading = {this.state.loading}/>
      
        </button>
      </div>);
    }

}

export default Confirm;
